import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  FormGroup,
  Input,
  Row,
} from "reactstrap";

import {
  Badges,
  DefaultButton,
  MUIIcons,
  H3,
  H4,
  SecondaryButton,
} from "../../../../../AbstractElements";
import { STATUS_200, WILL_BE_UPLOADED } from "../../../../../Api/constants";
import ButtonDropdown from "../../../../../CommonElements/ButtonDropdown";
import ChooseOptions from "../../../../../CommonElements/ChooseOptions";
import FileHandler from "../../../../../CommonElements/FileHandler";
import LabelTooltip from "../../../../../CommonElements/LabelTooltip";
import CommonModal from "../../../../../CommonElements/Modal";
import MultiFileUpload from "../../../../../CommonElements/MultiFileUpload/MultiFileUpload";
import QuestionNumberChooseButton from "../../../../../CommonElements/QuestionNumberChoose";
import { useAppDispatch } from "../../../../../ReduxToolkit/Hooks";
import {
  deleteModuleOrQb,
  updateQuestioninQb,
} from "../../../../../ReduxToolkit/Reducers/QuestionBankSlice";
import { uploadFileApi } from "../../../../../ReduxToolkit/Reducers/UserSlice";
import {
  AddQuestions,
  AddResponses,
  Choice,
  ClickHereToAddReferenceFile,
  Confirm,
  DeleteQuestionFromModule,
  DeleteQuestionHeader,
  Enter,
  Question,
  QBEnterQuestionsToolTipMessage,
  QuestionFileSizeNote,
  QuestionFillData,
  QuestionFillDataForMultiple,
  Save,
  QBQuestionReferenceFileToolTipMessage,
} from "../../../../../utils/Constant";
import {
  extractFileName,
  getQuestionsType,
  MAX_RESPONSE_COUNT,
  showToast,
} from "../../../../../utils/helper/helper";

const SingleAndMultiSelectQuestions = ({
  questionBankPayloadData,
  type,
  selectedModule,
  selectedQuestion,
  selectedQuestionType,
  getQuestionTypes,
  questionBankId,
  eachModuleQuestionMap,
  setEachModuleQuestionMap,
  getEachModuleQuestion,
  eachModuleQuestionNumberMap,
  getQuestionBankData,
  setSelectedQuestion,
}) => {
  const dispatch = useAppDispatch();
  const [flushData, setFlushData] = useState(false);
  const [questionName, setQuestionName] = useState("");
  const [mediaFile, setMediaFile] = useState<any>("");
  const [responses, setResponses] = useState<
    { option_text: string; is_correct: boolean }[]
  >(
    eachModuleQuestionMap[selectedQuestion]?.options || [
      { option_text: "", is_correct: false },
      { option_text: "", is_correct: false },
    ]
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setQuestionName("");
    setMediaFile("");
    setResponses([
      { option_text: "", is_correct: false },
      { option_text: "", is_correct: false },
    ]);
    setFlushData(true);
    setFlushData(false);
  }, [selectedQuestionType]);

  useEffect(() => {
    setQuestionName("");
    setMediaFile("");
    setResponses([
      { option_text: "", is_correct: false },
      { option_text: "", is_correct: false },
    ]);
    setFlushData(true);
    setFlushData(false);
    const module_id = selectedModule;
    if (
      module_id &&
      selectedQuestion &&
      eachModuleQuestionMap &&
      eachModuleQuestionMap[module_id] &&
      eachModuleQuestionMap[module_id][selectedQuestion]
    ) {
      setQuestionName(
        eachModuleQuestionMap[module_id][selectedQuestion]?.question_text || ""
      );
      setMediaFile(
        eachModuleQuestionMap[module_id][selectedQuestion]?.media_url || ""
      );
      if (eachModuleQuestionMap[module_id][selectedQuestion]?.options) {
        setResponses(
          eachModuleQuestionMap[module_id][selectedQuestion]?.options
        );
      } else {
        setResponses([
          { option_text: "", is_correct: false },
          { option_text: "", is_correct: false },
        ]);
      }
    }
  }, [
    questionBankPayloadData,
    eachModuleQuestionMap,
    selectedModule,
    selectedQuestion,
  ]);

  const handleResponsesChange = (
    index: number,
    newValue: string,
    isCorrect?: boolean
  ) => {
    const newResponses = responses.map((response, i) => {
      if (i === index) {
        return {
          ...response,
          option_text: newValue,
          is_correct: isCorrect !== undefined ? isCorrect : response.is_correct,
        };
      }
      return response;
    });
    setResponses(newResponses);
  };

  const handleDeleteResponse = (index: number) => {
    if (responses.length > 2) {
      const newResponses = responses.filter((_, i) => i !== index);
      setResponses(newResponses);
    }
  };

  const handleAddResponse = () => {
    if (responses.length < MAX_RESPONSE_COUNT) {
      const newResponses = [
        ...responses,
        { option_text: "", is_correct: false },
      ];
      setResponses(newResponses);
    }
  };

  const toggleCorrectResponse = (index: number) => {
    if (selectedQuestionType === "single_select") {
      const newResponses = responses.map((response, i) => ({
        ...response,
        is_correct: i === index,
      }));
      setResponses(newResponses);
    } else {
      const currentCorrectCount = responses.filter(r => r.is_correct).length;
      const maxCorrectResponses =
        selectedQuestionType === "match_the_following" ? 1 : MAX_RESPONSE_COUNT;

      if (responses[index].is_correct) {
        handleResponsesChange(index, responses[index].option_text, false);
      } else if (currentCorrectCount < maxCorrectResponses) {
        handleResponsesChange(index, responses[index].option_text, true);
      }
    }
  };

  const toggleModal = () => {
    if (!isModalOpen) {
      setFlushData(false);
    }
    setIsModalOpen(!isModalOpen);
  };

  const uploadFile = async documents => {
    if (documents?.length > 0) {
      const postData = new FormData();
      documents?.forEach(file => {
        postData.append("files", file[0]);
      });
      const response = await dispatch(uploadFileApi({ content: postData }));
      return response?.payload?.data?.file_urls;
    }
  };

  return (
    <div className="mb-5">
      <CommonModal
        sizeTitle={DeleteQuestionHeader}
        modalBodyClassName=""
        isOpen={isModalOpen}
        toggle={toggleModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={async () => {
          let deleted = null;
          const module_id = selectedModule;
          if (eachModuleQuestionMap[module_id][selectedQuestion]?.id) {
            const deleteObj = {
              type: "question",
              id: [eachModuleQuestionMap[module_id][selectedQuestion]?.id],
            };

            deleted = await dispatch(deleteModuleOrQb({ data: deleteObj }));
            if (deleted?.payload?.status_code === STATUS_200) {
              const deleteObj = { ...eachModuleQuestionMap };
              delete deleteObj[module_id][selectedQuestion].id;
              setEachModuleQuestionMap(deleteObj);

              if (deleted?.payload?.message) {
                showToast(deleted?.payload?.message, "success");
              }
            }
            getQuestionTypes(selectedQuestionType);
            await getQuestionBankData(questionBankId);
          } else {
            setQuestionName("");
            setMediaFile("");
            setResponses([
              { option_text: "", is_correct: false },
              { option_text: "", is_correct: false },
            ]);
            if (!flushData) setFlushData(true);
          }
          toggleModal();
        }}
        primaryBtnText={Confirm}
      >
        {DeleteQuestionFromModule}
      </CommonModal>
      <Card>
        <CardBody className="m-2 mt-3">
          <div className="d-flex justify-content-between">
            <div className="d-flex gap-2 mt-1">
              <div className="choose-option">
                <H4 className="title">
                  {Question}{" "}
                  {selectedQuestion &&
                  eachModuleQuestionMap &&
                  selectedModule &&
                  eachModuleQuestionMap[selectedModule]
                    ? Object.keys(eachModuleQuestionMap[selectedModule])
                        ?.map(Number)
                        ?.indexOf(selectedQuestion) + 1
                    : eachModuleQuestionMap &&
                        selectedModule &&
                        eachModuleQuestionNumberMap[selectedModule]
                      ? eachModuleQuestionNumberMap[selectedModule] + 1
                      : 1}
                </H4>
              </div>
              {type ? <span>{type}</span> : null}
            </div>
            <div className="d-flex gap-4">
              {questionBankPayloadData?.is_competency ? (
                <DefaultButton
                  color="primary"
                  onClick={() => getQuestionTypes("open_text")}
                >
                  {AddQuestions}
                </DefaultButton>
              ) : (
                <ButtonDropdown
                  items={getQuestionsType(getQuestionTypes, selectedModule)}
                  btnText={AddQuestions}
                  direction="down"
                />
              )}
              <MUIIcons
                className="pointer primary-icon-color mt-2"
                iconName="DeleteOutlineOutlined"
                size={18}
                onClick={toggleModal}
              />
            </div>
          </div>

          <FormGroup className="mt-2">
            <LabelTooltip
              label={Question}
              tooltipText={QBEnterQuestionsToolTipMessage}
              important
            />
            <Input
              rows={4}
              name="question"
              type="text"
              value={questionName}
              placeholder={`${Enter} ${Question}`}
              maxLength={4000}
              onChange={e => setQuestionName(e.target.value)}
            />
          </FormGroup>

          {typeof mediaFile === "string" &&
          mediaFile !== "" &&
          extractFileName(mediaFile) !== WILL_BE_UPLOADED ? (
            <H3>
              <Badges className="pointer" color="light text-dark">
                <div className="d-flex gap-2 ">
                  <MUIIcons size={24} iconName="InsertDriveFileOutlined" />
                  <FileHandler mediaUrl={mediaFile} />
                  <MUIIcons
                    iconName="DeleteOutlineOutlined"
                    className="primary-icon-color pointer"
                    size={24}
                    onClick={() => {
                      setMediaFile([]);
                    }}
                  />
                </div>
              </Badges>
            </H3>
          ) : (
            <>
              <LabelTooltip
                label={ClickHereToAddReferenceFile}
                tooltipText={QBQuestionReferenceFileToolTipMessage}
                important={false}
              />
              <div className="text-danger">{QuestionFileSizeNote}</div>
              <MultiFileUpload
                flushData={flushData}
                accept=".pdf,.docx,.mp4,.mp3,.png,.jpg,.jpeg"
                multiple={false}
                onFileChange={(file, status) => {
                  if (status === "removed") {
                    if (mediaFile)
                      setMediaFile(prevDocuments =>
                        prevDocuments.filter(item => item[0].name !== file.name)
                      );
                  } else if (status === "done" && file) {
                    setMediaFile(prevDocuments => [
                      ...(Array.isArray(prevDocuments) ? prevDocuments : []),
                      [file],
                    ]);
                  }
                }}
              />
            </>
          )}
          <div className="choose-option">
            <H4 className="mt-5 title">{Choice}</H4>
            {responses.map((response, index) => (
              <Row key={index}>
                <Col>
                  <ChooseOptions
                    key={index}
                    index={index}
                    value={response.option_text}
                    onChange={(i, newValue) =>
                      handleResponsesChange(i, newValue)
                    }
                    onDelete={i => handleDeleteResponse(i)}
                  />
                </Col>
                <Col lg={1} className="mt-4">
                  <QuestionNumberChooseButton
                    selectedQuestionType={selectedQuestionType}
                    number={index + 1}
                    colorName={
                      response.is_correct
                        ? "text-light bg-success"
                        : "text-dark outline-lightblue"
                    }
                    onCorrectResponse={() => toggleCorrectResponse(index)}
                  />
                </Col>
              </Row>
            ))}
            {responses.length < MAX_RESPONSE_COUNT && (
              <DefaultButton
                className="primary-icon-color"
                onClick={handleAddResponse}
              >
                {AddResponses}
              </DefaultButton>
            )}
          </div>
        </CardBody>
        <CardFooter className="float-end">
          <SecondaryButton
            onClick={async () => {
              const module_id = selectedModule;
              if (
                !questionName ||
                !responses.every(option => option.option_text.trim() !== "") ||
                !responses.some(option => option.is_correct)
              ) {
                showToast(QuestionFillData, "error");
                return;
              }
              if (
                selectedQuestionType === "multiple_choice" &&
                responses.filter(option => option.is_correct).length < 2
              ) {
                showToast(QuestionFillDataForMultiple, "error");
                return;
              }

              let uploadedFile = "";
              if (
                mediaFile &&
                mediaFile !== "" &&
                typeof mediaFile !== "string"
              )
                uploadedFile = await uploadFile(mediaFile);

              const updatedModuleId: any =
                questionBankPayloadData?.modules?.filter(
                  item => item?.id === selectedModule
                );

              const questionPayload = {
                question_bank_id: questionBankId,
                modules: [
                  {
                    id: module_id,
                    module_name: updatedModuleId?.name,
                    questions: [
                      {
                        ...(selectedQuestion && { id: selectedQuestion }),
                        question_text: questionName,
                        question_type: selectedQuestionType,
                        media_url:
                          uploadedFile?.[0] ||
                          (typeof mediaFile === "string" && mediaFile) ||
                          "",
                        options: responses,
                      },
                    ],
                  },
                ],
              };
              setFlushData(true);
              const res = await dispatch(
                updateQuestioninQb({ questions: questionPayload })
              );
              if (
                res?.payload?.status_code === STATUS_200 &&
                res?.payload?.message
              ) {
                showToast(res?.payload?.message, "success");
              }
              await setSelectedQuestion(null);
              await getQuestionBankData(questionBankId);
              await getEachModuleQuestion(module_id, false, true);
              setQuestionName("");
              setMediaFile("");
              setResponses([
                { option_text: "", is_correct: false },
                { option_text: "", is_correct: false },
              ]);
              setFlushData(false);
              setFlushData(true);
            }}
          >
            {Save}
          </SecondaryButton>
        </CardFooter>
      </Card>
    </div>
  );
};

SingleAndMultiSelectQuestions.propTypes = {
  questionBankPayloadData: PropTypes.shape({
    modules: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        questions: PropTypes.arrayOf(
          PropTypes.shape({
            question_text: PropTypes.string,
            question_type: PropTypes.string,
            media_url: PropTypes.string,
            options: PropTypes.arrayOf(
              PropTypes.shape({
                option_text: PropTypes.string,
                is_correct: PropTypes.bool,
              })
            ),
          })
        ),
      })
    ),
    is_competency: PropTypes.bool,
  }).isRequired,
  type: PropTypes.string.isRequired,
  selectedModule: PropTypes.number.isRequired,
  selectedQuestion: PropTypes.number.isRequired,
  selectedQuestionType: PropTypes.string.isRequired,
  getQuestionTypes: PropTypes.func.isRequired,
  eachModuleQuestionMap: PropTypes.object,
  setEachModuleQuestionMap: PropTypes.func,
  eachModuleQuestionNumberMap: PropTypes.object,
  questionBankId: PropTypes.number,
  getEachModuleQuestion: PropTypes.func.isRequired,
  getQuestionBankData: PropTypes.func,
  setSelectedQuestion: PropTypes.func,
};

export default SingleAndMultiSelectQuestions;
