import PropTypes from "prop-types";

import "./style.scss";
import { PassDescription, YouPassed } from "../../utils/Constant";
import { roundToNearestInteger } from "../../utils/helper/helper";
import useIsMobile from "../../utils/helper/responsive";
import P from "../Paragraph";

const PassScoreCard = ({ score, attemptOn }) => {
  const isMobile = useIsMobile();
  const roundedScore = roundToNearestInteger(score);

  return (
    <div className={`score-card ${isMobile ? "score-card-m" : ""}`}>
      <div
        className={`score-card__attempted-on ${isMobile ? "score-card__attempted-on-m" : ""}`}
      >
        <P>{attemptOn}</P>
      </div>
      <div className="score-card__content">
        <div className="text-center">
          <div className="text-white">Your Score</div>
          <div className="score-card__score">{`${roundedScore}%`}</div>
        </div>
        <div>
          <div className="score-card__message">{YouPassed}</div>
          <div className="score-card__details">{PassDescription}</div>
        </div>
      </div>
    </div>
  );
};

PassScoreCard.propTypes = {
  score: PropTypes.string,
  attemptOn: PropTypes.string,
};

export default PassScoreCard;
