import React, { useEffect, useState } from "react";
import { Row, Col, FormGroup, Input, Card, CardBody } from "reactstrap";

import {
  AdditionalInformation,
  Available,
  CourseName,
  Days,
  From,
  HappeningAt,
  MonthlyTotals,
  monthNamesLong,
  Room,
  SelectMonth,
  SelectYear,
  To,
  Trainer,
  Utilized,
} from "../../utils/Constant";
import {
  getFormattedDays,
  getOrdinalSuffix,
  hardCodedRoomCalendar,
  hardCodedTrainerCalendar,
} from "../../utils/helper/helper";
import { CheckAvailabilityProps } from "../../utils/helper/propTypes";
import DetailHeader from "../DetailHeader";
import LabelTooltip from "../LabelTooltip";
import CommonModal from "../Modal";

import RoundedCard from "./RoundedCard";

const CalendarAvailability: React.FC<CheckAvailabilityProps> = ({
  locationId,
  checkingFor,
}) => {
  const [calendarData, setCalendarData] = useState(null);
  const [selectedYear, setSelectedYear] = useState<number>(
    new Date().getFullYear()
  );
  const [selectedMonth, setSelectedMonth] = useState<string>(
    new Date().toLocaleString("en-US", { month: "long" })
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clickedBooking, setClickedBooking] = useState(null);
  const [bookings, setBookings] = useState([]);
  const [days, setDays] = useState([]);
  const years = Array.from(
    { length: 11 },
    (_, i) => new Date().getFullYear() - 5 + i
  );

  useEffect(() => {
    if (checkingFor === "trainer") {
      // Checking Availability for trainer
      console.error(checkingFor);
      setCalendarData(hardCodedTrainerCalendar);
      setBookings(
        hardCodedTrainerCalendar?.monthBookings[
          `${selectedYear}-${selectedMonth}`
        ] || []
      );
    } else if (checkingFor === "classroom") {
      // Checking Availability for classroom
      console.error(locationId);
      setCalendarData(hardCodedRoomCalendar);
      setBookings(
        hardCodedRoomCalendar?.monthBookings[
          `${selectedYear}-${selectedMonth}`
        ] || []
      );
    }

    setDays(getFormattedDays(selectedYear, selectedMonth, monthNamesLong));
  }, [selectedYear, selectedMonth]);

  const handleCardClick = (booking: any) => {
    setClickedBooking(booking);
    toggleModal();
  };
  const toggleModal = () => {
    if (isModalOpen) {
      setIsModalOpen(false);
    } else {
      setIsModalOpen(true);
    }
  };

  const getModalTitle = () => {
    return AdditionalInformation;
  };

  const renderModalBody = () => {
    return (
      <>
        <Card className="p-2 border border-grey">
          <CardBody>
            <div className="mt-1">
              <Row>
                <Col>
                  <DetailHeader
                    assign={CourseName}
                    name={clickedBooking?.rowLabel}
                  />
                </Col>
                <Col>
                  <DetailHeader
                    assign={checkingFor === "classroom" ? HappeningAt : Trainer}
                    name={clickedBooking?.rowItem}
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <DetailHeader
                    assign={From}
                    name={`${clickedBooking?.start} ${getOrdinalSuffix(clickedBooking?.start)} ${clickedBooking?.month}`}
                  />
                </Col>
                <Col>
                  <DetailHeader
                    assign={To}
                    name={`${clickedBooking?.end} ${getOrdinalSuffix(clickedBooking?.end)} ${clickedBooking?.month}`}
                  />
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </>
    );
  };
  return (
    <div className="learning-assessment overflow-x-auto px-4">
      <div className="pb-5">
        <CommonModal
          sizeTitle={getModalTitle()}
          modalBodyClassName=""
          isOpen={isModalOpen}
          toggle={toggleModal}
          backdrop="static"
          size="lg"
          showFooter={false}
        >
          {renderModalBody()}
        </CommonModal>
      </div>

      <div className="mb-4 flex gap-4">
        <Row>
          <Col lg="3">
            <FormGroup>
              <LabelTooltip
                label={SelectYear}
                tooltipText={SelectYear}
                important={true}
              />
              <Input
                name="select_year"
                type="select"
                value={selectedYear}
                onChange={(e: { target: { value: any } }) =>
                  setSelectedYear(Number(e.target.value))
                }
              >
                {years?.map(option => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <LabelTooltip
                label={SelectMonth}
                tooltipText={SelectMonth}
                important={true}
              />
              <Input
                name="select_month"
                type="select"
                value={selectedMonth}
                onChange={(e: { target: { value: any } }) =>
                  setSelectedMonth(e.target.value)
                }
              >
                {monthNamesLong?.map(option => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
        </Row>
      </div>
      <table className="border-collapse border">
        <thead>
          <tr>
            <th className="custom-table-cell">
              {checkingFor === "classroom" ? Room : Trainer}
            </th>
            {days.map(day => (
              <th
                key={day}
                className={`custom-table-cell ${day.includes("Sun") ? "primary-bg-color" : ""}`}
              >
                {day}
              </th>
            ))}
            <th className="custom-table-cell">{MonthlyTotals}</th>
          </tr>
        </thead>
        <tbody>
          {calendarData?.rows?.map(rowItem => {
            const roomBookings = bookings.filter(b => b.rowItem === rowItem);
            const utilizedDays = new Set<number>();
            roomBookings.forEach(booking => {
              for (let d = booking.start; d <= booking.end; d++) {
                utilizedDays.add(d);
              }
            });
            return (
              <tr key={rowItem}>
                <td className="custom-table-cell">{rowItem}</td>
                {days.map((_, index) => {
                  const dayNumber = index + 1;
                  const overlappingBookings = roomBookings.filter(
                    b => dayNumber >= b.start && dayNumber <= b.end
                  );
                  return (
                    <td
                      key={dayNumber}
                      className={`custom-table-cell ${days[index].includes("Sun") ? "primary-bg-color" : ""}`}
                    >
                      {overlappingBookings.length > 0 && (
                        <div className="relative h-full flex flex-col space-y-1">
                          {overlappingBookings.map((booking, i) => (
                            <RoundedCard
                              key={i}
                              size="small"
                              onClick={() => {
                                handleCardClick(booking);
                              }}
                            >
                              {booking.rowLabel}
                            </RoundedCard>
                          ))}
                        </div>
                      )}
                    </td>
                  );
                })}
                <td className="custom-table-cell">
                  {`${Available} ${Days} : `} {days.length}
                  <br />
                  {`${Utilized} ${Days} : `} {utilizedDays.size} (
                  {((utilizedDays.size / days.length) * 100).toFixed(1)}%)
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CalendarAvailability;
