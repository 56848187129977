import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

import { H1, H2 } from "../../../AbstractElements";
import CourseSummary from "../../../CommonElements/CourseSummaryCard";
import MultiProgressBar from "../../../CommonElements/MultiProgressBar";
import TargetLevelCard from "../../../CommonElements/TargetLevelCard";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import {
  getMappedSgaList,
  getOverallStatistics,
  getUserOverviewData,
} from "../../../ReduxToolkit/Reducers/LearningPlanSlice";
import { getProficiencyList } from "../../../ReduxToolkit/Reducers/MasterSlice";
import { NA, OverallStatistics } from "../../../utils/Constant";
import {
  calculateValuePercentage,
  ilpCourseSummaryData,
  multiProgressData,
} from "../../../utils/helper/helper";
import "./style.scss";

const ILPOverview = ({ userId, jobRoleId }) => {
  const dispatch = useAppDispatch();
  const [targetLevelInfoList, setTargetLevelInfoList] = useState([]);
  const { mappedSga, userOverviewData, overallStatistics } = useAppSelector(
    state => state.learningPlan
  );
  const { proficiencyList } = useAppSelector(state => state.master);

  useEffect(() => {
    dispatch(getUserOverviewData({ userId: userId }));
    dispatch(getProficiencyList());
    dispatch(getOverallStatistics({ selfEnrollment: false, userId: userId }));
    dispatch(
      getMappedSgaList({
        type: "overview",
        userId: userId,
        jobRoleId: jobRoleId,
      })
    );
  }, []);

  useEffect(() => {
    const overallStats = [...(userOverviewData?.completed_levels || [])];
    if (userOverviewData?.target_level) {
      overallStats.push(userOverviewData?.target_level);
    }

    const targetLevelInfo = overallStats?.map((item, ind) => {
      const { progress, level_id, status } = item;
      const percentageCompleted = calculateValuePercentage(
        progress?.total_completed,
        progress?.total_courses
      );

      return {
        id: ind,
        expertiseLevel: getLevelNameById(level_id),
        totalCount: progress?.total_courses,
        completedCount: progress?.total_completed,
        percentageCompleted: percentageCompleted,
        sga_id: item?.id,
        status: status,
        completedDate:
          status === "completed" && item.certified_at ? item.certified_at : "-",
      };
    });

    setTargetLevelInfoList(targetLevelInfo);
  }, [mappedSga, proficiencyList, userOverviewData]);

  const getLevelNameById = (id: number): string => {
    const level = proficiencyList?.proficiency?.find(level => level.id === id);
    return level ? level.level_name : "";
  };

  const currentPercentage =
    (userOverviewData?.target_level?.progress?.total_completed /
      userOverviewData?.target_level?.progress?.total_courses) *
    100;

  const transformData = () => {
    return {
      targetLevel: userOverviewData?.target_level?.level_name || NA,
      currentLevel: userOverviewData?.current_level?.level_name || NA,
      percentageCompleted: currentPercentage || "0",
    };
  };

  const getAllLevelData = () => {
    dispatch(getUserOverviewData({ userId: userId }));
  };

  return (
    <>
      <TargetLevelCard
        cardData={transformData()}
        infoData={targetLevelInfoList}
        userId={userId}
        getAllLevelData={getAllLevelData}
      />
      <Card className="p-2 mb-3">
        <CardBody>
          <Row className="justify-content-center gap-5 gap-lg-0">
            <Col className="mt-3" lg={4} xs={12}>
              <H2 className="text-black">{OverallStatistics}</H2>
              <H1 className="mt-1 custom-overall-percentage">
                {overallStatistics &&
                  `${calculateValuePercentage(
                    overallStatistics.completed_courses,
                    overallStatistics.total_courses
                  )}%`}
              </H1>
              <div className="mt-2">
                {overallStatistics?.total_courses > 0 && (
                  <MultiProgressBar
                    progressBars={multiProgressData(overallStatistics)}
                  />
                )}
              </div>
            </Col>
            <Col lg={8} xs={12}>
              {overallStatistics && (
                <CourseSummary data={ilpCourseSummaryData(overallStatistics)} />
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

ILPOverview.propTypes = {
  userId: PropTypes.number,
  jobRoleId: PropTypes.number,
};
export default ILPOverview;
