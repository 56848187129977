import PropTypes from "prop-types";
import { useState } from "react";
import { Input } from "reactstrap";

import { useAppDispatch } from "../../ReduxToolkit/Hooks";
import {
  getCourseCountForYear,
  mapCourse,
} from "../../ReduxToolkit/Reducers/LearningPlanSlice";
import {
  AssignYear,
  NoOfCoursesMappedForSelectedYear,
  Note,
  SelectYear,
  Submit,
} from "../../utils/Constant";
import { generateFiscalYearSeries } from "../../utils/helper/helper";
import CommonModal from "../Modal";

interface MapCourseProps {
  isOpen: boolean;
  userId: string;
  toggle: (dorefresh: boolean) => void;
  selectedCourse: any;
}

const SelectYearModal: React.FC<MapCourseProps> = ({
  isOpen,
  userId,
  toggle,
  selectedCourse,
}) => {
  const dispatch = useAppDispatch();
  const [selectedYear, setSelectedYear] = useState<string>("");
  const [extractedYear, setExtractedYear] = useState<string>("");
  const fiscalYears = generateFiscalYearSeries(0, 10);
  const [noOfCoursesMappedForTheYear, setNoOfCoursesMappedForTheYear] =
    useState(null);

  const fetchCourseMappedForTheYear = async (year: string) => {
    const response = await dispatch(getCourseCountForYear({ year, userId }));
    setNoOfCoursesMappedForTheYear(response?.payload?.course_count);
  };

  const handleYearChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedYear(event.target.value);
    const extractedYear = parseInt(
      event.target.value.split(" ")[1].split("-")[0]
    );
    const startYear =
      extractedYear < 50 ? 2000 + extractedYear : 1900 + extractedYear;
    setExtractedYear(startYear.toString());
    fetchCourseMappedForTheYear(startYear.toString());
  };

  const getYearModalBody = () => {
    return (
      <div className="mb-3">
        <span className="text-black d-block mb-1">{AssignYear}</span>
        <Input type="select" value={selectedYear} onChange={handleYearChange}>
          <option value="" disabled>
            {SelectYear}
          </option>
          {fiscalYears.map(year => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </Input>
        {selectedYear && noOfCoursesMappedForTheYear != null && (
          <span className="text-gray mt-1">
            {`${Note} : ${noOfCoursesMappedForTheYear} ${NoOfCoursesMappedForSelectedYear}`}
          </span>
        )}
      </div>
    );
  };

  const handleSubmit = () => {
    dispatch(
      mapCourse({
        courseContent: {
          type: "modify",
          id: selectedCourse?.id,
          year: extractedYear,
          user_id: userId,
        },
      })
    ).then(res => {
      if (res?.payload) {
        setSelectedYear("");
        toggle(true);
      }
    });
  };

  return (
    <CommonModal
      backdrop="static"
      size="lg"
      isOpen={isOpen}
      toggle={() => {
        setSelectedYear("");
        toggle(false);
      }}
      sizeTitle={selectedCourse?.course_name}
      showFooter={true}
      primaryBtnText={Submit}
      onPrimaryBtnClick={handleSubmit}
    >
      {getYearModalBody()}
    </CommonModal>
  );
};
SelectYearModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  selectedCourse: PropTypes.objectOf(
    PropTypes.shape({
      course_id: PropTypes.number.isRequired,
      course_name: PropTypes.string.isRequired,
      course_type: PropTypes.string.isRequired,
    })
  ),
};

export default SelectYearModal;
