import type { Placement } from "@popperjs/core";
import React from "react";
import { UncontrolledTooltip } from "reactstrap";

import "./style.scss";

interface ToolTipProps {
  children?: React.ReactNode;
  target: string | HTMLElement | React.RefObject<HTMLElement>;
  placement?: Placement;
  isOpen?: boolean;
  className?: string;
  toggle?: () => void;
  trigger?: string;
  autohide?: boolean;
  disabled?: boolean;
}

const ToolTipComponent: React.FC<ToolTipProps> = ({
  children,
  target,
  ...rest
}: ToolTipProps) => {
  return (
    <UncontrolledTooltip target={target} trigger="hover" {...rest}>
      {children}
    </UncontrolledTooltip>
  );
};

export default ToolTipComponent;
