import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

import { H3, ToolTip } from "../../AbstractElements";
import {
  ALL_COURSE_FILTER_STATUS,
  courseTypeMap,
  tmTabsStatusMap,
  WBT,
} from "../../Api/constants";
import FilterSliderModal from "../../CommonElements/FilterSliderModal";
import SearchBar from "../../CommonElements/SearchBar";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks";
import { getCourseList } from "../../ReduxToolkit/Reducers/CourseSlice";
import { getDomainList } from "../../ReduxToolkit/Reducers/MasterSlice";
import {
  CourseName,
  CourseType,
  CreatedOn,
  Domain,
  Duration,
  Select,
  Days,
  Hours,
  Minutes,
  SideBarTitleCourseCatalog,
} from "../../utils/Constant";
import {
  convertMinutesToDaysHoursMinutes,
  courseFilterDropdownList,
  formatCustomDate,
  truncateText,
} from "../../utils/helper/helper";

import "./style.scss";

const CourseCatalog = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});
  const [courseFilterValues, setCourseFilterValues] = useState({
    courseType: [],
    domain: [],
  });
  const [domainListData, setDomainListData] = useState([]);
  const [clearData, setClearData] = useState(false);

  const { courseList } = useAppSelector(state => state.course);
  const { domainList } = useAppSelector(state => state.master);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setSearchResults(courseList?.course_list);
  }, [courseList?.course_list]);

  useEffect(() => {
    dispatch(getDomainList());
  }, []);

  useEffect(() => {
    fetchCourses();
  }, [courseFilterValues]);

  useEffect(() => {
    const domains = [];
    domainList?.domain?.forEach(element => {
      domains.push({
        name: element.id.toString(),
        value: element.domain_name,
        label: element.domain_name,
      });
    });
    setDomainListData(domains);
  }, [domainList]);

  const fetchCourses = () => {
    const { courseType = ALL_COURSE_FILTER_STATUS, domain = [] } =
      courseFilterValues;
    const filteredCourseType =
      courseType.length > 0 ? courseType : ALL_COURSE_FILTER_STATUS;
    const filteredDomain = domain.length > 0 ? domain : null;

    dispatch(
      getCourseList({
        status: tmTabsStatusMap["published"],
        course_type: filteredCourseType,
        domain: filteredDomain,
      })
    );
  };

  const handleSearch = query => {
    setClearData(false);
    const filteredCourse = courseList?.course_list?.filter(course =>
      course.course_name.toLowerCase().includes(query.toLowerCase())
    );
    setSearchResults(!query ? courseList?.course_list : filteredCourse);
  };

  const handleDone = async values => {
    setClearData(true);
    const selectedCourseTypes = values["request_type"];
    const selectedDomains = values["domain"];
    setCourseFilterValues(prevState => ({
      ...prevState,
      courseType: selectedCourseTypes ?? [],
      domain: selectedDomains ?? [],
    }));
  };

  const handleClear = async () => {
    setClearData(true);
    setSelectedFilterValues({});
    setDefaultFilterValues({});
    setCourseFilterValues({
      courseType: [],
      domain: [],
    });
  };

  const handleClick = item => {
    return navigate(`/course-catalog/course-details?course_id=${item.id}`);
  };

  const columns = [
    {
      name: CourseName,
      selector: row => row?.course_name,
      cell: row => (
        <>
          <span
            id={`tooltip-name-${row.id}`}
            className="text-truncate d-flex align-items-center"
          >
            {truncateText(row?.course_name, 40)}
          </span>
          <ToolTip placement="top" target={`tooltip-name-${row.id}`}>
            {row?.course_name}
          </ToolTip>
        </>
      ),
      minWidth: "19rem",
      sortable: true,
    },
    {
      name: CourseType,
      selector: row => courseTypeMap[row.course_type],
      minWidth: "5rem",
      sortable: true,
    },
    {
      name: CreatedOn,
      selector: row => formatCustomDate(row.created_at),
      minWidth: "5rem",
      sortable: true,
    },
    {
      name: Domain,
      selector: row =>
        row?.domain_names?.filter(domain => domain !== null).length > 0
          ? row.domain_names
          : "-",
      minWidth: "5rem",
      sortable: true,
    },
    {
      name: Duration,
      selector: row => {
        const duration = convertMinutesToDaysHoursMinutes(row?.duration);
        return row.course_type === WBT ? duration.days : row?.estimated_time;
      },
      cell: row => {
        const duration = convertMinutesToDaysHoursMinutes(row?.duration);
        return row.course_type === WBT
          ? `${duration.days} ${Days} ${duration.hours} ${Hours} ${duration.minutes} ${Minutes}`
          : `${row?.estimated_time} ${Days}`;
      },
      minWidth: "5rem",
      sortable: true,
    },
  ];

  return (
    <div className="page-body page-body-margin course-catelog-list">
      <Card className="p-1">
        <CardBody>
          <div className="d-flex justify-content-between align-items-center mt-sm-0 mt-2 gap-3 ms-auto">
            <H3>{SideBarTitleCourseCatalog}</H3>
            <div className="d-flex gap-2 align-items-center">
              <SearchBar
                showSearch
                className="mb-1"
                onSearch={handleSearch}
                clearOnProp={clearData}
              />
              <div className="mt-2">
                <FilterSliderModal
                  dropdowns={[
                    {
                      label: CourseType,
                      key: "request_type",
                      tooltipText: `${Select} ${CourseType}`,
                      options: courseFilterDropdownList,
                      isMultiSelect: true,
                    },
                    {
                      label: Domain,
                      key: "domain",
                      tooltipText: `${Select} ${Domain}`,
                      options: domainListData,
                      isMultiSelect: true,
                    },
                  ]}
                  selectedFilterValues={selectedFilterValues}
                  defaultFilterValues={defaultFilterValues}
                  setSelectedFilterValues={setSelectedFilterValues}
                  setDefaultFilterValues={setDefaultFilterValues}
                  onDone={handleDone}
                  onClear={handleClear}
                />
              </div>
            </div>
          </div>
          <div className="mt-3">
            <DataTable
              highlightOnHover
              onRowClicked={handleClick}
              columns={columns}
              data={searchResults}
              pagination
              fixedHeader
              striped
            />
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default CourseCatalog;
