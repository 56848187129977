import debounce from "lodash.debounce";
import { useEffect, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import ReactSelect from "react-select";
import { FixedSizeList as List } from "react-window";
import { Card, CardBody, Col, FormGroup, Input } from "reactstrap";

import { Btn, H4, MUIIcons } from "../../AbstractElements";
import BorderTabs from "../../CommonElements/BorderTabs";
import Divider from "../../CommonElements/Divider";
import LabelTooltip from "../../CommonElements/LabelTooltip";
import CommonModal from "../../CommonElements/Modal";
import MultiSelectDropdown from "../../CommonElements/MultiSelectDropdown";
import SearchBar from "../../CommonElements/SearchBar";
// import SearchDropDownPaginator from "../../CommonElements/SearchDropDownPaginator";
// import CheckboxButton from "../../CommonElements/SwitchButton";
import UserProfileCardDetails from "../../CommonElements/UserProfileCardDetails";
import AuditLog from "../../container/AuditLog";
import NoData from "../../container/NoData";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks";
import {
  getIndustryJobRolesList,
  getIndustryList,
} from "../../ReduxToolkit/Reducers/MasterSlice";
import {
  getLMSRoles,
  getSuspendedUsersList,
  getUserDetailsById,
  getUsersListOnTabs,
  mapRolesToUser,
  setUserDetailsToInitials,
  suspendExternalUser,
  unMapRolesToUser,
} from "../../ReduxToolkit/Reducers/UserManagement";
import { dynamicImage } from "../../Service";
import {
  Add,
  ChangeLogs,
  Confirm,
  CWSAndCatRecId,
  Disable,
  DisableEmployee,
  DisableEmployeeHeader,
  // DoYouWantToMergeTrainingHistory,
  Edit,
  EditRole,
  EmployeeId,
  EnableText,
  Enter,
  ExternalUserIndustryToolTipMessage,
  ExternalUserJobRoleToolTipMessage,
  ExternalUserLMSRoleToolTipMessage,
  Industry,
  JobRole,
  JobRoleRequired,
  ListEmployee,
  LMSRole,
  LMSRoleRequired,
  MapRole,
  // MergeTrainingHistoryToolTipMessage,
  Mobile,
  New,
  NoDataText,
  // OldEmployee,
  Select,
  SomethingWentWrong,
  ValidTill,
} from "../../utils/Constant";
import {
  // customStyles,
  externalEmployeeStatus,
  getTomorrowDate,
  roleMapping,
  showToast,
  userManagementListTabs,
} from "../../utils/helper/helper";
import { hasPermissionToComponent } from "../../utils/helper/permission";

import "./style.scss";

export interface UserCardDetails {
  id: number;
  name: string;
  detail: string;
  cwsAndCatRecId: number;
  userProfileUrl: string;
  expertiseLevel: string;
  location: string;
  rmName: string;
  phoneNumber: number;
  mailId: string;
  job_role_id?: string;
  empId: number;
  sbu: string;
  auditLogs?: any;
}

const UserManagement = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const statusParam = new URLSearchParams(location.search).get("status");
  const [activeTab, setActiveTab] = useState(statusParam || "internal");
  const [searchValue, setSearchValue] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [selectedModalType, setSelectedModalType] = useState("");
  const [selectedDate, setSelectedDate] = useState(getTomorrowDate());
  const { industryList, roleOnFilter } = useAppSelector(state => state.master);
  const usersList = useAppSelector(
    state => state.userManagement.usersListOnTabs.users || []
  );
  // const suspendedUsersList = useAppSelector(
  //   state => state.userManagement.suspendedUsersList.users || []
  // );
  const { lmsRole } = useAppSelector(state => state.userManagement);
  const [lmsRolesOptions, setLMSRolesOptions] = useState([]);
  const [preSelectedLMSRoles, setPreSelectedLMSRoles] = useState([]);
  const [formValues, setFormValues] = useState({
    cws_cat_rec: null,
    industry: "",
    lms_role: [],
    job_role: "",
    old_user_id: "",
  });
  const [dbSelectedLMSRoles, setDBSelectedLMSRoles] = useState([]);
  const [showActivityLog, setShowActivityLog] = useState<boolean>(false);
  const [auditLogsDetails, setAuditLogsDetails] = useState(null);
  // const [mergeTrainingHistory, setmergeTrainingHistory] = useState(false);
  const [selectedOldEmployeeId, setSelectedOldEmployeeId] =
    useState<string>("");
  // const [oldEmployeeOptions, setOldEmployeeOptions] = useState([]);

  useEffect(() => {
    setFormValues({
      cws_cat_rec: null,
      industry: "",
      lms_role: [],
      job_role: "",
      old_user_id: "",
    });
    dispatch(getIndustryList());
    dispatch(getSuspendedUsersList());
    if (hasPermissionToComponent("USER_MANAGEMENT.MAP_ROLE_TO_USER")) {
      dispatch(getLMSRoles());
    }
  }, [dispatch]);

  useEffect(() => {
    setFormValues(prevValues => ({
      ...prevValues,
      old_user_id: selectedOldEmployeeId,
    }));
  }, [selectedOldEmployeeId]);

  useEffect(() => {
    const roles = [];
    lmsRole?.lms_role?.forEach(item => {
      const roleDetails = roleMapping?.[item?.role_name];
      if (roleDetails) {
        roles.push({
          name: item?.id?.toString(),
          value: roleDetails?.value,
          label: roleDetails?.value,
        });
      }
    });
    setLMSRolesOptions(roles);
  }, [lmsRole]);

  // useEffect(() => {
  //   const options = suspendedUsersList.map((user: any) => ({
  //     value: user.id,
  //     label: user.user_name,
  //     employeeCode: user.employee_code,
  //   }));
  //   setOldEmployeeOptions(options);
  // }, [suspendedUsersList]);

  useEffect(() => {
    dispatch(getUsersListOnTabs({ tab: activeTab }));
  }, [dispatch, activeTab]);

  useEffect(() => {
    const perselectedLMSRolesMap =
      formValues.lms_role?.map((role: any) => ({
        name: role,
        value: lmsRolesOptions.find(item => item.name === role)?.label || "",
        label: lmsRolesOptions.find(item => item.name === role)?.label || "",
      })) || [];

    setPreSelectedLMSRoles(perselectedLMSRolesMap);
  }, [formValues.lms_role, lmsRolesOptions]);

  const getValuesOnTabs = type => {
    if (activeTab === "internal") {
      return [EditRole];
    }
    if (activeTab === "external") {
      return [Disable, Edit];
    }
    if (activeTab === "unmapped_role") {
      return [MapRole];
    }
    if (activeTab === "suspended" && externalEmployeeStatus.includes(type)) {
      return [EnableText];
    }
    if (activeTab === "last_changes") {
      return [ChangeLogs];
    }
  };

  const getDropsOnTabs = type => {
    if (activeTab === "internal") {
      return true;
    }
    if (activeTab === "external") {
      return true;
    }
    if (activeTab === "unmapped_role") {
      return true;
    }
    if (activeTab === "suspended" && externalEmployeeStatus.includes(type)) {
      return true;
    }
    if (activeTab === "last_changes") {
      return true;
    }
    return false;
  };

  const mappedUsers = useMemo(
    () =>
      usersList?.map((user: any) => ({
        id: user.id,
        name: user.user_name,
        userProfileUrl: dynamicImage("dashboard-4/user.png"),
        rmName: user.mapped_rm?.[0]?.name || "-",
        phoneNumber: user.phone,
        mailId: user.email,
        empId: user.employee_code || "-",
        sbu: user.sbu || "-",
        job_role_id: user.job_role_id,
        tag:
          (activeTab === "suspended" &&
            externalEmployeeStatus.includes(user?.employee_type)) ||
          false,
        showDrop:
          hasPermissionToComponent("USER_MANAGEMENT.MAP_ROLE_TO_USER") &&
          getDropsOnTabs(user?.employee_type),
        dropVal: getValuesOnTabs(user?.employee_type),
        auditLogs: user?.audit_logs,
        is_new_user: user?.is_new_user,
      })),
    [usersList]
  );

  const filteredUsers = useMemo(() => {
    return mappedUsers.filter((user: any) =>
      activeTab === "internal"
        ? user.status === "active" || !user.status
        : user.status === activeTab || !user.status
    );
  }, [mappedUsers, activeTab]);

  const userNameMap = useMemo(() => {
    return mappedUsers.reduce(
      (acc, user: any) => {
        acc[user.id] = {
          name: user?.name,
          id: user?.empId,
          phone: user?.phoneNumber,
        };
        return acc;
      },
      {} as Record<string, { name: string; id: string; phone: string }>
    );
  }, [mappedUsers, activeTab]);

  const handleTabClick = (tabId: string) => {
    setActiveTab(tabId);
    setSearchValue("");
  };

  const handleCardClick = (user: UserCardDetails) => {
    const baseUrl = `/user-management/user-details?user_id=${user?.id}`;
    const url = user.job_role_id
      ? `${baseUrl}&job_role_id=${user.job_role_id}`
      : baseUrl;

    const newTab = window.open(url, "_blank");

    if (newTab) {
      newTab.focus();
    }
  };

  const debouncedSearch = useMemo(
    () => debounce(value => setSearchValue(value), 300),
    []
  );

  const handleSearchInputChange = (value: string) => {
    debouncedSearch(value);
  };

  const searchResults = useMemo(() => {
    const lowerCaseSearch = searchValue.toLowerCase();
    return filteredUsers.filter(({ name, mailId, empId }) =>
      [name, empId, mailId]
        .map(field => (field ? field.toString().toLowerCase() : ""))
        .some(field => field.includes(lowerCaseSearch))
    );
  }, [searchValue, filteredUsers]);

  const onDropClick = async (id, name) => {
    if (name === Edit) {
      return navigate("/add-employee", {
        state: { userId: id },
      });
    }
    if (name === Disable) {
      setSelectedId(id);
      setSelectedModalType("disable");
      toggleModal();
    }
    if (name === MapRole) {
      const roleNames = [];
      const idMap = [];
      lmsRole?.lms_role?.forEach(item => {
        const roleDetails = roleMapping?.[item?.role_name];
        if (roleDetails && item?.role_name === "il") {
          idMap.push(item?.id?.toString());
          roleNames.push({
            name: item?.id?.toString(),
            value: roleMapping?.[item?.role_name]?.value,
            label: roleMapping?.[item?.role_name]?.value,
            key: item?.role_name,
          });
        }
      });

      setFormValues(prevValues => ({
        ...prevValues,
        lms_role: idMap,
      }));
      setPreSelectedLMSRoles(roleNames);
      setSelectedId(id);
      setSelectedModalType("map_role");
      toggleModal();
    }
    if (name === EnableText) {
      setSelectedId(id);
      setSelectedModalType("enable");
      toggleModal();
    }
    if (name === ChangeLogs) {
      const findElementId = searchResults?.find(item => item.id === id);
      setAuditLogsDetails(findElementId);
      handleActivityModal();
    }
    if (name === EditRole) {
      const res = await dispatch(getUserDetailsById({ id: id }));
      if (res?.payload?.users?.[0]) {
        const userVal = res?.payload?.users?.[0];
        const ids = [];
        const idMap = [];
        userVal?.mapped_lms_roles?.forEach(item => {
          const roleDetails = roleMapping?.[item?.role_name];
          if (roleDetails) {
            ids.push(item?.role_id?.toString());
            idMap.push({
              name: item?.role_id?.toString(),
              value: roleMapping?.[item?.role_name]?.value,
              label: roleMapping?.[item?.role_name]?.value,
              unique_id: item?.id,
            });
          }
        });
        setDBSelectedLMSRoles(idMap);
        setPreSelectedLMSRoles(idMap);
        await dispatch(
          getIndustryJobRolesList({ ind_id: userVal?.industry_id })
        );

        setFormValues(prevValues => ({
          ...prevValues,
          cws_cat_rec: userVal?.cws_cat_rec || null,
          industry: userVal?.industry_id || "",
          lms_role: ids,
          job_role: userVal?.job_role_id || "",
        }));

        setSelectedId(id);
        setSelectedModalType("edit_role");
        toggleModal();
      } else {
        showToast(SomethingWentWrong, "error");
      }
    }
  };

  const renderUserCards = () => {
    if (!searchResults.length)
      return (
        <NoData svg={"empty-folder-icon"} title={NoDataText} showCard={false} />
      );

    return (
      <List
        height={500}
        itemCount={searchResults.length}
        itemSize={100}
        width="100%"
        className="user-list__card-container"
      >
        {({ index, style }) => {
          const user: any = searchResults[index];
          return (
            <div
              key={user.id}
              style={style}
              onClick={() => handleCardClick(user)}
              className="pointer user-list__card"
            >
              <UserProfileCardDetails
                {...user}
                uniqueKey={user.id}
                onDropClick={onDropClick}
                badgeText={user?.is_new_user ? New : null}
              />
            </div>
          );
        }}
      </List>
    );
  };

  const toggleModal = () => {
    if (isModalOpen) {
      setSelectedId("");
      setSelectedDate(getTomorrowDate());
      setSelectedModalType("");
      setFormValues({
        cws_cat_rec: null,
        industry: "",
        lms_role: [],
        job_role: "",
        old_user_id: "",
      });
      setSelectedOldEmployeeId("");
      // setmergeTrainingHistory(false);
    }
    setIsModalOpen(!isModalOpen);
  };

  let sizeTitle = "";
  if (selectedModalType === "disable") {
    sizeTitle = DisableEmployeeHeader;
  } else if (selectedModalType === "map_role") {
    sizeTitle = MapRole;
  } else if (selectedModalType === "edit_role") {
    sizeTitle = EditRole;
  } else if (selectedModalType === "enable") {
    sizeTitle = EnableText;
  }

  const renderModalBody = type => {
    if (type === "disable") {
      return DisableEmployee;
    }
    if (type === "enable") {
      return (
        <div>
          <LabelTooltip
            label={ValidTill}
            tooltipText={`${Select} ${ValidTill}`}
            important
          />
          <div className="w-100">
            <DatePicker
              selected={selectedDate}
              onChange={(date: Date | null) => {
                setSelectedDate(date);
              }}
              minDate={getTomorrowDate()}
              placeholderText={`${Select} ${ValidTill}`}
              className={"form-control"}
              dateFormat="dd-MM-yyyy"
              disabledKeyboardNavigation
              onKeyDown={e => e.preventDefault()}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </div>
        </div>
      );
    }
    if (selectedModalType === "map_role" || selectedModalType === "edit_role") {
      return (
        <div>
          <Divider />
          <H4 className="mt-3">
            {userNameMap[selectedId]?.name
              ? userNameMap[selectedId]?.name
              : "-"}
          </H4>
          <div className="text-muted mt-2">
            {EmployeeId}:{" "}
            {userNameMap?.[selectedId]?.id ? (
              <span className="text-dark">{userNameMap[selectedId]?.id}</span>
            ) : (
              "-"
            )}
            {" | "}
            {Mobile}:{" "}
            {userNameMap[selectedId]?.phone ? (
              <span className="text-dark">
                {userNameMap[selectedId]?.phone}
              </span>
            ) : (
              "-"
            )}
          </div>
          <Divider />

          <FormGroup className="mt-2">
            <LabelTooltip
              label={CWSAndCatRecId}
              tooltipText={CWSAndCatRecId}
              important={false}
              placement={"bottom"}
            />
            <Input
              type="text"
              name="cws_cat_rec"
              placeholder={`${Enter} ${CWSAndCatRecId}`}
              max={100}
              value={formValues.cws_cat_rec}
              onChange={e => {
                if (e.target.value?.length <= 100)
                  setFormValues(prevValues => ({
                    ...prevValues,
                    cws_cat_rec: e.target.value,
                  }));
              }}
            />
          </FormGroup>
          <FormGroup>
            <LabelTooltip
              label={Industry}
              tooltipText={ExternalUserIndustryToolTipMessage}
              important={false}
            />
            <Input
              type="select"
              value={formValues.industry}
              onChange={e => {
                if (e.target.value)
                  dispatch(getIndustryJobRolesList({ ind_id: e.target.value }));
                setFormValues(prevValues => ({
                  ...prevValues,
                  industry: e.target.value,
                }));
              }}
              name="employee_type"
            >
              <option value="" disabled>
                {Select} {Industry}
              </option>
              {industryList?.industry?.map(option => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <LabelTooltip
              label={JobRole}
              tooltipText={ExternalUserJobRoleToolTipMessage}
              important
            />
            <Input
              type="select"
              value={formValues.job_role}
              onChange={e => {
                setFormValues(prevValues => ({
                  ...prevValues,
                  job_role: e.target.value,
                }));
              }}
              name="job_role"
            >
              <option value="" disabled>
                {Select} {JobRole}
              </option>
              {roleOnFilter?.job_role?.map(option => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <LabelTooltip
              label={LMSRole}
              tooltipText={ExternalUserLMSRoleToolTipMessage}
              important
            />
            <MultiSelectDropdown
              onChange={values => {
                setFormValues(prevValues => ({
                  ...prevValues,
                  lms_role: values,
                }));
              }}
              options={lmsRolesOptions}
              placeholder={`${Select} ${LMSRole}`}
              defaultSelected={preSelectedLMSRoles}
            />
          </FormGroup>

          {/* {selectedModalType === "map_role" && (
            <>
              <Row>
                <Col sm="12" lg="6" className="d-flex gap-2 mt-3">
                  <p className="fw-bold">
                    <LabelTooltip
                      label={DoYouWantToMergeTrainingHistory}
                      tooltipText={MergeTrainingHistoryToolTipMessage}
                      important={false}
                      placement={"bottom"}
                    />
                  </p>
                  <div className="mt-1">
                    <CheckboxButton
                      onChange={e => {
                        setmergeTrainingHistory(e.target.checked);
                      }}
                      checked={mergeTrainingHistory}
                    />
                  </div>
                </Col>
              </Row>
              {mergeTrainingHistory && (
                <div className="mb-3">
                  <LabelTooltip
                    label={OldEmployee}
                    tooltipText={`${Select} ${OldEmployee}`}
                    important
                  />
                  <ReactSelect
                    name="course"
                    className="text-gray"
                    onChange={selectedOption => {
                      setSelectedOldEmployeeId(
                        selectedOption ? selectedOption.value : ""
                      );
                    }}
                    value={
                      oldEmployeeOptions.find(
                        option => option.value === selectedOldEmployeeId
                      ) || null
                    }
                    options={oldEmployeeOptions.map(option => ({
                      value: option.value,
                      label: option.label,
                      employeeCode: option.employeeCode,
                    }))}
                    isSearchable
                    isClearable={false}
                    placeholder={`${Select} ${OldEmployee}`}
                    styles={customStyles}
                    components={{
                      MenuList: props => (
                        <SearchDropDownPaginator {...props} maxHeight={200} />
                      ),
                    }}
                    filterOption={(option, inputValue) => {
                      const { label, data } = option;

                      const labelText = label ? label.toLowerCase() : "";
                      const employeeCodeText = data?.employeeCode
                        ? data.employeeCode.toLowerCase()
                        : "";

                      return (
                        labelText.includes(inputValue.toLowerCase()) ||
                        employeeCodeText.includes(inputValue.toLowerCase())
                      );
                    }}
                  />
                </div>
              )}
            </>
          )} */}
        </div>
      );
    }
  };

  const handlePrimaryBtnClick = async () => {
    if (selectedModalType === "disable") {
      const payload = {
        id: selectedId,
        is_suspended: 1,
      };
      await dispatch(suspendExternalUser({ payload: payload }));
    }
    if (selectedModalType === "enable") {
      const payload = {
        id: selectedId,
        is_suspended: 0,
        valid_till: selectedDate?.toISOString().split("T")[0] || "",
      };
      await dispatch(suspendExternalUser({ payload: payload }));
    }
    if (selectedModalType === "map_role" || selectedModalType === "edit_role") {
      if (!formValues?.job_role) {
        showToast(JobRoleRequired, "error");
        return;
      }
      if (!preSelectedLMSRoles || preSelectedLMSRoles?.length === 0) {
        showToast(LMSRoleRequired, "error");
        return;
      }

      const lms_role_id = preSelectedLMSRoles?.map(item =>
        parseInt(item?.name)
      );

      const difference = dbSelectedLMSRoles
        ?.filter(role => !lms_role_id?.includes(Number(role?.name)))
        .map(role => role?.unique_id);

      if (difference && difference.length > 0) {
        const unMap = {
          user_id: selectedId,
          lms_role_id: difference,
        };

        await dispatch(unMapRolesToUser({ payload: unMap }));
      }

      const payload = {
        user_id: selectedId,
        job_role_id: parseInt(formValues?.job_role) || null,
        lms_role_id: lms_role_id,
        cws_cat_rec: formValues?.cws_cat_rec,
        old_user_id: formValues?.old_user_id,
      };

      await dispatch(mapRolesToUser({ payload: payload }));
    }
    await dispatch(getUsersListOnTabs({ tab: activeTab }));
    toggleModal();
  };

  const handleActivityModal = () => {
    setShowActivityLog(prevState => !prevState);
  };

  return (
    <div className="page-body page-body-margin">
      <CommonModal
        sizeTitle={sizeTitle}
        modalBodyClassName=""
        isOpen={isModalOpen}
        toggle={toggleModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={handlePrimaryBtnClick}
        primaryBtnText={Confirm}
      >
        {renderModalBody(selectedModalType)}
      </CommonModal>
      <Col className="user-list">
        <Card className="p-2">
          <CardBody>
            <div className="d-flex justify-content-between">
              <H4>{ListEmployee}</H4>
            </div>

            {hasPermissionToComponent("USER_MANAGEMENT.SUSPENDED_USERS") && (
              <div className="mt-4 mb-4 d-flex flex-column flex-md-row justify-content-between">
                <BorderTabs
                  tabs={userManagementListTabs}
                  activeTab={activeTab}
                  handleTabClick={handleTabClick}
                  showCard={false}
                />
              </div>
            )}

            <div className="d-flex align-items-center justify-content-end gap-2 mt-2">
              <div className="w-100">
                <SearchBar
                  showSearch
                  className="mb-4 custom-userList-search-bar"
                  key={activeTab}
                  onSearch={handleSearchInputChange}
                />
              </div>

              {activeTab === "external" && (
                <Link
                  className="d-flex justify-content-end mb-3"
                  to={"/add-employee"}
                  onClick={() => {
                    dispatch(setUserDetailsToInitials());
                  }}
                >
                  <Btn
                    icon={
                      <MUIIcons size={14} iconName="AddCircleOutlineOutlined" />
                    }
                    color="primary"
                  >
                    {Add}
                  </Btn>
                </Link>
              )}
            </div>

            {renderUserCards()}
          </CardBody>
        </Card>
      </Col>
      <AuditLog
        auditLogs={auditLogsDetails?.auditLogs}
        handleActivityModal={handleActivityModal}
        showActivityLog={showActivityLog}
      />
    </div>
  );
};

export default UserManagement;
