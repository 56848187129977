import { useCallback, useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";

import { Btn, H3, MUIIcons } from "../../../AbstractElements";
import { STATUS_200 } from "../../../Api/constants";
import { UpsertJobRolePayload } from "../../../Api/entities/ManageMasterDataEntity";
import { MultiselectOption } from "../../../Api/entities/SgaEntity";
import MasterDataAccordion from "../../../CommonElements/MasterDataAccordion";
import MasterDataAddNewModal from "../../../CommonElements/MasterDataAddNewModal";
import CommonModal from "../../../CommonElements/Modal";
import SearchBar from "../../../CommonElements/SearchBar";
import NoData from "../../../container/NoData";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import {
  createJobRole,
  deleteJobRole,
  getJobRoleList,
  getJobRoleListById,
  setJobRoleListByIdToIntialValue,
  updateJobRole,
} from "../../../ReduxToolkit/Reducers/ManageMasterDataSlice";
import {
  getGradeList,
  getIndustryList,
} from "../../../ReduxToolkit/Reducers/MasterSlice";
import {
  AddNew,
  Confirm,
  DeleteJobRoleHeader,
  DeleteJobRoleMessage,
  Enter,
  Grade,
  GradeNameToolTipMessage,
  Grades,
  Industry,
  IndustryNameToolTipMessage,
  IntendedAudienceDescriptionToolTipMessage,
  JobRole,
  JobRoleEmptyDescription,
  JobRoles,
  MasterDataIntendedAudienceToolTipMessage,
  NoDataText,
  NotYetAdded,
  Select,
} from "../../../utils/Constant";
import { mapListToOptions } from "../../../utils/helper/helper";
import {
  getFromLocalStorage,
  LOGGED_IN_USER,
} from "../../../utils/helper/localStorageutils";

const JobRolePage = () => {
  const dispatch = useAppDispatch();
  const { jobRoleList, jobRoleListById, loading } = useAppSelector(
    state => state.manageMasterData
  );
  const loginData = getFromLocalStorage(LOGGED_IN_USER);
  const { gradeList } = useAppSelector(state => state.master);
  const { industryList } = useAppSelector(state => state.master);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
    useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [jobRoleToDelete, setJobRoleToDelete] = useState(null);
  const [accordionData, setAccordionData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedIndustryId, setSelectedIndustryId] = useState("");
  const [industryListData, setIndustryListData] = useState([]);
  const [gradeOptions, setGradeOptions] = useState<MultiselectOption[]>([]);
  const [preSelectedGrades, setPreSelectedGrades] = useState<
    MultiselectOption[]
  >([]);
  const [modalFormValues, setModalFormValues] = useState({
    name: "",
    description: "",
    selectedOption: "",
  });
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    dispatch(getJobRoleList());
    dispatch(getGradeList());
    if (!modalOpen) {
      dispatch(getIndustryList());
    }
  }, [dispatch]);

  useEffect(() => {
    if (jobRoleList?.job_role?.length > 0) {
      setAccordionData(
        jobRoleList?.job_role?.map(item => ({
          id: item.id.toString(),
          name: item.name,
          description: item.description || "-",
          createdOn: item.created_at,
          modifiedOn: item.updated_at,
          depedentBadges: [
            { id: 1, badge: `${Grade}: ${item.grade_count}` },
            { id: 2, badge: `${Industry}: ${item.industry_id ? "1" : "0"}` },
          ],
        }))
      );
    }
  }, [jobRoleList]);

  useEffect(() => {
    if (searchQuery) {
      const filtered = accordionData.filter(jobRole =>
        jobRole.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(accordionData);
    }
  }, [searchQuery, accordionData]);

  useEffect(() => {
    const data = gradeList?.grades?.map(grade => ({
      label: grade.name,
      value: grade.name,
      name: grade?.id.toString(),
    }));
    setGradeOptions(data);
  }, [gradeList]);

  useEffect(() => {
    const industries = mapListToOptions(industryList?.industry, "name", "id");
    setIndustryListData(industries);
  }, [industryList]);

  useEffect(() => {
    if (jobRoleListById) {
      setModalFormValues(prevState => ({
        ...prevState,
        name: jobRoleListById.name,
        description: jobRoleListById.description,
        selectedOption: jobRoleListById.industry_id?.toString(),
      }));
      if (jobRoleListById.grades) {
        setPreSelectedGrades(
          jobRoleListById.grades.map(item => ({
            name: item.id.toString(),
            value: item.name,
            label: item.name,
          }))
        );
      }
    }
  }, [jobRoleListById]);

  const toggleModal = () => setModalOpen(!modalOpen);

  const toggleDeleteConfirmModal = () =>
    setIsDeleteConfirmModalOpen(!isDeleteConfirmModalOpen);

  const handleDelete = (id: string) => {
    const selectedId = parseInt(id);
    setJobRoleToDelete(selectedId);
    toggleDeleteConfirmModal();
  };

  const confirmDelete = () => {
    if (jobRoleToDelete) {
      dispatch(deleteJobRole({ id: jobRoleToDelete })).then(() => {
        dispatch(getJobRoleList());
        toggleDeleteConfirmModal();
      });
    }
  };

  const handleSave = useCallback(
    async (
      name: string,
      description: string,
      industryId: string,
      grades: any[]
    ) => {
      const gradeIds = grades.map(item => item.name);
      const payload: UpsertJobRolePayload = {
        industry_id: industryId ? parseInt(industryId) : 0,
        name: name,
        description: description,
        grade: gradeIds || [],
      };

      let res = null;
      if (jobRoleListById) {
        payload.id = jobRoleListById.id;
        res = await dispatch(
          updateJobRole({ updateJobRolePayload: payload })
        ).then(val => {
          return val;
        });
      } else {
        res = await dispatch(
          createJobRole({ createJobRolePayload: payload })
        ).then(val => {
          return val;
        });
      }
      if (res?.payload?.status_code === STATUS_200) {
        toggleModal();
      }
      await dispatch(getJobRoleList());
    },
    [dispatch, toggleModal]
  );

  const handleEdit = (id: string) => {
    setIsEdit(true);
    const selectedId = parseInt(id);
    dispatch(getJobRoleListById({ id: selectedId }));
    toggleModal();
  };

  const renderContent = () => {
    if (loading) {
      return null;
    }

    if (accordionData?.length === 0) {
      return (
        <NoData
          svg={"empty-folder-icon"}
          title={NotYetAdded}
          description={JobRoleEmptyDescription}
          buttonText={AddNew}
          onclick={toggleModal}
        />
      );
    }

    if (filteredData?.length === 0) {
      return <NoData svg={"empty-folder-icon"} title={NoDataText} />;
    }

    return filteredData?.map(item => (
      <MasterDataAccordion
        key={item.id}
        accordionItems={item}
        onEditClick={() => handleEdit(item.id)}
        onDeleteClick={() => handleDelete(item.id)}
        loginData={loginData}
      />
    ));
  };

  return (
    <div className="page-body page-body-margin">
      <CommonModal
        sizeTitle={DeleteJobRoleHeader}
        isOpen={isDeleteConfirmModalOpen}
        toggle={toggleDeleteConfirmModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={confirmDelete}
        primaryBtnText={Confirm}
      >
        {DeleteJobRoleMessage}
      </CommonModal>
      <Card>
        <CardBody>
          <div className="d-flex flex-row align-items-center">
            <H3 className="fw-bold">{JobRoles}</H3>
            <div className="d-flex justify-content-end align-items-center mt-sm-0 mt-2 gap-3 ms-auto">
              <SearchBar
                showSearch
                className="mb-2"
                onSearch={query => setSearchQuery(query)}
              />
              <Btn
                icon={
                  <MUIIcons iconName="AddCircleOutlineOutlined" size={16} />
                }
                color="primary"
                onClick={() => {
                  setIsEdit(false);
                  setSelectedIndustryId("");
                  dispatch(setJobRoleListByIdToIntialValue());
                  setModalFormValues(null);
                  toggleModal();
                }}
              >
                {AddNew}
              </Btn>
            </div>
          </div>
        </CardBody>
      </Card>

      {renderContent()}

      <MasterDataAddNewModal
        isEdit={isEdit}
        isOpen={modalOpen}
        toggle={toggleModal}
        onSave={handleSave}
        labelText={JobRole}
        placeholderText={`${Enter} ${JobRole}`}
        toolTipText={MasterDataIntendedAudienceToolTipMessage}
        dropdownLabel={Industry}
        dropdownLabelTooltip={IndustryNameToolTipMessage}
        selectedDropdownValue={selectedIndustryId}
        onDropdownChange={value => setSelectedIndustryId(value)}
        dropdownOptions={industryListData}
        initialValues={modalFormValues}
        multiSelectDropdownLabel={Grades}
        multiSelectDropdownLabelTooltip={GradeNameToolTipMessage}
        multiSelectOptions={gradeOptions}
        selectedMultiSelectValues={preSelectedGrades}
        multiSelectPlaceholder={`${Select} ${Grade}`}
        toolTipDescriptionText={IntendedAudienceDescriptionToolTipMessage}
      />
    </div>
  );
};

export default JobRolePage;
