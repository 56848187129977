import PropTypes from "prop-types";

import { Badges, H1, MUIIcons, P } from "../../AbstractElements";
import { ScheduledOn } from "../../utils/Constant";
import "./style.scss";
import { formatCustomDate } from "../../utils/helper/helper";

const ProjectDescription = ({
  title,
  courseValues,
  description,
  scheduledOn,
}) => {
  const CertificateBadge = ({ label, onClick }) => (
    <Badges
      onClick={onClick}
      className="d-flex align-items-center pointer"
      status="published"
    >
      {label}
      <MUIIcons className="pointer" size={24} iconName="FileDownloadOutlined" />
    </Badges>
  );

  CertificateBadge.propTypes = {
    label: PropTypes.string,
    onClick: PropTypes.func,
  };

  return (
    <div className="course-description">
      <div className="gap-1 justify-content-between course-description__container pb-3">
        <div>
          <H1 className="course-description__title">{title}</H1>
        </div>
      </div>
      <div className="d-flex align-items-center gap-1 mb-3">
        {courseValues.map(item => (
          <>
            <div key={item.id} className="course-description__course-item">
              <P className="m-0 course-description__course-value">
                {item.label}: {item.value}
              </P>
            </div>
            <span className="course-description__devider" />
          </>
        ))}
      </div>
      <P className="course-description__content mt-2 mb-0 pt-2">
        {description}
      </P>

      {scheduledOn && (
        <div className="course-description__date-wrapper d-flex gap-2">
          <MUIIcons
            className="course-description__calender"
            size={18}
            iconName="CalendarTodayOutlined"
          />
          <P className="course-description__schedule-on m-0">
            {ScheduledOn} {formatCustomDate(scheduledOn)}
          </P>
        </div>
      )}
    </div>
  );
};

ProjectDescription.propTypes = {
  title: PropTypes.string.isRequired,
  courseValues: PropTypes.array,
  description: PropTypes.string,
  scheduledOn: PropTypes.string,
};

export default ProjectDescription;
