import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Btn } from "../../../AbstractElements";
import { PENDING_STATUS, REJECTED_STATUS } from "../../../Api/constants";
import FixedFooter from "../../../CommonElements/FixedFooter";
import PassScoreCard from "../../../CommonElements/PassScoreCard";
import CatlogAssessmentHeading from "../../../container/CatlogAssessmentHeading";
import ParticipantsDetailsWithoutHeight from "../../../container/ParticipantDetailsWithoutHeight";
import FailedScoreCard from "../../../container/ScoreCardContainer";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import { setLearnerAssessmentDetailsToInitial } from "../../../ReduxToolkit/Reducers/AssessmentCatlogSlice";
import {
  clearAssessmentDetails,
  getAssessmentDetailsByParticipantId,
} from "../../../ReduxToolkit/Reducers/AssessmentSlice";
import { dynamicImage } from "../../../Service";
import {
  Start,
  RequestExtension,
  YouFailed,
  AttemptOn,
  Resume,
  Retake,
  AssessmentType,
  EstimatedTimer,
  TotalQuestion,
  YourReason,
  TMReason,
  Days,
  Status,
  ViewDetails,
  ReattemptDays,
  SelfAssessment,
  ExtensionsAlreadyRaised,
  Declined,
  Course,
  ExamAlreadyInProgress,
  AttemptedOn,
} from "../../../utils/Constant";
import {
  formatDate,
  formatCustomDate,
  getOrdinalWord,
  assessmentStatus,
  examStatus,
  assessmentTabStatus,
  assessmentTypesMap,
  calculateDayDifference,
  capitalizeFirstLetter,
  showToast,
} from "../../../utils/helper/helper";

import RequestExtensionModal from "./RequestExtenstionModal";
import "./style.scss";

const AssessmentListDetails = () => {
  const location = useLocation();
  const { state } = location;
  const [requestExtensionModal, setRequestExtensionModal] = useState(false);
  const [participantDetails, setParticipantDetails] = useState([]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { assessmentDetails } = useAppSelector(state => state.assessment);

  useEffect(() => {
    dispatch(clearAssessmentDetails());
    if (!isSelfAssessment()) {
      dispatch(
        getAssessmentDetailsByParticipantId({
          id: state?.assessment?.participant_id,
        })
      );
    }
  }, []);

  useEffect(() => {
    const extensionData = assessmentDetails?.extension_request?.map(
      extension => ({
        userDetails: {
          imagePath: dynamicImage("dashboard-2/user/2.png"),
          name: assessmentDetails?.user_dict
            ? assessmentDetails?.user_dict?.[extension.user_id]?.user_name
            : null,
          empId: assessmentDetails?.user_dict
            ? assessmentDetails?.user_dict?.[extension.user_id]?.employee_code
            : null,
          phoneNumber: assessmentDetails?.user_dict
            ? assessmentDetails?.user_dict?.[extension.user_id]?.phone
            : null,
          detail: `Extenstion requested on : ${formatDate(extension.created_at)}`,
        },
        childStatus: [
          {
            label: YourReason,
            numbers: [extension.requester_comments],
            childLabel: TMReason,
            childNumbers: [extension.reviewer_comments],
            isLarge: 4,
          },
          {
            label: Days,
            numbers: [extension.requested_days],
            childLabel: extension?.status === REJECTED_STATUS ? "" : Days,
            childNumbers: [extension.provided_days],
          },
          {
            label: Status,
            numbers:
              extension.status === "rejected"
                ? [Declined]
                : [capitalizeFirstLetter(extension.status)],
          },
        ],
      })
    );

    setParticipantDetails(extensionData);
  }, [assessmentDetails]);

  const handleRequestExtensionModal = () => {
    const hasPendingStatus = assessmentDetails?.extension_request?.some(
      item => item.status === PENDING_STATUS
    );

    if (hasPendingStatus) {
      showToast(ExtensionsAlreadyRaised, "error");
      return;
    }
    setRequestExtensionModal(prevState => !prevState);
  };

  const getButtonTitle = status => {
    if (status === assessmentStatus.inProgress) {
      return Resume;
    } else if (status === assessmentStatus.yetToStart) {
      return Start;
    } else if (status === assessmentStatus.retake) {
      return Retake;
    }
  };

  const handleExam = () => {
    if (state?.isResume) {
      showToast(ExamAlreadyInProgress, "error");
      return;
    }

    if (state?.assessment?.assessment_type === assessmentTypesMap.competency) {
      dispatch(setLearnerAssessmentDetailsToInitial());
      return navigate("/self-assessment", {
        state: {
          participantId: state?.assessment?.participant_id,
        },
      });
    }
    if (state?.assessment?.status === assessmentStatus.yetToStart) {
      //Came via Start
      return navigate("/assessment-instruction", {
        state: {
          participantId: state?.assessment?.participant_id,
          name: state?.assessment?.name,
          termsAndCondition: state?.assessment?.term_and_condition,
        },
      });
    } else {
      //Came via Resume
      return navigate("/learning-assessment", {
        state: {
          participantId: state?.assessment?.participant_id,
          name: state?.assessment?.name,
        },
      });
    }
  };

  const isSelfAssessment = () => {
    return state?.assessment?.assessment_type === assessmentTypesMap.competency;
  };

  const handleSelfAssessment = () => {
    return navigate(
      `${process.env.PUBLIC_URL}/assessments/self-assessment-details`,
      {
        state: {
          participantId: state?.assessment?.participant_id,
          preview: true,
        },
      }
    );
  };

  const coolingPeriod = calculateDayDifference(
    assessmentDetails?.cooling_period_date
  );

  const findLastFailedAttempt = () => {
    if (assessmentDetails?.result?.length > 0) {
      const failedAssessments = assessmentDetails.result.filter(
        assessment => assessment.status === assessmentStatus.failed
      );

      if (failedAssessments.length === 0) {
        return null;
      }

      return failedAssessments.reduce((prev, current) =>
        prev.attempt_number > current.attempt_number ? prev : current
      );
    }
  };

  const assessments = assessmentDetails?.result ?? [];
  const sortedAssessments = [...assessments];
  sortedAssessments.sort(
    (firstAssessment, secondAssessment) =>
      firstAssessment?.attempt_number - secondAssessment?.attempt_number
  );

  return (
    <div className="page-body assessment-list-details">
      <CatlogAssessmentHeading
        heading={state?.assessment?.name}
        badgeText=""
        rows={[
          {
            columns: [
              {
                assign: AssessmentType,
                name: (() => {
                  if (
                    state?.assessment?.assessment_type ===
                      assessmentTypesMap.sga ||
                    state?.assessment?.assessment_type ===
                      assessmentTypesMap.course
                  ) {
                    return Course;
                  }

                  if (isSelfAssessment()) {
                    return SelfAssessment;
                  }

                  return capitalizeFirstLetter(
                    state?.assessment?.assessment_type
                  );
                })(),
                key: 1,
              },
              ...(!isSelfAssessment()
                ? [
                    {
                      assign: EstimatedTimer,
                      name: state?.assessment?.estimated_time,
                      key: 2,
                    },
                  ]
                : []),
              {
                assign: TotalQuestion,
                name: state?.assessment?.overall_question_count,
                key: 3,
              },
            ],
          },
        ]}
      />

      {participantDetails?.length > 0 && (
        <ParticipantsDetailsWithoutHeight
          showCard={false}
          sections={participantDetails}
          title="Participants Details"
        />
      )}

      {sortedAssessments?.length > 0 &&
        sortedAssessments.map((assessment, index) => (
          <Link
            to={`/assessment-list/assessment-details/attempts?participant_id=${state?.assessment?.participant_id}&assessment_id=${state?.assessment?.assessment_id}&assessment_result_id=${assessment?.assessment_result_id}`}
            key={index}
          >
            {assessment.status === examStatus.passed && (
              <PassScoreCard
                score={`${assessment.score}`}
                attemptOn={
                  assessment?.start_time
                    ? `${AttemptedOn} ${formatCustomDate(assessment?.start_time)}`
                    : null
                }
              />
            )}

            {assessment.status === examStatus.failed && (
              <FailedScoreCard
                score={assessment.score}
                message={YouFailed}
                attemptOn={`${getOrdinalWord(assessment.attempt_number)} ${AttemptOn} ${formatCustomDate(assessment?.start_time)}`}
                reattempDays={
                  findLastFailedAttempt()?.attempt_number ===
                    assessment.attempt_number && coolingPeriod > 0
                    ? `${ReattemptDays} ${coolingPeriod} ${Days}`
                    : ""
                }
              />
            )}
          </Link>
        ))}

      <RequestExtensionModal
        handleModal={handleRequestExtensionModal}
        requestExtensionModal={requestExtensionModal}
      />

      {!state.hideAction &&
        state?.assessment?.status !== assessmentStatus.underReview &&
        state?.assessment?.status !== assessmentStatus.passed &&
        state?.assessment?.status !== assessmentStatus.failed && (
          <FixedFooter>
            {state?.assessment?.status !== assessmentStatus.inProgress &&
              state?.assessment?.assessment_type !== assessmentTypesMap.sga &&
              state?.assessment?.assessment_type !==
                assessmentTypesMap.course &&
              !isSelfAssessment() && (
                <Btn
                  onClick={handleRequestExtensionModal}
                  outline
                  className="alert-light-primary"
                  color="primary"
                >
                  {RequestExtension}
                </Btn>
              )}
            {state.activeTab !== assessmentTabStatus.upcoming && (
              <Btn
                color="primary"
                onClick={handleExam}
                disabled={
                  state?.assessment?.status === assessmentStatus.retake &&
                  coolingPeriod > 0
                }
              >
                {getButtonTitle(state?.assessment?.status)}
              </Btn>
            )}
          </FixedFooter>
        )}

      {isSelfAssessment() && state.activeTab === assessmentTabStatus.past && (
        <FixedFooter>
          <Btn color="primary" onClick={handleSelfAssessment}>
            {ViewDetails}
          </Btn>
        </FixedFooter>
      )}
    </div>
  );
};

export default AssessmentListDetails;
