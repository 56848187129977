import { useLocation } from "react-router-dom";

import CalendarAvailabilityComponent from "../../../CommonElements/CalendarAvailability/CalendarAvailability";
import Loader from "../../../Layout/Loader/Loader";
import { useAppSelector } from "../../../ReduxToolkit/Hooks";
import { CalendarAvailabilityText } from "../../../utils/Constant";
import Header from "../../LearningAssessment/Header";

const CheckAvailability = () => {
  const { loading } = useAppSelector(state => state.loader);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const checkingFor = params.get("availability");
  const locationId = params.get("location_id");

  return (
    <>
      <Header name={CalendarAvailabilityText} />
      {loading && <Loader />}
      <CalendarAvailabilityComponent
        locationId={locationId}
        checkingFor={checkingFor}
      />
    </>
  );
};

export default CheckAvailability;
