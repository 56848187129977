import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";

import { Btn, H4 } from "../../AbstractElements";
import FixedFooter from "../../CommonElements/FixedFooter";
import ModuleCard from "../../CommonElements/ModuleCard";
import PassScoreCard from "../../CommonElements/PassScoreCard";
import RemedialAction from "../../container/RemedialAction";
import FailedScoreCard from "../../container/ScoreCardContainer";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks";
import { downloadAssessmentCertificate } from "../../ReduxToolkit/Reducers/AssessmentSlice";
import { getCourseContentByIds } from "../../ReduxToolkit/Reducers/CourseSlice";
import {
  YouFailed,
  ModuleDetails,
  Close,
  DownloadCertificate,
  AttemptedOn,
} from "../../utils/Constant";
import {
  examStatus,
  formatCustomDate,
  roundToNearestInteger,
} from "../../utils/helper/helper";
import Header from "../LearningAssessment/Header";

import WrongQuestions from "./WrongQuestions";

import "./style.scss";

const AssessmentDetails = () => {
  const [remedialActions, setRemedialActions] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const assessmentDetails = location.state?.assessmentDetails;

  const dispatch = useAppDispatch();
  const { courseContentByIds } = useAppSelector(state => state.course);

  useEffect(() => {
    const courseIds = assessmentDetails?.module_results
      ?.flatMap(item => item?.remedial_action?.courses || [])
      .filter(course => course !== undefined);
    if (courseIds?.length > 0) {
      dispatch(
        getCourseContentByIds({
          id: courseIds,
        })
      );
    }
  }, []);

  useEffect(() => {
    const formattedData = assessmentDetails?.module_results?.map(module => {
      const remedialCourses =
        courseContentByIds &&
        module?.remedial_action?.courses
          ?.map(courseId => courseContentByIds[courseId])
          .filter(course => course !== undefined);

      const mediaUrls = module?.remedial_action?.media_urls;
      const remedialNote = module?.remedial_action?.remedial_note;
      return {
        courses: remedialCourses,
        module_name: module.module_name,
        documents: mediaUrls,
        greeting: module.module_name,
        paths: mediaUrls,
        remedialNote: remedialNote,
      };
    });

    const mediaUrlsData = formattedData.map(
      ({ documents, greeting, paths }) => ({
        documents,
        greeting,
        paths,
      })
    );

    const remedialNoteData = formattedData.map(
      ({ module_name, remedialNote }) => ({
        module_name,
        remedialNote,
      })
    );
    setRemedialActions({
      corseData: formattedData.map(({ courses, module_name }) => ({
        courses,
        module_name,
      })),
      literature: mediaUrlsData,
      remedialNote: remedialNoteData,
    });
  }, [courseContentByIds, assessmentDetails?.module_results]);

  const handleDone = () => {
    if (location.state?.scheduleCourse) {
      return navigate("/schedule-list");
    }
    return navigate("/assessment-list");
  };

  const hasRemedialAction = assessmentDetails?.module_results?.some(
    module =>
      module.remedial_action && Object.keys(module.remedial_action).length > 0
  );

  const handleCertificate = () => {
    dispatch(
      downloadAssessmentCertificate({
        type: "assessment",
        assessmentResultId: assessmentDetails.assessment_result_id,
        name: assessmentDetails?.assessment_name,
      })
    );
  };

  return (
    <div className="assessment-details">
      <Container>
        <Header name={assessmentDetails?.assessment_name} />
        {assessmentDetails.status === examStatus.passed && (
          <PassScoreCard
            score={assessmentDetails.score}
            attemptOn={
              assessmentDetails?.start_time
                ? `${AttemptedOn} ${formatCustomDate(assessmentDetails?.start_time)}`
                : null
            }
          />
        )}
        {assessmentDetails.status === examStatus.failed && (
          <FailedScoreCard
            score={assessmentDetails.score}
            message={YouFailed}
          />
        )}

        <Card className="mt-2">
          <CardHeader>
            <H4>{ModuleDetails}</H4>
          </CardHeader>
          <CardBody className="mt-2">
            <Row>
              {assessmentDetails?.module_results.map((item, index) => (
                <Col xs="12" md="4" key={index} className="mb-3">
                  <ModuleCard
                    title={item.module_name}
                    score={`${item.score ? roundToNearestInteger(item.score) : 0}%`}
                  />
                </Col>
              ))}
            </Row>
          </CardBody>
        </Card>
        {assessmentDetails?.wrongly_answered_questions.length > 0 && (
          <WrongQuestions
            wrongQuestions={assessmentDetails?.wrongly_answered_questions}
          />
        )}
        {hasRemedialAction && (
          <RemedialAction remedialActions={remedialActions} />
        )}
      </Container>
      <FixedFooter>
        <FixedFooter>
          <Btn
            onClick={handleDone}
            outline
            className="alert-light-primary"
            color="primary"
          >
            {Close}
          </Btn>
          {assessmentDetails.status !== examStatus.failed && (
            <Btn onClick={handleCertificate} color="primary">
              {DownloadCertificate}
            </Btn>
          )}
        </FixedFooter>
      </FixedFooter>
    </div>
  );
};

export default AssessmentDetails;
