import React from "react";
import { Badge } from "reactstrap";

import { listStatusMapping } from "../../Api/constants";
import "./style.scss";
import MUIIcons from "../MUIIcon/MUIIcons";

interface Props {
  children?: React.ReactNode;
  className?: string;
  color?: string;
  pill?: boolean;
  onClick?: () => void;
  status?: string;
  badgeTextColor?: boolean;
}

const Badges: React.FC<Props> = ({
  children,
  status,
  className,
  badgeTextColor,
  ...rest
}: Props) => {
  const { message, color, icon } = listStatusMapping[status] || {
    message: "",
    color: "#e3e3e3",
    icon: "",
  };

  const badgeColor = message ? color : "#e3e3e3";
  const textColor = message ? "#ffffff" : "#000";
  const badgeIcon = message ? icon : "";
  const badgeText = "#000";

  const customStyle = {
    backgroundColor: badgeColor,
    color: badgeTextColor ? badgeText : textColor,
  };

  return (
    <Badge
      {...rest}
      color=""
      className={`badge-custom-radius ${className}`}
      style={customStyle}
    >
      {badgeIcon && (
        <MUIIcons className="icon mb-1" iconName={badgeIcon} size={10} />
      )}
      {children || message}
    </Badge>
  );
};

export default Badges;
