import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Table } from "reactstrap";

import { Badges, MUIIcons, H3, H4, H5, ToolTip } from "../../AbstractElements";
import CardHeaderDropDown from "../../CommonElements/CommonCardHeader/CardHeaderDropDown";
import "./style.scss";
import CommonModal from "../../CommonElements/Modal";
import { Knowledge, LearningOutcomes } from "../../utils/Constant";
import {
  capitalizeFirstLetter,
  getEllipsedFileName,
  sanitizeString,
} from "../../utils/helper/helper";
import useIsMobile from "../../utils/helper/responsive";

interface AssessmentData {
  skillName: string;
  lo: string[];
  type: string;
  questions?: number;
  modules?: string;
  assessmentDuration?: number;
  learnerDocs?: string;
  trainerDocs?: string;
  actions: string;
}

interface AssessmentTableProps {
  data: AssessmentData[];
  headers: string[];
  title: string;
  onClickItem?: (value, type) => void;
  setSelectedAssessment?: (value) => void;
  isEdit?: boolean;
  courseId?: any;
  showLastAction?: boolean;
}

const AssessmentTable: React.FC<AssessmentTableProps> = ({
  data,
  headers,
  title,
  onClickItem,
  setSelectedAssessment,
  isEdit = true,
  courseId,
  showLastAction = true,
}) => {
  const isMobile = useIsMobile();
  const [loData, setLoData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    if (isModalOpen) {
      setLoData([]);
    }
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <CommonModal
        sizeTitle={LearningOutcomes}
        modalBodyClassName=""
        isOpen={isModalOpen}
        toggle={toggleModal}
        backdrop="static"
        size="lg"
        showFooter
      >
        {loData?.map((item, index) => <div key={index}>• {item.trim()}</div>)}
      </CommonModal>
      <Card>
        <CardHeader>
          <H4>{title}</H4>
        </CardHeader>
        <CardBody className="pt-0 projects px-0">
          <div className="dataTables_wrapper">
            <div
              className={
                isMobile
                  ? "table-responsive theme-scrollbar"
                  : "theme-scrollbar"
              }
            >
              <Table
                className="table display dataTable no-footer"
                id="selling-product"
              >
                <thead className="light-background">
                  <tr>
                    {headers.map((header, index) => (
                      <th className=".b-b-secondary" key={index}>
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data.map((item: any, i) => {
                    return (
                      <tr key={i}>
                        {title === Knowledge && <td>{item.skillName}</td>}
                        {title !== Knowledge && (
                          <td className="lo-cell">
                            <div>
                              <H5 className="mt-3 d-flex gap-2">
                                {item?.lo[0]}{" "}
                                <Badges
                                  onClick={() => {
                                    setLoData(item.lo);
                                    toggleModal();
                                  }}
                                  className="pointer"
                                >
                                  {item.lo.length > 1 &&
                                    `${item.lo.length - 1}+`}
                                </Badges>
                              </H5>
                            </div>
                          </td>
                        )}
                        <td>
                          {item?.type === "ojt"
                            ? item?.type?.toString()?.toUpperCase()
                            : capitalizeFirstLetter(item?.type)}
                        </td>
                        {title === Knowledge && (
                          <td className="pointer">
                            <Link
                              to={
                                "/course-management/course-details/question-bank"
                              }
                              className={"pointer text-muted"}
                              state={{
                                questionBankId: item?.question_bank_id,
                                courseId: courseId,
                                selectedModules: item?.selected_module,
                              }}
                            >
                              {item.question_bank_name}
                            </Link>
                          </td>
                        )}
                        {item.questions && <td>{item.questions}</td>}
                        {item.modules && (
                          <td className="modules-cell">{item.modules}</td>
                        )}
                        {item.assessmentDuration && (
                          <td className="assessment-duration-cell">
                            {item.assessmentDuration}
                          </td>
                        )}
                        {item.learnerDocs && (
                          <td>
                            {item.learnerDocs?.map((doc, docIndex) => {
                              const uniqueId = `${i}-${docIndex}`;
                              return (
                                <H3 className="mt-2" key={docIndex}>
                                  <Badges color="light text-dark">
                                    <div className="d-flex align-items-center mb-1">
                                      <MUIIcons
                                        size={24}
                                        iconName="InsertDriveFileOutlined"
                                      />
                                      <span
                                        className="mt-1 mx-1"
                                        id={sanitizeString(
                                          `tooltip-course1-name-${uniqueId}`
                                        )}
                                      >
                                        {getEllipsedFileName(doc?.file_name)}
                                      </span>
                                      <ToolTip
                                        placement="top"
                                        target={sanitizeString(
                                          `tooltip-course1-name-${uniqueId}`
                                        )}
                                      >
                                        {doc?.file_name}
                                      </ToolTip>
                                    </div>
                                  </Badges>
                                </H3>
                              );
                            })}
                          </td>
                        )}
                        {item.trainerDocs && (
                          <td>
                            {item?.trainerDocs?.length > 0
                              ? item.trainerDocs?.map((doc, docIndex) => {
                                  const uniqueId2 = `${i}-${docIndex}-${docIndex + i}`;
                                  return (
                                    <H3 className="mt-2" key={docIndex}>
                                      <Badges color="light text-dark">
                                        <div className="d-flex align-items-center mb-1">
                                          <MUIIcons
                                            size={24}
                                            iconName="InsertDriveFileOutlined"
                                          />
                                          <span
                                            className="mt-1 mx-1"
                                            id={sanitizeString(
                                              `tooltip-course2-name-${uniqueId2}`
                                            )}
                                          >
                                            {getEllipsedFileName(
                                              doc?.file_name
                                            )}
                                          </span>
                                          <ToolTip
                                            placement="top"
                                            target={sanitizeString(
                                              `tooltip-course2-name-${uniqueId2}`
                                            )}
                                          >
                                            {doc?.file_name}
                                          </ToolTip>
                                        </div>
                                      </Badges>
                                    </H3>
                                  );
                                })
                              : "-"}
                          </td>
                        )}
                        {showLastAction && (
                          <td onClick={() => setSelectedAssessment(i)}>
                            <CardHeaderDropDown
                              mainTitle={
                                isEdit || (title === "Skill" && !isEdit)
                              }
                              firstItem={isEdit ? "Edit" : ""}
                              secondItem={isEdit ? "Delete" : ""}
                              thirdItem={
                                title === "Skill" || !isEdit ? "Download" : ""
                              }
                              onClickItem={value => onClickItem(value, title)}
                            />
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

AssessmentTable.propTypes = {
  data: PropTypes.array.isRequired,
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default AssessmentTable;
