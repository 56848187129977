import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { Card, CardBody, FormGroup, Input, Row, Col } from "reactstrap";

import LabelTooltip from "../../../CommonElements/LabelTooltip";
import CheckboxButton from "../../../CommonElements/SwitchButton";
import {
  globalSettingsStatusMap,
  globalSettingsTootlTipStatusMap,
  handleKeyDownOnNumericWithoutDecimal,
} from "../../../utils/helper/helper";

const EligibleListing = ({ settings, setSettings }) => {
  const [selectedFilterValues, setSelectedFilterValues] = useState({});

  const handleCheckboxChange = (filterName, checked) => {
    setSelectedFilterValues(prev => ({
      ...prev,
      [filterName]: checked,
    }));
  };

  const handleInputChange = (filterName, ruleName, value) => {
    if (value <= 100) {
      setSelectedFilterValues(prev => ({
        ...prev,
        [`${filterName}_${ruleName}`]: value,
      }));
    }
  };

  useEffect(() => {
    const updatedFilters = settings.filters?.map(filter => ({
      ...filter,
      enabled:
        selectedFilterValues[filter.name] !== undefined
          ? selectedFilterValues[filter.name]
          : filter.enabled,
      rules: Array.isArray(filter.rules)
        ? filter.rules.map(rule => ({
            ...rule,
            value:
              selectedFilterValues[`${filter.name}_${rule.name}`] !== undefined
                ? selectedFilterValues[`${filter.name}_${rule.name}`]
                : rule.value,
          }))
        : [],
    }));

    if (updatedFilters) {
      setSettings(prev => ({
        ...prev,
        filters: updatedFilters,
      }));
    }
  }, [selectedFilterValues]);

  return (
    <Card className="p-2">
      <CardBody>
        <Row>
          {settings.filters?.map(filter => {
            return (
              <Col xs={12} key={filter.id} className="mt-4">
                <div className="align-items-center">
                  <Row>
                    <Col>
                      <LabelTooltip
                        label={globalSettingsStatusMap[filter.name]}
                        tooltipText={`${globalSettingsTootlTipStatusMap[filter.name]}`}
                        important={false}
                        placement="bottom"
                      />
                    </Col>
                    <Col className="mt-1">
                      <CheckboxButton
                        onChange={e =>
                          handleCheckboxChange(filter.name, e.target.checked)
                        }
                        checked={
                          selectedFilterValues[filter.name] ?? filter.enabled
                        }
                      />
                    </Col>
                  </Row>
                </div>
                {Array.isArray(filter.rules) &&
                  filter.rules.length > 0 &&
                  filter.enabled && (
                    <div>
                      {filter.rules.map(rule => (
                        <FormGroup key={rule.name}>
                          <LabelTooltip
                            label={
                              globalSettingsStatusMap[
                                filter.name + "_" + rule.name
                              ]
                            }
                            tooltipText={`${globalSettingsTootlTipStatusMap[filter.name + "_" + rule.name]}`}
                            important={false}
                          />
                          <Input
                            type="number"
                            onChange={e =>
                              handleInputChange(
                                filter.name,
                                rule.name,
                                e.target.value
                              )
                            }
                            value={
                              selectedFilterValues[
                                `${filter.name}_${rule.name}`
                              ] ?? rule.value
                            }
                            onKeyDown={handleKeyDownOnNumericWithoutDecimal}
                            min={0}
                            max={100}
                          />
                        </FormGroup>
                      ))}
                    </div>
                  )}
              </Col>
            );
          })}
        </Row>
      </CardBody>
    </Card>
  );
};

EligibleListing.propTypes = {
  settings: PropTypes.shape({
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        enabled: PropTypes.bool.isRequired,
        rules: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired,
          })
        ).isRequired,
      })
    ).isRequired,
  }).isRequired,
  setSettings: PropTypes.func.isRequired,
};

export default EligibleListing;
