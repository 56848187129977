import React from "react";
import { Row, Col, Container } from "reactstrap";

import "./style.scss";
import { P, ToolTip } from "../../AbstractElements";
import { sanitizeString } from "../../utils/helper/helper";

interface Detail {
  label: string;
  value: string;
  key: string;
}

interface GenericModuleCardProps {
  details: Detail[];
}

const GenericModuleCard: React.FC<GenericModuleCardProps> = ({ details }) => {
  return (
    <Container className="generic-module-card p-2">
      {details?.map(
        (detail, index) =>
          index % 2 === 0 && (
            <Row key={detail.key} className="mt-2 generic-module-card-margin">
              <>
                <Col xs="6">
                  <div className="text-ellips-parent">
                    <P
                      className="text-ellips"
                      id={`tool_tip${sanitizeString(detail.key)}`}
                    >
                      {detail.label ? `${detail.label}:` : ""}
                      {detail.value}
                    </P>
                  </div>
                  <ToolTip
                    placement="top"
                    target={`tool_tip${sanitizeString(detail.key)}`}
                  >
                    {detail.value}
                  </ToolTip>
                </Col>
                {index + 1 < details.length && (
                  <Col xs="6">
                    <div className="text-ellips-parent">
                      <P
                        className="text-ellips"
                        id={`tool_tip${sanitizeString(details[index + 1].key)}`}
                      >
                        {details[index + 1].label
                          ? `${details[index + 1].label}:`
                          : ""}
                        {details[index + 1].value}
                      </P>
                    </div>
                    <ToolTip
                      placement="top"
                      target={`tool_tip${sanitizeString(details[index + 1].key)}`}
                    >
                      {details[index + 1].value}
                    </ToolTip>
                  </Col>
                )}
              </>
            </Row>
          )
      )}
    </Container>
  );
};

export default GenericModuleCard;
