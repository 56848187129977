import PropTypes from "prop-types";

import { UL, LI, Image, P, ToolTip } from "../../AbstractElements";
import { dynamicImage } from "../../Service";

const ImageList = ({ data, userMap }) => {
  return (
    <div className="customers social-group">
      <UL>
        {data?.map((item: { user_id: number; img: string }) => {
          return (
            <LI className="d-inline-block" key={item?.user_id}>
              {item.img !== "" ? (
                <ToolTip target={`tooltip-${item?.user_id}`} placement="top">
                  {userMap[item?.user_id]?.user_name}
                </ToolTip>
              ) : (
                <P className="bg-light rounded-circle">5+</P>
              )}
              <Image
                id={`tooltip-${item?.user_id}`}
                className="img-30 rounded-circle"
                src={dynamicImage(`dashboard-2/user/${item.img}`)}
                alt="users"
              />
            </LI>
          );
        })}
      </UL>
    </div>
  );
};

ImageList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      img: PropTypes.string.isRequired,
      user_id: PropTypes.string.isRequired,
    })
  ),
  userMap: PropTypes.objectOf(
    PropTypes.shape({
      user_name: PropTypes.string,
    })
  ),
};

export default ImageList;
