import PropTypes from "prop-types";
import { Col, Pagination, PaginationItem, PaginationLink } from "reactstrap";

import { Next, Prev } from "../../utils/Constant";
import "./style.scss";

const DefaultPagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePageClick = pageNumber => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      onPageChange(pageNumber);
    }
  };

  const getPageNumbers = () => {
    const visiblePages = [];
    const maxPageNumbers = 5;
    let startPage = currentPage - Math.floor(maxPageNumbers / 2);
    let endPage = currentPage + Math.floor(maxPageNumbers / 2);

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(maxPageNumbers, totalPages);
    }

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, totalPages - maxPageNumbers + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      visiblePages.push(i);
    }

    return visiblePages;
  };

  const visiblePages = getPageNumbers();

  return (
    <Col md="6">
      <Pagination
        className="pagination-primary pagin-border-primary"
        aria-label="Page navigation example"
      >
        <PaginationItem disabled={currentPage === 1}>
          <PaginationLink
            previous
            onClick={() => handlePageClick(currentPage - 1)}
          >
            {Prev}
          </PaginationLink>
        </PaginationItem>

        {currentPage > 3 && (
          <PaginationItem>
            <PaginationLink onClick={() => handlePageClick(1)}>
              1
            </PaginationLink>
          </PaginationItem>
        )}

        {currentPage > 3 && (
          <PaginationItem disabled>
            <PaginationLink>...</PaginationLink>
          </PaginationItem>
        )}

        {visiblePages.map(number => (
          <PaginationItem key={number} active={number === currentPage}>
            <PaginationLink onClick={() => handlePageClick(number)}>
              {number}
            </PaginationLink>
          </PaginationItem>
        ))}

        {currentPage < totalPages - 2 && (
          <PaginationItem disabled>
            <PaginationLink>...</PaginationLink>
          </PaginationItem>
        )}

        {currentPage < totalPages - 2 && (
          <PaginationItem>
            <PaginationLink onClick={() => handlePageClick(totalPages)}>
              {totalPages}
            </PaginationLink>
          </PaginationItem>
        )}

        <PaginationItem disabled={currentPage === totalPages}>
          <PaginationLink next onClick={() => handlePageClick(currentPage + 1)}>
            {Next}
          </PaginationLink>
        </PaginationItem>
      </Pagination>
    </Col>
  );
};

DefaultPagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default DefaultPagination;
