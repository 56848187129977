import { useCallback, useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";

import { Btn, H3, MUIIcons } from "../../../AbstractElements";
import { UpsertLocationPayload } from "../../../Api/entities/ManageMasterDataEntity";
import MasterDataAccordion from "../../../CommonElements/MasterDataAccordion";
import MasterDataAddNewModal from "../../../CommonElements/MasterDataAddNewModal";
import CommonModal from "../../../CommonElements/Modal";
import SearchBar from "../../../CommonElements/SearchBar";
import NoData from "../../../container/NoData";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import {
  createSubLocation,
  deleteSubLocation,
  getManageMasterLocationList,
  getManageMasterSubLocationList,
  getManageMasterSubLocationListById,
  setSubLocationListByIdToInitialValue,
  updateSubLocation,
} from "../../../ReduxToolkit/Reducers/ManageMasterDataSlice";
import {
  AddNew,
  Enter,
  Location,
  NoDataText,
  NotYetAdded,
  Select,
  Confirm,
  LocationAddDescriptionToolTipMessage,
  SubLocations,
  SubLocation,
  SubLocationEmptyDescription,
  Asset,
  DeleteSubLocationHeader,
  DeleteSubLocationMessage,
} from "../../../utils/Constant";
import { mapListToOptions } from "../../../utils/helper/helper";
import {
  getFromLocalStorage,
  LOGGED_IN_USER,
} from "../../../utils/helper/localStorageutils";

const SubLocationPage = () => {
  const dispatch = useAppDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const loginData = getFromLocalStorage(LOGGED_IN_USER);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
    useState(false);
  const [selectedLocationId, setSelectedLocationId] = useState("");
  const [locationListData, setLocationListData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [modalFormValues, setModalFormValues] = useState({
    name: "",
    description: "",
    selectedOption: "",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [accordionData, setAccordionData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [locationToDelete, setLocationToDelete] = useState(null);

  const { locationList, subLocationList, subLocationListById, loading } =
    useAppSelector(state => state.manageMasterData);

  const toggleModal = () => setModalOpen(!modalOpen);
  const toggleDeleteConfirmModal = () =>
    setIsDeleteConfirmModalOpen(!isDeleteConfirmModalOpen);

  useEffect(() => {
    dispatch(getManageMasterSubLocationList());
    if (!modalOpen) {
      dispatch(getManageMasterLocationList());
    }
  }, [dispatch]);

  useEffect(() => {
    const locs = mapListToOptions(locationList?.location, "name", "id");
    setLocationListData(locs);
  }, [locationList]);

  useEffect(() => {
    setAccordionData([]);
    if (subLocationList?.sub_location?.length > 0) {
      setAccordionData(
        subLocationList?.sub_location?.map(location => ({
          id: location.id.toString(),
          name: location.name,
          description: location.description,
          createdOn: location.created_at,
          modifiedOn: location.updated_at,
          depedentBadges: [
            { id: 1, badge: `${Asset}: ${location?.asset_count}` },
          ],
        }))
      );
    }
  }, [subLocationList]);

  useEffect(() => {
    if (searchQuery) {
      const filtered = accordionData?.filter(location =>
        location.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(accordionData);
    }
  }, [searchQuery, accordionData]);

  useEffect(() => {
    if (subLocationListById) {
      setModalFormValues(prevState => ({
        ...prevState,
        name: subLocationListById.name,
        description: subLocationListById.description,
        selectedOption: subLocationListById.location_id.toString(),
      }));
    }
  }, [subLocationListById]);

  const handleSave = useCallback(
    (name: string, description: string, locationId: string) => {
      const payload: UpsertLocationPayload = {
        name,
        description,
        location_id: parseInt(locationId),
      };

      if (subLocationListById) {
        payload.id = subLocationListById.id;
        dispatch(updateSubLocation({ createLocationPayload: payload })).then(
          () => {
            dispatch(getManageMasterSubLocationList());
            toggleModal();
          }
        );
      } else {
        dispatch(createSubLocation({ createLocationPayload: payload })).then(
          () => {
            dispatch(getManageMasterSubLocationList());
            toggleModal();
          }
        );
      }
    },
    [dispatch, toggleModal]
  );

  const handleEdit = (id: string) => {
    setIsEdit(true);
    const selectedId = parseInt(id);
    dispatch(getManageMasterSubLocationListById({ id: selectedId }));
    toggleModal();
  };

  const handleDelete = (id: string) => {
    const selectedId = parseInt(id);
    setLocationToDelete(selectedId);
    toggleDeleteConfirmModal();
  };

  const confirmDelete = () => {
    if (locationToDelete) {
      dispatch(deleteSubLocation({ id: locationToDelete })).then(() => {
        dispatch(getManageMasterSubLocationList());
        toggleDeleteConfirmModal();
      });
    }
  };
  const renderContent = () => {
    if (loading) {
      return null;
    }

    if (accordionData?.length === 0) {
      return (
        <NoData
          svg={"empty-folder-icon"}
          title={NotYetAdded}
          description={SubLocationEmptyDescription}
          buttonText={AddNew}
          onclick={toggleModal}
        />
      );
    }

    if (filteredData?.length === 0) {
      return <NoData svg={"empty-folder-icon"} title={NoDataText} />;
    }

    return filteredData?.map(item => (
      <MasterDataAccordion
        key={item.id}
        accordionItems={item}
        onEditClick={() => handleEdit(item.id)}
        onDeleteClick={() => handleDelete(item.id)}
        loginData={loginData}
      />
    ));
  };

  return (
    <div className="page-body page-body-margin">
      <CommonModal
        sizeTitle={DeleteSubLocationHeader}
        isOpen={isDeleteConfirmModalOpen}
        toggle={toggleDeleteConfirmModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={confirmDelete}
        primaryBtnText={Confirm}
      >
        {DeleteSubLocationMessage}
      </CommonModal>
      <Card>
        <CardBody>
          <div className="d-flex flex-row align-items-center">
            <H3 className="fw-bold">{SubLocations}</H3>
            <div className="d-flex justify-content-end align-items-center mt-sm-0 mt-2 gap-3 ms-auto">
              <SearchBar
                showSearch
                className="mb-2"
                onSearch={query => setSearchQuery(query)}
              />
              <Btn
                icon={
                  <MUIIcons iconName="AddCircleOutlineOutlined" size={16} />
                }
                color="primary"
                onClick={() => {
                  dispatch(setSubLocationListByIdToInitialValue());
                  setSelectedLocationId("");
                  setIsEdit(false);
                  setModalFormValues({
                    name: "",
                    description: "",
                    selectedOption: "",
                  });
                  toggleModal();
                }}
              >
                {AddNew}
              </Btn>
            </div>
          </div>
        </CardBody>
      </Card>

      {renderContent()}

      <MasterDataAddNewModal
        isEdit={isEdit}
        isOpen={modalOpen}
        toggle={toggleModal}
        onSave={handleSave}
        labelText={SubLocation}
        placeholderText={`${Enter} ${SubLocation}`}
        toolTipText={`${Enter} ${SubLocation}`}
        initialValues={modalFormValues}
        dropdownLabel={Location}
        dropdownLabelTooltip={`${Select} ${Location}`}
        dropdownOptions={locationListData}
        selectedDropdownValue={selectedLocationId}
        onDropdownChange={value => setSelectedLocationId(value)}
        toolTipDescriptionText={LocationAddDescriptionToolTipMessage}
      />
    </div>
  );
};

export default SubLocationPage;
