import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";

import { Btn, H4 } from "../../../../AbstractElements";
import FixedFooter from "../../../../CommonElements/FixedFooter";
import ModuleCard from "../../../../CommonElements/ModuleCard";
import PassScoreCard from "../../../../CommonElements/PassScoreCard";
import CatlogAssessmentHeading from "../../../../container/CatlogAssessmentHeading";
import RemedialAction from "../../../../container/RemedialAction";
import FailedScoreCard from "../../../../container/ScoreCardContainer";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import {
  getAssessmentDetailsByParticipantId,
  downloadAssessmentCertificate,
} from "../../../../ReduxToolkit/Reducers/AssessmentSlice";
import { getCourseContentByIds } from "../../../../ReduxToolkit/Reducers/CourseSlice";
import {
  AssessmentType,
  AttemptedOn,
  AttemptOn,
  Course,
  DownloadCertificate,
  EstimatedTimer,
  ModuleDetails,
  SelfAssessment,
  TotalDuration,
  YouFailed,
} from "../../../../utils/Constant";
import {
  assessmentTypesMap,
  capitalizeFirstLetter,
  examStatus,
  formatCustomDate,
  getOrdinalWord,
  roundToNearestInteger,
} from "../../../../utils/helper/helper";

import "./style.scss";

const Attempts = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { courseContentByIds } = useAppSelector(state => state.course);

  const [remedialActions, setRemedialActions] = useState(null);
  const [filteredAssessment, setFilteredAssessment] = useState(null);

  const queryParams = new URLSearchParams(location.search);
  const participantId = queryParams.get("participant_id");
  const assessmentId = queryParams.get("assessment_id");
  const assessmentResultId = queryParams.get("assessment_result_id");
  const { assessmentDetails } = useAppSelector(state => state.assessment);

  useEffect(() => {
    dispatch(
      getAssessmentDetailsByParticipantId({
        id: Number(participantId),
      })
    );
  }, []);

  useEffect(() => {
    const courseIds = filteredAssessment?.module_results
      ?.flatMap(item => item?.remedial_action?.courses || [])
      .filter(course => course !== undefined);
    if (courseIds?.length > 0) {
      dispatch(
        getCourseContentByIds({
          id: courseIds,
        })
      );
    }
  }, [filteredAssessment]);

  useEffect(() => {
    const assessment = assessmentDetails?.result?.find(
      item =>
        item.assessment_id === Number(assessmentId) &&
        item.assessment_result_id === Number(assessmentResultId)
    );
    const formattedData = assessment?.module_results?.map(module => {
      const remedialCourses =
        courseContentByIds &&
        module?.remedial_action?.courses
          ?.map(courseId => courseContentByIds[courseId])
          .filter(course => course !== undefined);

      const mediaUrls = module?.remedial_action.media_urls;
      const remedialNote = module?.remedial_action.remedial_note;
      return {
        courses: remedialCourses,
        module_name: module.module_name,
        documents: mediaUrls,
        greeting: module.module_name,
        paths: mediaUrls,
        remedialNote: remedialNote,
      };
    });

    const mediaUrlsData = formattedData?.map(
      ({ documents, greeting, paths }) => ({
        documents,
        greeting,
        paths,
      })
    );

    const remedialNoteData = formattedData?.map(
      ({ module_name, remedialNote }) => ({
        module_name,
        remedialNote,
      })
    );
    setRemedialActions({
      corseData: formattedData?.map(({ courses, module_name }) => ({
        courses,
        module_name,
      })),
      literature: mediaUrlsData,
      remedialNote: remedialNoteData,
    });
    setFilteredAssessment(assessment);
  }, [assessmentDetails, courseContentByIds]);

  const isSelfAssessment = () => {
    return assessmentDetails?.assessment_type === assessmentTypesMap.competency;
  };

  const handleCertificate = () => {
    dispatch(
      downloadAssessmentCertificate({
        type: "assessment",
        assessmentResultId: filteredAssessment.assessment_result_id,
        name: assessmentDetails?.assessment_name,
      })
    );
  };

  const hasRemedialAction = filteredAssessment?.module_results?.some(
    module =>
      module.remedial_action && Object.keys(module.remedial_action).length > 0
  );

  return (
    <div className="page-body attempts">
      <CatlogAssessmentHeading
        heading={assessmentDetails?.assessment_name}
        rows={[
          {
            columns: [
              {
                assign: AssessmentType,
                name: (() => {
                  if (
                    assessmentDetails?.assessment_type ===
                      assessmentTypesMap.sga ||
                    assessmentDetails?.assessment_type ===
                      assessmentTypesMap.course
                  ) {
                    return Course;
                  }

                  if (isSelfAssessment()) {
                    return SelfAssessment;
                  }

                  return capitalizeFirstLetter(
                    assessmentDetails?.assessment_type
                  );
                })(),
                key: 1,
              },
              {
                assign: EstimatedTimer,
                name: assessmentDetails?.estimated_time?.toString(),
                key: 2,
              },
              {
                assign: TotalDuration,
                name: assessmentDetails?.overall_question_count?.toString(),
                key: 3,
              },
            ],
          },
        ]}
        dateRange={{
          startDate: formatCustomDate(assessmentDetails?.start_date),
          endDate: formatCustomDate(assessmentDetails?.end_date),
        }}
      />

      {filteredAssessment?.status === examStatus.passed && (
        <PassScoreCard
          score={`${filteredAssessment.score}`}
          attemptOn={
            filteredAssessment?.start_time
              ? `${AttemptedOn} ${formatCustomDate(filteredAssessment?.start_time)}`
              : null
          }
        />
      )}
      {filteredAssessment?.status === examStatus.failed && (
        <FailedScoreCard
          score={filteredAssessment.score}
          message={YouFailed}
          attemptOn={
            filteredAssessment?.start_time
              ? `${getOrdinalWord(filteredAssessment.attempt_number)} ${AttemptOn} ${formatCustomDate(filteredAssessment?.start_time)}`
              : null
          }
        />
      )}

      <Card className="mt-2">
        <CardHeader>
          <H4>{ModuleDetails}</H4>
        </CardHeader>
        <CardBody className="mt-2">
          <Row>
            {filteredAssessment?.module_results.map((item, index) => (
              <Col xs="12" md="4" key={index} className="mb-3">
                <ModuleCard
                  title={item.module_name}
                  score={`${item.score ? roundToNearestInteger(item.score) : 0}%`}
                />
              </Col>
            ))}
          </Row>
        </CardBody>
      </Card>
      {hasRemedialAction && (
        <RemedialAction remedialActions={remedialActions} />
      )}
      {filteredAssessment?.status !== examStatus.failed && (
        <FixedFooter>
          <Btn onClick={handleCertificate} color="primary">
            {DownloadCertificate}
          </Btn>
        </FixedFooter>
      )}
    </div>
  );
};

export default Attempts;
