import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useLocation, useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";

import {
  DefaultButton,
  H4,
  MUIIcons,
  SecondaryButton,
  ToolTip,
} from "../../../AbstractElements";
import {
  ILT_VILT_COURSE_FILTER_STATUS,
  PUBLISHED,
  UNPUBLISHED,
} from "../../../Api/constants";
import {
  FormValues,
  DropdownOption,
  MultiselectOption,
  SelectedCourse,
} from "../../../Api/entities/SgaEntity";
import { parseSgaRequestData } from "../../../Api/parser/sga";
import CurriculumCourseCard from "../../../CommonElements/CurriculumCourseCard";
import Divider from "../../../CommonElements/Divider";
import FilterSliderModal from "../../../CommonElements/FilterSliderModal";
import LabelTooltip from "../../../CommonElements/LabelTooltip";
import CommonModal from "../../../CommonElements/Modal";
import MultiSelectDropdown from "../../../CommonElements/MultiSelectDropdown";
import SearchDropDownPaginator from "../../../CommonElements/SearchDropDownPaginator";
import CheckboxButton from "../../../CommonElements/SwitchButton";
import AuditLog from "../../../container/AuditLog";
import AssessmentCard from "../../../container/GenericCard";
import VersionControl from "../../../container/VersionControl";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import { getGradeList } from "../../../ReduxToolkit/Reducers/MasterSlice";
import { getReAttemptPolicyList } from "../../../ReduxToolkit/Reducers/PolicySilce";
import {
  deleteSgaCourse,
  getAllSelfAssessment,
  getCurriculumByRoleId,
  getSgaById,
  mapToSga,
  setSgaDetailsToInitials,
} from "../../../ReduxToolkit/Reducers/SgaSlice";
import {
  Course,
  Duration,
  Grade,
  Select,
  Enter,
  EffectiveFromDate,
  ReAttemptPolicy,
  AuditLogs,
  MapSGA,
  Type,
  Question,
  Module,
  Time,
  CourseIsRequired,
  GradeIsRequired,
  DurationTypeIsRequired,
  DurationIsRequired,
  EffectiveFromDateIsRequired,
  ReAttemptPolicyIsRequired,
  PleaseProvideRequiredInformation,
  CourseType,
  DeleteCourse,
  Confirmation,
  Confirm,
  SGASubmitAlert,
  SelfAssessment,
  AddValidNumberInRange99,
  SelfAssessmentToolTipMessage,
  SGACourseToolTipMessage,
  GradeToolTipMessage,
  DurationTypeToolTipMessage,
  EffectiveFromDateToolTipMessage,
  ReAttemptPolicyToolTipMessage,
  VersionControlText,
  IsAutoAssign,
} from "../../../utils/Constant";
import {
  sgaDurationOptions,
  mapListToOptions,
  scrollToTop,
  iltViltFilterDropdownList,
  handleKeyDownOnNumericWithoutDecimal,
  showToast,
  assessmentTypesMap,
  customStyles,
} from "../../../utils/helper/helper";
import "./style.scss";
import useIsMobile from "../../../utils/helper/responsive";

interface FormErrors {
  course: string;
  grade: string;
  durationType: string;
  duration: string;
  effectiveFromDate: string;
  reAttemptPolicy: string;
}

interface LocationState {
  role_id: number;
  level_id: number;
  title: string;
  level: string;
  id?: number;
}

const MapSga: React.FC = () => {
  const [competencyOptions, setCompetencyOptions] = useState<DropdownOption[]>(
    []
  );
  const [gradeOptions, setGradeOptions] = useState<MultiselectOption[]>([]);
  const [competencyData, setCompetencyData] = useState<any>({});
  const [courseOptions, setCourseOptions] = useState<SelectedCourse[]>([]);
  const [courseOptionsCopy, setCourseOptionsCopy] = useState<
    SelectedCourse[] | null
  >(null);
  const [selectedCourses, setSelectedCourses] = useState<SelectedCourse[]>([]);
  const [selectedGrades, setSelectedGrades] = useState<MultiselectOption[]>([]);
  const [reAttemptPolicyOptions, setReAttemptPolicyOptions] = useState<
    DropdownOption[]
  >([]);
  const [showActivityLog, setShowActivityLog] = useState<boolean>(false);

  const [formValues, setFormValues] = useState<FormValues>({
    selfRatingAssessment: "",
    isMappable: false,
    durationType: "",
    duration: null,
    effectiveFromDate: new Date(),
    reAttemptPolicy: null,
  });

  const [errors, setErrors] = useState<FormErrors>({
    course: "",
    grade: "",
    durationType: "",
    duration: "",
    effectiveFromDate: "",
    reAttemptPolicy: "",
  });
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});
  const [sgaDetailsData, setSgaDetailsData] = useState(null);
  const [isShowVersionModal, setIsShowVersionModal] = useState(false);
  const [modalInfo, setModalInfo] = useState(null);

  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();

  const { selfAssessmentList, courseList, sgaDetails } = useAppSelector(
    state => state.sga
  );

  const { gradeList } = useAppSelector(state => state.master);
  const { policyList } = useAppSelector(state => state.policy);
  const location = useLocation();
  const { state } = location as { state: LocationState };
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getAllSelfAssessment());
    dispatch(
      getCurriculumByRoleId({
        role_id: state?.role_id,
        level_id: state.level_id,
        course_type: ILT_VILT_COURSE_FILTER_STATUS,
      })
    ).then(result => {
      const data = getCourseOptions(result?.payload?.courses);
      setCourseOptionsCopy(data);
    });
    dispatch(getGradeList());
    dispatch(getReAttemptPolicyList({}));
  }, [dispatch, state?.id, state?.role_id]);

  useEffect(() => {
    if (state?.id) {
      dispatch(getSgaById({ id: state.id }));
    }
  }, [state?.id]);

  useEffect(() => {
    if (selfAssessmentList?.assessments) {
      const options = mapListToOptions(
        selfAssessmentList.assessments,
        "name",
        "id"
      );
      setCompetencyOptions(options);
    }
  }, [selfAssessmentList]);

  useEffect(() => {
    if (sgaDetails?.assessment_id) {
      findAssessmentById(sgaDetails.assessment_id.toString());
    }
  }, [selfAssessmentList, sgaDetails]);

  useEffect(() => {
    if (policyList?.policy) {
      const optionsData = mapListToOptions(policyList.policy, "name", "id");
      setReAttemptPolicyOptions(optionsData);
    }
  }, [policyList]);

  const isArchive = () => {
    return state?.id && sgaDetailsData?.status === UNPUBLISHED;
  };

  const getCourseOptions = courses => {
    const data = courses?.map(course => ({
      label: course.course_name,
      value: course.course_name,
      name: course.course_id.toString(),
      course_type: course.course_type,
      isNew: true,
    }));
    return data;
  };

  useEffect(() => {
    const data = getCourseOptions(courseList?.courses);
    setCourseOptions(data);
  }, [courseList, state?.level]);

  useEffect(() => {
    const data = gradeList?.grades?.map(grade => ({
      label: grade.name,
      value: grade.name,
      name: grade?.id.toString(),
    }));
    setGradeOptions(data);
  }, [gradeList]);

  useEffect(() => {
    const matched = gradeOptions.filter(grade =>
      sgaDetailsData?.grades?.includes(parseInt(grade.name))
    );
    setSelectedGrades(matched);
  }, [gradeOptions, sgaDetailsData]);

  useEffect(() => {
    if (sgaDetails?.id) {
      setFormValues({
        selfRatingAssessment: sgaDetails?.assessment_id
          ? sgaDetails.assessment_id.toString()
          : "",
        durationType: sgaDetails.duration_type,
        duration: sgaDetails.duration_count,
        effectiveFromDate: new Date(sgaDetails.effective_from),
        reAttemptPolicy: sgaDetails.policy_id,
        isMappable:
          sgaDetails?.is_mappable && sgaDetails?.is_mappable !== null
            ? true
            : false,
      });
      const courseData = courseList?.courses
        .filter(course => sgaDetails?.courses?.includes(course?.course_id))
        .map(course => ({
          label: course?.course_name,
          value: course?.course_name,
          name: course?.course_id.toString(),
          course_type: course?.course_type,
          isNew: false,
        }));
      setSelectedCourses(courseData);
      setSgaDetailsData(sgaDetails);
    }

    return () => {
      dispatch(setSgaDetailsToInitials());
    };
  }, [sgaDetails, courseList]);

  const moduleDetails = [
    {
      label: Type,
      value:
        competencyData?.assessment_type === assessmentTypesMap.competency
          ? SelfAssessment
          : competencyData?.assessment_type,
      key: `${competencyData?.id}_${Type}`,
    },
    {
      label: Question,
      value: competencyData?.overall_question_count,
      key: `${competencyData?.id}_${Question}`,
    },
    {
      label: Module,
      value: competencyData?.module_count,
      key: `${competencyData?.id}_${Module}`,
    },
    {
      label: Time,
      value: competencyData?.estimated_time,
      key: `${competencyData?.id}_${Time}`,
    },
  ];

  const handleDateChange = (name: string, date: Date) => {
    setFormValues(prevState => ({
      ...prevState,
      [name]: date || null,
    }));
  };

  const handleChange = e => {
    const { name, value } = e.target;

    setFormValues(prevState => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "duration" && (Number(value) > 99 || value.length > 2)) {
      setFormValues(prevState => ({
        ...prevState,
        [name]: "",
      }));
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: AddValidNumberInRange99,
      }));
      return;
    }

    if (name === "selfRatingAssessment") {
      findAssessmentById(value);
    }

    setErrors(prevErrors => ({ ...prevErrors, [name]: "" }));
  };

  const findAssessmentById = value => {
    const findAssessmentById = selfAssessmentList?.assessments.find(
      (assessment: { id: number }) => assessment.id.toString() === value
    );
    setCompetencyData(findAssessmentById);
  };

  const handleCourseChange = async (e: string[]) => {
    const value = courseOptionsCopy
      .filter(option => e.includes(option.name))
      .map(item => {
        const isPresent = sgaDetailsData?.courses?.includes(Number(item.name));
        return {
          ...item,
          isNew: isPresent ? false : true,
        };
      });
    setSelectedCourses(value);
  };

  const handleDelete = async (item: MultiselectOption) => {
    handleUpdateItem(item);
    setModalInfo(null);
  };

  const handleUpdateItem = (item: MultiselectOption) => {
    const filteredCourse = selectedCourses.filter(
      element => element.name !== item.name
    );
    setSelectedCourses(filteredCourse);
  };

  const handleDeleteCourse = async (course: number[]) => {
    const payload = {
      id: state.id,
      courses: course,
    };
    return await dispatch(deleteSgaCourse({ courseContent: payload }));
  };

  const handleGradeChange = (e: string[]) => {
    const value = gradeOptions.filter(grade => e.includes(grade.name));
    setSelectedGrades(value);
  };

  const validateForm = () => {
    let isValid = true;

    const requiredFields = {
      course: selectedCourses.length === 0 ? CourseIsRequired : "",
      grade: selectedGrades.length === 0 ? GradeIsRequired : "",
      durationType: !formValues.durationType ? DurationTypeIsRequired : "",
      duration:
        !formValues.duration || formValues.duration <= 0
          ? DurationIsRequired
          : "",
      effectiveFromDate: !formValues.effectiveFromDate
        ? EffectiveFromDateIsRequired
        : "",
      reAttemptPolicy: !formValues.reAttemptPolicy
        ? ReAttemptPolicyIsRequired
        : "",
    };

    const newErrors = {
      course: requiredFields.course || "",
      grade: requiredFields.grade || "",
      durationType: requiredFields.durationType || "",
      duration: requiredFields.duration || "",
      effectiveFromDate: requiredFields.effectiveFromDate || "",
      reAttemptPolicy: requiredFields.reAttemptPolicy || "",
    };

    isValid = !Object.values(newErrors).some(error => error !== "");
    setErrors(newErrors);

    if (!isValid) {
      showToast(PleaseProvideRequiredInformation, "error");
      scrollToTop();
    }

    return isValid;
  };

  const handleSubmit = async () => {
    const isValid = validateForm();

    if (!isValid) {
      return;
    }

    setModalInfo({
      isOpen: true,
      title: SGASubmitAlert,
      callback: () => {
        handleSubmitSga();
      },
    });
  };

  const handleSubmitSga = async () => {
    const payload = parseSgaRequestData(
      formValues,
      state.role_id,
      state.level_id,
      selectedCourses,
      selectedGrades
    );

    if (state.id) {
      payload.id = state.id;
    }

    if (sgaDetailsData?.status === PUBLISHED) {
      const coursees = selectedCourses?.map(item => parseInt(item.name, 10));
      payload.courses = coursees;
    }

    if (sgaDetailsData?.id) {
      const courseIds = selectedCourses.map(course => Number(course.name));
      const missingIds = sgaDetailsData?.courses?.filter(
        id => !courseIds.includes(id)
      );
      if (sgaDetailsData?.status !== PUBLISHED && missingIds.length > 0) {
        const response = await handleDeleteCourse(missingIds);
        if (!response.payload) {
          return;
        }
      }
    }

    dispatch(mapToSga({ sgaContentPayload: payload })).then(response => {
      closeModal();
      if (response?.payload) {
        return navigate(
          `${process.env.PUBLIC_URL}/master-data/skill-gap-analysis`
        );
      }
    });
  };

  const handleActivityModal = () => {
    setShowActivityLog(prevState => !prevState);
  };

  const handleDone = async (values: { [key: string]: string[] }) => {
    const result = await dispatch(
      getCurriculumByRoleId({
        role_id: state?.role_id,
        level_id: state.level_id,
        course_type:
          !values?.course_type || values?.course_type?.length === 0
            ? ILT_VILT_COURSE_FILTER_STATUS
            : values.course_type,
      })
    );

    const data = getCourseOptions(result?.payload?.courses);
    setCourseOptions(data);
  };

  const handleClear = async () => {
    const result = await dispatch(
      getCurriculumByRoleId({
        role_id: state?.role_id,
        level_id: state.level_id,
        course_type: ILT_VILT_COURSE_FILTER_STATUS,
      })
    );
    const data = getCourseOptions(result?.payload?.courses);
    setCourseOptions(data);
    setSelectedFilterValues({});
    setDefaultFilterValues({});
  };

  const handleVersionModal = () => {
    setIsShowVersionModal(prevState => !prevState);
  };

  const handleClickVersion = element => {
    handleVersionModal();
    if (state?.id) {
      dispatch(getSgaById({ id: element.id }));
    }
  };

  const handleConfirmDelete = item => {
    setModalInfo({
      isOpen: true,
      title: DeleteCourse,
      callback: () => handleDelete(item),
    });
  };

  const closeModal = () => {
    setModalInfo(null);
  };

  return (
    <div className="page-body map-sga pb-5">
      <CommonModal
        sizeTitle={Confirmation}
        modalBodyClassName=""
        isOpen={modalInfo?.isOpen}
        toggle={closeModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={modalInfo?.callback}
        primaryBtnText={Confirm}
      >
        {modalInfo?.title}
      </CommonModal>
      <Card className="mt-4">
        <CardHeader className="d-flex justify-content-between align-items-center">
          <H4>{`${state?.title}-(${state?.level})`}</H4>
          {!!state?.id && (
            <span id="versionControl">
              <MUIIcons
                onClick={handleVersionModal}
                size={24}
                iconName="ManageHistoryOutlined"
                className="primary-icon-color pointer"
              />
            </span>
          )}

          {!!state?.id && (
            <ToolTip
              placement="top"
              target="versionControl"
              disabled={isMobile}
            >
              {VersionControlText}
            </ToolTip>
          )}
        </CardHeader>
        <CardBody>
          <Form
            className="stepper-one g-3 needs-validation custom-input"
            noValidate
          >
            <Row>
              <Col sm="12" lg="6">
                <FormGroup>
                  <LabelTooltip
                    label={SelfAssessment}
                    tooltipText={SelfAssessmentToolTipMessage}
                    important={false}
                  />
                  <ReactSelect
                    name="selfRatingAssessment"
                    className="text-gray"
                    onChange={selectedOption =>
                      handleChange({
                        target: {
                          name: "selfRatingAssessment",
                          value: selectedOption ? selectedOption.value : "",
                        },
                      })
                    }
                    value={
                      competencyOptions?.find(
                        option =>
                          option.value === formValues.selfRatingAssessment
                      ) || null
                    }
                    options={competencyOptions?.map(option => ({
                      value: option.value,
                      label: option.label,
                    }))}
                    isSearchable={false}
                    isClearable={false}
                    isDisabled={isArchive()}
                    styles={customStyles}
                    components={{
                      MenuList: props => (
                        <SearchDropDownPaginator {...props} maxHeight={250} />
                      ),
                    }}
                    filterOption={(option, inputValue) =>
                      option?.label
                        ?.toLowerCase()
                        ?.includes(inputValue?.toLowerCase())
                    }
                  />
                </FormGroup>
              </Col>
              <Col className="fw-bold">
                <LabelTooltip
                  label={IsAutoAssign}
                  tooltipText={IsAutoAssign}
                  important={false}
                  placement={"bottom"}
                />
                <div className="mt-1">
                  <CheckboxButton
                    onChange={e => {
                      handleChange({
                        target: {
                          name: "isMappable",
                          value: e.target.checked,
                        },
                      });
                    }}
                    checked={formValues?.isMappable}
                  />
                </div>
              </Col>
            </Row>
            {competencyData?.name && (
              <Row>
                <Col sm="12" lg="5">
                  <AssessmentCard
                    header={competencyData.name}
                    moduleDetails={moduleDetails}
                    isHideMoreOption={true}
                  />
                </Col>
              </Row>
            )}
            <Divider />

            <Row>
              <Col sm="12" lg="6">
                <div className="d-flex w-100  gap-3">
                  <FormGroup className="w-100">
                    <LabelTooltip
                      label={Course}
                      tooltipText={SGACourseToolTipMessage}
                      important
                    />
                    <div className="map-sga__course-field">
                      <MultiSelectDropdown
                        options={courseOptions.map(item => ({
                          name: item.name,
                          value: item.value,
                          label: item.label,
                        }))}
                        placeholder={`${Select} ${Course}`}
                        onChange={handleCourseChange}
                        defaultSelected={selectedCourses.map(item => ({
                          name: item.name,
                          value: item.value,
                          label: item.label,
                        }))}
                        closeButton={false}
                        disabled={isArchive() ? true : false}
                      />
                    </div>

                    {errors.course && (
                      <div className="mt-1 text-danger">{errors.course}</div>
                    )}
                  </FormGroup>
                  <div className="filter-margin">
                    <FilterSliderModal
                      dropdowns={[
                        {
                          label: CourseType,
                          key: "course_type",
                          tooltipText: `${Select} ${CourseType}`,
                          options: iltViltFilterDropdownList,
                          isMultiSelect: true,
                        },
                      ]}
                      selectedFilterValues={selectedFilterValues}
                      defaultFilterValues={defaultFilterValues}
                      setSelectedFilterValues={setSelectedFilterValues}
                      setDefaultFilterValues={setDefaultFilterValues}
                      onDone={handleDone}
                      onClear={handleClear}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mt-4">
              {selectedCourses.map((item, index) => (
                <Col sm="12" lg="4" key={index}>
                  <CurriculumCourseCard
                    header={item.label}
                    id={item.name}
                    showDelete={isArchive() ? false : true}
                    handleDelete={() => handleConfirmDelete(item)}
                    type={item?.course_type}
                  />
                </Col>
              ))}
            </Row>
            <Divider />
            <Row>
              <Col sm="12" lg="6">
                <FormGroup>
                  <FormGroup>
                    <LabelTooltip
                      label={Grade}
                      tooltipText={GradeToolTipMessage}
                      important
                    />
                    <MultiSelectDropdown
                      options={gradeOptions}
                      placeholder={`${Select} ${Grade}`}
                      onChange={handleGradeChange}
                      defaultSelected={selectedGrades}
                      disabled={isArchive() ? true : false}
                    />
                    {errors.grade && (
                      <div className="mt-1 text-danger">{errors.grade}</div>
                    )}
                  </FormGroup>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <LabelTooltip
                    label={ReAttemptPolicy}
                    tooltipText={ReAttemptPolicyToolTipMessage}
                    important
                  />
                  <ReactSelect
                    name="reAttemptPolicy"
                    className="text-gray"
                    onChange={selectedOption =>
                      handleChange({
                        target: {
                          name: "reAttemptPolicy",
                          value: selectedOption ? selectedOption.value : "",
                        },
                      })
                    }
                    value={
                      reAttemptPolicyOptions?.find(
                        option =>
                          option.value?.toString() ===
                          formValues.reAttemptPolicy?.toString()
                      ) || null
                    }
                    options={reAttemptPolicyOptions?.map(option => ({
                      value: option.value,
                      label: option.label,
                    }))}
                    isSearchable={false}
                    isClearable={false}
                    isDisabled={isArchive()}
                    styles={customStyles}
                    components={{
                      MenuList: props => (
                        <SearchDropDownPaginator {...props} maxHeight={250} />
                      ),
                    }}
                    filterOption={(option, inputValue) =>
                      option?.label
                        ?.toLowerCase()
                        ?.includes(inputValue?.toLowerCase())
                    }
                  />
                  {errors.reAttemptPolicy && (
                    <div className="mt-1 text-danger">
                      {errors.reAttemptPolicy}
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row className="">
              <Col sm="12" lg="6">
                <LabelTooltip
                  label={Duration}
                  tooltipText={DurationTypeToolTipMessage}
                  important
                />
                <Row>
                  <Col>
                    <Input
                      name="duration"
                      type="number"
                      inputMode="numeric"
                      placeholder={`${Enter} ${Duration}`}
                      onChange={handleChange}
                      onKeyDown={handleKeyDownOnNumericWithoutDecimal}
                      value={formValues.duration}
                      invalid={!!errors.duration}
                      min={1}
                      max={99}
                      disabled={isArchive() ? true : false}
                    />
                    <div className="invalid-feedback">{errors.duration}</div>
                  </Col>
                  <Col>
                    <Input
                      onChange={handleChange}
                      value={formValues.durationType}
                      name="durationType"
                      type="select"
                      invalid={!!errors.durationType}
                      disabled={isArchive() ? true : false}
                    >
                      {sgaDurationOptions?.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Input>
                    <div className="invalid-feedback">
                      {errors.durationType}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col sm="12" lg="6">
                <FormGroup>
                  <LabelTooltip
                    label={EffectiveFromDate}
                    tooltipText={EffectiveFromDateToolTipMessage}
                    important
                  />
                  <div className={"w-100"}>
                    <DatePicker
                      selected={formValues?.effectiveFromDate}
                      onChange={(date: Date | null) =>
                        handleDateChange("effectiveFromDate", date)
                      }
                      minDate={new Date()}
                      placeholderText={`${Select} ${EffectiveFromDate}`}
                      className={`form-control ${errors?.effectiveFromDate ? "is-invalid" : ""}`}
                      dateFormat="dd-MM-yyyy"
                      disabledKeyboardNavigation={true}
                      onKeyDown={e => e.preventDefault()}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />

                    {errors?.effectiveFromDate && (
                      <div className="invalid-date-picker-feedback">
                        {errors?.effectiveFromDate}
                      </div>
                    )}
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
        {!isArchive() && (
          <CardFooter>
            <Row className="d-flex justify-content-between">
              <Col xs="auto">
                <SecondaryButton onClick={handleActivityModal}>
                  {AuditLogs}
                </SecondaryButton>
              </Col>
              <Col xs="auto" className="ml-auto">
                <DefaultButton onClick={handleSubmit} color="primary">
                  {MapSGA}
                </DefaultButton>
              </Col>
            </Row>
          </CardFooter>
        )}
      </Card>
      <AuditLog
        auditLogs={sgaDetailsData?.audit_logs}
        handleActivityModal={handleActivityModal}
        showActivityLog={showActivityLog}
      />
      {isShowVersionModal && (
        <VersionControl
          isShowVersionModal={isShowVersionModal}
          handleVersionModal={handleVersionModal}
          versions={sgaDetailsData?.version}
          handleClickVersion={handleClickVersion}
          activeId={sgaDetailsData?.id}
        />
      )}
    </div>
  );
};

export default MapSga;
