import PropTypes from "prop-types";
import { Card, CardBody, CardHeader } from "reactstrap";

import { H4, MUIIcons, P } from "../../AbstractElements";
import {
  CourseCompletion,
  DownloadCertificates,
  Participation,
} from "../../utils/Constant";
import "./style.scss";

interface DownloadCertificateProps {
  handleDownload: (isCompletion?: boolean) => void;
  isWBT: () => boolean;
}

const DownloadCertificate: React.FC<DownloadCertificateProps> = ({
  handleDownload,
  isWBT,
}) => {
  const certificates = [
    { label: Participation, handleClick: () => handleDownload(true), id: 1 },
    {
      label: CourseCompletion,
      handleClick: () => handleDownload(false),
      id: 2,
    },
  ].filter(({ label }) => !(isWBT() && label === Participation));

  return (
    <Card className="download-certificate">
      <CardHeader>
        <H4>{DownloadCertificates}</H4>
      </CardHeader>
      <CardBody className="mt-2">
        {certificates.map(({ label, handleClick, id }) => (
          <div key={id} className="download-certificate__container">
            <div className="d-flex align-items-center justify-content-between">
              <P className="m-0 download-certificate__name">{label}</P>
              <MUIIcons
                size={24}
                className="primary-icon-color pointer"
                iconName="FileDownloadOutlined"
                onClick={handleClick}
                aria-label={`Download ${label} certificate`}
              />
            </div>
          </div>
        ))}
      </CardBody>
    </Card>
  );
};

DownloadCertificate.propTypes = {
  handleDownload: PropTypes.func.isRequired,
  isWBT: PropTypes.func.isRequired,
};

export default DownloadCertificate;
