import PropTypes from "prop-types";
import React, { useState } from "react";

import "./style.scss";
import { Badges, H5, MUIIcons, P } from "../../AbstractElements";
import CommonModal from "../../CommonElements/Modal";
import Progressbar from "../../CommonElements/Progressbar";
import { useAppDispatch } from "../../ReduxToolkit/Hooks";
import { reviewModificationRequest } from "../../ReduxToolkit/Reducers/AssessmentCatlogSlice";
import {
  Approve,
  Decline,
  Extension,
  ExtensionRequest,
  NumberOfDays,
  Reason,
} from "../../utils/Constant";
import { hasPermissionToComponent } from "../../utils/helper/permission";

interface SgaCardProps {
  id?: string;
  role: string;
  expertiseLevel: string;
  percentageCompleted: number;
  showUnlink?: boolean;
  showExtension?: boolean;
  extensionRequested?: boolean;
  status?: string;
  onUnlinkClick?: (id: string) => void;
  onExtensionClick?: (id: string) => void;
  reason?: string;
  days?: string;
  extension_id?: string;
  getSGAData?: any;
}

const SgaCard: React.FC<SgaCardProps> = ({
  id,
  role,
  expertiseLevel,
  percentageCompleted,
  showUnlink,
  onUnlinkClick,
  status,
  showExtension,
  onExtensionClick,
  extensionRequested,
  reason,
  days,
  extension_id,
  getSGAData,
}) => {
  const dispatch = useAppDispatch();
  const [isActionModalOpen, setIsActionModalOpen] = useState(false);

  const toggleActionModal = () => {
    setIsActionModalOpen(!isActionModalOpen);
  };

  const onReviewRequest = async type => {
    const payload = {
      type: "sga",
      id: extension_id,
      status: type,
    };
    await dispatch(reviewModificationRequest({ data: payload }));
    await getSGAData();
    toggleActionModal();
  };

  return (
    <>
      <CommonModal
        sizeTitle={Extension}
        modalBodyClassName=""
        isOpen={isActionModalOpen}
        toggle={toggleActionModal}
        backdrop="static"
        size="sm"
        showFooter
        primaryBtnText={Approve}
        secondaryBtnText={Decline}
        onPrimaryBtnClick={() => onReviewRequest("approved")}
        onSecondaryBtnClick={() => onReviewRequest("rejected")}
      >
        <P className="mt-1">
          <div className="text-muted">{NumberOfDays}:</div>
          {days}
        </P>
        <P className="mt-2">
          <div className="text-muted">{Reason}:</div>
          {reason}
        </P>
      </CommonModal>
      <div>
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
          <h3 className="text-black mb-3 mb-md-0 d-flex gap-3">
            {` ${role} - ${expertiseLevel}`}{" "}
            <H5>
              <Badges
                className="d-flex align-items-center mt-1"
                status={status}
              />
            </H5>
            {extensionRequested &&
              hasPermissionToComponent("RAISE_SGA_EXTENSION") && (
                <H5>
                  <Badges
                    className="d-flex align-items-center mt-1"
                    status={"extension_requested"}
                  />
                </H5>
              )}
            {extensionRequested &&
              hasPermissionToComponent("APPROVE_SGA_EXTENSION") && (
                <Badges
                  className="mt-1 reduced-height-badge small-text"
                  status="request_for_extesion"
                >
                  <div className="d-flex align-items-center gap-2">
                    {ExtensionRequest}
                    <MUIIcons
                      onClick={toggleActionModal}
                      className="text-white mb-1 pointer fs-small"
                      size={14}
                      iconName="InfoOutlined"
                    />
                  </div>
                </Badges>
              )}
          </h3>
          <div className="d-flex flex-row align-items-center gap-3">
            {showUnlink && hasPermissionToComponent("UNMAP_SGA") && (
              <button
                className="border-0 bg-transparent pointer mt-1"
                onClick={() => onUnlinkClick(id)}
              >
                <MUIIcons
                  className="pointer primary-icon-color"
                  iconName="LinkOffOutlined"
                  size={20}
                />
              </button>
            )}
            {showExtension && !extensionRequested && (
              <button
                className="border-0 mt-1 bg-transparent pointer"
                onClick={() => onExtensionClick(id)}
              >
                <MUIIcons
                  className="pointer primary-icon-color"
                  iconName="SendTimeExtensionOutlined"
                  size={22}
                />
              </button>
            )}
            {`${percentageCompleted}%`}
            <Progressbar
              barClassName="custom-sga-progressBar"
              value={percentageCompleted}
              className="custom-sgaProgressBar"
            />
          </div>
        </div>
      </div>
    </>
  );
};

SgaCard.propTypes = {
  role: PropTypes.string.isRequired,
  expertiseLevel: PropTypes.string.isRequired,
};

export default SgaCard;
