import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";

import {
  BULK_UPLOAD_QB,
  BULK_UPLOAD_QB_URL,
  CREATE_QUESTION_BANK,
  CREATE_QUESTION_BANK_URL,
  CREATE_RA_FOR_QB,
  CREATE_RA_FOR_QB_URL,
  DELETE,
  DELETE_MODULE_OR_QB,
  DELETE_MODULE_OR_QB_URL,
  GET,
  GET_ALL_QUESTIONS_AND_MODULES,
  GET_ALL_QUESTIONS_AND_MODULES_URL,
  GET_ASSESSMENT_MODULE_BY_QB_ID,
  GET_ASSESSMENT_MODULE_QB_BY_ID_URL,
  GET_MODULE_QB_BY_ID_URL,
  GET_MODULE_QB_BY_IDS,
  GET_QUESTION_AND_OPTION_BY_MODULE,
  GET_QUESTION_AND_OPTION_BY_MODULE_URL,
  GET_QUESTION_BANK_LIST,
  GET_QUESTION_BANK_LIST_URL,
  GET_QUESTION_BANK_LIST_WITH_BOTH_URL,
  GET_QUESTION_BANK_LIST_WITH_SELF_URL,
  GET_QUESTION_BANK_LIST_WITH_TAG_URL,
  GET_RA_BY_MODULE_ID,
  GET_RA_BY_MODULE_ID_URL,
  POST,
  PUT,
  UPDATE_QUESTION_BANK,
  UPDATE_QUESTION_BANK_URL,
  UPDATE_QUESTION_IN_QB,
  UPDATE_QUESTION_IN_QB_URL,
  UPDATE_RA_FOR_QB,
  UPDATE_RA_FOR_QB_URL,
} from "../../Api";
import apiRequest from "../../Api/connector";
import { STATUS_200 } from "../../Api/constants";
import {
  AssessmentQuestionBank,
  ModuleByQbIds,
  ModuleQuestionBankList,
  QBRemedialActions,
  QuestionBankListResponse,
} from "../../Api/entities/QuestionBankEntity";
import { SomethingWentWrong } from "../../utils/Constant";
import { showToast } from "../../utils/helper/helper";

interface CuriculumInterface {
  questionBankPayloadData: AssessmentQuestionBank;
  questionBankList: QuestionBankListResponse;
  questionList: ModuleQuestionBankList;
  questionAndModuleList: AssessmentQuestionBank;
  raByModuleIdList: QBRemedialActions;
  moduleByQbList: ModuleByQbIds;
  loading: boolean;
  error: string | null;
  assessmentModule: any;
}

const initialState: CuriculumInterface = {
  questionBankPayloadData: {
    name: "",
    status: "draft",
    is_competency: false,
    tag: [],
    modules: [],
  },
  questionBankList: { question_banks: [] },
  questionList: { questions: [] },
  moduleByQbList: { questions: [] },
  raByModuleIdList: { remedial_action: [] },
  questionAndModuleList: {
    name: "",
    status: "draft",
    is_competency: false,
    tag: [],
    modules: [],
  },
  loading: false,
  error: null,
  assessmentModule: [],
};

export const deleteModuleOrQb = createAsyncThunk(
  DELETE_MODULE_OR_QB,
  async ({ data }: { data: any }) => {
    const response = await apiRequest(DELETE, DELETE_MODULE_OR_QB_URL(), data);
    return response;
  }
);

export const getQuestionAndOptionByModule = createAsyncThunk(
  GET_QUESTION_AND_OPTION_BY_MODULE,
  async ({ module_id, page }: { module_id: string; page: number }) => {
    const response = await apiRequest(
      GET,
      GET_QUESTION_AND_OPTION_BY_MODULE_URL(module_id, page)
    );
    return response.data;
  }
);

export const getAllQuestionsAndModules = createAsyncThunk(
  GET_ALL_QUESTIONS_AND_MODULES,
  async ({ question_bank_id }: { question_bank_id: number }) => {
    const response = await apiRequest(
      GET,
      GET_ALL_QUESTIONS_AND_MODULES_URL(question_bank_id)
    );
    return response.data;
  }
);

export const getRAByModuleId = createAsyncThunk(
  GET_RA_BY_MODULE_ID,
  async ({ id }: { id: number }) => {
    const response = await apiRequest(GET, GET_RA_BY_MODULE_ID_URL(id));
    return response.data;
  }
);

export const getModuleByQbId = createAsyncThunk(
  GET_MODULE_QB_BY_IDS,
  async ({ id }: { id: number }) => {
    const response = await apiRequest(GET, GET_MODULE_QB_BY_ID_URL(id));
    return response;
  }
);

export const createRAForQB = createAsyncThunk(
  CREATE_RA_FOR_QB,
  async ({ remedial_actions }: { remedial_actions: any }) => {
    const response = await apiRequest(
      POST,
      CREATE_RA_FOR_QB_URL(),
      remedial_actions
    );
    return response;
  }
);

export const updateRAForQB = createAsyncThunk(
  UPDATE_RA_FOR_QB,
  async ({ remedial_actions }: { remedial_actions: any }) => {
    const response = await apiRequest(
      PUT,
      UPDATE_RA_FOR_QB_URL(),
      remedial_actions
    );
    return response;
  }
);

export const updateQuestioninQb = createAsyncThunk(
  UPDATE_QUESTION_IN_QB,
  async ({ questions }: { questions: any }) => {
    const response = await apiRequest(
      PUT,
      UPDATE_QUESTION_IN_QB_URL(),
      questions
    );
    return response;
  }
);

export const bulkUploadQb = createAsyncThunk(
  BULK_UPLOAD_QB,
  async ({ id, file }: { id: number; file: any }) => {
    const response = await apiRequest(PUT, BULK_UPLOAD_QB_URL(id), file);
    return response;
  }
);

export const getAssessmentQuestionBankList = createAsyncThunk(
  GET_QUESTION_BANK_LIST,
  async ({
    status,
    tag,
    self_assessment,
  }: {
    status: string;
    tag: string;
    self_assessment: string;
  }) => {
    let url = GET_QUESTION_BANK_LIST_URL(status);
    if (tag && !self_assessment) {
      url = GET_QUESTION_BANK_LIST_WITH_TAG_URL(status, tag);
    }
    if (!tag && self_assessment) {
      url = GET_QUESTION_BANK_LIST_WITH_SELF_URL(status, self_assessment);
    }
    if (tag && self_assessment) {
      url = GET_QUESTION_BANK_LIST_WITH_BOTH_URL(status, tag, self_assessment);
    }

    const response = await apiRequest(GET, url);
    return response.data;
  }
);

export const createQuestionBank = createAsyncThunk(
  CREATE_QUESTION_BANK,
  async ({ question_bank }: { question_bank: AssessmentQuestionBank }) => {
    const response = await apiRequest(
      POST,
      CREATE_QUESTION_BANK_URL(),
      question_bank
    );
    return response;
  }
);

export const updateQuestionBank = createAsyncThunk(
  UPDATE_QUESTION_BANK,
  async ({ question_bank }: { question_bank: AssessmentQuestionBank }) => {
    const response = await apiRequest(
      PUT,
      UPDATE_QUESTION_BANK_URL(),
      question_bank
    );
    return response;
  }
);

export const getAssessmentModuleByQbId = createAsyncThunk(
  GET_ASSESSMENT_MODULE_BY_QB_ID,
  async ({ question_bank_id }: { question_bank_id: number }) => {
    const response = await apiRequest(
      GET,
      GET_ASSESSMENT_MODULE_QB_BY_ID_URL(question_bank_id)
    );
    return response.data;
  }
);

const QuestionBankSlice = createSlice({
  name: "question-bank",
  reducers: {
    setQuestionBankPayloadToInitials: state => {
      state.questionAndModuleList = initialState.questionAndModuleList;
      state.questionBankPayloadData = initialState.questionBankPayloadData;
    },
    setQuestionBankPayload: (state, action) => {
      state.questionBankPayloadData = action.payload;
    },
    setQuestionBankListToInitial: state => {
      state.questionBankList = initialState.questionBankList;
    },
    setRAtoInitialValue: state => {
      state.raByModuleIdList = initialState.raByModuleIdList;
    },
  },
  initialState,
  extraReducers: builder => {
    builder
      .addCase(createQuestionBank.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createQuestionBank.fulfilled, state => {
        state.loading = false;
      })
      .addCase(createQuestionBank.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getAssessmentModuleByQbId.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAssessmentModuleByQbId.fulfilled, (state, action) => {
        state.loading = false;
        state.assessmentModule = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(getAssessmentModuleByQbId.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(updateRAForQB.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateRAForQB.fulfilled, (state, action) => {
        state.loading = false;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(updateRAForQB.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(updateQuestionBank.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateQuestionBank.fulfilled, state => {
        state.loading = false;
      })
      .addCase(updateQuestionBank.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getAllQuestionsAndModules.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getAllQuestionsAndModules.fulfilled,
        (state, action: PayloadAction<AssessmentQuestionBank>) => {
          state.loading = false;
          state.questionAndModuleList = action.payload;
        }
      )
      .addCase(getAllQuestionsAndModules.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getRAByModuleId.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getRAByModuleId.fulfilled,
        (state, action: PayloadAction<QBRemedialActions>) => {
          state.loading = false;
          state.raByModuleIdList = action.payload;
        }
      )
      .addCase(getRAByModuleId.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getAssessmentQuestionBankList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getAssessmentQuestionBankList.fulfilled,
        (state, action: PayloadAction<QuestionBankListResponse>) => {
          state.loading = false;
          state.questionBankList = action.payload;
        }
      )
      .addCase(getAssessmentQuestionBankList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getModuleByQbId.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getModuleByQbId.fulfilled,
        (state, action: PayloadAction<ModuleByQbIds>) => {
          state.loading = false;
          state.moduleByQbList = action.payload;
        }
      )
      .addCase(getModuleByQbId.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(bulkUploadQb.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(bulkUploadQb.fulfilled, state => {
        state.loading = false;
      })
      .addCase(bulkUploadQb.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(updateQuestioninQb.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateQuestioninQb.fulfilled, state => {
        state.loading = false;
      })
      .addCase(updateQuestioninQb.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
  },
});

export const {
  setQuestionBankPayload,
  setQuestionBankListToInitial,
  setQuestionBankPayloadToInitials,
  setRAtoInitialValue,
} = QuestionBankSlice.actions;

export default QuestionBankSlice.reducer;
