import PropTypes from "prop-types";
import DataTable from "react-data-table-component";

import { courseScheduleStatus } from "../../Api/constants";
import {
  Available,
  CourseName,
  CoursePeriod,
  Instructor,
  Location,
  NominationPeriod,
  Room,
  Status,
} from "../../utils/Constant";
import {
  formatCustomDate,
  courseScheduleStatusMap,
} from "../../utils/helper/helper";

interface ScheduleListContainerProps {
  data: CourseSchedule[];
  onRowClick?: (row: CourseSchedule) => void;
  filterColumns?: any;
  userDetails?: any;
}

const getNominationDateRange = row => {
  if (
    row.status === courseScheduleStatus.ongoing ||
    row.status === courseScheduleStatus.nomination_closed
  ) {
    return formatCustomDate(row?.nomination_end_date);
  }
  return `${formatCustomDate(row?.nomination_start_date)} - ${formatCustomDate(row?.nomination_end_date)}`;
};

const ScheduleListContainer: React.FC<ScheduleListContainerProps> = ({
  data,
  onRowClick,
  filterColumns,
  userDetails,
}) => {
  const columns = [
    {
      name: CourseName,
      selector: (row: CourseSchedule) => row.course_name,
      minWidth: "19rem",
      sortable: true,
    },
    {
      name: Instructor,
      selector: (row: CourseSchedule) =>
        userDetails?.[row.primary_trainer_id]?.user_name || "-",
      minWidth: "8rem",
      sortable: true,
    },
    {
      name: Location,
      selector: (row: CourseSchedule) => row?.location_name || "-",
      minWidth: "8rem",
      sortable: true,
    },
    {
      name: Room,
      selector: (row: CourseSchedule) => row.classroom_name || "-",
      minWidth: "8rem",
      sortable: true,
    },
    {
      name: NominationPeriod,
      selector: row => getNominationDateRange(row),
      minWidth: "15rem",
      sortable: true,
      cell: row => <div>{getNominationDateRange(row)}</div>,
    },
    {
      name: Available,
      selector: (row: CourseSchedule) => row.available_count || 0,
      minWidth: "6rem",
      sortable: true,
    },
    {
      name: CoursePeriod,
      selector: (row: CourseSchedule) =>
        `${formatCustomDate(row?.course_start_date)} - ${formatCustomDate(row?.course_end_date)}`,
      minWidth: "15rem",
      sortable: true,
    },
    {
      name: Status,
      minWidth: "16rem",
      sortable: true,
      selector: row => courseScheduleStatusMap[row.status],
      cell: (row: CourseSchedule) =>
        onRowClick ? (
          <button
            onClick={() => onRowClick(row)}
            className={`border-0 bg-transparent schedule-list__${row.status}`}
          >
            {courseScheduleStatusMap[row.status]}
          </button>
        ) : (
          <span className={`schedule-list__${row.status}`}>
            {courseScheduleStatusMap[row.status]}
          </span>
        ),
    },
  ];

  const filteredColumns = columns?.filter(
    column => !filterColumns?.includes(column.name)
  );

  return (
    <DataTable
      onRowClicked={onRowClick}
      columns={filteredColumns}
      data={data}
      pagination
      fixedHeader
      striped
    />
  );
};

ScheduleListContainer.propTypes = {
  data: PropTypes.array,
  onRowClick: PropTypes.func,
  filterColumns: PropTypes.any,
  userDetails: PropTypes.any,
};

export default ScheduleListContainer;
