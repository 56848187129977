import PropTypes from "prop-types";
import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";

import { MUIIcons, ToolTip } from "../../AbstractElements";
import "./style.scss";
import { useAppDispatch } from "../../ReduxToolkit/Hooks";
import { downloadAssessmentCertificate } from "../../ReduxToolkit/Reducers/AssessmentSlice";
import {
  CertifiedOn,
  ExpiredOn,
  RenewCertification,
} from "../../utils/Constant";
import {
  formatCustomDate,
  isDateLessThanOrEqualToToday,
} from "../../utils/helper/helper";

interface CertificationListContainerProps {
  uniqueKey?: string;
  title: string;
  certifiedOn: string;
  expiredOn: string;
  id: number;
}

const CertificationListContainer: React.FC<CertificationListContainerProps> = ({
  uniqueKey,
  title,
  certifiedOn,
  expiredOn,
  id,
}) => {
  const dispatch = useAppDispatch();

  const isEqual = isDateLessThanOrEqualToToday(expiredOn);

  const handleCertificateDownload = () => {
    dispatch(
      downloadAssessmentCertificate({
        type: "sga",
        id: id,
        name: title,
      })
    );
  };

  return (
    <Card className="p-3 custom-certifcation-card">
      <CardBody>
        <Row className="align-items-center">
          <Col
            md="2"
            xs="7"
            className="d-flex align-items-center gap-2 order-1 order-md-1 custom-header-col"
          >
            <MUIIcons
              className="primary-icon-color"
              iconName="CardMembershipOutlined"
              size={25}
            />
            <span id={`tool_tip${uniqueKey}`} className="custom-heading">
              {title}
            </span>
            <ToolTip target={`tool_tip${uniqueKey}`}>{title}</ToolTip>
          </Col>
          <Col
            md="3"
            xs="7"
            className="d-flex align-items-center gap-2 mt-3 mt-md-0 flex-grow-1 order-3 order-md-2"
          >
            <div className="custom-certifiedOn">
              <MUIIcons iconName={"CalendarTodayOutlined"} size={20} />
              <span>
                {CertifiedOn} : {formatCustomDate(certifiedOn)}
              </span>
            </div>
          </Col>
          <Col
            md="4"
            xs="9"
            className="d-flex align-items-center gap-2 mt-3 mt-md-0 order-4 order-md-3"
          >
            {isEqual && expiredOn && (
              <span className="custom-expired">
                {ExpiredOn} : {formatCustomDate(expiredOn)}
              </span>
            )}
          </Col>
          <Col
            md="3"
            xs="5"
            className="d-flex justify-content-end order-2 order-md-4"
          >
            {!isEqual ? (
              <MUIIcons
                className="primary-icon-color"
                iconName={"Download"}
                size={25}
                onClick={handleCertificateDownload}
              />
            ) : (
              <div className="p-2 custom-re-certification pointer">
                <p className="primary-text-color">{RenewCertification}</p>
              </div>
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

CertificationListContainer.propTypes = {
  title: PropTypes.string,
  expiredOn: PropTypes.string,
  certifiedOn: PropTypes.string,
  id: PropTypes.number,
};

export default CertificationListContainer;
