import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FixedSizeList as List } from "react-window";
import { Card, CardBody } from "reactstrap";

import { H4, ToolTip } from "../../../../AbstractElements";
import Divider from "../../../../CommonElements/Divider";
import SearchBar from "../../../../CommonElements/SearchBar";
import NoData from "../../../../container/NoData";
import UserProfileContainer from "../../../../container/UserProfileContainer";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { getAllMenteesUnderMentorList } from "../../../../ReduxToolkit/Reducers/LearnerSlice";
import {
  getDomainList,
  getFilterRolesList,
} from "../../../../ReduxToolkit/Reducers/MasterSlice";
import { Menteess, NoDataText } from "../../../../utils/Constant";
import {
  formatCustomDate,
  truncateText,
} from "../../../../utils/helper/helper";

const MentorMenteeList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { getAllMenteesUnderMentor } = useAppSelector(state => state.learner);
  const { domainList, roleOnFilter } = useAppSelector(state => state.master);

  const [combinedMenteeData, setCombinedMenteeData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [roleFilterList, setRoleFilterList] = useState({});

  useEffect(() => {
    dispatch(getDomainList());
    dispatch(getAllMenteesUnderMentorList({ status: null }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getFilterRolesList());
  }, []);

  useEffect(() => {
    const data = (getAllMenteesUnderMentor?.mentees || []).map(mentee => {
      const menteeDetails =
        getAllMenteesUnderMentor.user_dict[mentee.mentee_id];
      return { ...mentee, ...menteeDetails };
    });
    setCombinedMenteeData(data);
  }, [getAllMenteesUnderMentor]);

  useEffect(() => {
    if (searchQuery) {
      const filtered = combinedMenteeData?.filter(
        mentor =>
          mentor?.user_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          mentor?.employee_code
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(combinedMenteeData);
    }
  }, [searchQuery, combinedMenteeData]);

  useEffect(() => {
    const value = {};
    if (roleOnFilter?.job_role) {
      roleOnFilter.job_role.forEach(item => {
        if (item?.id && item?.name) {
          value[item.id] = {
            label: item.name,
            value: item.name,
          };
        }
      });
    }
    setRoleFilterList(value);
  }, [roleOnFilter]);

  const getIcons = (
    domain,
    role,
    department,
    countOfSessions,
    latestSessiondate,
    mentorMenteeId
  ) => [
    {
      id: 1,
      iconName: "DomainOutlined",
      value: (
        <>
          <span
            id={`tooltip-domain-${mentorMenteeId}`}
            className="text-truncate d-flex align-items-center"
          >
            {truncateText(domain, 20)}
          </span>
          <ToolTip placement="top" target={`tooltip-domain-${mentorMenteeId}`}>
            {domain}
          </ToolTip>
        </>
      ),
      columnSize: "3",
    },
    {
      id: 2,
      iconName: "EngineeringOutlined",
      value: (
        <>
          <span
            id={`tooltip-role-${mentorMenteeId}`}
            className="text-truncate d-flex align-items-center"
          >
            {truncateText(role, 20)}
          </span>
          <ToolTip placement="top" target={`tooltip-role-${mentorMenteeId}`}>
            {role}
          </ToolTip>
        </>
      ),
      columnSize: "3",
    },
    { id: 3, iconName: "BadgeOutlined", value: department, columnSize: "2" },
    {
      id: 4,
      iconName: "CastForEducationOutlined",
      value: countOfSessions,
      columnSize: "1",
    },
    {
      id: 5,
      iconName: "CalendarTodayOutlined",
      value: latestSessiondate,
      columnSize: "3",
    },
  ];

  const onRowClick = (
    mentorMenteeId: number,
    menteeId: number,
    countOfSessions: number,
    latestSessiondate: string
  ) => {
    navigate(`${process.env.PUBLIC_URL}/mentoring/mentee-details`, {
      state: { mentorMenteeId, menteeId, countOfSessions, latestSessiondate },
    });
  };

  const getDomainName = (domainId: number) => {
    const domain = domainList?.domain?.find(item => item.id === domainId);
    return domain ? domain.domain_name : "";
  };

  return (
    <Card>
      <CardBody>
        <div className="d-flex flex-row justify-content-between align-items-center">
          <H4 className="fw-bold mt-2">{Menteess}</H4>
          <SearchBar showSearch onSearch={query => setSearchQuery(query)} />
        </div>

        <Divider />
        {filteredData?.length > 0 ? (
          <List
            height={400}
            itemCount={filteredData.length}
            itemSize={100}
            width="100%"
            className="user-list__card-container"
          >
            {({ index, style }) => {
              const mentee = filteredData[index];
              return (
                <div
                  key={mentee.mentor_mentee_id}
                  style={style}
                  className="pointer user-list__card"
                >
                  <UserProfileContainer
                    userDetails={{
                      name: mentee?.user_name,
                      empId: mentee?.employee_code || "-",
                      mobile: mentee?.phone || "-",
                      id: mentee?.mentee_id,
                    }}
                    iconItems={getIcons(
                      getDomainName(mentee?.domain_id) || "-",
                      roleFilterList[mentee?.job_role_id]?.value,
                      mentee?.sbu,
                      mentee?.count_of_sessions,
                      formatCustomDate(mentee?.latest_session_date),
                      mentee?.mentor_mentee_id
                    )}
                    onRowClick={() =>
                      onRowClick(
                        mentee?.mentor_mentee_id,
                        mentee?.mentee_id,
                        mentee?.count_of_sessions,
                        mentee?.latest_session_date
                      )
                    }
                    iconItemsColSize="9"
                  />
                </div>
              );
            }}
          </List>
        ) : (
          <NoData
            svg={"empty-folder-icon"}
            title={NoDataText}
            showCard={false}
          />
        )}
      </CardBody>
    </Card>
  );
};

export default MentorMenteeList;
