import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import {
  GET,
  GET_COURSE_RATINGS,
  GET_COURSE_RATINGS_URL,
  GET_LEARNER_FEEDBACK,
  GET_LEARNER_FEEDBACK_BY_ID,
  GET_LEARNER_FEEDBACK_URL,
  GET_TRAINER_FEEDBACK,
  GET_TRAINER_FEEDBACK_URL,
  POST,
  UPDATE_TRAINER_FEEDBACK,
  UPDATE_TRAINER_FEEDBACK_URL,
  DELETE,
  DELETE_MODULE_FEEDBACK,
  GET_FEEDBACK_QUESTION,
  GET_FEEDBACK_QUESTION_API,
  POST_FEEDBACK_QUESTION,
  POST_FEEDBACK_QUESTION_API,
  POST_MODULE_FEEDBACK,
  POST_MODULE_FEEDBACK_API,
  PUT,
  GET_LEARNER_FEEDBACK_BY_ID_URL,
} from "../../Api";
import apiRequest from "../../Api/connector";
import { STATUS_200 } from "../../Api/constants";
import {
  CourseFeedbackResponse,
  Feedback,
  FeedbackData,
} from "../../Api/entities/FeedbackRatingEntity";
import { SomethingWentWrong } from "../../utils/Constant";
import { showToast } from "../../utils/helper/helper";

interface FeedbackRatingState {
  feedbackList: Feedback;
  feedbackDetail: any;
  loading: boolean;
  ratings: FeedbackData;
  ratingsList: CourseFeedbackResponse;
  questionsList: FeedbackQuestion;
  error: string | null;
}

const initialState: FeedbackRatingState = {
  feedbackList: { feedback: [] },
  feedbackDetail: {
    feedback: [],
  },
  ratings: {
    course_schedule_id: null,
    questions: [],
    ratings: [],
  },
  ratingsList: {
    course_schedule_id: null,
    feedback: [],
  },
  questionsList: {
    questions: [],
    module_feedback: [],
    feedback: [],
  },
  loading: false,
  error: null,
};

export const getFeedbackByLearner = createAsyncThunk(
  GET_LEARNER_FEEDBACK,
  async ({ course_schedule_id }: { course_schedule_id: number }) => {
    const response = await apiRequest(
      GET,
      GET_LEARNER_FEEDBACK_URL(course_schedule_id)
    );
    return response?.data;
  }
);

export const getFeedbackByCourseFeedbackId = createAsyncThunk(
  GET_LEARNER_FEEDBACK_BY_ID,
  async ({
    course_feedback_id,
    participant_id,
  }: {
    course_feedback_id: number;
    participant_id: number;
  }) => {
    const response = await apiRequest(
      GET,
      GET_LEARNER_FEEDBACK_BY_ID_URL(course_feedback_id, participant_id)
    );
    return response?.data;
  }
);

export const getTrainerFeedback = createAsyncThunk(
  GET_TRAINER_FEEDBACK,
  async ({ course_schedule_id }: { course_schedule_id: number }) => {
    const response = await apiRequest(
      GET,
      GET_TRAINER_FEEDBACK_URL(course_schedule_id)
    );
    return response?.data;
  }
);

export const updateTrainerFeedback = createAsyncThunk(
  UPDATE_TRAINER_FEEDBACK,
  async ({ data }: { data: any }) => {
    const response = await apiRequest(
      POST,
      UPDATE_TRAINER_FEEDBACK_URL(),
      data
    );
    return response;
  }
);

export const getCourseRatings = createAsyncThunk(
  GET_COURSE_RATINGS,
  async ({ course_schedule_id }: { course_schedule_id: number }) => {
    const response = await apiRequest(
      GET,
      GET_COURSE_RATINGS_URL(course_schedule_id)
    );
    return response?.data;
  }
);

export const getCourseModuleFeedabck = createAsyncThunk(
  GET_FEEDBACK_QUESTION,
  async ({
    type,
    courseScheduleId,
    id,
    schedule_id,
    participant_id,
  }: {
    type: string;
    courseScheduleId: number | string;
    id?: number;
    schedule_id?;
    participant_id?;
  }) => {
    const response = await apiRequest(
      GET,
      GET_FEEDBACK_QUESTION_API(
        courseScheduleId,
        type,
        id,
        schedule_id,
        participant_id
      )
    );
    return response.data;
  }
);

export const postFeedback = createAsyncThunk(
  POST_FEEDBACK_QUESTION,
  async ({ payload }: { payload: CourseFeedbackPayload }) => {
    const response = await apiRequest(
      POST,
      POST_FEEDBACK_QUESTION_API(),
      payload
    );
    return response;
  }
);

export const postOrUpdateModuleFeedback = createAsyncThunk(
  POST_MODULE_FEEDBACK,
  async ({ moduleContent }: { moduleContent: PostModuleFeedback }) => {
    const response = await apiRequest(
      moduleContent?.id ? PUT : POST,
      POST_MODULE_FEEDBACK_API(),
      moduleContent
    );
    return response;
  }
);

export const deleteModuleFeedback = createAsyncThunk(
  DELETE_MODULE_FEEDBACK,
  async ({ moduleContent }: { moduleContent: DeleteModuleFeedbackPayload }) => {
    const response = await apiRequest(
      DELETE,
      POST_MODULE_FEEDBACK_API(),
      moduleContent
    );
    return response;
  }
);

const FeedbackRatingSlice = createSlice({
  name: "feedback",
  initialState,
  reducers: {
    setQuestionListToInitial(state) {
      state.questionsList = initialState?.questionsList;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getFeedbackByLearner.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getFeedbackByLearner.fulfilled, (state, action) => {
        state.loading = false;
        state.feedbackList = action.payload;
      })
      .addCase(getFeedbackByLearner.rejected, (state, action) => {
        try {
          state.feedbackList = initialState?.feedbackList;
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getFeedbackByCourseFeedbackId.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getFeedbackByCourseFeedbackId.fulfilled, (state, action) => {
        state.loading = false;
        state.feedbackDetail = action?.payload;
      })
      .addCase(getFeedbackByCourseFeedbackId.rejected, (state, action) => {
        try {
          state.feedbackDetail = {
            feedback: [],
          };
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getTrainerFeedback.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTrainerFeedback.fulfilled, (state, action) => {
        state.loading = false;
        state.ratings = action.payload;
      })
      .addCase(getTrainerFeedback.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getCourseRatings.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCourseRatings.fulfilled, (state, action) => {
        state.loading = false;
        state.ratingsList = action.payload;
      })
      .addCase(getCourseRatings.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getCourseModuleFeedabck.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getCourseModuleFeedabck.fulfilled,
        (state, action: PayloadAction<FeedbackQuestion>) => {
          state.loading = false;
          state.questionsList = action.payload;
        }
      )
      .addCase(getCourseModuleFeedabck.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(postFeedback.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(postFeedback.fulfilled, (state, action) => {
        state.loading = false;

        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(postFeedback.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(updateTrainerFeedback.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateTrainerFeedback.fulfilled, (state, action) => {
        state.loading = false;

        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(updateTrainerFeedback.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(postOrUpdateModuleFeedback.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(postOrUpdateModuleFeedback.fulfilled, (state, action) => {
        state.loading = false;

        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(postOrUpdateModuleFeedback.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(deleteModuleFeedback.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteModuleFeedback.fulfilled, (state, action) => {
        state.loading = false;

        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(deleteModuleFeedback.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;

          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
  },
});

export const { setQuestionListToInitial } = FeedbackRatingSlice.actions;

export default FeedbackRatingSlice.reducer;
