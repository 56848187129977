import React from "react";
import { Card, Row, Col, Badge, CardBody } from "reactstrap";

import Divider from "../../CommonElements/Divider";
import { Replacing } from "../../utils/Constant";
import "./style.scss";
import NoDataFound from "../NoDataFound";

interface RmReplacementCardProps {
  data: RmReplacementData[];
}

interface RmReplacementData {
  id: number;
  oldName: string;
  newName: string;
}

const RmReplacementCard: React.FC<RmReplacementCardProps> = ({ data }) => {
  return (
    <Card>
      <CardBody>
        {data.map((item, index) => (
          <>
            <Row className="my-2" key={item.id}>
              <Col>
                <span>{item.oldName}</span>
                <Badge className="text-black mx-2 replacement-card-badge">
                  {Replacing}
                </Badge>
                <span>{item.newName}</span>
              </Col>
            </Row>
            {index < data.length - 1 && <Divider />}
          </>
        ))}
        {data.length === 0 && <NoDataFound />}
      </CardBody>
    </Card>
  );
};

export default RmReplacementCard;
