import { useCallback, useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";

import { Btn, H3, MUIIcons } from "../../../AbstractElements";
import { UpsertLocationPayload } from "../../../Api/entities/ManageMasterDataEntity";
import MasterDataAccordion from "../../../CommonElements/MasterDataAccordion";
import MasterDataAddNewModal from "../../../CommonElements/MasterDataAddNewModal";
import CommonModal from "../../../CommonElements/Modal";
import SearchBar from "../../../CommonElements/SearchBar";
import NoData from "../../../container/NoData";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import {
  createLocation,
  deleteLocation,
  getManageMasterLocationList,
  getManageMasterLocationListById,
  getManageMasterRegionList,
  setLocationListByIdToInitialValue,
  updateLocation,
} from "../../../ReduxToolkit/Reducers/ManageMasterDataSlice";
import {
  AddNew,
  Classroom,
  LocationEmptyDescription,
  Enter,
  Location,
  Locations,
  NoDataText,
  NotYetAdded,
  Region,
  Select,
  DeleteLocationHeader,
  Confirm,
  DeleteLocationMessage,
  MasterLocationToolTipMessage,
  LocationAddDescriptionToolTipMessage,
} from "../../../utils/Constant";
import { mapListToOptions } from "../../../utils/helper/helper";
import {
  getFromLocalStorage,
  LOGGED_IN_USER,
} from "../../../utils/helper/localStorageutils";

const LocationPage = () => {
  const dispatch = useAppDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const loginData = getFromLocalStorage(LOGGED_IN_USER);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
    useState(false);
  const [selectedRegionId, setSelectedRegionId] = useState("");
  const [regionListData, setRegionListData] = useState([]);
  const [modalFormValues, setModalFormValues] = useState({
    name: "",
    description: "",
    selectedOption: "",
  });
  const [isEdit, setIsEdit] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [accordionData, setAccordionData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [locationToDelete, setLocationToDelete] = useState(null);

  const { locationList, regionList, locationListById, loading } =
    useAppSelector(state => state.manageMasterData);

  const toggleModal = () => setModalOpen(!modalOpen);
  const toggleDeleteConfirmModal = () =>
    setIsDeleteConfirmModalOpen(!isDeleteConfirmModalOpen);

  useEffect(() => {
    dispatch(getManageMasterLocationList());
    if (!modalOpen) {
      dispatch(getManageMasterRegionList());
    }
  }, [dispatch]);

  useEffect(() => {
    const regions = mapListToOptions(regionList?.region, "name", "id");
    setRegionListData(regions);
  }, [regionList]);

  useEffect(() => {
    if (locationList?.location?.length > 0) {
      setAccordionData(
        locationList?.location?.map(location => ({
          id: location.id.toString(),
          name: location.name,
          description: location.description || "-",
          createdOn: location.created_at,
          modifiedOn: location.updated_at,
          depedentBadges: [
            { id: 1, badge: `${Classroom}: ${location.class_room_count}` },
          ],
        }))
      );
    }
  }, [locationList]);

  useEffect(() => {
    if (searchQuery) {
      const filtered = accordionData?.filter(location =>
        location.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(accordionData);
    }
  }, [searchQuery, accordionData]);

  useEffect(() => {
    if (locationListById) {
      setModalFormValues(prevState => ({
        ...prevState,
        name: locationListById.name,
        description: locationListById.description,
        selectedOption: locationListById.region_id.toString(),
      }));
    }
  }, [locationListById]);

  const handleSave = useCallback(
    (name: string, description: string, regionId: string) => {
      const payload: UpsertLocationPayload = {
        name,
        description,
        region_id: parseInt(regionId),
      };

      if (locationListById) {
        payload.id = locationListById.id;
        dispatch(updateLocation({ updateLocationPayload: payload })).then(
          () => {
            dispatch(getManageMasterLocationList());
            toggleModal();
          }
        );
      } else {
        dispatch(createLocation({ createLocationPayload: payload })).then(
          () => {
            dispatch(getManageMasterLocationList());
            toggleModal();
          }
        );
      }
    },
    [dispatch, toggleModal]
  );

  const handleEdit = (id: string) => {
    setIsEdit(true);
    const selectedId = parseInt(id);
    dispatch(getManageMasterLocationListById({ id: selectedId }));
    toggleModal();
  };

  const handleDelete = (id: string) => {
    const selectedId = parseInt(id);
    setLocationToDelete(selectedId);
    toggleDeleteConfirmModal();
  };

  const confirmDelete = () => {
    if (locationToDelete) {
      dispatch(deleteLocation({ id: locationToDelete })).then(() => {
        dispatch(getManageMasterLocationList());
        toggleDeleteConfirmModal();
      });
    }
  };
  const renderContent = () => {
    if (loading) {
      return null;
    }

    if (accordionData?.length === 0) {
      return (
        <NoData
          svg={"empty-folder-icon"}
          title={NotYetAdded}
          description={LocationEmptyDescription}
          buttonText={AddNew}
          onclick={toggleModal}
        />
      );
    }

    if (filteredData?.length === 0) {
      return <NoData svg={"empty-folder-icon"} title={NoDataText} />;
    }

    return filteredData?.map(item => (
      <MasterDataAccordion
        key={item.id}
        accordionItems={item}
        onEditClick={() => handleEdit(item.id)}
        onDeleteClick={() => handleDelete(item.id)}
        loginData={loginData}
      />
    ));
  };

  return (
    <div className="page-body page-body-margin">
      <CommonModal
        sizeTitle={DeleteLocationHeader}
        isOpen={isDeleteConfirmModalOpen}
        toggle={toggleDeleteConfirmModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={confirmDelete}
        primaryBtnText={Confirm}
      >
        {DeleteLocationMessage}
      </CommonModal>
      <Card>
        <CardBody>
          <div className="d-flex flex-row align-items-center">
            <H3 className="fw-bold">{Locations}</H3>
            <div className="d-flex justify-content-end align-items-center mt-sm-0 mt-2 gap-3 ms-auto">
              <SearchBar
                showSearch
                className="mb-2"
                onSearch={query => setSearchQuery(query)}
              />
              <Btn
                icon={
                  <MUIIcons iconName="AddCircleOutlineOutlined" size={16} />
                }
                color="primary"
                onClick={() => {
                  setIsEdit(false);
                  dispatch(setLocationListByIdToInitialValue());
                  setModalFormValues(null);
                  toggleModal();
                }}
              >
                {AddNew}
              </Btn>
            </div>
          </div>
        </CardBody>
      </Card>

      {renderContent()}

      <MasterDataAddNewModal
        isEdit={isEdit}
        isOpen={modalOpen}
        toggle={toggleModal}
        onSave={handleSave}
        labelText={Location}
        placeholderText={`${Enter} ${Location}`}
        toolTipText={MasterLocationToolTipMessage}
        initialValues={modalFormValues}
        dropdownLabel={Region}
        dropdownLabelTooltip={`${Select} ${Region}`}
        dropdownOptions={regionListData}
        selectedDropdownValue={selectedRegionId}
        onDropdownChange={value => setSelectedRegionId(value)}
        toolTipDescriptionText={LocationAddDescriptionToolTipMessage}
      />
    </div>
  );
};

export default LocationPage;
