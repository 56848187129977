import PropTypes from "prop-types";

import { P, ToolTip } from "../../AbstractElements";

import "./style.scss";

const CourseActivity = ({ domain, name, getStatus, courseType, id }) => {
  const truncatedHeader = domain
    ? domain?.length <= 1
      ? domain[0]
      : `${domain[0]} ${domain?.length - 1}+`
    : null;

  return (
    <div className="course-activity">
      <div className="course-activity__course d-flex align-items-center justify-content-between">
        <div className="course-activity__name">
          {domain && id && (
            <>
              <span
                className="m-0 course-activity__domain-name pb-1"
                id={`tooltip-domain-name${id}`}
              >
                {truncatedHeader}
              </span>
              <ToolTip placement="top" target={`tooltip-domain-name${id}`}>
                {domain.join(", ")}
              </ToolTip>
            </>
          )}
          <P className="m-0">{name}</P>
          {courseType && (
            <P className="course-activity__course-type m-0">({courseType})</P>
          )}
        </div>
        <P className="m-0">{getStatus()}</P>
      </div>
    </div>
  );
};

CourseActivity.propTypes = {
  name: PropTypes.string,
  getStatus: PropTypes.func.isRequired,
  domain: PropTypes.any,
  courseType: PropTypes.any,
  id: PropTypes.any,
};

export default CourseActivity;
