import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

import { Btn, H2, H4, MUIIcons } from "../../../../../AbstractElements";
import {
  CourseStructureFileKey,
  ALL_USERS_STATUS,
} from "../../../../../Api/constants";
import Divider from "../../../../../CommonElements/Divider";
import FixedFooter from "../../../../../CommonElements/FixedFooter";
import SliderModal from "../../../../../CommonElements/SliderModal";
import Comments from "../../../../../container/Comments";
import CourseDetails from "../../../../../container/CourseDetails";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../ReduxToolkit/Hooks";
import {
  addCommentToStages,
  downloadFile,
  getCourseContent,
  updateCommentStatus,
} from "../../../../../ReduxToolkit/Reducers/CourseSlice";
import { getRolesList } from "../../../../../ReduxToolkit/Reducers/MasterSlice";
import { getUsersList } from "../../../../../ReduxToolkit/Reducers/UserSlice";
import {
  CourseStructureFile,
  AssessmentCriteria,
  Back,
  Comment,
  EnterComments,
  IntendedAudience,
  Literature,
  MaximumAttendance,
  MinimumAttendance,
  Next,
  PracticalActivityDetails,
  PracticalRatio,
  Qualification,
  RegistrationRequirement,
  TheoryRatio,
  PreCourseWork,
  OtherIntendedAudience,
  OtherPreCourseWork,
  CourseStructure,
} from "../../../../../utils/Constant";
import {
  sanitizedContent,
  showToast,
  sortCommentsByDate,
} from "../../../../../utils/helper/helper";

const AdditionalInformation: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { courseId } = location.state || "";
  const [sliderModal, setSliderModal] = useState(false);
  const { courseContent } = useAppSelector(state => state.course);
  const [commentList, setCommentList] = useState([]);
  const [userMap, setUserMap] = useState(null);
  const { usersList } = useAppSelector(state => state.user);
  const [iconComments, setIconComments] = useState(null);
  const [additionalDetails, setAdditionalDetails] = useState(null);
  const [isComentRead, setIsComentRead] = useState(null);
  const [intendedAudiences, setIntendedAudiences] = useState({});
  const { roleList } = useAppSelector(state => state.master);

  useEffect(() => {
    getAllData();
  }, [dispatch, courseId]);

  const getAllData = async () => {
    await dispatch(getRolesList({ getMappedRole: null }));
    await dispatch(getUsersList({ role: ALL_USERS_STATUS }));
    await dispatch(getCourseContent({ id: courseId }));
  };

  useEffect(() => {
    const audiencesMap = {};
    roleList?.job_role?.forEach(audience => {
      const audienceObj = {
        name: audience.id.toString(),
        value: audience.name,
        label: audience.name,
      };
      audiencesMap[audience.id] = audienceObj;
      setIntendedAudiences(audiencesMap);
    });
  }, [roleList]);

  useEffect(() => {
    let comment = [];
    if (courseContent?.comment?.additional_information) {
      comment = sortCommentsByDate(
        courseContent.comment?.additional_information
      );
    }

    if (courseContent?.comment?.comment_status) {
      try {
        const infoStage = courseContent?.comment?.comment_status?.find(
          (stage: { stages: string }) =>
            stage.stages === "additional_information"
        );
        setIsComentRead(infoStage ? !infoStage.is_read : false);
      } catch (error) {
        setIsComentRead(false);
      }
    }

    if (courseContent) {
      const content = courseContent?.additional_information;
      const audiences =
        (intendedAudiences &&
          content?.intended_audience
            ?.map(item => intendedAudiences[item?.id]?.label)
            .join(", ")) ||
        "-";
      const preCourses =
        content?.pre_course_work?.map(({ name }) => name).join(", ") || "-";
      const additionalDetailData = [
        {
          details: [
            { assign: IntendedAudience, name: audiences },
            {
              assign: OtherIntendedAudience,
              name: (
                <div
                  className="sanitized-content"
                  dangerouslySetInnerHTML={{
                    __html: sanitizedContent(
                      content?.other_intended_audiences || "-"
                    ),
                  }}
                />
              ),
            },
            { assign: MinimumAttendance, name: content?.min_attendance },
          ],
        },
        {
          details: [
            { assign: MaximumAttendance, name: content?.max_attendance },
            { assign: Literature, name: content.suggested_reading },
            {
              assign: Qualification,
              name: content?.qualification_certification,
            },
          ],
        },
        {
          details: [
            { assign: AssessmentCriteria, name: content?.assessment_criteria },
            { assign: TheoryRatio, name: content?.theory_time_ratio },
            { assign: PracticalRatio, name: content?.practical_time_ratio },
          ],
        },
        {
          details: [
            {
              assign: PracticalActivityDetails,
              name: content?.practical_activity_details,
            },
            {
              assign: PreCourseWork,
              name: preCourses,
            },
            {
              assign: OtherPreCourseWork,
              name: (
                <div
                  className="sanitized-content"
                  dangerouslySetInnerHTML={{
                    __html: sanitizedContent(
                      content?.other_pre_course_works || "-"
                    ),
                  }}
                />
              ),
            },
          ],
        },
        {
          details: [
            {
              assign: RegistrationRequirement,
              name: content?.registration_requirement,
            },
          ],
        },
      ];
      setAdditionalDetails(additionalDetailData);
    }
    setCommentList(comment);
  }, [courseContent]);

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserMap(userMapObj);
  }, [usersList]);

  const sliderToggle = async () => {
    setSliderModal(!sliderModal);
    await dispatch(
      updateCommentStatus({
        correspondingStatus: {
          component_name: "additional_information",
          course_id: courseId,
        },
      })
    );
    setIsComentRead(false);
  };

  const onCommentsChange = e => {
    if (
      (e.target.value && e.target.value?.length <= 255) ||
      e.target.value === ""
    )
      setIconComments(e.target.value);
  };

  const onSendComments = async () => {
    if (iconComments === "") {
      showToast(EnterComments, "error");
      return;
    }

    const payload = {
      course_id: courseId,
      is_comment: true,
      comment_data: {
        entity_type: "additional_information",
        comment: iconComments,
      },
    };
    await dispatch(addCommentToStages({ comment: payload }));
    setIconComments("");
    await dispatch(getCourseContent({ id: courseId }));
  };

  return (
    <div className="page-body pb-5">
      <SliderModal isOpen={sliderModal} toggle={sliderToggle}>
        <H2>{Comment}</H2>
        <Divider />
        <Comments
          userMap={userMap}
          text={iconComments}
          comments={commentList}
          send
          onSendComments={onSendComments}
          onCommentsChange={onCommentsChange}
        />
      </SliderModal>
      <Card className="p-2">
        <CardBody>
          <div className="d-flex justify-content-between">
            <H4 className="mt-2">{CourseStructure}</H4>
            <div className="d-flex gap-4">
              <MUIIcons
                size={24}
                className="primary-icon-color mt-2 pointer"
                iconName="FileDownloadOutlined"
                onClick={() => {
                  downloadFile(
                    courseId,
                    CourseStructureFileKey,
                    `${CourseStructureFile}_${courseContent.course_name}_${courseContent.course_id}`
                  );
                }}
              />
              <div className="comment-icon-container">
                <MUIIcons
                  size={24}
                  onClick={sliderToggle}
                  className="primary-icon-color mt-2 pointer"
                  iconName="ChatBubbleOutlineOutlined"
                />
                {isComentRead != null && isComentRead && (
                  <span className="red-dot"></span>
                )}
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      <div className="mt-4 pb-5">
        {additionalDetails !== null && (
          <CourseDetails level={5} data={additionalDetails} headerText="" />
        )}
      </div>
      <FixedFooter>
        <Row>
          <Col xs="auto">
            <Link
              to="/course-management/course-details/lesson-plan"
              state={{ courseId: courseId }}
            >
              <Btn outline className="alert-light-primary" color="primary">
                {Back}
              </Btn>
            </Link>
          </Col>
          <Col xs="auto">
            <Link
              to="/course-management/course-details/assessment"
              state={{ courseId: courseId }}
            >
              <Btn color="primary">{Next}</Btn>
            </Link>
          </Col>
        </Row>
      </FixedFooter>
    </div>
  );
};

export default AdditionalInformation;
