import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

import { Btn, MUIIcons } from "../../../AbstractElements";
import {
  ALL_COURSE_FILTER_STATUS,
  ALL_USERS_STATUS,
  courseTypeMap,
  listStatusMapping,
  tmTabsStatusMap,
} from "../../../Api/constants";
import FilterSliderModal from "../../../CommonElements/FilterSliderModal";
import FixedFooter from "../../../CommonElements/FixedFooter";
import JustifyTabs from "../../../CommonElements/JustifyTabs";
import SearchBar from "../../../CommonElements/SearchBar";
import TextTooltip from "../../../CommonElements/TextTooltip/inxex";
import ViewModeDropdown from "../../../CommonElements/ViewMoreDropdown";
import CourseCard from "../../../container/CourseCard";
import NoData from "../../../container/NoData";
import DefaultPagination from "../../../container/Paginator";
import TableView from "../../../container/TableView";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import {
  getCourseList,
  setCourseContentToNull,
  setCourseListToNull,
} from "../../../ReduxToolkit/Reducers/CourseSlice";
import { setCourseBasicInputFormValueToInitials } from "../../../ReduxToolkit/Reducers/CourseWizardSlice";
import { getDomainList } from "../../../ReduxToolkit/Reducers/MasterSlice";
import { getUsersList } from "../../../ReduxToolkit/Reducers/UserSlice";
import {
  AddCourse,
  CourseName,
  CourseStatus,
  CourseType,
  CreatedOn,
  Domain,
  Levels,
  NoDataText,
  NotYetAdded,
  Participant,
  Select,
  Trainers,
} from "../../../utils/Constant";
import {
  formatCustomDate,
  courseListTabs,
  courseFilterDropdownList,
  capitalizeFirstLetter,
  CARD_PER_PAGE,
} from "../../../utils/helper/helper";
import { hasPermissionToComponent } from "../../../utils/helper/permission";
import { CourseFilterState } from "../../../utils/helper/propTypes";
import useIsMobile from "../../../utils/helper/responsive";
import "./style.scss";

const CourseList: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const statusParam = params.get("status");
  const viewParam = params.get("view");
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});
  const [domainListData, setDomainListData] = useState([]);
  const [courseFilterValues, setCourseFilterValues] =
    useState<CourseFilterState>({
      courseType: [],
      domain: [],
    });
  const [activeTab, setActiveTab] = useState<string>(
    statusParam || "new_request"
  );
  const [clearData, setClearData] = useState(false);
  const { courseList } = useAppSelector(state => state.course);
  const { usersList } = useAppSelector(state => state.user);
  const { domainList } = useAppSelector(state => state.master);
  const [userNameMap, setUserNameMap] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [noDataFound, setNoDataFound] = useState(false);
  const [viewMode, setViewMode] = useState("card");
  const isMobile = useIsMobile();

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = CARD_PER_PAGE;

  useEffect(() => {
    if (statusParam && statusParam !== activeTab) {
      setActiveTab(statusParam);
    }
  }, [statusParam, activeTab]);

  useEffect(() => {
    if (viewParam) {
      setViewMode(viewParam);
    }
  }, [viewParam]);

  useEffect(() => {
    const domains = [];
    domainList?.domain?.forEach(element => {
      domains.push({
        name: element.id.toString(),
        value: element.domain_name,
        label: element.domain_name,
      });
    });
    setDomainListData(domains);
  }, [domainList]);

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    await dispatch(getDomainList());
    await dispatch(getUsersList({ role: ALL_USERS_STATUS }));
    dispatch(setCourseContentToNull());
  };

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserNameMap(userMapObj);
  }, [usersList]);

  useEffect(() => {
    dispatch(setCourseListToNull());
    fetchCourses();
  }, [activeTab, courseFilterValues]);

  const fetchCourses = () => {
    const { courseType = ALL_COURSE_FILTER_STATUS, domain = [] } =
      courseFilterValues;
    const filteredCourseType =
      courseType.length > 0 ? courseType : ALL_COURSE_FILTER_STATUS;
    const filteredDomain = domain.length > 0 ? domain : null;

    dispatch(
      getCourseList({
        status: tmTabsStatusMap[activeTab],
        course_type: filteredCourseType,
        domain: filteredDomain,
        page: "course_management",
      })
    ).then(response => {
      setNoDataFound(!response?.payload?.course_list?.length);
    });
  };

  const handleTabClick = (tabId: any) => {
    setClearData(true);
    setActiveTab(tabId);
    setSearchResults([]);
    setCurrentPage(1);
    params.set("status", tabId);
    navigate(`${location.pathname}?${params.toString()}`);
  };

  const handleClick = () => {
    dispatch(setCourseContentToNull());
    dispatch(setCourseBasicInputFormValueToInitials());
  };

  const handleSearch = (query: string) => {
    setClearData(false);
    setCurrentPage(1);
    const filteredCourses = courseList?.course_list?.filter(course =>
      course.course_name.toLowerCase().includes(query.toLowerCase())
    );
    setSearchResults(filteredCourses || []);
    setNoDataFound(!filteredCourses || filteredCourses?.length === 0);
  };

  const handleDone = async (values: { [key: string]: string[] }) => {
    setClearData(true);
    const selectedCourseTypes = values["request_type"];
    const selectedDomains = values["domain"];
    setCourseFilterValues(prevState => ({
      ...prevState,
      courseType: selectedCourseTypes ?? [],
      domain: selectedDomains ?? [],
    }));
    setCurrentPage(1);
  };

  const handleClear = async () => {
    setClearData(true);
    setSelectedFilterValues({});
    setDefaultFilterValues({});
    setCourseFilterValues({
      courseType: [],
      domain: [],
    });
    setCurrentPage(1);
  };

  const handleSetView = type => {
    setViewMode(type);
    params.set("view", type);
    navigate(`${location.pathname}?${params.toString()}`);
  };

  const filterNullFromArray = array => {
    return array.filter(item => item !== null && item !== undefined);
  };

  const renderCourseCard = (course: CourseList, index) => {
    return (
      <Col key={index} sm={12} lg={4}>
        <Link to={`/course-management/course-details?courseId=${course.id}`}>
          <CourseCard
            userNameMap={userNameMap}
            course_id={course?.id?.toString()}
            title={course?.domain_names}
            header={course?.course_name}
            rm={course?.description}
            requestedOn={formatCustomDate(course?.created_at)}
            status={course?.status}
            levels={
              course?.expertise_levels
                ? course?.expertise_levels.filter(item => item !== null)
                : []
            }
            type={course?.course_type}
            range={`${course?.min_attendance ? course?.min_attendance : 0}-${course?.max_attendance ? course?.max_attendance : 0}`}
            assignments={course?.assignments}
          />
        </Link>
      </Col>
    );
  };

  const getTrainer = assignments => {
    const trainerIds = assignments
      .filter(item => item.assignment_type === "trainer")
      .map(item => userNameMap[item.user_id]?.user_name);

    return trainerIds?.join(", ");
  };

  const createTooltipCell = (content: string | string[], uniqueId: string) => (
    <TextTooltip tooltipText={content || "-"} toolTipUniqueId={uniqueId}>
      <div className="text-ellips-parent">
        <div className="text-ellips">{content || "-"}</div>
      </div>
    </TextTooltip>
  );

  const renderRequestTable = () => {
    const tableColumns = [
      {
        name: CourseName,
        selector: (row: any) => capitalizeFirstLetter(row?.course_name),
        cell: (row: any) =>
          createTooltipCell(row.course_name, `course-name-${row.id}`),
        sortable: true,
      },
      {
        name: CourseType,
        selector: (row: any) => courseTypeMap[row?.course_type],
        sortable: true,
      },
      {
        name: Domain,
        selector: (row: any) =>
          filterNullFromArray(row?.domain_names)?.length > 0
            ? row?.domain_names.join(", ")
            : "-",
        cell: (row: any) =>
          createTooltipCell(
            filterNullFromArray(row?.domain_names)?.join(", ") || "-",
            `domain-${row.id}`
          ),
        sortable: true,
      },
      {
        name: Participant,
        selector: (row: any) => row?.min_attendance,
        sortable: true,
        cell: (row: any) =>
          `${row?.min_attendance || 0}-${row?.max_attendance || 0}`,
      },
      {
        name: Levels,
        selector: (row: any) =>
          filterNullFromArray(row?.expertise_levels)?.length > 0
            ? row?.expertise_levels.join(", ")
            : "-",
        cell: (row: any) =>
          createTooltipCell(
            filterNullFromArray(row?.expertise_levels)?.join(", ") || "-",
            `levels-${row.id}`
          ),
        sortable: true,
      },
      {
        name: Trainers,
        selector: (row: any) => getTrainer(row.assignments),
        cell: (row: any) =>
          createTooltipCell(getTrainer(row.assignments), `trainers-${row.id}`),
        sortable: true,
      },
      {
        name: CourseStatus,
        cell: (row: any) =>
          createTooltipCell(
            listStatusMapping[row?.status]?.message,
            `course-status-${row.id}`
          ),
        sortable: true,
      },
      {
        name: CreatedOn,
        selector: (row: any) => formatCustomDate(row.created_at),
        sortable: true,
      },
    ];

    const tableData =
      searchResults?.length > 0 ? searchResults : courseList?.course_list;

    return (
      <Col>
        <Card className="p-1">
          <CardBody>
            <TableView
              tableColumns={tableColumns}
              tableData={tableData}
              onRowClick={row =>
                navigate(`/course-management/course-details?courseId=${row.id}`)
              }
            />
          </CardBody>
        </Card>
      </Col>
    );
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const renderCoursesContent = () => {
    if (noDataFound) {
      return (
        <NoData svg={"empty-folder-icon"} title={NoDataText} showCard={false} />
      );
    }

    if (courseList?.course_list?.length === 0) {
      return (
        <NoData
          svg={"empty-folder-icon"}
          title={NotYetAdded}
          showCard={false}
        />
      );
    }

    const cardData =
      searchResults?.length > 0 ? searchResults : courseList?.course_list;

    return viewMode === "card"
      ? cardData
          ?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
          .map((course, index) => renderCourseCard(course, index))
      : renderRequestTable();
  };

  return (
    <div className="page-body page-body-margin course-list pb-5">
      <Card className="p-1">
        <CardBody>
          <div
            className={`d-flex flex-column flex-md-row justify-content-between ${!isMobile ? "align-items-center" : ""}`}
          >
            <div className="mb-1 mb-sm-0 d-flex flex-column gap-2 ">
              <JustifyTabs
                tabs={courseListTabs}
                activeTab={activeTab}
                onTabClick={handleTabClick}
              />
            </div>
            <div className="d-flex justify-content-end align-items-center mt-sm-0 mt-2 gap-3 ms-auto">
              <SearchBar
                className="mb-2"
                showSearch
                onSearch={handleSearch}
                clearOnProp={clearData}
              />
              <div className="mt-2">
                <FilterSliderModal
                  dropdowns={[
                    {
                      label: CourseType,
                      key: "request_type",
                      tooltipText: `${Select} ${CourseType}`,
                      options: courseFilterDropdownList,
                      isMultiSelect: true,
                    },
                    {
                      label: Domain,
                      key: "domain",
                      tooltipText: `${Select} ${Domain}`,
                      options: domainListData,
                      isMultiSelect: true,
                    },
                  ]}
                  selectedFilterValues={selectedFilterValues}
                  defaultFilterValues={defaultFilterValues}
                  setSelectedFilterValues={setSelectedFilterValues}
                  setDefaultFilterValues={setDefaultFilterValues}
                  onDone={handleDone}
                  onClear={handleClear}
                />
              </div>
              <ViewModeDropdown
                onChange={handleSetView}
                currentView={viewMode}
              />
              {hasPermissionToComponent("ADD_COURSE") && (
                <Link
                  to={"/course-management/add-course"}
                  state={{ courseRequestId: "" }}
                >
                  <Btn
                    icon={
                      <MUIIcons size={16} iconName="AddCircleOutlineOutlined" />
                    }
                    color="primary"
                    onClick={handleClick}
                  >
                    {AddCourse}
                  </Btn>
                </Link>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
      <Row>{renderCoursesContent()}</Row>

      {viewMode === "card" &&
        !noDataFound &&
        (searchResults?.length > 0 ||
          (searchResults?.length === 0 &&
            courseList?.course_list?.length > 0)) && (
          <FixedFooter>
            <DefaultPagination
              currentPage={currentPage}
              totalPages={Math.ceil(
                (searchResults?.length > 0
                  ? searchResults?.length
                  : courseList?.course_list?.length) / itemsPerPage
              )}
              onPageChange={handlePageChange}
            />
          </FixedFooter>
        )}
    </div>
  );
};

export default CourseList;
