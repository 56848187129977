import { useEffect, useMemo, useState } from "react";
import DataTable, { TableStyles } from "react-data-table-component";
import { useLocation } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

import { Badges, H3, H4 } from "../../../../AbstractElements";
import { ALL_USERS_STATUS } from "../../../../Api/constants";
import Divider from "../../../../CommonElements/Divider";
import FilterSliderModal from "../../../../CommonElements/FilterSliderModal";
import SearchBar from "../../../../CommonElements/SearchBar";
import NoData from "../../../../container/NoData";
import TotalSeatCard from "../../../../container/TotalSeatCard";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { getAssetsHistory } from "../../../../ReduxToolkit/Reducers/AssetManagementSlice";
import { getUsersList } from "../../../../ReduxToolkit/Reducers/UserSlice";
import {
  AssetsAddition,
  AssetsHistory,
  CourseName,
  DateOfUsage,
  Duration,
  Instructor,
  NoHistoryData,
  Select,
  TotalHourUsage,
  UsageCount,
} from "../../../../utils/Constant";
import {
  convertMinutesToHours,
  formatCustomDate,
} from "../../../../utils/helper/helper";

const AssetHistory = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState("");
  const [parsedData, setParsedData] = useState([]);
  const queryParams = new URLSearchParams(location.search);
  const { assetHistory } = useAppSelector(state => state.assets);
  const { usersList } = useAppSelector(state => state.user);
  const resourceId = queryParams.get("resource_id");
  const headerName = queryParams.get("name");
  const assetName = queryParams.get("asset_name");
  const [userMap, setUserMap] = useState(null);
  const [instructorNameMap, setInstructorNameMap] = useState([]);
  const [clearData, setClearData] = useState(false);
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});
  const [totalSeatData, setTotalSeatData] = useState([
    {
      id: 1,
      count: "-",
      title: AssetsAddition,
    },
    {
      id: 2,
      count: 0,
      title: UsageCount,
    },
    {
      id: 3,
      count: convertMinutesToHours(0) + "h",
      title: TotalHourUsage,
    },
  ]);

  useEffect(() => {
    dispatch(getUsersList({ role: ALL_USERS_STATUS }));
  }, [dispatch]);

  useEffect(() => {
    if (resourceId) dispatch(getAssetsHistory({ id: resourceId }));
  }, [dispatch, resourceId]);

  useEffect(() => {
    const userMapObj = {};
    const instructorMapObj = [];
    usersList?.users?.forEach(user => {
      if (user?.role?.includes("it") || user?.role?.includes("et")) {
        instructorMapObj.push({
          name: user.id?.toString(),
          value: user?.user_name,
          label: user?.user_name,
        });
      }
      userMapObj[user.id] = user;
    });
    setInstructorNameMap(instructorMapObj);
    setUserMap(userMapObj);
  }, [usersList]);

  useEffect(() => {
    const addedData = [];
    if (assetHistory) {
      setTotalSeatData([
        {
          id: 1,
          count: assetHistory?.asset_addition
            ? formatCustomDate(assetHistory?.asset_addition)
            : "-",
          title: AssetsAddition,
        },
        {
          id: 2,
          count: assetHistory?.usage_count || 0,
          title: UsageCount,
        },
        {
          id: 3,
          count: convertMinutesToHours(assetHistory?.total_duration) || 0 + "h",
          title: TotalHourUsage,
        },
      ]);
      assetHistory?.history?.forEach(item => {
        addedData.push({
          courseName: item?.course_name,
          dateOfUsage: item?.usage_date,
          instructorName:
            userMap && item?.trainer_id && userMap[item?.trainer_id]?.user_name,
          duration: item?.duration,
        });
      });
    }
    setParsedData(addedData);
  }, [assetHistory]);

  const filteredAssets = useMemo(() => {
    if (searchQuery) {
      return parsedData?.filter(item =>
        item.courseName.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    return parsedData;
  }, [searchQuery, parsedData]);

  const customStyles: TableStyles = {
    rows: {
      style: {
        padding: "1rem 0",
      },
    },
    table: {
      style: {
        marginTop: "2rem",
      },
    },
  };

  const AssetsTableColumns = [
    {
      name: CourseName,
      cell: row => <div>{row.courseName ? row.courseName : "-"}</div>,
      minWidth: "8rem",
    },
    {
      name: DateOfUsage,
      cell: row => (row.dateOfUsage ? formatCustomDate(row.dateOfUsage) : "-"),
      minWidth: "7rem",
    },
    {
      name: Instructor,
      cell: row => row.instructorName,
      minWidth: "8rem",
    },
    {
      name: Duration,
      cell: row => convertMinutesToHours(row.duration) + "h",
      minWidth: "9rem",
    },
  ];

  const handleDone = async (values: { [key: string]: string[] }) => {
    setClearData(true);
    const instructorIds = values["instructor"]
      ? values["instructor"].join(",")
      : "";

    await dispatch(
      getAssetsHistory({ id: resourceId, instructor: instructorIds })
    );
  };

  const handleClear = async () => {
    setClearData(true);
    setSelectedFilterValues({});
    setDefaultFilterValues({});
    await dispatch(getAssetsHistory({ id: resourceId }));
  };

  return (
    <div className="page-body">
      <Card>
        <CardBody>
          <div className="d-flex flex-row justify-content-between p-1 mt-2 align-items-center">
            <H3 className="fw-bold">{headerName + " - " + assetName}</H3>
            <Badges className="mt-1" status={assetHistory?.status} />
          </div>
          <Divider />
          <Row className="mt-3">
            <TotalSeatCard data={totalSeatData} />
          </Row>
          <Col>
            <div className="d-flex flex-row justify-content-between align-items-center">
              <H4>{AssetsHistory}</H4>
              <div className="d-flex gap-3 align-items-center">
                <SearchBar
                  className="mb-2"
                  showSearch
                  onSearch={query => {
                    setClearData(false);
                    setSearchQuery(query);
                  }}
                  clearOnProp={clearData}
                />
                <div className="mt-2">
                  <FilterSliderModal
                    dropdowns={[
                      {
                        label: Instructor,
                        key: "instructor",
                        tooltipText: `${Select} ${Instructor}`,
                        options: instructorNameMap,
                        isMultiSelect: true,
                      },
                    ]}
                    selectedFilterValues={selectedFilterValues}
                    defaultFilterValues={defaultFilterValues}
                    setSelectedFilterValues={setSelectedFilterValues}
                    setDefaultFilterValues={setDefaultFilterValues}
                    onDone={handleDone}
                    onClear={handleClear}
                  />
                </div>
              </div>
            </div>

            {filteredAssets?.length > 0 ? (
              <DataTable
                pagination
                highlightOnHover
                columns={AssetsTableColumns}
                data={filteredAssets}
                customStyles={customStyles}
              />
            ) : (
              <NoData
                svg={"empty-folder-icon"}
                title={NoHistoryData}
                showCard={false}
              />
            )}
          </Col>
        </CardBody>
      </Card>
    </div>
  );
};

export default AssetHistory;
