import React from "react";
import { Card, CardBody } from "reactstrap";

import { Badges, MUIIcons, H4, P, ToolTip } from "../../AbstractElements";
import CardHeaderDropDown from "../../CommonElements/CommonCardHeader/CardHeaderDropDown";
import Divider from "../../CommonElements/Divider";
import UserCardDetails from "../../CommonElements/UserCardDetails";
import { DeleteOption } from "../../utils/Constant";
import {
  UserCardDetailsProps,
  UserCardProps,
} from "../../utils/helper/propTypes";
import AssessmentModuleCard from "../GenericCardModules";

interface GenericCardProps {
  id?: string;
  header: string;
  footerDate?: string;
  footerBadge?: string;
  userCardWithoutDetail?: UserCardProps;
  moduleDetails?: UserCardDetailsProps[];
  onHeaderDropdownClick?: (e) => void;
  isHideMoreOption?: boolean;
  status?: string;
  title?: string;
  footerBadgeRight?: string;
  btnType?: any;
  altText?: string;
  footerBadgeStatus?: string;
  showUnlink?: boolean;
  onUnlinkClick?: (e) => void;
}

const GenericCard: React.FC<GenericCardProps> = ({
  id,
  header,
  footerDate,
  footerBadge,
  userCardWithoutDetail,
  moduleDetails,
  onHeaderDropdownClick,
  isHideMoreOption,
  status,
  footerBadgeRight,
  btnType,
  altText,
  footerBadgeStatus,
  showUnlink,
  onUnlinkClick,
}) => {
  return (
    <Card className="course-card-height">
      <CardBody className="m-1">
        <div className="d-flex justify-content-between">
          <span id={`tooltip-course-name-${id}`}>
            <div className="text-ellips-parent">
              <H4
                className="header-text truncated-header text-ellips"
                data-fulltext={header}
              >
                {header}
              </H4>
            </div>
          </span>
          {status && isHideMoreOption && (
            <Badges className="d-flex align-items-center" status={status} />
          )}
          <ToolTip placement="top" target={`tooltip-course-name-${id}`}>
            {header}
          </ToolTip>
          {!isHideMoreOption && (
            <CardHeaderDropDown
              onHeaderDropdownClick={onHeaderDropdownClick}
              mainTitle={true}
              firstItem={DeleteOption}
            />
          )}
          {showUnlink && (
            <button
              className="border-0 bg-transparent pointer"
              onClick={() => onUnlinkClick(id)}
            >
              <MUIIcons
                className="pointer primary-icon-color"
                iconName="LinkOffOutlined"
                size={20}
              />
            </button>
          )}
        </div>
        {userCardWithoutDetail && (
          <div className="mt-3">
            <UserCardDetails
              imagePath={userCardWithoutDetail.imagePath}
              name={userCardWithoutDetail.name}
            />
          </div>
        )}
        <div className="mt-3">
          <AssessmentModuleCard details={moduleDetails} />
        </div>
        {(footerBadge || footerDate) && (
          <div className="card-bottom-section mt-auto">
            <Divider />
            <div className="d-flex justify-content-between mt-2 mb-1">
              <div className="d-flex gap-2">
                {footerDate && (
                  <MUIIcons
                    className="text-gray mt-1"
                    size={18}
                    iconName="CalendarTodayOutlined"
                  />
                )}
                <P className="text-muted mt-1">{footerDate}</P>
              </div>
              {footerBadge && (
                <Badges
                  status={`${footerBadgeStatus || "published"}`}
                  className="mt-1"
                >
                  {footerBadge}
                </Badges>
              )}
            </div>
          </div>
        )}

        {(footerBadgeRight || btnType?.title || altText) && (
          <>
            <Divider />
            <div className="d-flex justify-content-between align-items-center">
              <div>
                {footerBadgeRight && (
                  <Badges status="published" className="mt-1">
                    {footerBadgeRight}
                  </Badges>
                )}

                {altText && <P className="text-muted mt-1">{altText}</P>}
              </div>

              {btnType?.title && (
                <div className="d-flex gap-2">
                  <div
                    className={`edit-details-button  ${btnType.disabled ? "disabled" : ""}`}
                    color="primary"
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      btnType.onClick();
                    }}
                  >
                    {btnType?.title}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default GenericCard;
