import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

import { H3 } from "../../../../AbstractElements";
import { ALL_USERS_STATUS } from "../../../../Api/constants";
import Divider from "../../../../CommonElements/Divider";
import Graph from "../../../../CommonElements/Graph";
import FeedbackAnalysis from "../../../../container/FeedbackAnalysisCard";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { getFeedbackByLearner } from "../../../../ReduxToolkit/Reducers/FeedbackRatingSlice";
import { getUsersList } from "../../../../ReduxToolkit/Reducers/UserSlice";
import {
  CourseContent,
  CourseEffectiveness,
  InstructorEvaluation,
  StageAnalysis,
} from "../../../../utils/Constant";
import { options } from "../../../../utils/helper/helper";

const FeedbackListing = ({
  scheduledId,
  courseName,
  startDate,
  endDate,
  locationName,
  classRoomName,
  instructorId,
}) => {
  const dispatch = useAppDispatch();
  const { feedbackList } = useAppSelector(state => state.feedback);
  const { usersList } = useAppSelector(state => state.user);
  const [statusData, setStatusData] = useState([]);
  const [userMap, setUserMap] = useState({});
  const [categorySums, setCategorySums] = useState([0, 0, 0]);

  useEffect(() => {
    dispatch(getUsersList({ role: ALL_USERS_STATUS }));
    dispatch(getFeedbackByLearner({ course_schedule_id: scheduledId }));
  }, [dispatch, scheduledId]);

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserMap(userMapObj);
  }, [usersList]);

  useEffect(() => {
    let courseContentSum = 0;
    let instructorEvaluationSum = 0;
    let courseEffectivenessSum = 0;

    feedbackList?.feedback?.forEach((feedback: any) => {
      feedback?.overall_category?.forEach(category => {
        if (category.category === "course_content") {
          courseContentSum += category.percentage;
        } else if (category.category === "instructor_evaluation") {
          instructorEvaluationSum += category.percentage;
        } else if (category.category === "course_effectiveness") {
          courseEffectivenessSum += category.percentage;
        }
      });
    });

    setCategorySums([
      courseContentSum,
      courseEffectivenessSum,
      instructorEvaluationSum,
    ]);

    const statusItems = feedbackList?.feedback?.map((feedback: any) => {
      if (userMap) {
        const participant = userMap[feedback.participant_id];
        return {
          id: participant?.id,
          name: participant?.user_name,
          empId: participant?.employee_code,
          mobile: participant?.phone,
          course_feedback_id: feedback?.course_feedback_id,
          participant_id: feedback.participant_id?.toString(),
          feedback: [
            {
              label: CourseContent,
              value:
                feedback?.overall_category
                  ?.find(item => item?.category === "course_content")
                  ?.percentage?.toFixed(2) + "%" || "0%",
            },
            {
              label: CourseEffectiveness,
              value:
                feedback?.overall_category
                  ?.find(item => item?.category === "course_effectiveness")
                  ?.percentage?.toFixed(2) + "%" || "0%",
            },
            {
              label: InstructorEvaluation,
              value:
                feedback?.overall_category
                  ?.find(item => item?.category === "instructor_evaluation")
                  ?.percentage?.toFixed(2) + "%" || "0%",
            },
          ],
        };
      }
    });

    setStatusData(statusItems);
  }, [feedbackList, userMap]);

  const totalSum = feedbackList?.feedback?.length;

  const series = [
    {
      name: "Feedback Scores",
      data: [
        categorySums[0] / totalSum || 0,
        categorySums[1] / totalSum || 0,
        categorySums[2] / totalSum || 0,
      ],
    },
  ];

  const updatedOptions = {
    ...options,
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val.toFixed(2);
      },
    },
    yaxis: {
      max: 100,
      labels: {
        formatter: function (val) {
          return val.toFixed(2);
        },
      },
    },
  };

  return (
    <div className="mt-3">
      <H3>{StageAnalysis}</H3>
      <Card className="mt-2">
        <CardBody>
          <Graph series={series} options={updatedOptions} />
        </CardBody>
      </Card>
      <div className="mt-2 p-1">
        {statusData?.map(item => {
          return (
            <div key={item.participant_id}>
              <Link
                to={"/schedule-list/course-details/course-feedback"}
                state={{
                  course_name: courseName,
                  location_name: locationName,
                  classroom_name: classRoomName,
                  tranierName: userMap?.[instructorId]?.user_name,
                  course_start_date: startDate,
                  course_end_date: endDate,
                  course_schedule_id: scheduledId,
                  review: true,
                  course_feedback_id: item?.course_feedback_id,
                }}
                className="mt-2"
              >
                <FeedbackAnalysis
                  userDetails={item}
                  statusItems={item.feedback}
                />
              </Link>
              <Divider />
            </div>
          );
        })}
      </div>
    </div>
  );
};

FeedbackListing.propTypes = {
  scheduledId: PropTypes.string.isRequired,
  courseName: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  locationName: PropTypes.string.isRequired,
  classRoomName: PropTypes.string.isRequired,
  instructorId: PropTypes.string.isRequired,
};

export default FeedbackListing;
