import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useLocation, useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

import {
  DefaultButton,
  H4,
  MUIIcons,
  SecondaryButton,
  ToolTip,
} from "../../../AbstractElements";
import {
  DRAFT_STATUS,
  PUBLISHED,
  PUBLISH_STATUS,
  ILT,
  VILT,
  courseTypeMap,
  SCHEDULED_FOR_PUBLISH,
  UNPUBLISHED,
  ALL_COURSE_FILTER_STATUS,
  WEBINAR,
  WBT,
} from "../../../Api/constants";
import {
  FormValues,
  ProficiencyState,
  PublishedCourse,
  CourseLevel,
} from "../../../Api/entities/CurriculumEntity";
import { parseCurriculumRequestData } from "../../../Api/parser/curriculum";
import SimpleAccordion from "../../../CommonElements/Accordion";
import CurriculumCourseCard from "../../../CommonElements/CurriculumCourseCard";
import FilterSliderModal from "../../../CommonElements/FilterSliderModal";
import LabelTooltip from "../../../CommonElements/LabelTooltip";
import CommonModal from "../../../CommonElements/Modal";
import MultiSelectDropdown from "../../../CommonElements/MultiSelectDropdown";
import SearchDropDownPaginator from "../../../CommonElements/SearchDropDownPaginator";
import StatusDisplay from "../../../CommonElements/StatusDisplay";
import CheckboxButton from "../../../CommonElements/SwitchButton";
import VersionControl from "../../../container/VersionControl";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import { getCourseList } from "../../../ReduxToolkit/Reducers/CourseSlice";
import {
  createCurriculumContent,
  deleteCurriculumCourse,
  getCurriculumById,
} from "../../../ReduxToolkit/Reducers/CurriculumSlice";
import {
  getDomainList,
  getProficiencyList,
  getRolesList,
} from "../../../ReduxToolkit/Reducers/MasterSlice";
import {
  CurriculumDetails,
  PublishNow,
  CurriculumName,
  Enter,
  Course,
  Select,
  FutureDate,
  PublishLater,
  Dates,
  Courses,
  Domain,
  IsSpecialCurriculum,
  CourseType,
  CurriculumNameIsRequired,
  RoleIsRequired,
  DomainIsRequired,
  FutureDateIsRequired,
  SaveAsDraft,
  PleaseProvideRequiredInformation,
  Confirm,
  CurriculumScheduleConfirmation,
  CurriculumPublishConfirmation,
  CurriculumDraftConfirmation,
  DeleteCourse,
  Confirmation,
  CourseMandatoryLevel,
  CurriculumNameToolTipMessage,
  RoleToolTipMessage,
  CurriculumDomainToolTipMessage,
  CurriculumFutureDateToolTipMessage,
  CourseToolTipMessage,
  IsSpecialCurriculumToolTipMessage,
  CurriculumFirstTimePublishConfirmation,
  JobRole,
  VersionControlText,
} from "../../../utils/Constant";
import {
  PROFICIENCY_LEVEL,
  getTomorrowDate,
  mapListToOptions,
  getProficiencyListData,
  scrollToTop,
  showToast,
  courseFilterDropdownList,
  customStyles,
} from "../../../utils/helper/helper";
import "./style.scss";
import useIsMobile from "../../../utils/helper/responsive";

interface Errors {
  curriculum_name: string;
  role: string;
  futureDate: string;
  domain: string;
}

const AddCurriculum: React.FC = () => {
  const [formValues, setFormValues] = useState<FormValues>({
    curriculum_name: "",
    role: "",
    isPublishLater: false,
    futureDate: getTomorrowDate(),
  });
  const [proficiencyLevel, setProficiencyLevel] = useState<ProficiencyState>({
    [PROFICIENCY_LEVEL.PRE_FOUNDATIONAL]: {
      course: [],
      level_id: null,
    },
    [PROFICIENCY_LEVEL.FOUNDATIONAL]: {
      course: [],
      level_id: null,
    },
    [PROFICIENCY_LEVEL.ADVANCED]: {
      course: [],
      level_id: null,
    },
    [PROFICIENCY_LEVEL.EXPERT]: {
      course: [],
      level_id: null,
    },
  });
  const [errors, setErrors] = useState<Errors>({
    curriculum_name: "",
    role: "",
    futureDate: "",
    domain: "",
  });
  const [publishedCourse, setPublishedCourse] = useState(null);
  const [publishedCourseCopy, setPublishedCourseCopy] = useState(null);
  const [filteredStatus, setFilteredStatus] = useState<
    Record<string, { value: string }>
  >({});
  const [domainListData, setDomainListData] = useState([]);
  const [selectedDomainIds, setSelectedDomainIds] = useState(null);
  const [isSpecialCurriculum, setIsSpecialCurriculum] = useState(false);
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});
  const [roleListData, setRoleListData] = useState([]);
  const [isShowVersionModal, setIsShowVersionModal] = useState(false);
  const [modalInfo, setModalInfo] = useState(null);
  const location = useLocation();
  const curriculumId = location?.state?.curriculumRequestId as
    | number
    | undefined;

  const [curriculumRequestId, setCurriculumRequestId] = useState(curriculumId);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const { domainList, roleList, proficiencyList } = useAppSelector(
    state => state.master
  );
  const { courseList } = useAppSelector(state => state.course);
  const { curriculum } = useAppSelector(state => state.curriculum);

  const mapCourses = (courses: any[]): PublishedCourse[] => {
    return courses.map(course => ({
      id: course.id,
      label: course.course_name,
      value: course.course_name,
      name: course.course_id.toString(),
      title: course?.domain_names?.[0] || "",
      course_type: course.course_type,
      isNew: false,
      duration: course.duration,
    }));
  };

  const getCourseOptions = course_list => {
    return course_list.map(course => ({
      id: course.id,
      label: course.course_name,
      value: course.course_name,
      name: course.id.toString(),
      title: course?.domain_names?.[0] || "",
      course_type: course.course_type,
      isNew: true,
      duration: course.duration,
    }));
  };

  useEffect(() => {
    scrollToTop();
    dispatch(getRolesList({}));
    dispatch(getProficiencyList());
    dispatch(getDomainList());
    dispatch(
      getCourseList({
        status: PUBLISH_STATUS,
        course_type: ALL_COURSE_FILTER_STATUS,
        domain: null,
      })
    ).then(res => {
      const getCourse = getPublishedCourse(res.payload.course_list);
      setPublishedCourseCopy(getCourse);
    });
  }, []);

  useEffect(() => {
    if (courseList?.course_list?.length > 0) {
      const publishedCourse = getPublishedCourse(courseList?.course_list);
      setPublishedCourse(publishedCourse);
    }
  }, [courseList]);

  const getPublishedCourse = course_list => {
    const courseOptions = getCourseOptions(course_list);
    const publishedCourse = {
      [PROFICIENCY_LEVEL.PRE_FOUNDATIONAL]: courseOptions || [],
      [PROFICIENCY_LEVEL.FOUNDATIONAL]: courseOptions || [],
      [PROFICIENCY_LEVEL.ADVANCED]: courseOptions || [],
      [PROFICIENCY_LEVEL.EXPERT]: courseOptions || [],
    };

    return publishedCourse;
  };

  useEffect(() => {
    if (curriculumRequestId) {
      dispatch(getCurriculumById({ id: curriculumRequestId }));
    }
  }, [curriculumRequestId]);

  useEffect(() => {
    if (curriculumRequestId && proficiencyList.proficiency.length > 0) {
      const selectedProficiencyList = {};
      proficiencyList.proficiency.forEach(element => {
        const proficiencyKey = element.level_name
          .toLowerCase()
          .replace(/[\s-]/g, "_");

        selectedProficiencyList[proficiencyKey] = {
          course: mapCourses(
            curriculum.level.find(level => level.level === element.level_name)
              ?.courses || []
          ),
          level_id: element.id,
        };
      });
      setProficiencyLevel(selectedProficiencyList);
      setFormValues({
        curriculum_name: curriculum.name,
        role: curriculum.role_id?.toString() || "",
        isPublishLater:
          curriculum.status === SCHEDULED_FOR_PUBLISH ? true : false,
        futureDate: curriculum.publish_at
          ? new Date(curriculum.publish_at)
          : null,
      });
      setIsSpecialCurriculum(curriculum.is_special_curriculum);

      if (curriculum?.domain?.length > 0) {
        setSelectedDomainIds(curriculum?.domain[0].id.toString());
      }
    }
  }, [curriculum, proficiencyList]);

  useEffect(() => {
    const options = mapListToOptions(domainList.domain, "domain_name", "id");
    setDomainListData(options);
  }, [domainList]);

  useEffect(() => {
    if (roleList) {
      const options = mapListToOptions(roleList.job_role, "name", "id");
      setRoleListData(options);
    }
  }, [roleList]);

  const handleDelete = async (key: string, item: PublishedCourse) => {
    setModalInfo({
      isOpen: true,
      title: DeleteCourse,
      callback: () => handleDeleteCourse(key, item),
    });
  };

  const handleDeleteCourse = (key, item) => {
    const updatedData = { ...proficiencyLevel };
    const courseIndex = updatedData[key]?.course?.findIndex(
      course => course.name === item.name
    );

    updatedData[key].course.splice(courseIndex, 1);

    setProficiencyLevel(updatedData);
    setModalInfo(null);
  };

  const handleDelelteCurriculumCourse = async levels => {
    const payloadAfterDelete = {
      curriculum_id: curriculum.id,
      role_id: curriculum.role_id,
      levels: levels,
    };

    return await dispatch(
      deleteCurriculumCourse({ curriculumContent: payloadAfterDelete })
    );
  };

  const countCourseTypes = (
    courses: PublishedCourse[]
  ): { label: string; value: number; key?: string }[] => {
    const counts = { ilt: 0, vilt: 0, webinar: 0, wbt: 0 };

    courses?.forEach(({ course_type }) => {
      if (counts[course_type] !== undefined) counts[course_type]++;
    });

    return [
      { label: Courses, value: courses.length },
      { label: courseTypeMap.ilt, value: counts.ilt, key: ILT },
      { label: courseTypeMap.vilt, value: counts.vilt, key: VILT },
      { label: courseTypeMap.webinar, value: counts.webinar, key: WEBINAR },
      { label: courseTypeMap.wbt, value: counts.wbt, key: WBT },
    ];
  };

  const handleChange = e => {
    setFormValues(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleMultiselectChange = async (
    e: string[],
    type: string,
    proficiencyId: number
  ) => {
    const value = publishedCourseCopy[type]
      ?.filter(course => e.includes(course.name))
      .map(item => {
        const level = curriculum?.level.find(l => l.level_id === proficiencyId);
        const courseExists = level?.courses.some(
          course => course.course_id.toString() === item.name
        );
        return {
          ...item,
          isNew: !courseExists,
        };
      });

    setProficiencyLevel(prevState => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        course: value,
        level_id: proficiencyId,
      },
    }));
  };

  const getFilteredCourses = (type: string) => {
    const selectedCourses = Object.keys(proficiencyLevel)
      .filter(key => key !== type)
      .flatMap(key => proficiencyLevel[key].course);

    return (
      publishedCourse &&
      publishedCourse[type]?.filter(
        course =>
          !selectedCourses.some(selected => selected.name === course.name)
      )
    );
  };

  const filterCoursesByType = (
    proficiencyType: string,
    courseType: string
  ): PublishedCourse[] => {
    if (!courseType) return proficiencyLevel[proficiencyType].course;
    return proficiencyLevel[proficiencyType].course.filter(
      ({ course_type }) => course_type === courseType
    );
  };

  const handleFilterStatus = (key, value) => {
    setFilteredStatus(prevState => {
      return {
        ...prevState,
        [key]: {
          value: value,
        },
      };
    });
  };

  const isActive = (proficiencyType, stat) => {
    return filteredStatus[proficiencyType]?.value === stat;
  };

  const fetchAndSetCourses = async (
    key: string,
    course_type: string[],
    domain: string[],
    clearFilters: boolean
  ) => {
    const result = await dispatch(
      getCourseList({
        status: PUBLISH_STATUS,
        course_type: course_type || ALL_COURSE_FILTER_STATUS,
        domain: domain,
      })
    );

    const courseList = getCourseOptions(result?.payload?.course_list);
    setPublishedCourse(prevState => ({
      ...prevState,
      [key]: courseList,
    }));

    if (clearFilters) {
      setSelectedFilterValues(prevState => ({
        ...prevState,
        [key]: [],
      }));

      setDefaultFilterValues(prevState => ({
        ...prevState,
        [key]: [],
      }));
    }
  };

  const handleDone = async (
    values: { [key: string]: string[] },
    proficiencyType
  ) => {
    const selectedCourseTypes = values[`${proficiencyType}_course_type`];
    const selectedDomains = values[`${proficiencyType}_domain`];
    await fetchAndSetCourses(
      proficiencyType,
      selectedCourseTypes,
      selectedDomains,
      false
    );
  };

  const handleClear = async (values: { [key: string]: string[] }) => {
    const key = Object.keys(values)[0];
    await fetchAndSetCourses(key, ALL_COURSE_FILTER_STATUS, null, true);
  };

  const findMissingCourses = (
    source: CourseLevel[],
    target: ProficiencyState
  ) => {
    const missingCoursesMap = {};

    source.forEach(sourceLevel => {
      const { level_id, courses } = sourceLevel;
      const targetCourses = Object.values(target).find(
        targetLevel => targetLevel.level_id === level_id
      )?.course;

      courses.forEach(course => {
        const courseExistsInTarget = targetCourses?.some(
          targetCourse =>
            targetCourse.name === course.course_id.toString() &&
            targetCourse.course_type === course.course_type
        );

        if (!courseExistsInTarget) {
          if (!missingCoursesMap[level_id]) {
            missingCoursesMap[level_id] = [];
          }
          missingCoursesMap[level_id].push(course.course_id);
        }
      });
    });
    const missingCourses = Object.entries(missingCoursesMap).map(
      ([level_id, courses]) => ({
        level_id: Number(level_id),
        courses,
      })
    );

    return missingCourses;
  };

  const getProficiency = (proficiencyType: string, proficiencyId) => {
    return (
      <div>
        <Row className="align-items-center mb-3">
          {countCourseTypes(proficiencyLevel[proficiencyType]?.course).map(
            stat => (
              <React.Fragment key={stat.key}>
                <Col
                  xs="6"
                  className="text-secondary pointer"
                  md="auto"
                  onClick={() => handleFilterStatus(proficiencyType, stat.key)}
                >
                  <div
                    className={`${isActive(proficiencyType, stat?.key) ? "badge bg-primary" : ""}`}
                  >
                    <StatusDisplay
                      label={stat.label}
                      number={stat.value?.toString()}
                      color={`${isActive(proficiencyType, stat?.key) ? "text-white" : ""}`}
                    />
                  </div>
                </Col>
              </React.Fragment>
            )
          )}
        </Row>
        <Row>
          <Col sm="12" lg="6">
            <div className="d-flex w-100  gap-3">
              <FormGroup className="w-100">
                <LabelTooltip
                  label={Course}
                  tooltipText={CourseToolTipMessage}
                  important={false}
                />
                <div className="add-curriculum__course">
                  <MultiSelectDropdown
                    options={getFilteredCourses(proficiencyType)?.map(item => ({
                      name: item.name,
                      value: item.value,
                      label: item.label,
                    }))}
                    placeholder={`${Select} ${Course}`}
                    defaultSelected={proficiencyLevel[
                      proficiencyType
                    ]?.course?.map(item => ({
                      name: item.name,
                      value: item.value,
                      label: item.label,
                    }))}
                    onChange={e =>
                      handleMultiselectChange(e, proficiencyType, proficiencyId)
                    }
                    closeButton={false}
                    disabled={isArchive() ? true : false}
                  />
                </div>
              </FormGroup>
              <div className="filter-margin">
                <FilterSliderModal
                  dropdowns={[
                    {
                      label: CourseType,
                      key: proficiencyType + "_course_type",
                      tooltipText: `${Select} ${CourseType}`,
                      options: courseFilterDropdownList,
                      isMultiSelect: true,
                    },
                    {
                      label: Domain,
                      key: proficiencyType + "_domain",
                      tooltipText: `${Select} ${Domain}`,
                      options: domainListData,
                      isMultiSelect: true,
                    },
                  ]}
                  selectedFilterValues={selectedFilterValues}
                  defaultFilterValues={defaultFilterValues}
                  setSelectedFilterValues={setSelectedFilterValues}
                  setDefaultFilterValues={setDefaultFilterValues}
                  onDone={value => handleDone(value, proficiencyType)}
                  onClear={handleClear}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          {filterCoursesByType(
            proficiencyType,
            filteredStatus[proficiencyType]?.value
          ).map(item => (
            <Col sm="12" lg="4" key={item.id}>
              <CurriculumCourseCard
                header={item.label}
                title={item.title}
                id={item.name}
                showDelete={isArchive() ? false : true}
                handleDelete={() => handleDelete(proficiencyType, item)}
                type={item?.course_type}
              />
            </Col>
          ))}
        </Row>
      </div>
    );
  };

  const getRoleNameById = id => {
    const role = roleList?.job_role?.find(role => role.id.toString() === id);
    return role ? role.name : "";
  };

  const validateForm = () => {
    const newErrors = {
      curriculum_name: "",
      role: "",
      futureDate: "",
      domain: "",
    };

    let isError = false;
    if (!formValues.curriculum_name) {
      newErrors.curriculum_name = CurriculumNameIsRequired;
      isError = true;
    }

    if (!isSpecialCurriculum && !formValues.role) {
      newErrors.role = RoleIsRequired;
      isError = true;
    }

    if (selectedDomainIds.length === 0) {
      newErrors.domain = DomainIsRequired;
      isError = true;
    }

    if (formValues.isPublishLater && !formValues.futureDate) {
      newErrors.futureDate = FutureDateIsRequired;
      isError = true;
    }

    setErrors(newErrors);
    if (isError) {
      showToast(PleaseProvideRequiredInformation, "error");
      scrollToTop();
    }
    return isError;
  };

  const handleSubmitCurriculum = async status => {
    const payload = parseCurriculumRequestData(
      formValues,
      proficiencyLevel,
      status,
      selectedDomainIds,
      isSpecialCurriculum
    );

    if (curriculum.status === PUBLISHED) {
      const levels = Object.keys(proficiencyLevel).reduce((acc, level) => {
        const courses = proficiencyLevel[level].course.map(course => ({
          course_id: Number(course.name),
        }));

        if (courses.length > 0) {
          acc.push({
            level_id: proficiencyLevel[level].level_id,
            courses,
          });
        }

        return acc;
      }, []);

      payload.levels = levels?.length > 0 ? levels : [];
    }

    const missingIds = findMissingCourses(curriculum.level, proficiencyLevel);

    if (curriculumRequestId) {
      payload.id = curriculumRequestId;
    }

    if (curriculum.status !== PUBLISHED && missingIds.length > 0) {
      const response = await handleDelelteCurriculumCourse(missingIds);

      if (!response.payload) {
        return;
      }
    }

    const response = await dispatch(
      createCurriculumContent({
        curriculumContent: payload,
      })
    );

    if (!response.payload) {
      return;
    }

    let navigateUrl = `${process.env.PUBLIC_URL}/master-data/curriculum`;

    if (status === PUBLISHED) {
      navigateUrl += "?status=published";
    }

    return navigate(navigateUrl);
  };

  const getModalTitle = (status: string) => {
    if (status === DRAFT_STATUS) {
      return CurriculumDraftConfirmation;
    } else if (formValues.isPublishLater) {
      return CurriculumScheduleConfirmation;
    } else if (curriculumId && status !== DRAFT_STATUS) {
      return CurriculumPublishConfirmation;
    } else {
      return CurriculumFirstTimePublishConfirmation;
    }
  };

  const handleSubmit = async (status: string) => {
    if (validateForm()) {
      return;
    }
    if (!hasCourses()) {
      return showToast(CourseMandatoryLevel, "error");
    }
    setModalInfo({
      isOpen: true,
      title: getModalTitle(status),
      callback: () => handleSubmitCurriculum(status),
    });
  };

  const handleDomainChange = e => {
    setSelectedDomainIds(e.target.value);
  };

  const handleDateChange = (name: string, date: Date) => {
    setFormValues(prevState => ({
      ...prevState,
      [name]: date || null,
    }));
  };

  const handleVersionModal = () => {
    setIsShowVersionModal(prevState => !prevState);
  };

  const handleClickVersion = element => {
    setCurriculumRequestId(element.id);
    handleVersionModal();
  };

  const closeModal = () => {
    setModalInfo(null);
  };

  const isArchive = () => {
    return curriculumRequestId && curriculum?.status === UNPUBLISHED;
  };

  const hasCourses = () => {
    return Object.values(proficiencyLevel)?.some(
      level => level?.course?.length > 0
    );
  };

  return (
    <div className="page-body add-curriculum">
      <CommonModal
        sizeTitle={Confirmation}
        modalBodyClassName=""
        isOpen={modalInfo?.isOpen}
        toggle={closeModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={modalInfo?.callback}
        primaryBtnText={Confirm}
      >
        {modalInfo?.title}
      </CommonModal>
      <Card className="mt-4">
        <CardHeader className="d-flex justify-content-between align-items-center">
          <H4>{CurriculumDetails}</H4>
          {!!curriculumRequestId && (
            <span id="versionControl">
              <MUIIcons
                onClick={handleVersionModal}
                size={24}
                iconName="ManageHistoryOutlined"
                className="primary-icon-color pointer"
              />
            </span>
          )}
          {!!curriculumRequestId && (
            <ToolTip
              placement="top"
              target="versionControl"
              disabled={isMobile}
            >
              {VersionControlText}
            </ToolTip>
          )}
        </CardHeader>

        <CardBody>
          <Form className="g-3 custom-input" noValidate>
            <Row className="d-flex align-items-end">
              <Col sm="12" lg="6">
                <FormGroup>
                  <LabelTooltip
                    label={CurriculumName}
                    tooltipText={CurriculumNameToolTipMessage}
                    important
                  />
                  <Input
                    onChange={handleChange}
                    value={formValues.curriculum_name}
                    name="curriculum_name"
                    type="text"
                    placeholder={`${Enter} ${CurriculumName}`}
                    maxLength={100}
                    invalid={!!errors.curriculum_name}
                    disabled={isArchive() ? true : false}
                  />
                  {errors.curriculum_name && (
                    <div className="invalid-feedback">
                      {errors.curriculum_name}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col sm="12" lg="6" className="d-flex gap-2 mt-3">
                <p className="fw-bold">
                  <LabelTooltip
                    label={IsSpecialCurriculum}
                    tooltipText={IsSpecialCurriculumToolTipMessage}
                    important={false}
                    placement={"bottom"}
                  />
                </p>
                <div className="mt-1">
                  <CheckboxButton
                    onChange={e => {
                      setIsSpecialCurriculum(e.target.checked);
                    }}
                    checked={isSpecialCurriculum}
                    disabled={isArchive() ? true : false}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm="12" lg="6">
                <FormGroup>
                  <LabelTooltip
                    label={JobRole}
                    tooltipText={RoleToolTipMessage}
                    important={isSpecialCurriculum ? false : true}
                  />
                  <ReactSelect
                    name="role"
                    className="text-gray"
                    onChange={selectedOption => {
                      handleChange({
                        target: {
                          name: "role",
                          value: selectedOption ? selectedOption.value : "",
                        },
                      });
                    }}
                    value={
                      roleListData?.find(
                        option => option.value === formValues.role
                      ) || null
                    }
                    options={roleListData?.map(option => ({
                      value: option.value,
                      label: option.label,
                    }))}
                    isDisabled={isArchive()}
                    isSearchable
                    isClearable={false}
                    styles={customStyles}
                    components={{
                      MenuList: props => (
                        <SearchDropDownPaginator {...props} maxHeight={200} />
                      ),
                    }}
                    filterOption={(option, inputValue) =>
                      option?.label
                        ?.toLowerCase()
                        ?.includes(inputValue?.toLowerCase())
                    }
                  />

                  {errors.role && (
                    <div className="mt-1 text-danger">{errors.role}</div>
                  )}
                </FormGroup>
              </Col>
              <Col sm="12" lg="6">
                <FormGroup>
                  <LabelTooltip
                    label={Domain}
                    tooltipText={CurriculumDomainToolTipMessage}
                    important={true}
                  />
                  <ReactSelect
                    name="domain"
                    className="text-gray"
                    onChange={selectedOption =>
                      handleDomainChange({
                        target: {
                          name: "domain",
                          value: selectedOption ? selectedOption?.value : "",
                        },
                      })
                    }
                    value={
                      domainListData?.find(
                        option => option.value === selectedDomainIds
                      ) || null
                    }
                    options={domainListData?.map(option => ({
                      value: option.value,
                      label: option.label,
                    }))}
                    isSearchable
                    isClearable={false}
                    styles={customStyles}
                    components={{
                      MenuList: props => (
                        <SearchDropDownPaginator {...props} maxHeight={250} />
                      ),
                    }}
                    filterOption={(option, inputValue) =>
                      option?.label
                        ?.toLowerCase()
                        ?.includes(inputValue?.toLowerCase())
                    }
                    isDisabled={isArchive()}
                  />

                  {errors.domain && (
                    <div className="mt-1 text-danger">{errors.domain}</div>
                  )}
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>

      <Card className="mt-4">
        {formValues?.role && (
          <CardHeader>
            <H4>{getRoleNameById(formValues.role)}</H4>
          </CardHeader>
        )}
        <CardBody>
          <SimpleAccordion
            accordionList={getProficiencyListData(
              getProficiency,
              proficiencyList.proficiency,
              proficiencyLevel
            )}
          />
          <div className="mt-4">
            <div className="form-check text-dark">
              <Input
                className="primary-checkbox"
                id="flexCheckPublishLater"
                type="checkbox"
                checked={formValues.isPublishLater}
                onChange={() =>
                  setFormValues(prevState => ({
                    ...prevState,
                    futureDate: getTomorrowDate(),
                    isPublishLater: !prevState.isPublishLater,
                  }))
                }
                disabled={isArchive() ? true : false}
              />
              <Label
                className="text-dark"
                htmlFor="flexCheckPublishLater"
                check
              >
                {PublishLater}
              </Label>
            </div>
          </div>
          {formValues.isPublishLater && (
            <Row className="mt-1">
              <Col sm="12" lg="6">
                <FormGroup>
                  <LabelTooltip
                    label={FutureDate}
                    tooltipText={CurriculumFutureDateToolTipMessage}
                    important
                  />

                  <div className={"w-100"}>
                    <DatePicker
                      selected={formValues?.futureDate}
                      onChange={(date: Date | null) =>
                        handleDateChange("futureDate", date)
                      }
                      minDate={getTomorrowDate()}
                      placeholderText={`${Enter} ${Dates}`}
                      className={`form-control ${errors?.futureDate ? "is-invalid" : ""}`}
                      dateFormat="dd-MM-yyyy"
                      disabledKeyboardNavigation={true}
                      onKeyDown={e => e.preventDefault()}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />

                    {errors?.futureDate && (
                      <div className="invalid-date-picker-feedback">
                        {errors?.futureDate}
                      </div>
                    )}
                  </div>
                </FormGroup>
              </Col>
            </Row>
          )}
        </CardBody>
        {!isArchive() && (
          <CardFooter className="d-flex justify-content-end">
            <Row>
              <Col xs="auto" className="d-flex gap-2">
                <SecondaryButton onClick={() => handleSubmit(DRAFT_STATUS)}>
                  {SaveAsDraft}
                </SecondaryButton>
              </Col>
              <Col xs="auto">
                <DefaultButton
                  onClick={() => handleSubmit(PUBLISHED)}
                  color="primary"
                >
                  {formValues.isPublishLater ? PublishLater : PublishNow}
                </DefaultButton>
              </Col>
            </Row>
          </CardFooter>
        )}
      </Card>
      {isShowVersionModal && (
        <VersionControl
          isShowVersionModal={isShowVersionModal}
          handleVersionModal={handleVersionModal}
          versions={curriculum?.version}
          handleClickVersion={handleClickVersion}
          activeId={curriculumRequestId}
        />
      )}
    </div>
  );
};

export default AddCurriculum;
