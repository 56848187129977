import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { Card, CardBody, FormGroup, Input, Row, Col } from "reactstrap";

import LabelTooltip from "../../../CommonElements/LabelTooltip";
import CheckboxButton from "../../../CommonElements/SwitchButton";
import { Enter } from "../../../utils/Constant";
import {
  handleKeyDownOnNumericWithoutDecimal,
  participantSubMenuTooltipMap,
  participantSunMenuStatusMap,
  participantValidationStatusMap,
  participantValidationTooltipMap,
} from "../../../utils/helper/helper";

const ParticipantValidation = ({ settings, setSettings }) => {
  const [selectedRuleValues, setSelectedRuleValues] = useState({});

  useEffect(() => {
    const initialValues = {};
    settings.rules.forEach(rule => {
      initialValues[rule.name] = {
        enabled: rule.enabled,
        rules: rule.rules.reduce((acc, nestedRule) => {
          acc[nestedRule.name] = nestedRule.value;
          return acc;
        }, {}),
      };
    });
    setSelectedRuleValues(initialValues);
  }, [settings.rules]);

  const handleCheckboxChange = (key, checked) => {
    setSelectedRuleValues(prev => ({
      ...prev,
      [key]: { ...prev[key], enabled: checked },
    }));
  };

  const handleInputChange = (ruleName, nestedRuleName, value) => {
    if (value <= 100)
      setSelectedRuleValues(prev => ({
        ...prev,
        [ruleName]: {
          ...prev[ruleName],
          rules: {
            ...prev[ruleName]?.rules,
            [nestedRuleName]: value,
          },
        },
      }));
  };

  useEffect(() => {
    const updatedRules = settings.rules?.map(rule => ({
      name: rule.name,
      enabled:
        selectedRuleValues[rule.name]?.enabled !== undefined
          ? selectedRuleValues[rule.name].enabled
          : rule.enabled,
      rules: rule.rules.map(nestedRule => ({
        name: nestedRule.name,
        value:
          selectedRuleValues[rule.name]?.rules?.[nestedRule.name] !== undefined
            ? selectedRuleValues[rule.name].rules[nestedRule.name]
            : nestedRule.value,
      })),
    }));

    if (JSON.stringify(settings.rules) !== JSON.stringify(updatedRules)) {
      setSettings(prev => ({
        ...prev,
        rules: updatedRules,
      }));
    }
  }, [selectedRuleValues]);

  return (
    <Card className="p-2">
      <CardBody>
        <Row>
          {settings?.rules?.map(rule => {
            return (
              <Col xs={12} key={rule.id || rule.name} className="mt-3">
                <Row>
                  <Col>
                    <LabelTooltip
                      label={participantValidationStatusMap[rule.name]}
                      tooltipText={`${participantValidationTooltipMap[rule.name]}`}
                      important={false}
                      placement="bottom"
                    />
                  </Col>
                  <Col className="mt-2">
                    <CheckboxButton
                      onChange={e =>
                        handleCheckboxChange(rule.name, e.target.checked)
                      }
                      checked={
                        selectedRuleValues[rule.name]?.enabled ?? rule.enabled
                      }
                    />
                  </Col>
                </Row>
                {selectedRuleValues[rule.name]?.enabled && (
                  <div>
                    {rule.rules.map(nestedRule => {
                      return (
                        <FormGroup key={nestedRule.name}>
                          <LabelTooltip
                            label={`${Enter} ${participantSunMenuStatusMap[rule.name + "_" + (nestedRule.name ? nestedRule.name : "")]}`}
                            tooltipText={`${participantSubMenuTooltipMap[rule.name + "_" + (nestedRule.name ? nestedRule.name : "")]}`}
                            important={false}
                          />
                          <Input
                            type="number"
                            onKeyDown={handleKeyDownOnNumericWithoutDecimal}
                            onChange={e =>
                              handleInputChange(
                                rule.name,
                                nestedRule.name,
                                e.target.value
                              )
                            }
                            min={0}
                            max={100}
                            value={
                              selectedRuleValues[rule.name]?.rules?.[
                                nestedRule.name
                              ] ?? nestedRule.value
                            }
                          />
                        </FormGroup>
                      );
                    })}
                  </div>
                )}
              </Col>
            );
          })}
        </Row>
      </CardBody>
    </Card>
  );
};

ParticipantValidation.propTypes = {
  settings: PropTypes.shape({
    rules: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        enabled: PropTypes.bool.isRequired,
        rules: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired,
          })
        ).isRequired,
      })
    ).isRequired,
  }).isRequired,
  setSettings: PropTypes.func.isRequired,
};

export default ParticipantValidation;
