import React from "react";
import { Card, CardBody } from "reactstrap";

import {
  Badges,
  MUIIcons,
  H4,
  H5,
  P,
  SVG,
  ToolTip,
} from "../../AbstractElements";
import { courseTypeMap } from "../../Api/constants";
import CommonCardHeader from "../../CommonElements/CommonCardHeader/CommonCardHeader";
import Divider from "../../CommonElements/Divider";
import "./style.scss";
import { sanitizeString } from "../../utils/helper/helper";
import useIsMobile from "../../utils/helper/responsive";

interface CourseCardProps {
  title: string[];
  header: string;
  rm: string;
  requestedOn: string;
  status: string;
  levels: any[];
  type: string;
  course_id: string;
  range: string;
  assignments: any[];
  userNameMap: Record<string, { user_name: string }>;
}

const CourseCard: React.FC<CourseCardProps> = ({
  title,
  header,
  levels = [],
  requestedOn,
  status,
  type,
  course_id,
  range,
  assignments = [],
  userNameMap = {},
}) => {
  const trainerIds = assignments
    .filter(item => item.assignment_type === "trainer")
    .map(item => item.user_id);
  const validUserNameMap = userNameMap || {};
  const trainerUsernames = trainerIds
    .map((id, index, array) => {
      const username = validUserNameMap[id]?.user_name;
      return index < array.length - 1 ? username : username || "";
    })
    .filter(name => name)
    .join(", ");
  const firstTrainerId = assignments.find(
    trainer => trainer.assignment_type === "trainer"
  )?.user_id;
  const firstTrainerUsername = firstTrainerId
    ? validUserNameMap[firstTrainerId]?.user_name
    : null;

  const levelIconId = levels?.[0]?.toLowerCase() || "pre-foundational";
  const displayedRange = range === "0-0" ? "-" : range;
  const isMobile = useIsMobile();

  const getAllLevels = () => {
    if (levels && levels.length > 0) {
      return levels?.filter(label => label !== "").join(", ");
    } else return "";
  };

  const getLevelText = () => {
    if (levels && levels.length > 0) {
      if (levels.length > 1) {
        return `${levels[0]} ${levels.length - 1}+`;
      } else {
        return levels[0] ? levels[0] : "";
      }
    } else {
      return "";
    }
  };

  return (
    <Card className={`pointer ${isMobile ? "" : "course-card-height"}`}>
      <CommonCardHeader
        courseId={course_id}
        status={status}
        badge="text"
        headClass="card-no-border pb-0"
        title={title}
      />
      <CardBody className="card-body d-flex flex-column">
        <div className="flex-grow-1">
          <div>
            <span id={sanitizeString(`tooltip-course-name-${course_id}`)}>
              <div className="text-ellips-parent">
                <H4 className="header-text text-ellips" data-fulltext={header}>
                  {header}
                </H4>
              </div>
            </span>
            <ToolTip
              placement="top"
              target={sanitizeString(`tooltip-course-name-${course_id}`)}
            >
              {header}
            </ToolTip>
          </div>
          <div className="mt-3">
            {trainerUsernames ? (
              <>
                <span id={sanitizeString(`tooltip-trainers-${course_id}`)}>
                  <H5>
                    {firstTrainerUsername}{" "}
                    {trainerIds?.length > 1 && `${trainerIds?.length - 1}+`}
                  </H5>
                </span>
                <ToolTip
                  placement="top"
                  target={sanitizeString(`tooltip-trainers-${course_id}`)}
                >
                  {trainerUsernames}
                </ToolTip>
              </>
            ) : (
              "-"
            )}
          </div>

          <div className="d-flex gap-2 mt-3">
            <div className="d-flex gap-2">
              <MUIIcons size={18} iconName="GroupOutlined" />
              <P>{displayedRange}</P>
            </div>
            <div className="divider-container">
              <div className="divider align-item-center bg-gray"></div>
            </div>
            <div className="custom-icon-size d-flex gap-2">
              <SVG iconId={levelIconId} className="custom-svg" />
            </div>
            <div>
              <span
                className="d-inline"
                id={sanitizeString(`tooltip-course-level-${course_id}`)}
              >
                <H5>{getLevelText()}</H5>
              </span>
              <ToolTip
                placement="top"
                target={sanitizeString(`tooltip-course-level-${course_id}`)}
              >
                {getAllLevels()}
              </ToolTip>
            </div>
          </div>
        </div>
        <div className="card-bottom-section mt-auto">
          <Divider />
          <div className="d-flex justify-content-between my-2">
            <div className="d-flex gap-2">
              <MUIIcons
                className="text-gray mt-1"
                size={18}
                iconName="CalendarTodayOutlined"
              />
              <P className="text-muted mt-1">{requestedOn}</P>
            </div>
            <Badges className="mt-1">{courseTypeMap[type]}</Badges>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default CourseCard;
