import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  CREATE_EXTERNAL_USER,
  CREATE_EXTERNAL_USERS_URL,
  DELETE,
  GET,
  GET_ENCRYPTION_TOKEN,
  GET_ENCRYPTION_TOKEN_URL,
  GET_LMS_ROLES,
  GET_LMS_ROLES_URL,
  GET_SUSPENDED_USERS,
  GET_USER_DETAILS_BY_ID,
  GET_USER_DETAILS_BY_ID_URL,
  GET_USERS_ON_TABS,
  GET_USERS_ON_TABS_URL,
  MAP_ROLES_TO_USER,
  MAP_ROLES_TO_USER_URL,
  POST,
  PUT,
  SUSPEND_EXTERNAL_USER,
  SUSPEND_EXTERNAL_USERS_URL,
  UN_MAP_ROLES_TO_USER,
  UPDATE_EXTERNAL_USER,
} from "../../Api";
import apiRequest from "../../Api/connector";
import { STATUS_200 } from "../../Api/constants";
import { SomethingWentWrong } from "../../utils/Constant";
import { showToast } from "../../utils/helper/helper";

interface MappedRM {
  id: number;
  name: string;
  email: string;
  primary_mobile_number: string;
  rm_phone: string;
}

interface MappedRM {
  id: number;
  name: string;
  email: string | null;
  rm_phone: string | null;
  primary_mobile_number: string | null;
}

interface MappedLMSRole {
  id: number;
  user_id: number;
  role_id: number;
  role_name: string;
}

interface User {
  id: number;
  user_name: string;
  employee_code: string;
  cws_cat_rec: string;
  sbu: string;
  job_role_id: number;
  job_role_name: string;
  designation: string;
  business_group: string;
  department: string;
  phone: string | null;
  employee_type: string;
  office_state: string;
  office_city: string;
  grade_id: number;
  grade: string;
  email: string;
  primary_mobile_number: string;
  mapped_rm: MappedRM[];
  mapped_lms_roles: MappedLMSRole[];
}

interface UsersDataList {
  users: User[];
}

interface LMSRole {
  id: number;
  role_name: string;
}

interface FormValues {
  lms_role: LMSRole[];
}

interface UsersData {
  userDetails: any;
  lmsRole: FormValues;
  usersListOnTabs: UsersDataList;
  suspendedUsersList: UsersDataList;
  loading: boolean;
  error: string | null;
}

const initialState: UsersData = {
  userDetails: {},
  usersListOnTabs: { users: [] },
  suspendedUsersList: { users: [] },
  lmsRole: { lms_role: [] },
  loading: false,
  error: null,
};

export const getUsersListOnTabs = createAsyncThunk(
  GET_USERS_ON_TABS,
  async ({ tab }: { tab: string }) => {
    const response = await apiRequest(GET, GET_USERS_ON_TABS_URL(tab));
    return response.data;
  }
);
export const getSuspendedUsersList = createAsyncThunk(
  GET_SUSPENDED_USERS,
  async () => {
    const response = await apiRequest(GET, GET_USERS_ON_TABS_URL("suspended"));
    return response.data;
  }
);

export const getEncryptionToken = createAsyncThunk(
  GET_ENCRYPTION_TOKEN,
  async () => {
    const response = await apiRequest(GET, GET_ENCRYPTION_TOKEN_URL());
    return response.data;
  }
);

export const getUserDetailsById = createAsyncThunk(
  GET_USER_DETAILS_BY_ID,
  async ({ id }: { id: string }) => {
    const response = await apiRequest(GET, GET_USER_DETAILS_BY_ID_URL(id));
    return response.data;
  }
);

export const getLMSRoles = createAsyncThunk(GET_LMS_ROLES, async () => {
  const response = await apiRequest(GET, GET_LMS_ROLES_URL());
  return response?.data;
});

export const createExternalUser = createAsyncThunk(
  CREATE_EXTERNAL_USER,
  async ({ payload }: { payload: any }) => {
    const response = await apiRequest(
      POST,
      CREATE_EXTERNAL_USERS_URL(),
      payload
    );
    return response?.data;
  }
);

export const updateExternalUser = createAsyncThunk(
  UPDATE_EXTERNAL_USER,
  async ({ payload }: { payload: any }) => {
    const response = await apiRequest(
      PUT,
      CREATE_EXTERNAL_USERS_URL(),
      payload
    );
    return response?.data;
  }
);

export const suspendExternalUser = createAsyncThunk(
  SUSPEND_EXTERNAL_USER,
  async ({ payload }: { payload: any }) => {
    const response = await apiRequest(
      PUT,
      SUSPEND_EXTERNAL_USERS_URL(),
      payload
    );
    return response;
  }
);

export const mapRolesToUser = createAsyncThunk(
  MAP_ROLES_TO_USER,
  async ({ payload }: { payload: any }) => {
    const response = await apiRequest(PUT, MAP_ROLES_TO_USER_URL(), payload);
    return response;
  }
);

export const unMapRolesToUser = createAsyncThunk(
  UN_MAP_ROLES_TO_USER,
  async ({ payload }: { payload: any }) => {
    const response = await apiRequest(DELETE, MAP_ROLES_TO_USER_URL(), payload);
    return response;
  }
);

const UserManagementSlice = createSlice({
  name: "userManagement",
  initialState,
  reducers: {
    setUserDetailsToInitials: state => {
      state.userDetails = initialState.userDetails;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getUsersListOnTabs.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUsersListOnTabs.fulfilled, (state, action) => {
        state.loading = false;
        state.usersListOnTabs = action.payload;
      })
      .addCase(getUsersListOnTabs.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getSuspendedUsersList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSuspendedUsersList.fulfilled, (state, action) => {
        state.loading = false;
        state.suspendedUsersList = action.payload;
      })
      .addCase(getSuspendedUsersList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getUserDetailsById.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserDetailsById.fulfilled, (state, action) => {
        state.loading = false;
        state.userDetails = action.payload;
      })
      .addCase(getUserDetailsById.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(mapRolesToUser.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(mapRolesToUser.fulfilled, (state, action) => {
        state.loading = false;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(mapRolesToUser.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(unMapRolesToUser.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(unMapRolesToUser.fulfilled, (state, action) => {
        state.loading = false;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(unMapRolesToUser.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(suspendExternalUser.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(suspendExternalUser.fulfilled, (state, action) => {
        state.loading = false;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(suspendExternalUser.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getLMSRoles.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getLMSRoles.fulfilled, (state, action) => {
        state.loading = false;
        state.lmsRole = action.payload;
      })
      .addCase(getLMSRoles.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(createExternalUser.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createExternalUser.fulfilled, (state, action) => {
        state.loading = false;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(createExternalUser.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(updateExternalUser.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateExternalUser.fulfilled, (state, action) => {
        state.loading = false;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(updateExternalUser.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
  },
});

export const { setUserDetailsToInitials } = UserManagementSlice.actions;

export default UserManagementSlice.reducer;
