import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

import { H4, ToolTip } from "../../../../AbstractElements";
import { useAppContext } from "../../../../AppContext";
import IconWithValue from "../../../../CommonElements/IconWithValue";
import SessionList from "../../../../container/SessionList";
import UserDetailsCard from "../../../../container/UserDetailsCard";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { getAllMentorSessionListUrl } from "../../../../ReduxToolkit/Reducers/LearnerSlice";
import {
  MenteeDetails,
  SideBarTitleIAmAMentor,
  UpcomingSessionErrorToastMessage,
} from "../../../../utils/Constant";
import {
  formatCustomDate,
  showToast,
  truncateText,
} from "../../../../utils/helper/helper";

const MenteeDetailsPage = () => {
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const { activeMenu } = useAppContext();
  const navigate = useNavigate();
  const { menteeId, mentorMenteeId, countOfSessions, latestSessiondate } =
    state || {};
  const { getAllMentorSessionList } = useAppSelector(state => state.learner);
  const [filteredSessionData, setFilteredSessionData] = useState<any[]>([]);

  useEffect(() => {
    if (mentorMenteeId) {
      dispatch(
        getAllMentorSessionListUrl({
          mentorMenteeId,
        })
      );
    }
  }, [dispatch, mentorMenteeId]);

  useEffect(() => {
    const mappedData =
      getAllMentorSessionList?.session_list?.map(session => {
        return {
          topic: session.topic,
          duration: session.duration,
          sessionDate: session.start_date,
          status: session.status,
          sessionId: session.session_id,
        };
      }) || [];
    setFilteredSessionData(mappedData);
  }, [getAllMentorSessionList]);

  const getIcons = (
    role,
    department,
    countOfSessions,
    latestSessiondate,
    menteeId
  ) => [
    {
      id: 1,
      iconName: "EngineeringOutlined",
      value: (
        <>
          <span
            id={`tooltip-role-${menteeId}`}
            className="text-truncate d-flex align-items-center"
          >
            {truncateText(role, 20)}
          </span>
          <ToolTip placement="top" target={`tooltip-role-${menteeId}`}>
            {role}
          </ToolTip>
        </>
      ),
      columnSize: "4",
    },
    { id: 2, iconName: "BadgeOutlined", value: department, columnSize: "3" },
    {
      id: 3,
      iconName: "CastForEducationOutlined",
      value: countOfSessions,
      columnSize: "2",
    },
    {
      id: 4,
      iconName: "CalendarTodayOutlined",
      value: latestSessiondate,
      columnSize: "3",
    },
  ];

  const onSessionRowClick = row => {
    const result = [SideBarTitleIAmAMentor].some(role =>
      activeMenu.includes(role)
    );

    if (row.status === "completed") {
      navigate(`${process.env.PUBLIC_URL}/mentoring/session-details`, {
        state: { sessionId: row.sessionId },
      });
    } else if (row.status === "upcoming") {
      if (!result) {
        showToast(UpcomingSessionErrorToastMessage, "error");
        return;
      }
      navigate(`${process.env.PUBLIC_URL}/mentoring/i-am-mentor/add-session`, {
        state: { sessionId: row.sessionId },
      });
    }
  };

  const handleSearch = (searchTerm: string) => {
    const trimmedSearchTerm = searchTerm.trim().toLowerCase();
    const mappedSessions =
      getAllMentorSessionList?.session_list?.map(session => ({
        topic: session.topic,
        duration: session.duration,
        sessionDate: session.start_date,
        status: session.status,
        session: session.session_id,
      })) || [];

    const filteredData =
      trimmedSearchTerm === ""
        ? mappedSessions
        : mappedSessions.filter(session =>
            session.topic?.toLowerCase().includes(trimmedSearchTerm)
          );

    setFilteredSessionData(filteredData);
  };

  return (
    <div className="page-body page-body-margin">
      <Card className="mb-3">
        <CardBody className="mt-2 p-3">
          <H4>{MenteeDetails}</H4>
          {getAllMentorSessionList?.user_dict && (
            <Row key={menteeId} className="mt-4 align-items-center">
              <Col md="3">
                <UserDetailsCard
                  userDetails={{
                    id: menteeId,
                    name: getAllMentorSessionList?.user_dict[menteeId]
                      ?.user_name,
                    empId:
                      getAllMentorSessionList?.user_dict[menteeId]
                        ?.employee_code || "-",
                    mobile:
                      getAllMentorSessionList?.user_dict[menteeId]?.phone ||
                      "-",
                  }}
                />
              </Col>
              <Col md="8">
                <IconWithValue
                  items={getIcons(
                    getAllMentorSessionList?.user_dict[menteeId]
                      ?.job_role_name || "-",
                    getAllMentorSessionList?.user_dict[menteeId]?.sbu,
                    countOfSessions || 0,
                    formatCustomDate(latestSessiondate),
                    menteeId
                  )}
                />
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>

      <SessionList
        data={filteredSessionData}
        onRowClick={onSessionRowClick}
        handleSearch={handleSearch}
      />
    </div>
  );
};

export default MenteeDetailsPage;
