import React from "react";
import { Badge, Table } from "reactstrap";

import { Badges, MUIIcons, H5, H4, ToolTip } from "../../AbstractElements";
import { PendingStatus, RejectedStatus, YtsStatus } from "../../Api/constants";
import BadgeWithIcon from "../../CommonElements/BadgeWithIcon";
import CardHeaderDropDown from "../../CommonElements/CommonCardHeader/CardHeaderDropDown";
import Divider from "../../CommonElements/Divider";
import {
  Approve,
  Approved,
  Decline,
  Declined,
  NotYetStarted,
  PendingForReview,
  Rejected,
  UploadFile,
} from "../../utils/Constant";
import "./style.scss";
import {
  capitalizeFirstLetter,
  handleAllFilesDownload,
} from "../../utils/helper/helper";
import { hasPermissionToComponent } from "../../utils/helper/permission";

interface PendingSkillData {
  lo: string[];
  type: string;
  learnerDocs?: string;
  trainerDocs?: string;
  status?: string;
  actions?: string;
}

interface PendingSkillTableProps {
  data: PendingSkillData[];
  TableHeaders: string[];
  onDropdownClick?: (id: string) => void;
  onUpdateStatus?: (id: string, type: string) => void;
  onToggleModal?: (lo?: string, id?: string, data?: any) => void;
}

const ILPPendingSkillTable: React.FC<PendingSkillTableProps> = ({
  data,
  TableHeaders,
  onDropdownClick,
  onUpdateStatus,
  onToggleModal,
}) => {
  const renderStatusBadge = (status: string | undefined) => {
    switch (status) {
      case Approved:
        return (
          <BadgeWithIcon
            badgeClassName="badge-approved-bg"
            iconName="DoneOutlined"
            text={capitalizeFirstLetter(Approved)}
          />
        );
      case PendingStatus:
        return (
          <H4 className="d-flex flex-row gap-5 align-items-center">
            <Badges pill>{PendingForReview}</Badges>
          </H4>
        );
      case Rejected:
        return (
          <BadgeWithIcon
            iconName="CloseOutlined"
            text={capitalizeFirstLetter(Declined)}
          />
        );
      default:
        return (
          <H4>
            <Badges pill>{NotYetStarted}</Badges>
          </H4>
        );
    }
  };

  const renderActions = item => {
    if (
      hasPermissionToComponent("UPLOAD_SKILL_DOC") &&
      (item?.status === YtsStatus || item?.status === RejectedStatus)
    ) {
      return (
        <CardHeaderDropDown
          mainTitle
          firstItem={UploadFile}
          onClickItem={() => onDropdownClick(item?.id)}
        />
      );
    } else if (
      hasPermissionToComponent("APPROVE_SKILL_DOC") &&
      item?.status === PendingStatus &&
      item?.trainerDocs &&
      item?.trainerDocs?.length > 0
    ) {
      return (
        <div className="d-flex flex-row gap-2">
          <div>
            <span id={"tooltip-approve"}>
              <MUIIcons
                onClick={() => onUpdateStatus(item?.id, "declined")}
                className="primary-icon-color pointer"
                iconName="CancelOutlined"
                size={27}
              />
            </span>
            <ToolTip placement="top" target={"tooltip-approve"}>
              {Decline}
            </ToolTip>
          </div>

          <div>
            <span id={"tooltip-reject"}>
              <MUIIcons
                onClick={() => onUpdateStatus(item?.id, "approved")}
                className="text-success pointer"
                iconName="CheckCircleOutlined"
                size={27}
              />
            </span>
            <ToolTip placement="top" target={"tooltip-reject"}>
              {Approve}
            </ToolTip>
          </div>
        </div>
      );
    } else {
      return "-";
    }
  };

  return (
    <div className="dataTables_wrapper">
      <div className="table-responsive theme-scrollbar">
        <Table
          className="table display dataTable no-footer custom-skill-table"
          id="selling-product"
        >
          <thead className="light-background custom-skill-table-thead">
            <tr>
              {TableHeaders.map((header, index) => (
                <th className=".b-b-secondary text-black fw-bold" key={index}>
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="fs-10 custom-skill-table-tbody">
            {data?.map((item: any, i) => {
              return (
                <React.Fragment key={i}>
                  <tr className="text-black">
                    <td className="lo-cell-skill-update">
                      <div>
                        <span id={`tooltip-lo-${i}`}>
                          <H5 className="d-flex gap-2">
                            {item?.lo[0]}
                            <Badges className="pointer">
                              {item?.lo?.length > 1 &&
                                `${item?.lo?.length - 1}+`}
                            </Badges>
                          </H5>
                        </span>
                        {item?.lo?.length > 1 && (
                          <ToolTip placement="top" target={`tooltip-lo-${i}`}>
                            {item?.lo.slice(1).join(", ")}
                          </ToolTip>
                        )}
                      </div>
                    </td>
                    <td>{item?.type}</td>
                    <td>
                      {item?.learnerDocs && item?.learnerDocs?.length > 0 ? (
                        <Badges
                          onClick={() =>
                            handleAllFilesDownload(item?.learnerDocs)
                          }
                          color="light text-dark"
                        >
                          <MUIIcons
                            className="pointer"
                            iconName={"InsertDriveFileOutlined"}
                            size={24}
                          />
                          <div className="notification-wrapper">
                            <MUIIcons
                              className="pointer"
                              iconName={"DownloadForOfflineOutlined"}
                              size={24}
                            />
                            {item?.learnerDocs?.length > 0 && (
                              <Badge className="doc-notification-badge ">
                                {item?.learnerDocs?.length}
                              </Badge>
                            )}
                          </div>
                        </Badges>
                      ) : (
                        "-"
                      )}
                    </td>

                    <td>
                      {item?.trainerDocs && item?.trainerDocs?.length > 0 ? (
                        <Badges
                          onClick={() =>
                            onToggleModal(
                              item?.lo[0],
                              item?.trainerDocs,
                              item?.extraData
                            )
                          }
                          color="light text-dark"
                        >
                          <MUIIcons
                            className="pointer"
                            iconName={"InsertDriveFileOutlined"}
                            size={24}
                          />
                          <div className="notification-wrapper">
                            <MUIIcons
                              className="pointer"
                              iconName={"DownloadForOfflineOutlined"}
                              size={24}
                            />
                            {item?.trainerDocs?.length > 0 && (
                              <Badge className="doc-notification-badge">
                                {item?.trainerDocs?.length}
                              </Badge>
                            )}
                          </div>
                        </Badges>
                      ) : (
                        "-"
                      )}
                    </td>

                    <td>{renderStatusBadge(item?.status)}</td>

                    <td>{renderActions(item)}</td>
                  </tr>
                  {i < data.length - 1 && (
                    <tr>
                      <td className="p-0" colSpan={TableHeaders.length}>
                        <Divider />
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default ILPPendingSkillTable;
