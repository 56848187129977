import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

import { Badges, Btn, MUIIcons, P } from "../../../AbstractElements";
import { courseTypeMap, WBT, YtsStatus } from "../../../Api/constants";
import DetailHeader from "../../../CommonElements/DetailHeader";
import MapCurriculumModal from "../../../CommonElements/MapCurriculumModal";
import CommonModal from "../../../CommonElements/Modal";
import NoData from "../../../container/NoData";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import { getSkillAssessmentTrainingHistory } from "../../../ReduxToolkit/Reducers/CourseSlice";
import { unMapCurriculum } from "../../../ReduxToolkit/Reducers/CurriculumSlice";
import {
  getCourseByUserCurriculumId,
  getLOForAssessment,
  getMappedCurriculumList,
} from "../../../ReduxToolkit/Reducers/LearningPlanSlice";
import {
  getDomainList,
  getProficiencyList,
  getRolesList,
} from "../../../ReduxToolkit/Reducers/MasterSlice";
import {
  Add,
  Close,
  Confirm,
  Domain,
  Location,
  MachineSerialNumber,
  NoDataText,
  SkillAssessment,
  StartDate,
  UnlinkCurriculum,
  UnlinkCurriculumMessage,
  WorkOrderNumber,
} from "../../../utils/Constant";
import {
  assessmentStatus,
  extractFileName,
  formatCustomDate,
  getEllipsedFileName,
  handleAllFilesDownload,
  mapListToOptions,
  trainingHistoryColumns,
} from "../../../utils/helper/helper";
import { hasPermissionToComponent } from "../../../utils/helper/permission";
import "./style.scss";

const ILPCurriculum = ({ userId }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [activeCurriculum, setActiveCurriculum] = useState(null);
  const [isMapCurriculumModalOpen, setIsMapCurriculumModalOpen] =
    useState(false);
  const [domainListData, setDomainListData] = useState([]);
  const [roleListData, setRoleListData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  const { mappedCurriculum, mappedCurriculumUserCourse } = useAppSelector(
    state => state.learningPlan
  );
  const { domainList, roleList, proficiencyList } = useAppSelector(
    state => state.master
  );
  const { loading } = useAppSelector(state => state.learningPlan);
  const [outcomesData, setOutcomesData] = useState({});
  const [isSkillModalOpen, setIsSkillModalOpen] = useState(false);

  const toggleSkillModal = () => {
    if (isSkillModalOpen) {
      setOutcomesData({});
    }
    setIsSkillModalOpen(!isSkillModalOpen);
  };

  useEffect(() => {
    dispatch(getMappedCurriculumList({ userId: userId }));
    dispatch(getRolesList({}));
    dispatch(getDomainList());
    dispatch(getProficiencyList());
  }, []);

  useEffect(() => {
    if (activeCurriculum?.userCurriculumId && activeCurriculum?.activeLevel) {
      dispatch(
        getCourseByUserCurriculumId({
          userCurriculumId: activeCurriculum.userCurriculumId,
        })
      );
    }
  }, [activeCurriculum?.userCurriculumId]);

  const getMappedList = () => {
    dispatch(getMappedCurriculumList({ userId: userId }));
  };

  useEffect(() => {
    const options = mapListToOptions(domainList?.domain, "domain_name", "id");
    setDomainListData(options);
  }, [domainList]);

  useEffect(() => {
    if (roleList) {
      const options = mapListToOptions(roleList.job_role, "name", "id");
      setRoleListData(options);
    }
  }, [roleList]);

  useEffect(() => {
    if (mappedCurriculum?.curriculum?.length > 0) {
      const currentCurriculum = mappedCurriculum?.curriculum[0];
      setActiveCurriculum({
        userCurriculumId: currentCurriculum?.user_curriculum_id,
        activeLevel: currentCurriculum?.level_ids?.[0],
        id: currentCurriculum.id,
      });
    }
  }, [mappedCurriculum]);

  const findLevel = id => {
    return proficiencyList?.proficiency?.find(level => level.id === id);
  };

  const mapTrainingHistoryToLearningPlanCardData = () => {
    const mappedCourse =
      mappedCurriculumUserCourse && activeCurriculum?.activeLevel
        ? mappedCurriculumUserCourse[activeCurriculum.activeLevel]
        : [];
    return mappedCourse?.map(course => {
      return {
        id: course?.id,
        scheduleId: course?.course_schedule_id?.toString(),
        courseId: course?.course_id?.toString(),
        status:
          course.status === assessmentStatus.mapped &&
          course?.course_type === WBT
            ? YtsStatus
            : course.status,
        badgeTextColor: true,
        courseName: course.course_name,
        completedOn: course?.completed_on,
        courseType: courseTypeMap[course?.course_type],
        knowledgeScore: course?.knowledge_percentage,
        skillScore: course?.skill_percentage,
      };
    });
  };

  const onRowClick = item => {
    return navigate(
      `/my-learning-plan/course-details?course_id=${item.courseId}`
    );
  };

  const handleSkillCompletionClick = async row => {
    const assessmentIds = [];

    const recievedData = await dispatch(
      getSkillAssessmentTrainingHistory({
        courseScheduleId: row?.scheduleId,
        userId: userId,
      })
    );

    recievedData?.payload?.skill_document?.forEach(item => {
      assessmentIds.push(item?.course_assessment_id);
    });

    const LOs = await dispatch(
      getLOForAssessment({ ids: assessmentIds?.toString() })
    );

    const outcomes = {};
    if (LOs?.payload?.data) {
      recievedData?.payload?.skill_document?.forEach(item => {
        if (LOs?.payload?.data[item?.course_assessment_id]) {
          if (!outcomes[item?.course_assessment_id]) {
            outcomes[item?.course_assessment_id] = {};
          }
          LOs?.payload?.data[item?.course_assessment_id]?.outcomes?.forEach(
            lo => {
              outcomes[item?.course_assessment_id] = {
                outcome: lo?.outcome,
                work_order_number: item?.work_order_number,
                start_date: item?.start_date,
                machine_serial_number: item?.machine_serial_number,
                location: item?.location,
                uploaded_learner_document: item?.uploaded_learner_document,
              };
            }
          );
        }
      });
    }
    setOutcomesData(outcomes);

    toggleModal();
  };

  const renderCourses = () => {
    if (loading) {
      return null;
    }

    return (
      <DataTable
        columns={[
          ...trainingHistoryColumns(handleSkillCompletionClick),
          {
            name: Domain,
            selector: () =>
              mappedCurriculum?.domain[activeCurriculum?.id]
                ?.map(item => item.name)
                ?.join(", ") || "-",
            minWidth: "8rem",
            sortable: true,
          },
        ]}
        data={mapTrainingHistoryToLearningPlanCardData()}
        pagination
        fixedHeader
        striped
        onRowClicked={onRowClick}
      />
    );
  };

  const handleLevelClick = (userCurriculumId, activeLevel, id) => {
    setActiveCurriculum({
      userCurriculumId,
      activeLevel,
      id,
    });
  };

  const mapCurriculumModalToggle = isMapped => {
    setIsMapCurriculumModalOpen(!isMapCurriculumModalOpen);
    if (isMapped === true) {
      getMappedList();
    }
  };

  const toggleModal = () => {
    if (isModalOpen) {
      setSelectedId("");
    }
    setIsModalOpen(!isModalOpen);
  };

  const handleUnlinkConfirm = async () => {
    const payload = {
      type: "curriculum",
      user_curriculum_id: Number(selectedId) || null,
    };
    await dispatch(unMapCurriculum({ payload: payload }));
    getMappedList();
    toggleModal();
  };

  const onUnlinkClick = id => {
    setSelectedId(id);
    toggleModal();
  };

  return (
    <div className="ilp-curriculum">
      <CommonModal
        sizeTitle={UnlinkCurriculum}
        isOpen={isModalOpen}
        toggle={toggleModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={handleUnlinkConfirm}
        primaryBtnText={Confirm}
      >
        {UnlinkCurriculumMessage}
      </CommonModal>

      <CommonModal
        sizeTitle={SkillAssessment}
        modalBodyClassName=""
        isOpen={isSkillModalOpen}
        toggle={toggleSkillModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={async () => {
          toggleSkillModal();
        }}
        primaryBtnText={Close}
      >
        <div>
          {outcomesData &&
            Object.keys(outcomesData)?.map((item: any, index) => {
              return (
                <Card
                  key={`skill-doc-${index}`}
                  className="mt-2 p-2 border border-grey"
                >
                  <CardBody>
                    <P className="fw-bold">{outcomesData[item]?.outcome}</P>
                    <div className="mt-4">
                      <Row>
                        <Col>
                          {
                            <DetailHeader
                              assign={Location}
                              name={outcomesData[item]?.location}
                            />
                          }
                        </Col>
                        <Col>
                          {
                            <DetailHeader
                              assign={MachineSerialNumber}
                              name={outcomesData[item]?.machine_serial_number}
                            />
                          }
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col>
                          {
                            <DetailHeader
                              assign={StartDate}
                              name={
                                outcomesData[item]?.start_date
                                  ? formatCustomDate(
                                      outcomesData[item]?.start_date
                                    )
                                  : "-"
                              }
                            />
                          }
                        </Col>
                        <Col>
                          {
                            <DetailHeader
                              assign={WorkOrderNumber}
                              name={outcomesData[item]?.work_order_number}
                            />
                          }
                        </Col>
                      </Row>
                    </div>

                    <Row className="mt-4">
                      {outcomesData[item]?.uploaded_learner_document?.map(
                        (file, ind) => (
                          <Col
                            lg="4"
                            className="mb-3 w-50"
                            key={`file-${index}-${ind}`}
                          >
                            <Badges className="w-100" color="light text-dark">
                              <div className="d-flex align-items-center">
                                <MUIIcons
                                  size={24}
                                  iconName="InsertDriveFileOutlined"
                                  className="mb-1"
                                />
                                <span className="text-start m-1 w-100 gap-1 p-1 ">
                                  {getEllipsedFileName(
                                    extractFileName(file),
                                    45
                                  )}
                                </span>
                                <MUIIcons
                                  className="pointer mb-1"
                                  onClick={() => handleAllFilesDownload([file])}
                                  size={24}
                                  iconName="FileDownloadOutlined"
                                />
                              </div>
                            </Badges>
                          </Col>
                        )
                      )}
                    </Row>
                  </CardBody>
                </Card>
              );
            })}
        </div>
      </CommonModal>

      {hasPermissionToComponent("USER_MANAGEMENT.ADD_CURRICULUM") && (
        <Card className="p-2 mb-3">
          <CardBody className="custom-overview-curriculum">
            <div className="d-flex flex-row justify-content-end align-items-center">
              {userId &&
                hasPermissionToComponent("USER_MANAGEMENT.ADD_CURRICULUM") && (
                  <>
                    <Btn
                      icon={
                        <MUIIcons
                          size={16}
                          iconName="AddCircleOutlineOutlined"
                        />
                      }
                      color="primary"
                      onClick={mapCurriculumModalToggle}
                    >
                      {Add}
                    </Btn>
                    {isMapCurriculumModalOpen && (
                      <MapCurriculumModal
                        isOpen={isMapCurriculumModalOpen}
                        toggle={mapCurriculumModalToggle}
                        domains={domainListData}
                        roles={roleListData}
                        userId={userId}
                      />
                    )}
                  </>
                )}
            </div>
          </CardBody>
        </Card>
      )}

      {mappedCurriculum?.curriculum?.length > 0 && (
        <Row>
          <Col xl="3" className="box-col-6">
            <div className="md-sidebar">
              <Card>
                <CardBody>
                  {mappedCurriculum?.curriculum?.length > 0 &&
                    mappedCurriculum.curriculum.map(item => (
                      <div className="ilp-curriculum__list" key={item.id}>
                        <h5 className="primary-text-color fw-bold py-2 mb-2 d-flex align-items-center gap-1">
                          {item.name}

                          {hasPermissionToComponent("UNMAP_CURRICULUM") && (
                            <button
                              className="border-0 bg-transparent pointer"
                              onClick={() =>
                                onUnlinkClick(item.user_curriculum_id)
                              }
                            >
                              <MUIIcons
                                className="pointer primary-icon-color"
                                iconName="LinkOffOutlined"
                                size={20}
                              />
                            </button>
                          )}
                        </h5>
                        <ul className="ml-3">
                          {item.level_ids.map(data => (
                            <li key={data}>
                              <button
                                className={`w-100 border-0 bg-white pointer p-2 text-start ${
                                  data === activeCurriculum?.activeLevel &&
                                  activeCurriculum.userCurriculumId ===
                                    item.user_curriculum_id
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() =>
                                  handleLevelClick(
                                    item.user_curriculum_id,
                                    data,
                                    item.id
                                  )
                                }
                              >
                                <span className="title">
                                  {findLevel(data)?.level_name}
                                </span>
                              </button>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                </CardBody>
              </Card>
            </div>
          </Col>

          <Col xl="9">{renderCourses()}</Col>
        </Row>
      )}

      {mappedCurriculum?.curriculum?.length === 0 && !loading && (
        <NoData svg={"empty-folder-icon"} title={NoDataText} showCard={false} />
      )}
    </div>
  );
};

ILPCurriculum.propTypes = {
  userId: PropTypes.number,
};

export default ILPCurriculum;
