import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import React, { ChangeEvent } from "react";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";

import LabelTooltip from "../../../../../CommonElements/LabelTooltip";
import CheckboxButton from "../../../../../CommonElements/SwitchButton";
import {
  Accomplishment,
  AccomplishmentToolTip,
  AccomplishmentToolTipMessage,
  AudienceCountToolTipMessage,
  ContentSummaryNotePlaceholder,
  ContentTopic,
  ContentTopicNotePlaceholder,
  CountOfTargetAudience,
  DurationType,
  Enter,
  ExpectedTargetAudienceToolTip,
  Frequency,
  FrequencyToggleToolTipMessage,
  FrequencyType,
  FrequencyTypeToolTipMessage,
  HowLongShouldTrainingBe,
  IsRecurringTraining,
  Objective,
  ObjectiveToolTipMessage,
  SelectFrequency,
  SuggestedDays,
  SuggestedDurationDaysToolTipMessage,
  SuggestedDurationHoursToolTipMessage,
  Summary,
  SummaryToolTipMessage,
  TargetAudience,
  TargetAudienceToolTipMessage,
  TaskForTrainingProgram,
  TopicToolTipMessage,
  WhoIsExpectedInTraining,
} from "../../../../../utils/Constant";
import {
  frequencyRangeOptions,
  handleKeyDownOnNumericWithDecimal,
  richTextSupportedItems,
  suggestedDaysOptions,
} from "../../../../../utils/helper/helper";
import "./style.scss";

interface BasicInputFormValueInterface {
  frequency_type: string;
  course_duration_type: string;
  frequency_count: string;
  content_topic: string;
  summary: string;
  accomplishment: string;
  target_audiance: string;
  expected_target_audience: string;
  course_duration: string;
  objective: string;
}

interface BasicInputFormValuePropsType {
  getRichTextUserData: (name: string, value: string) => void;
  getUserData: (event: ChangeEvent<HTMLInputElement>) => void;
  basicInputFormValue: BasicInputFormValueInterface;
  setIsRecurring: (event: boolean) => void;
  isRecurring: boolean;
  errors: {
    frequency_type: string;
    course_duration_type: string;
    frequency_count: string;
    content_topic: string;
    summary: string;
    accomplishment: string;
    target_audiance: string;
    expected_target_audience: string;
    course_duration: string;
    objective: string;
  };
}

const CourseFormTwo: React.FC<BasicInputFormValuePropsType> = ({
  basicInputFormValue,
  getUserData,
  getRichTextUserData,
  errors,
  isRecurring,
  setIsRecurring,
}) => {
  const {
    frequency_count,
    frequency_type,
    course_duration_type,
    content_topic,
    summary,
    accomplishment,
    target_audiance,
    expected_target_audience,
    course_duration,
    objective,
  } = basicInputFormValue;

  const handleInputChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "content_topic" || name === "target_audiance") {
      if (value.length <= 100) {
        getUserData(event);
      }
    } else if (name === "summary") {
      if (value.length <= 256) {
        getUserData(event);
      }
    } else if (name === "expected_target_audience") {
      if (
        value === "" ||
        (/^(?!.*[+-])\d{1,4}$/.test(value) &&
          parseInt(value) > 0 &&
          parseInt(value) <= 1000)
      ) {
        getUserData(event);
      }
    } else if (name === "course_duration" || name === "frequency_count") {
      const numberPattern = /^-?\d+(\.\d*)?$/;
      if (value.includes("+") || value.includes("-")) {
        return;
      }
      if (
        numberPattern.test(value) &&
        parseFloat(value) > 0 &&
        parseFloat(value) <= 99 &&
        !isNaN(parseFloat(value)) &&
        parseFloat(value) % 0.5 === 0
      ) {
        const decimalPart = value.split(".")[1];

        if (!decimalPart || decimalPart.length <= 2) {
          getUserData(event);
        }
      } else if (
        value === "" ||
        value === "." ||
        value === "0." ||
        value === "0"
      ) {
        getUserData(event);
      }
    }
  };

  return (
    <Form className="stepper-one g-3 needs-validation custom-input" noValidate>
      <>
        <Row>
          <Col sm="12" lg="6" className="d-flex gap-2 mt-3">
            <p className="fw-bold">
              <LabelTooltip
                label={IsRecurringTraining}
                tooltipText={FrequencyToggleToolTipMessage}
                important={false}
                placement={"bottom"}
              />
            </p>
            <div className="mt-1">
              <CheckboxButton
                onChange={e => {
                  setIsRecurring(e.target.checked);
                }}
                checked={isRecurring}
              />
            </div>
          </Col>
        </Row>
        {isRecurring && (
          <Row>
            <Col sm="12" lg="6">
              <FormGroup>
                <LabelTooltip
                  label={SelectFrequency}
                  tooltipText={FrequencyTypeToolTipMessage}
                  important
                />
                <Input
                  onChange={handleInputChange}
                  type="number"
                  inputMode="numeric"
                  step={0.5}
                  onKeyDown={handleKeyDownOnNumericWithDecimal}
                  value={frequency_count}
                  name="frequency_count"
                  placeholder={`${Enter} ${Frequency}`}
                  invalid={!!errors.frequency_count}
                  min={0}
                />
                <div className="invalid-feedback">{errors.frequency_count}</div>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <div>
                  <Label className="col-form-label">{FrequencyType}</Label>
                  <span className="txt-danger">*</span>
                </div>
                <Input
                  onChange={getUserData}
                  value={frequency_type}
                  name="frequency_type"
                  type="select"
                  invalid={!!errors.frequency_type}
                >
                  {frequencyRangeOptions?.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Input>
                {errors.frequency_type && (
                  <div className="invalid-feedback">
                    {errors.frequency_type}
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>
        )}
      </>
      <FormGroup className="mt-4">
        <LabelTooltip
          label={ContentTopic}
          tooltipText={TopicToolTipMessage}
          important={true}
        />
        <Input
          onChange={handleInputChange}
          value={content_topic}
          name="content_topic"
          type="textarea"
          rows={4}
          placeholder={ContentTopicNotePlaceholder}
          invalid={!!errors.content_topic}
        />
        <div className="invalid-feedback">{errors.content_topic}</div>
      </FormGroup>
      <FormGroup className="mt-4">
        <LabelTooltip
          label={Summary}
          tooltipText={SummaryToolTipMessage}
          important={true}
        />
        <Input
          onChange={handleInputChange}
          value={summary}
          name="summary"
          type="textarea"
          rows={4}
          placeholder={ContentSummaryNotePlaceholder}
          invalid={!!errors.summary}
        />
        <div className="invalid-feedback">{errors.summary}</div>
      </FormGroup>
      <FormGroup className="mt-4">
        <LabelTooltip
          label={Accomplishment}
          tooltipText={AccomplishmentToolTipMessage}
          important={true}
        />
        <div className={errors.accomplishment ? "border border-danger" : ""}>
          <CKEditor
            onChange={(_event, editor) => {
              const data = editor.getData();
              getRichTextUserData("accomplishment", data);
            }}
            config={{
              toolbar: {
                items: richTextSupportedItems,
              },
              placeholder: AccomplishmentToolTip,
            }}
            editor={ClassicEditor as any}
            data={accomplishment}
          />
        </div>
        <div className="mt-1 text-sm text-danger">{errors.accomplishment}</div>
      </FormGroup>
      <FormGroup className="mt-4">
        <LabelTooltip
          label={TargetAudience}
          tooltipText={TargetAudienceToolTipMessage}
          important={true}
        />
        <Input
          onChange={handleInputChange}
          value={target_audiance}
          name="target_audiance"
          type="textarea"
          rows={4}
          placeholder={WhoIsExpectedInTraining}
          invalid={!!errors.target_audiance}
        />
        <div className="invalid-feedback">{errors.target_audiance}</div>
      </FormGroup>
      <Row className="mt-4">
        <Col sm="12" lg="6">
          <FormGroup>
            <LabelTooltip
              label={CountOfTargetAudience}
              tooltipText={AudienceCountToolTipMessage}
              important={true}
            />
            <Input
              onChange={handleInputChange}
              value={expected_target_audience}
              name="expected_target_audience"
              type="text"
              placeholder={ExpectedTargetAudienceToolTip}
              invalid={!!errors.expected_target_audience}
            />
            <div className="invalid-feedback">
              {errors.expected_target_audience}
            </div>
          </FormGroup>
        </Col>
        <Col sm="12" lg="6">
          <Row>
            <Col sm="12" lg="6">
              <FormGroup>
                <LabelTooltip
                  label={SuggestedDays}
                  tooltipText={SuggestedDurationDaysToolTipMessage}
                  important={true}
                />
                <Input
                  onChange={handleInputChange}
                  value={course_duration}
                  name="course_duration"
                  type="number"
                  inputMode="numeric"
                  step={0.5}
                  onKeyDown={handleKeyDownOnNumericWithDecimal}
                  placeholder={HowLongShouldTrainingBe}
                  invalid={!!errors.course_duration}
                  min={0}
                />
                <div className="invalid-feedback">{errors.course_duration}</div>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <LabelTooltip
                  label={DurationType}
                  tooltipText={SuggestedDurationHoursToolTipMessage}
                  important
                />
                <Input
                  onChange={getUserData}
                  value={course_duration_type}
                  name="course_duration_type"
                  type="select"
                  invalid={!!errors.course_duration_type}
                >
                  {suggestedDaysOptions?.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Input>
                {errors.course_duration_type && (
                  <div className="invalid-feedback">
                    {errors.course_duration_type}
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <FormGroup className="mt-4">
        <LabelTooltip
          label={Objective}
          tooltipText={ObjectiveToolTipMessage}
          important={true}
        />
        <div className={errors.objective ? "border border-danger" : ""}>
          <CKEditor
            onChange={(_event, editor) => {
              const data = editor.getData();
              getRichTextUserData("objective", data);
            }}
            config={{
              toolbar: {
                items: richTextSupportedItems,
              },
              placeholder: TaskForTrainingProgram,
            }}
            editor={ClassicEditor as any}
            data={objective}
          />
        </div>
        <div className="mt-1 text-sm text-danger">{errors.objective}</div>
      </FormGroup>
    </Form>
  );
};

export default CourseFormTwo;
