import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

import { MUIIcons, H3 } from "../../../AbstractElements";
import Btn from "../../../CommonElements/Button";
import SearchBar from "../../../CommonElements/SearchBar";
import NoData from "../../../container/NoData";
import TotalSeatCard from "../../../container/TotalSeatCard";
import UserDetailsCard from "../../../container/UserDetailsCard";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import { getMappedMentorList } from "../../../ReduxToolkit/Reducers/LearnerSlice";
import {
  getDomainList,
  getUserMasterList,
} from "../../../ReduxToolkit/Reducers/MasterSlice";
import {
  AddNew,
  MapMentorAndMentee,
  MappedMentee,
  Replace,
  MappedMentor,
  TotalSession,
  TotalDuration,
  UpcomingSession,
  Mentor,
  Domain,
  Mentees,
  Duration,
  Action,
  NoDataText,
  NotYetAdded,
  ManageMentoringEmptyDescription,
} from "../../../utils/Constant";
import { convertMinutesToHours } from "../../../utils/helper/helper";

export interface MappedMentorData {
  mentor_domain_id: number;
  mentorId: number;
  mentorName: string;
  domain: string;
  menteesCount: number;
  totalSession: number;
  duration: number;
  upcomingSession: number;
  empId: string;
  mobile: string;
}

const ManageMentoring = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [mappedMentorData, setMappedMentorData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [usersList, setUsersList] = useState([]);

  const { mappedMentorList, loading } = useAppSelector(state => state.learner);
  const { domainList, userMasterList } = useAppSelector(state => state.master);

  useEffect(() => {
    dispatch(getMappedMentorList());
    dispatch(
      getUserMasterList({
        roleId: null,
        sbuId: null,
      })
    );
    dispatch(getDomainList());
  }, [dispatch]);

  useEffect(() => {
    const value = [];
    if (userMasterList) {
      userMasterList?.users?.forEach((item: any) => {
        value.push({
          name: item?.id,
          label: item?.user_name,
          empId: item?.employee_code,
          mobile: item?.phone,
        });
      });
    }
    setUsersList(value);
  }, [userMasterList]);

  useEffect(() => {
    setMappedMentorData(
      mappedMentorList?.data?.map(mentor => {
        const user =
          usersList?.find(user => user.name === mentor.mentor_id) || {};
        const domainName =
          domainList.domain.find(domain => domain.id === mentor.domain_id)
            ?.domain_name || "";

        return {
          mentor_domain_id: mentor.mentor_domain_id,
          mentorId: mentor.mentor_id,
          mentorName: user.label || "",
          empId: user.empId || "",
          mobile: user.mobile || "",
          domain: domainName,
          menteesCount: mentor.mentees_count,
          totalSession: mentor.total_sessions,
          duration: mentor.total_duration,
          upcomingSession: mentor.upcoming_sessions,
        };
      })
    );
  }, [mappedMentorList, usersList, domainList]);

  useEffect(() => {
    if (searchQuery) {
      const filtered = mappedMentorData?.filter(
        mappedMentor =>
          mappedMentor.mentorName
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          mappedMentor.domain
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          mappedMentor.empId
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          mappedMentor.mobile.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(mappedMentorData);
    }
  }, [searchQuery, mappedMentorData]);

  const toggleDropdown = (e, rowId) => {
    e.preventDefault();
    setDropdownOpen(prevDropdownOpen =>
      prevDropdownOpen === rowId ? null : rowId
    );
  };

  const handleReplace = (
    mentorId: number,
    mentorName: string,
    mentor_domain_id: number
  ) => {
    navigate(
      `${process.env.PUBLIC_URL}/mentoring/manage-mentoring/replace-mentor`,
      { state: { mentorId, mentorName, mentor_domain_id } }
    );
  };

  const MappedMentoringColumns = [
    {
      name: Mentor,
      minWidth: "20rem",
      cell: (row: MappedMentorData) => (
        <button
          className="border-0 bg-transparent text-start"
          onClick={() => handleRowClick(row)}
        >
          <UserDetailsCard
            userDetails={{
              id: row.mentorId,
              name: row.mentorName,
              empId: row.empId || "-",
              mobile: row.mobile || "-",
            }}
          />
        </button>
      ),
    },
    {
      name: Domain,
      minWidth: "14rem",
      cell: (row: MappedMentorData) => row.domain || "-",
    },
    {
      name: Mentees,
      minWidth: "6rem",
      cell: (row: MappedMentorData) => row.menteesCount,
    },
    {
      name: TotalSession,
      minWidth: "6rem",
      cell: (row: MappedMentorData) => row.totalSession,
    },
    {
      name: Duration,
      minWidth: "6rem",
      cell: (row: MappedMentorData) =>
        convertMinutesToHours(row.duration) + "h",
    },
    {
      name: UpcomingSession,
      minWidth: "9rem",
      cell: (row: MappedMentorData) => row.upcomingSession,
    },
    {
      name: Action,
      selector: (row: MappedMentorData) => row.mentorId,
      minWidth: "6rem",
      center: true,
      cell: (row: MappedMentorData) => (
        <div>
          <Dropdown
            isOpen={row.mentor_domain_id === dropdownOpen}
            toggle={e => toggleDropdown(e, row.mentor_domain_id)}
          >
            <DropdownToggle color="" className="three-dots">
              <MUIIcons
                iconName="MoreVertOutlined"
                className="primary-icon-color"
                size={17}
              />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem
                onClick={() =>
                  handleReplace(
                    row.mentorId,
                    row.mentorName,
                    row.mentor_domain_id
                  )
                }
              >
                {Replace}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      ),
    },
  ];

  const customStyles = {
    rows: {
      style: {
        padding: "1rem 0",
      },
    },
  };

  const TotalSeatData = [
    {
      id: 1,
      count: mappedMentorList?.mapped_mentor_count,
      title: MappedMentor,
    },
    {
      id: 2,
      count: mappedMentorList?.mapped_mentee_count,
      title: MappedMentee,
    },
    { id: 3, count: mappedMentorList?.total_sessions, title: TotalSession },
    {
      id: 4,
      count: convertMinutesToHours(mappedMentorList?.total_duration) + "h",
      title: TotalDuration,
    },
    {
      id: 4,
      count: mappedMentorList?.upcoming_sessions,
      title: UpcomingSession,
    },
  ];

  const handleRowClick = (row: MappedMentorData) => {
    navigate(
      `${process.env.PUBLIC_URL}/mentoring/manage-mentoring/mentor-details`,
      {
        state: {
          mentorId: row.mentorId,
          mentorDomainId: row.mentor_domain_id,
          domain: row.domain,
        },
      }
    );
  };

  const handleAddNewClick = () => {
    return navigate("/mentoring/map-mentor-mentee");
  };

  const renderContent = () => {
    if (loading) {
      return null;
    }

    if (mappedMentorData?.length === 0) {
      return (
        <NoData
          svg={"empty-folder-icon"}
          title={NotYetAdded}
          description={ManageMentoringEmptyDescription}
          buttonText={AddNew}
          onclick={handleAddNewClick}
          showCard={false}
          showIcon={true}
        />
      );
    }

    if (filteredData?.length === 0) {
      return (
        <NoData
          svg={"empty-folder-icon"}
          title={NoDataText}
          showIcon
          showCard={false}
        />
      );
    }

    return (
      <>
        <div className="mt-3">
          <TotalSeatCard data={TotalSeatData} />
        </div>

        <DataTable
          pagination
          className="mt-3"
          highlightOnHover
          pointerOnHover
          columns={MappedMentoringColumns}
          data={filteredData}
          customStyles={customStyles}
          onRowClicked={handleRowClick}
        />
      </>
    );
  };

  return (
    <div className="page-body page-body-margin">
      <Card>
        <CardBody>
          <div className="d-flex flex-row align-items-center">
            <H3>{MapMentorAndMentee}</H3>
            <div className="d-flex justify-content-end align-items-center mt-sm-0 mt-2 gap-3 ms-auto">
              <SearchBar
                showSearch
                className="mb-2"
                onSearch={query => setSearchQuery(query)}
              />
              {filteredData?.length > 0 && (
                <Link to={"/mentoring/map-mentor-mentee"}>
                  <Btn
                    icon={
                      <MUIIcons iconName="AddCircleOutlineOutlined" size={16} />
                    }
                    color="primary"
                  >
                    {AddNew}
                  </Btn>
                </Link>
              )}
            </div>
          </div>
        </CardBody>
      </Card>

      <Card>
        <CardBody>{renderContent()}</CardBody>
      </Card>
    </div>
  );
};

export default ManageMentoring;
