import React from "react";
import { Label } from "reactstrap";

import { ToolTip } from "../../AbstractElements";
import { sanitizeString } from "../../utils/helper/helper";
import MUIIcons from "../MUIIcon/MUIIcons";

import "./style.scss";
interface TooltipLabelProps {
  label: string;
  tooltipText: string;
  important: boolean;
  placement?: any;
  forHtml?: string;
}

const LabelTooltip: React.FC<TooltipLabelProps> = ({
  label,
  tooltipText,
  placement = "top",
  important = false,
  forHtml,
}) => {
  const toolTipUniqueId = sanitizeString(tooltipText);

  return (
    <div className="d-flex align-items-center gap-2">
      <div>
        <Label className="col-form-label" htmlFor={forHtml || undefined}>
          {label}
        </Label>
        {important && <span className="txt-danger">*</span>}
      </div>
      <span id={`TooltipIcon${toolTipUniqueId}`} className="ml-2 pointer">
        <MUIIcons className="mt-1" size={16} iconName="InfoOutlined" />
      </span>
      <ToolTip placement={placement} target={`TooltipIcon${toolTipUniqueId}`}>
        {tooltipText}
      </ToolTip>
    </div>
  );
};

export default LabelTooltip;
