import PropTypes from "prop-types";

import "./styles.scss";
import { stringToHexColor } from "../../utils/helper/helper";

const RoundedCard = ({ children, size = "medium", onClick }) => {
  const truncatedText =
    children?.length > 12 ? `${children?.substring(0, 12)}...` : children;
  return (
    <div
      className={`rounded-card ${size}`}
      onClick={onClick}
      style={{ backgroundColor: stringToHexColor(children) }}
    >
      {truncatedText}
    </div>
  );
};

RoundedCard.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  onClick: PropTypes.func,
};

export default RoundedCard;
