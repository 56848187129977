import PropTypes from "prop-types";
import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

import {
  DefaultButton,
  MUIIcons,
  H4,
  H5,
  ToolTip,
} from "../../AbstractElements";
import CardHeaderDropDown from "../../CommonElements/CommonCardHeader/CardHeaderDropDown";
import Divider from "../../CommonElements/Divider";
import StatusDisplay from "../../CommonElements/StatusDisplay";
import UserCardDetails from "../../CommonElements/UserCardDetails";
import {
  Approve,
  Review,
  Decline,
  Revoke,
  ViewDetails,
  NoDataText,
} from "../../utils/Constant";
import NoData from "../NoData";

interface StatusDisplayConfig {
  label: string;
  numbers: string[];
  childLabel?: string;
  childNumbers?: string[];
  isLarge?: number;
}

interface ActionConfig {
  type: "view" | "approve" | "reject" | "review" | "revoke";
}

export interface SectionConfig {
  status: StatusDisplayConfig;
  actions: ActionConfig[];
  userDetails: any;
  childStatus?: StatusDisplayConfig[];
}

interface ParticipantsDetailsProps {
  sections: SectionConfig[];
  title: string;
  showParticipants?: boolean;
  onRejectRequest?: (id: any) => void;
  onApproveRequest?: (id: any) => void;
  onRevokeRequest?: (id: any) => void;
  onDropdownClick?: (id: any, item: any, status?: string) => void;
  showCard?: boolean;
}

const ParticipantsDetailsWithoutHeight: React.FC<ParticipantsDetailsProps> = ({
  sections,
  title,
  showParticipants,
  onRejectRequest,
  onApproveRequest,
  onRevokeRequest,
  onDropdownClick,
  showCard = true,
}) => {
  const renderStatusDisplay = (statusConfig: StatusDisplayConfig) => (
    <Col className="d-flex mt-2 gap-5">
      <Row>
        {statusConfig?.numbers?.map((number, index) => (
          <Col xs="12" sm="12" lg="auto" key={index}>
            {number && (
              <StatusDisplay label={statusConfig.label} number={number} />
            )}
          </Col>
        ))}
      </Row>
    </Col>
  );

  const renderExtraStatus = (statusConfig: StatusDisplayConfig) => (
    <Col className="d-flex mt-2 gap-5">
      <Row>
        {statusConfig?.numbers?.map((number, index) => (
          <Col xs="12" sm="12" lg="auto" key={index}>
            {number && (
              <StatusDisplay label={statusConfig.label} number={number} />
            )}
          </Col>
        ))}
      </Row>
    </Col>
  );

  const renderActions = (actions: ActionConfig[]) => (
    <Col className="mt-2">
      <div className="d-flex gap-3 justify-content-end align-items-center">
        {actions?.map((action: any, index) => {
          switch (action?.type) {
            case "view":
              return (
                <H5 key={index} className="primary-text-color">
                  {ViewDetails}
                </H5>
              );
            case "dropdown": {
              const items = action.items || [];
              return (
                <CardHeaderDropDown
                  key={index}
                  firstItem={items[0] || ""}
                  secondItem={items[1] || ""}
                  thirdItem={items[2] || ""}
                  mainTitle={true}
                  onClickItem={item => {
                    onDropdownClick(action?.id, item, action?.status);
                  }}
                />
              );
            }
            case "revoke":
              return (
                <div
                  className="pointer"
                  key={index}
                  onClick={() => onRevokeRequest(action?.id)}
                >
                  <H5 className="primary-text-color">{Revoke}</H5>
                </div>
              );

            case "approve_icon":
              return (
                <>
                  <span id={"tooltip-approve"}>
                    <MUIIcons
                      onClick={() => onRejectRequest(action?.id)}
                      className="primary-icon-color pointer"
                      iconName="CancelOutlined"
                      size={27}
                    />
                  </span>
                  <ToolTip placement="top" target={"tooltip-approve"}>
                    {Decline}
                  </ToolTip>
                </>
              );
            case "reject_icon":
              return (
                <>
                  <span id={"tooltip-reject"}>
                    <MUIIcons
                      onClick={() => onApproveRequest(action?.id)}
                      className="text-success pointer"
                      iconName="CheckCircleOutlined"
                      size={27}
                    />
                  </span>
                  <ToolTip placement="top" target={"tooltip-reject"}>
                    {Approve}
                  </ToolTip>
                </>
              );

            case "approve":
              return (
                <DefaultButton
                  key={index}
                  className="w-auto text-nowrap"
                  type="submit"
                  color="success"
                  block
                  onClick={() => onApproveRequest(action?.id)}
                >
                  {Approve}
                </DefaultButton>
              );
            case "reject":
              return (
                <div
                  onClick={() => onRejectRequest(action?.id)}
                  key={index}
                  className="edit-details-button"
                >
                  <H5 className="primary-text-color pointer">{Decline}</H5>
                </div>
              );
            case "review":
              return (
                <H5 key={index} className="primary-text-color">
                  {Review}
                </H5>
              );
            default:
              return null;
          }
        })}
      </div>
    </Col>
  );

  const renderSections = () => (
    <>
      {sections?.length > 0 ? (
        sections.map((section: any, index) => (
          <React.Fragment key={index}>
            <Row className="pt-3 d-flex align-items-center">
              {section.name && (
                <Col lg="4">
                  <UserCardDetails
                    imagePath={section.imagePath}
                    name={section.name}
                    detail={section.detail}
                    showOwner={section?.owner}
                    showMain={section?.main}
                    showAssistant={section?.assistant}
                    showReplacement={section?.replacement}
                    showOtherDetails
                    empId={section?.empId}
                    phoneNumber={section?.phoneNumber}
                  />
                </Col>
              )}
              {section.status && renderStatusDisplay(section.status)}
              {section.extraStatus && renderExtraStatus(section.extraStatus)}
              {section.childStatus && renderChildStatusDisplay(section)}
              {renderActions(section.actions)}
            </Row>
            <Divider />
          </React.Fragment>
        ))
      ) : (
        <NoData
          showCard={false}
          showIcon={false}
          svg="empty-folder-icon"
          title={NoDataText}
        />
      )}
    </>
  );

  const renderChildStatusDisplay = statusConfig => (
    <>
      <Col lg="4">
        <UserCardDetails
          imagePath={statusConfig.userDetails.imagePath}
          name={statusConfig.userDetails.name}
          detail={statusConfig.userDetails.detail}
          showOwner={statusConfig.userDetails?.owner}
          showMain={statusConfig.userDetails?.main}
          showAssistant={statusConfig.userDetails?.assistant}
          showReplacement={statusConfig.userDetails?.replacement}
          showOtherDetails
          empId={statusConfig?.userDetails?.empId}
          phoneNumber={statusConfig?.userDetails?.phoneNumber}
        />
      </Col>
      {statusConfig?.childStatus?.map((item, index) => (
        <>
          {Array.isArray(item?.numbers) && item.numbers.length > 0 && (
            <Col
              md={item.isLarge ? item.isLarge : ""}
              className="d-flex mt-2 gap-5"
              key={index}
            >
              <Row>
                <Col xs="12" sm="12" lg="auto">
                  {item.numbers.map((number, numberIndex) => (
                    <React.Fragment key={numberIndex}>
                      {number && (
                        <StatusDisplay label={item.label} number={number} />
                      )}
                    </React.Fragment>
                  ))}
                  {item?.childLabel &&
                    item.childNumbers?.length > 0 &&
                    item.childNumbers.map((number, index) => (
                      <div className="mt-2" key={index}>
                        {number !== null && number !== "" && (
                          <StatusDisplay
                            label={item.childLabel}
                            number={number}
                          />
                        )}
                      </div>
                    ))}
                </Col>
              </Row>
            </Col>
          )}
        </>
      ))}
    </>
  );

  return showCard ? (
    <Card className="p-2 mt-2">
      <CardBody>
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-5">
            <H4>{title}</H4>
            {showParticipants && (
              <StatusDisplay label="Participants" number={"20/100"} />
            )}
          </div>
          {showParticipants && (
            <MUIIcons
              size={24}
              className="primary-icon-color pointer"
              iconName="FileDownloadOutlined"
            />
          )}
        </div>
        <Divider />
        {renderSections()}
      </CardBody>
    </Card>
  ) : (
    renderSections()
  );
};

ParticipantsDetailsWithoutHeight.propTypes = {
  sections: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

export default ParticipantsDetailsWithoutHeight;
