import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import { Col, FormGroup, Row } from "reactstrap";

import LabelTooltip from "../../../../../../CommonElements/LabelTooltip";
import {
  EndDate,
  Enter,
  ScheduleEndDateToolTipMessage,
  ScheduleStartDateToolTipMessage,
  StartDate,
} from "../../../../../../utils/Constant";

const ScheduleDetailsForm = ({
  start_date,
  end_date,
  errors,
  onDateChange,
  type,
}) => {
  return (
    <div>
      <Row className="mt-1">
        <Col sm="12" lg="6">
          <FormGroup>
            <LabelTooltip
              label={StartDate}
              tooltipText={ScheduleStartDateToolTipMessage}
              important
            />
            <div className={"w-100"}>
              <DatePicker
                selected={start_date}
                onChange={(date: Date | null) =>
                  onDateChange("start_date", date)
                }
                minDate={new Date()}
                placeholderText={`${Enter} ${StartDate}`}
                className={`w-100 form-control ${errors?.start_date ? "is-invalid" : ""}`}
                disabled={type === "past" || type === "current"}
                dateFormat="dd-MM-yyyy"
                disabledKeyboardNavigation={true}
                onKeyDown={e => e.preventDefault()}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
              {errors?.start_date && (
                <div className="invalid-date-picker-feedback">
                  {errors?.start_date}
                </div>
              )}
            </div>
          </FormGroup>
        </Col>
        <Col sm="12" lg="6">
          <FormGroup>
            <LabelTooltip
              label={EndDate}
              tooltipText={ScheduleEndDateToolTipMessage}
              important
            />
            <div className={"w-100"}>
              <DatePicker
                selected={end_date}
                onChange={(date: Date | null) => onDateChange("end_date", date)}
                minDate={start_date || new Date()}
                placeholderText={`${Enter} ${EndDate}`}
                className={`w-100 form-control ${errors?.end_date ? "is-invalid" : ""}`}
                disabled={type === "past" || type === "current"}
                dateFormat="dd-MM-yyyy"
                disabledKeyboardNavigation={true}
                onKeyDown={e => e.preventDefault()}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
              {errors?.end_date && (
                <div className="invalid-date-picker-feedback">
                  {errors?.end_date}
                </div>
              )}
            </div>
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};

ScheduleDetailsForm.propTypes = {
  start_date: PropTypes.instanceOf(Date).isRequired,
  end_date: PropTypes.instanceOf(Date).isRequired,
  errors: PropTypes.shape({
    start_date: PropTypes.string,
    end_date: PropTypes.string,
  }).isRequired,
  onDateChange: PropTypes.func.isRequired,
  type: PropTypes.string,
};

export default ScheduleDetailsForm;
