import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

import { Btn, MUIIcons } from "../../../AbstractElements";
import { ALL_USERS_STATUS, TAG_QUESTION_BANK } from "../../../Api/constants";
import { QuestionBankListItem } from "../../../Api/entities/QuestionBankEntity";
import FilterSliderModal from "../../../CommonElements/FilterSliderModal";
import FixedFooter from "../../../CommonElements/FixedFooter";
import JustifyTabs from "../../../CommonElements/JustifyTabs";
import CommonModal from "../../../CommonElements/Modal";
import SearchBar from "../../../CommonElements/SearchBar";
import TextTooltip from "../../../CommonElements/TextTooltip/inxex";
import ViewModeDropdown from "../../../CommonElements/ViewMoreDropdown";
import QuestionBankCard from "../../../container/GenericCard";
import NoData from "../../../container/NoData";
import DefaultPagination from "../../../container/Paginator";
import TableView from "../../../container/TableView";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import { getTagsList } from "../../../ReduxToolkit/Reducers/MasterSlice";
import {
  deleteModuleOrQb,
  getAssessmentQuestionBankList,
  setQuestionBankListToInitial,
  setQuestionBankPayloadToInitials,
} from "../../../ReduxToolkit/Reducers/QuestionBankSlice";
import { getUsersList } from "../../../ReduxToolkit/Reducers/UserSlice";
import { dynamicImage } from "../../../Service";
import {
  AddNew,
  Confirm,
  CreatedOn,
  CreatedBy,
  DeleteQB,
  DeleteQuestionBankHeader,
  Draft,
  Module,
  Modules,
  NoDataText,
  NotYetAdded,
  Question,
  QuestionBankName,
  Questions,
  Select,
  SelfAssessment,
  Tags,
} from "../../../utils/Constant";
import {
  capitalizeFirstLetter,
  CARD_PER_PAGE,
  formatCustomDate,
  questionBankTabs,
  selefAssesmentOptions,
} from "../../../utils/helper/helper";
import { hasPermissionToComponent } from "../../../utils/helper/permission";
import useIsMobile from "../../../utils/helper/responsive";

const QuestionBankList = () => {
  const dispatch = useAppDispatch();
  const { questionBankList } = useAppSelector(state => state.questionBank);
  const [activeTab, setActiveTab] = useState<string>(Draft);
  const [searchResults, setSearchResults] = useState([]);
  const [noDataFound, setNoDataFound] = useState(false);
  const { usersList } = useAppSelector(state => state.user);
  const { tagList } = useAppSelector(state => state.master);
  const [userMap, setUserMap] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedQb, setSelectedQb] = useState(null);
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});
  const [tagsListData, setTagsListData] = useState([]);
  const [tagsListDataIdsMap, setTagsListDataIdsMap] = useState({});
  const [clearData, setClearData] = useState(false);
  const [viewMode, setViewMode] = useState("card");
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = CARD_PER_PAGE;

  const params = new URLSearchParams(location.search);
  const viewParam = params.get("view");

  const handleTabClick = (tabId: any) => {
    setCurrentPage(1);
    setSelectedFilterValues({});
    setDefaultFilterValues({});
    setSearchResults([]);
    setClearData(true);
    setActiveTab(tabId);
  };

  const handleSearch = (query: string) => {
    setCurrentPage(1);
    setClearData(false);
    const filteredQuestionBank = questionBankList?.question_banks?.filter(
      bank => bank.name.toLowerCase().includes(query.toLowerCase())
    );
    setSearchResults(filteredQuestionBank || []);
    setNoDataFound(!filteredQuestionBank || filteredQuestionBank?.length === 0);
  };

  useEffect(() => {
    dispatch(getTagsList({ tagType: TAG_QUESTION_BANK }));
  }, [dispatch]);

  useEffect(() => {
    if (viewParam) {
      setViewMode(viewParam);
    }
  }, [viewParam]);

  useEffect(() => {
    const tags = [];
    const tagsMap = {};
    if (tagList) {
      tagList?.tags?.forEach((item, ind) => {
        tagsMap[ind] = item?.ids;
        tags.push({
          name: ind,
          value: item?.tag_name,
          label: item?.tag_name,
        });
      });
    }
    setTagsListData(tags);
    setTagsListDataIdsMap(tagsMap);
  }, [tagList]);

  useEffect(() => {
    dispatch(setQuestionBankListToInitial());
    fetchQuestionBank();
  }, [activeTab]);

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserMap(userMapObj);
  }, [usersList]);

  const fetchQuestionBank = async () => {
    await dispatch(getUsersList({ role: ALL_USERS_STATUS }));
    dispatch(
      getAssessmentQuestionBankList({
        status: activeTab,
        tag: null,
        self_assessment: null,
      })
    ).then(response => {
      if (response?.payload?.question_bank?.length === 0) {
        setNoDataFound(true);
      } else {
        setNoDataFound(false);
      }
    });
  };

  const onHeaderDropdownClick = e => {
    e.preventDefault();
    toggleModal();
  };

  const renderQuestionBankCard = (bank: QuestionBankListItem, index) => {
    return (
      <Col onClick={() => setSelectedQb(index)} key={index} sm={12} lg={3}>
        <Link
          to={"/master-data/add-question-bank"}
          state={{ questionBankId: bank?.id }}
        >
          <QuestionBankCard
            id={index}
            header={bank?.name}
            footerDate={formatCustomDate(bank?.created_at)}
            onHeaderDropdownClick={onHeaderDropdownClick}
            isHideMoreOption
            userCardWithoutDetail={{
              imagePath: dynamicImage("dashboard-2/user/2.png"),
              name:
                userMap &&
                bank?.created_by &&
                userMap[bank?.created_by]?.user_name,
            }}
            moduleDetails={[
              {
                label: Module,
                value: bank?.no_of_modules?.toString(),
                key: `${bank.id}_${Module}`,
              },
              {
                label: Question,
                value: bank?.no_of_question?.toString(),
                key: `${bank.id}_${Question}`,
              },
            ]}
          />
        </Link>
      </Col>
    );
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleDone = async (values: { [key: string]: string[] }) => {
    setSearchResults([]);
    dispatch(setQuestionBankListToInitial());
    setClearData(true);
    const tagIds = [];
    if (values["tags"]) {
      values["tags"]?.forEach(item => {
        if (tagsListDataIdsMap[item]) tagIds.push(...tagsListDataIdsMap[item]);
      });
    }

    let selfAssessmentValue: string | null = null;

    if (
      values["self_assessment"] &&
      values["self_assessment"].toString() !== ""
    ) {
      selfAssessmentValue =
        values["self_assessment"].toString() === "no" ? "0" : "1";
    }

    const params: { status: string; tag: string; self_assessment: string } = {
      status: activeTab,
      tag: tagIds?.toString() || null,
      self_assessment: selfAssessmentValue,
    };

    await dispatch(getAssessmentQuestionBankList(params)).then(response => {
      if (response?.payload?.question_bank?.length === 0) {
        setNoDataFound(true);
      } else {
        setNoDataFound(false);
      }
    });
    setCurrentPage(1);
  };

  const handleClear = async () => {
    setSearchResults([]);
    dispatch(setQuestionBankListToInitial());
    setClearData(true);
    await dispatch(
      getAssessmentQuestionBankList({
        status: activeTab,
        tag: null,
        self_assessment: null,
      })
    ).then(response => {
      if (response?.payload?.question_bank?.length === 0) {
        setNoDataFound(true);
      } else {
        setNoDataFound(false);
      }
    });
    setSelectedFilterValues({});
    setDefaultFilterValues({});
    setCurrentPage(1);
  };

  const renderContent = () => {
    if (noDataFound) {
      return (
        <NoData svg={"empty-folder-icon"} title={NoDataText} showCard={false} />
      );
    }

    if (questionBankList?.question_banks?.length === 0) {
      return (
        <NoData
          svg={"empty-folder-icon"}
          title={NotYetAdded}
          showCard={false}
        />
      );
    }

    const cardData =
      searchResults?.length > 0
        ? searchResults
        : questionBankList?.question_banks;

    return viewMode === "card"
      ? cardData
          ?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
          ?.map((course, index) => renderQuestionBankCard(course, index))
      : renderRequestTable();
  };

  const createTooltipCell = (content: string | string[], uniqueId: string) => (
    <TextTooltip tooltipText={content || "-"} toolTipUniqueId={uniqueId}>
      <div className="text-ellips-parent">
        <div className="text-ellips">{content || "-"}</div>
      </div>
    </TextTooltip>
  );

  const renderRequestTable = () => {
    const tableColumns = [
      {
        name: QuestionBankName,
        selector: row => capitalizeFirstLetter(row.name),
        cell: (row: any) =>
          createTooltipCell(row.name, `question-bank-name-${row.id}`),
        sortable: true,
      },
      {
        name: Modules,
        selector: row => row.no_of_modules,
        center: true,
        sortable: true,
      },
      {
        name: Questions,
        selector: row => row.no_of_question,
        center: true,
        sortable: true,
      },
      {
        name: CreatedBy,
        selector: row => userMap[row?.created_by]?.user_name,
        sortable: true,
      },
      {
        name: CreatedOn,
        selector: row => formatCustomDate(row.created_at),
        sortable: true,
      },
    ];

    const tableData =
      searchResults?.length > 0
        ? searchResults
        : questionBankList?.question_banks;

    return (
      <Col>
        <Card className="p-1">
          <CardBody>
            <TableView
              tableColumns={tableColumns}
              tableData={tableData}
              onRowClick={row =>
                navigate("/master-data/add-question-bank", {
                  state: {
                    questionBankId: row?.id,
                  },
                })
              }
            />
          </CardBody>
        </Card>
      </Col>
    );
  };

  const handleSetView = type => {
    setViewMode(type);
    params.set("view", type);
    navigate(`${location.pathname}?${params.toString()}`);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="page-body page-body-margin pb-5">
      <CommonModal
        sizeTitle={DeleteQuestionBankHeader}
        modalBodyClassName=""
        isOpen={isModalOpen}
        toggle={toggleModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={() => {
          dispatch(deleteModuleOrQb(selectedQb));
          toggleModal();
        }}
        primaryBtnText={Confirm}
      >
        {DeleteQB}
      </CommonModal>
      <Card className="p-1">
        <CardBody>
          <div
            className={`d-flex flex-column flex-md-row justify-content-between ${!isMobile ? "align-items-center" : ""}`}
          >
            <div className="mb-1 mb-sm-0 d-flex flex-column gap-2 align-items-center">
              <JustifyTabs
                tabs={questionBankTabs}
                activeTab={activeTab}
                onTabClick={handleTabClick}
              />
            </div>
            <div className="d-flex justify-content-end align-items-center mt-sm-0 mt-2 gap-4 ms-auto">
              <SearchBar
                className="mb-2"
                showSearch
                onSearch={handleSearch}
                clearOnProp={clearData}
              />
              <div className="mt-2">
                <FilterSliderModal
                  dropdowns={[
                    {
                      label: Tags,
                      key: "tags",
                      tooltipText: `${Select} ${Tags}`,
                      options: tagsListData,
                      isMultiSelect: true,
                    },
                    {
                      label: SelfAssessment,
                      key: "self_assessment",
                      tooltipText: `${Select} ${SelfAssessment}?`,
                      options: selefAssesmentOptions,
                      isMultiSelect: false,
                    },
                  ]}
                  selectedFilterValues={selectedFilterValues}
                  defaultFilterValues={defaultFilterValues}
                  setSelectedFilterValues={setSelectedFilterValues}
                  setDefaultFilterValues={setDefaultFilterValues}
                  onDone={handleDone}
                  onClear={handleClear}
                />
              </div>
              <ViewModeDropdown
                currentView={viewMode}
                onChange={handleSetView}
              />
              {hasPermissionToComponent("CREATE_QUESTION_BANK") && (
                <Link
                  to={"/master-data/add-question-bank"}
                  onClick={() => dispatch(setQuestionBankPayloadToInitials())}
                  state={{ questionBankId: null }}
                >
                  <Btn
                    icon={
                      <MUIIcons iconName="AddCircleOutlineOutlined" size={16} />
                    }
                    color="primary"
                  >
                    {AddNew}
                  </Btn>
                </Link>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
      <Row className="mt-5">{renderContent()}</Row>
      {viewMode === "card" &&
        !noDataFound &&
        (searchResults?.length > 0 ||
          (searchResults?.length === 0 &&
            questionBankList?.question_banks?.length > 0)) && (
          <FixedFooter>
            <DefaultPagination
              currentPage={currentPage}
              totalPages={Math.ceil(
                (searchResults?.length > 0
                  ? searchResults?.length
                  : questionBankList?.question_banks?.length) / itemsPerPage
              )}
              onPageChange={handlePageChange}
            />
          </FixedFooter>
        )}
    </div>
  );
};

export default QuestionBankList;
