import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";

import {
  CREATE_ASSESSMENT,
  CREATE_ASSESSMENT_URL,
  CREATE_EXTENSION_REQUEST,
  CREATE_EXTENSION_REQUEST_URL,
  CREATE_SGA_EXTENSION_REQUEST,
  DELETE,
  DELETE_ASSESSMENT_REQUEST,
  DELETE_ASSESSMENT_URL,
  DOWNLOAD_ASSESSMENT_CERTIFICATE,
  DOWNLOAD_ASSESSMENT_CERTIFICATE_API,
  GET,
  GET_ASSESSMENT_BY_PARTICIPANT_ID,
  GET_ASSESSMENT_BY_PARTICIPANT_ID_URL,
  GET_ASSESSMENT_DETAILS_BY_PARTICIPANT_ID,
  GET_ASSESSMENT_DETAILS_BY_PARTICIPANT_ID_URL,
  GET_ASSESSMENT_REQUEST,
  GET_ASSESSMENT_URL,
  GET_ASSIGNED_ASSESSMENT_FOR_LEARNER,
  GET_ASSIGNED_ASSESSMENT_FOR_LEARNER_URL,
  POST,
  PUT,
  SUBMIT_LEARNING_ASSESSMENT,
  SUBMIT_LEARNING_ASSESSMENT_URL,
  UPDATE_ASSESSMENT,
  UPDATE_ASSESSMENT_URL,
  UPDATE_RE_ATTEMPT_DAYS,
  UPDATE_RE_ATTEMPT_DAYS_API,
} from "../../Api";
import apiRequest from "../../Api/connector";
import { STATUS_200 } from "../../Api/constants";
import {
  ErrorWhileDownloading,
  SomethingWentWrong,
} from "../../utils/Constant";
import { showToast } from "../../utils/helper/helper";
import {
  COURSE_ASSESSMENT,
  LOGGED_IN_USER,
  getFromLocalStorage,
  saveToLocalStorage,
} from "../../utils/helper/localStorageutils";
import Store from "../Store";

import { hideLoader, showLoader } from "./LoaderSlice";

interface CourseInterface {
  createAssessments: CourseAssessments;
  updateAssessments: CourseAssessments;
  courseAssessments: GetCourseAssessments;
  deleteAssessments: DeleteAssessments;
  loading: boolean;
  error: string;
  questions: ExamAssessment;
  currentQuestionIndex: number;
  totalQuestions: number;
  learnerAssessmentList: LearnerAssessmentListResponse;
  assessmentDetails: AssessmentDetailsResponse;
}

const initialState: CourseInterface = {
  createAssessments: null,
  updateAssessments: null,
  courseAssessments: null,
  deleteAssessments: null,
  loading: false,
  error: null,
  questions: {
    assessment_id: null,
    participant_id: null,
    assessment_name: "",
    total_questions: null,
    created_at: "",
    questions: [],
    start_time: "",
    total_duration: 0,
  },
  currentQuestionIndex: 0,
  totalQuestions: 0,
  learnerAssessmentList: {
    assessments: [],
  },
  assessmentDetails: {
    result: [],
    extension_request: [],
    cooling_period_date: "",
    id: 0,
    assessment_type: "",
    assessment_name: "",
    estimated_time: 0,
    overall_question_count: 0,
    start_date: "",
    end_date: "",
  },
};

export const createAssessment = createAsyncThunk(
  CREATE_ASSESSMENT,
  async ({ assessmentData }: { assessmentData }) => {
    const response = await apiRequest(
      POST,
      CREATE_ASSESSMENT_URL(),
      assessmentData
    );
    return response.data;
  }
);

export const updateAssessment = createAsyncThunk(
  UPDATE_ASSESSMENT,
  async ({ assessmentData }: { assessmentData: any }) => {
    return await apiRequest(PUT, UPDATE_ASSESSMENT_URL(), assessmentData);
  }
);

export const getAssessment = createAsyncThunk(
  GET_ASSESSMENT_REQUEST,
  async ({ id }: { id: number }) => {
    const response = await apiRequest(GET, GET_ASSESSMENT_URL(id));
    return response.data;
  }
);

export const deleteAssessment = createAsyncThunk(
  DELETE_ASSESSMENT_REQUEST,
  async ({ deleteAssessmentData }: { deleteAssessmentData }) => {
    const response = await apiRequest(
      DELETE,
      DELETE_ASSESSMENT_URL(),
      deleteAssessmentData
    );
    return response;
  }
);

export const getAssessmentByParticipantId = createAsyncThunk(
  GET_ASSESSMENT_BY_PARTICIPANT_ID,
  async ({ id }: { id: number }) => {
    const response = await apiRequest(
      GET,
      GET_ASSESSMENT_BY_PARTICIPANT_ID_URL(id)
    );
    return response.data;
  }
);

export const submitLearningAssessment = createAsyncThunk(
  SUBMIT_LEARNING_ASSESSMENT,
  async ({
    learningAssessment,
  }: {
    learningAssessment: LearningAssessment;
  }) => {
    return await apiRequest(
      POST,
      SUBMIT_LEARNING_ASSESSMENT_URL(),
      learningAssessment
    );
  }
);

export const getAssignedAssessmentForLearner = createAsyncThunk(
  GET_ASSIGNED_ASSESSMENT_FOR_LEARNER,
  async ({
    status,
    assessment_type,
    tag,
  }: {
    status: string[];
    assessment_type?: string[];
    tag: string;
  }) => {
    const response = await apiRequest(
      GET,
      GET_ASSIGNED_ASSESSMENT_FOR_LEARNER_URL(status, assessment_type, tag)
    );
    return response.data;
  }
);

export const createExtensionRequest = createAsyncThunk(
  CREATE_EXTENSION_REQUEST,
  async ({
    extenstionRequest,
  }: {
    extenstionRequest: ScheduleExtensionRequest;
  }) => {
    return await apiRequest(
      POST,
      CREATE_EXTENSION_REQUEST_URL(),
      extenstionRequest
    );
  }
);

export const createSGAExtensionRequest = createAsyncThunk(
  CREATE_SGA_EXTENSION_REQUEST,
  async ({ extenstionRequest }: { extenstionRequest: any }) => {
    return await apiRequest(
      POST,
      CREATE_EXTENSION_REQUEST_URL(),
      extenstionRequest
    );
  }
);

export const getAssessmentDetailsByParticipantId = createAsyncThunk(
  GET_ASSESSMENT_DETAILS_BY_PARTICIPANT_ID,
  async ({ id }: { id: number }) => {
    const response = await apiRequest(
      GET,
      GET_ASSESSMENT_DETAILS_BY_PARTICIPANT_ID_URL(id)
    );
    return response.data;
  }
);

export const updateReAttemptDays = createAsyncThunk(
  UPDATE_RE_ATTEMPT_DAYS,
  async ({ request }: { request: UpdateReAttemptDaysRequest }) => {
    return await apiRequest(PUT, UPDATE_RE_ATTEMPT_DAYS_API(), request);
  }
);

const saveAssessmentToLocalStorage = (state: any) => {
  const { questions } = state;
  const stateToPersist = {
    questions,
  };
  saveToLocalStorage(COURSE_ASSESSMENT, stateToPersist);
};

export const downloadAssessmentCertificate = createAsyncThunk(
  DOWNLOAD_ASSESSMENT_CERTIFICATE,
  async ({
    type,
    sgaId,
    name,
    assessmentResultId,
    userId,
    id,
  }: {
    type: string;
    sgaId?: any;
    name: any;
    assessmentResultId?: any;
    userId?;
    id?: any;
  }) => {
    const loginData = getFromLocalStorage(LOGGED_IN_USER);
    const templateURL = DOWNLOAD_ASSESSMENT_CERTIFICATE_API(
      type,
      sgaId,
      assessmentResultId,
      userId,
      id
    );

    try {
      Store.dispatch(showLoader());
      const response = await fetch(templateURL, {
        method: "GET",
        headers: { "x-access-token": loginData.accessToken },
      });

      if (!response.ok) {
        throw new Error(ErrorWhileDownloading);
      }

      let fileName = name;

      if (sgaId) {
        fileName += sgaId;
      }

      if (assessmentResultId) {
        fileName += assessmentResultId;
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(ErrorWhileDownloading, error);
    } finally {
      Store.dispatch(hideLoader());
    }
  }
);

const AssessmentsSlice = createSlice({
  name: "assessments",
  initialState,
  reducers: {
    clearAssessmentDetails: state => {
      state.assessmentDetails = initialState.assessmentDetails;
    },
    nextQuestion: state => {
      state.currentQuestionIndex += 1;
    },
    previousQuestion: state => {
      state.currentQuestionIndex -= 1;
    },
    setQuestionIndex: (state, action: PayloadAction<number>) => {
      state.currentQuestionIndex = action.payload;
    },
    setQuetions: (state, action) => {
      state.questions = action.payload;
    },
    setTotalQuetions: (state, action) => {
      state.totalQuestions = action.payload;
    },
    setLearnerAssessmentListToInitals: state => {
      state.learnerAssessmentList = initialState.learnerAssessmentList;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createAssessment.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createAssessment.fulfilled, (state, action) => {
        state.loading = false;
        state.createAssessments = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(createAssessment.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(updateReAttemptDays.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateReAttemptDays.fulfilled, (state, action) => {
        state.loading = false;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(updateReAttemptDays.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(updateAssessment.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateAssessment.fulfilled, (state, action) => {
        state.loading = false;
        state.updateAssessments = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(updateAssessment.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
    builder
      .addCase(getAssessment.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getAssessment.fulfilled,
        (state, action: PayloadAction<GetCourseAssessments>) => {
          state.loading = false;
          state.courseAssessments = action.payload;
        }
      )
      .addCase(getAssessment.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
    builder
      .addCase(deleteAssessment.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteAssessment.fulfilled, (state, action) => {
        state.loading = false;
        state.deleteAssessments = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(deleteAssessment.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getAssessmentByParticipantId.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getAssessmentByParticipantId.fulfilled,
        (state, action: PayloadAction<ExamAssessment>) => {
          state.loading = false;
          state.questions = action.payload;
          state.totalQuestions = action?.payload?.questions?.length;
          saveAssessmentToLocalStorage(state);
        }
      )
      .addCase(getAssessmentByParticipantId.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getAssignedAssessmentForLearner.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getAssignedAssessmentForLearner.fulfilled,
        (state, action: PayloadAction<LearnerAssessmentListResponse>) => {
          state.loading = false;
          state.learnerAssessmentList = action.payload;
        }
      )
      .addCase(getAssignedAssessmentForLearner.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(createExtensionRequest.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createExtensionRequest.fulfilled, (state, action) => {
        state.loading = false;

        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(createExtensionRequest.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;

          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(createSGAExtensionRequest.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createSGAExtensionRequest.fulfilled, (state, action) => {
        state.loading = false;

        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(createSGAExtensionRequest.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;

          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getAssessmentDetailsByParticipantId.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getAssessmentDetailsByParticipantId.fulfilled,
        (state, action: PayloadAction<AssessmentDetailsResponse>) => {
          state.loading = false;
          state.assessmentDetails = action.payload;
        }
      )
      .addCase(
        getAssessmentDetailsByParticipantId.rejected,
        (state, action) => {
          try {
            const error = JSON.parse(action.error.message);
            state.error = error.message;
            showToast(state?.error, "error");
          } catch (e) {
            state.error = SomethingWentWrong;
          }
        }
      );
  },
});

export const {
  nextQuestion,
  setLearnerAssessmentListToInitals,
  previousQuestion,
  setQuestionIndex,
  setQuetions,
  setTotalQuetions,
  clearAssessmentDetails,
} = AssessmentsSlice.actions;
export default AssessmentsSlice.reducer;
