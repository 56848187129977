import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Row,
} from "reactstrap";

import { Btn, H3, MUIIcons } from "../../../AbstractElements";
import { ALL_USERS_STATUS } from "../../../Api/constants";
import BorderTabs from "../../../CommonElements/BorderTabs";
import DateRangeDisplay from "../../../CommonElements/DateRangeDisplay";
import Divider from "../../../CommonElements/Divider";
import LabelTooltip from "../../../CommonElements/LabelTooltip";
import CommonModal from "../../../CommonElements/Modal";
import StatusDisplay from "../../../CommonElements/StatusDisplay";
import NoData from "../../../container/NoData";
import TotalSeatCard from "../../../container/TotalSeatCard";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import {
  downloadCourseAttendeeFile,
  downloadCourseReportFile,
  getCourseContent,
} from "../../../ReduxToolkit/Reducers/CourseSlice";
import {
  getScheduledCourseById,
  updateScheduledCourse,
} from "../../../ReduxToolkit/Reducers/LoadFactorSlice";
import {
  getClassRoomList,
  getSbuList,
} from "../../../ReduxToolkit/Reducers/MasterSlice";
import { getUsersList } from "../../../ReduxToolkit/Reducers/UserSlice";
import {
  AddAssets,
  AddComment,
  AddParticipant,
  AddTrainer,
  AttendeesDetailsFile,
  Available,
  Booked,
  Cancel,
  CancelSchedule,
  Confirm,
  Description,
  DownloadReport,
  EditSchedule,
  EmptyCommentsAlert,
  Enter,
  Location,
  Nomination,
  PleaseSelectReportType,
  RatingEnableText,
  ReportType,
  Room,
  Select,
  Status,
  TotalSeat,
  WaitList,
} from "../../../utils/Constant";
import {
  assessmentReportTypes,
  courseScheduleStatusMap,
  editCourseScheduleStatus,
  formatCustomDate,
  rateLearnerStatus,
  reportNamesMap,
  scheduledCourseDetailsTabs,
  showToast,
} from "../../../utils/helper/helper";
import { hasPermissionToComponent } from "../../../utils/helper/permission";

import Assets from "./Assests";
import AttendanceList from "./AttendanceList";
import AttendeesList from "./Attendees";
import FeedbackListing from "./Feedback";
import ModuleFeedback from "./ModuleFeedback";
import NomineesList from "./NomineesList";
import RatingListing from "./Rating";
import ReplacementList from "./ReplacementList";
import ScheduledTrainersList from "./TrainerList";

import "./styles.scss";

const ScheduleDetails = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const childRef = useRef(null);
  const [activeTab, setActiveTab] = useState<string>("trainer");
  const [selectedReport, setSelectedReport] = useState<string>("");
  const { scheduleCourseById } = useAppSelector(state => state.loadFactor);
  const { classRoomList, sbuList } = useAppSelector(state => state.master);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedModalType, setSelectedModalType] = useState("");
  const { usersList } = useAppSelector(state => state.user);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showNominationButton, setShowNominationButton] = useState(false);
  const [userMap, setUserMap] = useState({});
  const { courseId, scheduledId } = location.state || "";
  const { courseContent } = useAppSelector(state => state.course);
  const [classListMap, setClassListMap] = useState({});
  const [comments, setComments] = useState("");
  const [assetModal, setAssetModal] = useState(null);
  const [totalSeatData, setTotalSeatData] = useState([
    { id: 1, count: 0, title: TotalSeat },
    { id: 2, count: 0, title: Available },
    { id: 3, count: 0, title: Booked },
    { id: 4, count: 0, title: WaitList },
  ]);
  const [sbuMap, setSbuMap] = useState({});

  useEffect(() => {
    dispatch(getSbuList());
    dispatch(getUsersList({ role: ALL_USERS_STATUS }));
  }, [dispatch]);

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserMap(userMapObj);
  }, [usersList]);

  useEffect(() => {
    if (courseId) {
      dispatch(getCourseContent({ id: courseId }));
    }
  }, [dispatch, courseId]);

  useEffect(() => {
    getScheduledDetails(scheduledId);
  }, [dispatch, scheduledId]);

  const getScheduledDetails = id => {
    if (id) {
      dispatch(getScheduledCourseById(id));
    }
  };

  useEffect(() => {
    const classMap = {};
    classRoomList?.class_room?.forEach(item => {
      classMap[item?.id] = item?.name;
    });
    setClassListMap(classMap);
  }, [classRoomList]);

  useEffect(() => {
    const valueMap = {};
    if (sbuList) {
      sbuList?.sbu?.forEach(item => {
        valueMap[item?.id] = item?.name;
      });
    }
    setSbuMap(valueMap);
  }, [sbuList]);

  useEffect(() => {
    if (scheduleCourseById) {
      if (scheduleCourseById?.location_id)
        dispatch(
          getClassRoomList({
            id: scheduleCourseById?.location_id,
          })
        );
      setTotalSeatData([
        {
          id: 1,
          count: scheduleCourseById?.batch_maximum_size,
          title: TotalSeat,
        },
        {
          id: 2,
          count: scheduleCourseById?.available_count,
          title: Available,
        },
        {
          id: 3,
          count: scheduleCourseById?.booked_count,
          title: Booked,
        },
        {
          id: 4,
          count: scheduleCourseById?.waitlist_count,
          title: WaitList,
        },
      ]);
    }
  }, [dispatch, scheduleCourseById]);

  const toggleDropdown = e => {
    e.preventDefault();
    e.stopPropagation();
    setDropdownOpen(prevState => !prevState);
  };

  const handleDropdownClick = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleTabClick = async (tabId: any) => {
    setActiveTab(tabId);
  };

  const handleClick = () => {
    if (activeTab === "trainer" && childRef.current) {
      childRef.current.addTrainers();
    }
    if (activeTab === "nomination" && childRef.current) {
      childRef.current.onHandleClick();
    }
  };

  const toggleModal = () => {
    if (isModalOpen) {
      setComments("");
      setSelectedModalType("");
      setSelectedReport("");
    }
    setIsModalOpen(!isModalOpen);
  };

  let sizeTitle = "";
  if (selectedModalType === "cancel") {
    sizeTitle = CancelSchedule;
  }
  if (selectedModalType === "report") {
    sizeTitle = DownloadReport;
  }

  const renderModalBody = type => {
    if (type === "cancel") {
      return (
        <FormGroup>
          <LabelTooltip
            label={AddComment}
            tooltipText={`${Enter} ${Description}`}
            important
          />
          <Input
            onChange={e => {
              if (
                (e.target.value && e.target.value.length <= 255) ||
                e.target.value === ""
              ) {
                setComments(e.target.value);
              }
            }}
            rows={4}
            name="objective"
            type="textarea"
            placeholder={`${Enter} ${Description}`}
          />
        </FormGroup>
      );
    }
    if (type === "report") {
      return (
        <>
          <LabelTooltip
            label={ReportType}
            tooltipText={`${Select} ${ReportType}`}
            important={true}
          />
          <Input
            type="select"
            value={selectedReport}
            onChange={async e => {
              const value = e.target.value;
              setSelectedReport(value);
            }}
            name="report"
          >
            <option value="" disabled>
              {Select} {ReportType}
            </option>
            {assessmentReportTypes.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Input>
        </>
      );
    }
  };

  const handlePrimaryBtnClick = async () => {
    if (selectedModalType === "cancel") {
      if (!comments || comments.trim() === "") {
        showToast(EmptyCommentsAlert, "error");
        return;
      }

      const payload = { ...scheduleCourseById };
      payload.status = "cancelled";
      await dispatch(updateScheduledCourse(payload));
      await dispatch(getScheduledCourseById(scheduledId));
      toggleModal();
      return navigate(
        `${process.env.PUBLIC_URL}/course-management/course-details?courseId=${courseId}`,
        {
          state: {
            courseId: courseId,
            scheduled: true,
          },
        }
      );
    }
    if (selectedModalType === "report") {
      if (selectedReport === "") {
        showToast(PleaseSelectReportType, "error");
        return;
      }
      if (selectedReport === "attendee") {
        await downloadCourseAttendeeFile(
          scheduledId,
          `${AttendeesDetailsFile}_${scheduledId}`
        );
      } else {
        await downloadCourseReportFile(
          scheduledId,
          selectedReport,
          reportNamesMap[selectedReport]
        );
      }
      toggleModal();
    }
  };

  const handleAssetModal = () => {
    setAssetModal(prevState => ({
      isOpen: !prevState?.isOpen,
    }));
  };

  return (
    <div className="page-body pb-5 course-schedule-details">
      <CommonModal
        sizeTitle={sizeTitle}
        modalBodyClassName=""
        isOpen={isModalOpen}
        toggle={toggleModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={handlePrimaryBtnClick}
        primaryBtnText={Confirm}
      >
        {renderModalBody(selectedModalType)}
      </CommonModal>
      <Card>
        <CardBody className="m-2">
          <H3 className="fw-bold">{courseContent?.course_name}</H3>
          <Row className="mt-3">
            <Col lg="4" className="mt-1">
              <DateRangeDisplay
                startDate={scheduleCourseById?.start_date}
                endDate={scheduleCourseById?.end_date}
              />
            </Col>
            <Col className="d-flex justify-content-between mt-3"></Col>
            <Col lg="1" className="mt-1 primary-icon-color text-end d-flex">
              <MUIIcons
                size={24}
                className="primary-icon-color pointer mt-2"
                iconName="FileDownloadOutlined"
                onClick={() => {
                  setSelectedModalType("report");
                  toggleModal();
                }}
              />
              {hasPermissionToComponent("UPSERT_COURSE_SCHEDULE") &&
                !editCourseScheduleStatus.includes(
                  scheduleCourseById?.status
                ) && (
                  <Dropdown
                    isOpen={dropdownOpen}
                    toggle={toggleDropdown}
                    onClick={handleDropdownClick}
                  >
                    <DropdownToggle color="" className="three-dots">
                      <MUIIcons
                        iconName="MoreVertOutlined"
                        className="primary-icon-color"
                        size={24}
                      />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem
                        onClick={() => {
                          setSelectedModalType("cancel");
                          toggleModal();
                        }}
                      >
                        {Cancel}
                      </DropdownItem>
                      <DropdownItem>
                        <Link
                          to={"/schedule-course"}
                          state={{
                            schedule_id: scheduledId,
                            course_id: courseContent?.id,
                            course_name: courseContent?.course_name,
                            ongoing_status: scheduleCourseById?.status,
                          }}
                        >
                          {EditSchedule}
                        </Link>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                )}
            </Col>
          </Row>
          <div className="d-flex justify-content-between mt-4">
            <StatusDisplay
              label={Location}
              number={scheduleCourseById?.location_name}
            />
            <StatusDisplay
              label={Room}
              number={classListMap[scheduleCourseById?.classroom_id]}
            />
            <StatusDisplay
              label={Nomination}
              number={
                formatCustomDate(scheduleCourseById?.nomination_start_date) +
                " - " +
                formatCustomDate(scheduleCourseById?.nomination_end_date)
              }
            />
            <StatusDisplay
              label={Status}
              number={courseScheduleStatusMap[scheduleCourseById?.status]}
            />
          </div>
          <div className="mt-4">
            <TotalSeatCard data={totalSeatData} />
          </div>
          <div className="mb-2">
            <Divider />
          </div>
          <div className="d-flex align-items-center">
            <div className="tabs-container d-flex flex-grow-1">
              <BorderTabs
                showCard={false}
                tabs={scheduledCourseDetailsTabs}
                activeTab={activeTab}
                handleTabClick={handleTabClick}
              />
            </div>
            <div className="d-flex justify-content-end m-3">
              {hasPermissionToComponent("ASSIGN_TRAINERS") &&
                !editCourseScheduleStatus.includes(
                  scheduleCourseById?.status
                ) &&
                activeTab === "trainer" && (
                  <Btn className="mt-1" color="primary" onClick={handleClick}>
                    {AddTrainer}
                  </Btn>
                )}
              {hasPermissionToComponent("ADD_NOMINEES") &&
                !editCourseScheduleStatus.includes(
                  scheduleCourseById?.status
                ) &&
                showNominationButton &&
                activeTab === "nomination" && (
                  <Btn className="mt-1" color="primary" onClick={handleClick}>
                    {AddParticipant}
                  </Btn>
                )}
              {hasPermissionToComponent("UPSERT_ASSET_TO_SCHEDULE") &&
                new Date(new Date().setHours(0, 0, 0, 0)) >=
                  new Date(
                    new Date(scheduleCourseById?.start_date).setHours(
                      0,
                      0,
                      0,
                      0
                    )
                  ) &&
                new Date(new Date().setHours(0, 0, 0, 0)) <=
                  new Date(
                    new Date(scheduleCourseById?.end_date).setHours(0, 0, 0, 0)
                  ) &&
                activeTab === "assets" && (
                  <Btn
                    className="mt-1"
                    color="primary"
                    onClick={handleAssetModal}
                  >
                    {AddAssets}
                  </Btn>
                )}
            </div>
          </div>

          {activeTab === "trainer" && (
            <ScheduledTrainersList
              scheduledId={scheduledId}
              userMap={userMap}
              ref={childRef}
              getScheduledDetails={getScheduledDetails}
              scheduleStatus={scheduleCourseById?.status}
            />
          )}
          {activeTab === "nomination" && (
            <NomineesList
              scheduledId={scheduledId}
              courseId={courseId}
              courseName={courseContent?.course_name}
              userMap={userMap}
              ref={childRef}
              setShowNominationButton={setShowNominationButton}
              sbuMap={sbuMap}
              startDate={scheduleCourseById?.nomination_start_date}
              endDate={scheduleCourseById?.nomination_end_date}
              primaryTrainerId={scheduleCourseById?.primary_trainer_id}
              replacedTrainerId={scheduleCourseById?.replaced_trainer_id}
              status={scheduleCourseById?.status}
              getScheduledDetails={getScheduledDetails}
              available={scheduleCourseById?.available_count}
              booked={scheduleCourseById?.booked_count}
            />
          )}
          {activeTab === "replacement" && (
            <ReplacementList
              scheduledId={scheduledId}
              userMap={userMap}
              sbuMap={sbuMap}
              getScheduledDetails={getScheduledDetails}
            />
          )}
          {activeTab === "attendees" && (
            <AttendeesList
              scheduledId={scheduledId}
              courseId={courseId}
              courseName={courseContent?.course_name}
              userMap={userMap}
              sbuMap={sbuMap}
              startDate={scheduleCourseById?.start_date}
              primaryTrainerId={scheduleCourseById?.primary_trainer_id}
              replacedTrainerId={scheduleCourseById?.replaced_trainer_id}
              getScheduledDetails={getScheduledDetails}
            />
          )}
          {activeTab === "attendance" && (
            <AttendanceList
              scheduledId={scheduledId}
              primaryTrainerId={scheduleCourseById?.primary_trainer_id}
              replacedTrainerId={scheduleCourseById?.replaced_trainer_id}
              startDate={scheduleCourseById?.start_date}
              endDate={scheduleCourseById?.end_date}
            />
          )}
          {activeTab === "courseFeedback" && (
            <FeedbackListing
              scheduledId={scheduledId}
              courseName={courseContent?.course_name}
              startDate={scheduleCourseById?.start_date}
              endDate={scheduleCourseById?.end_date}
              locationName={scheduleCourseById?.location_name}
              classRoomName={classListMap[scheduleCourseById?.classroom_id]}
              instructorId={scheduleCourseById?.primary_trainer_id}
            />
          )}

          {activeTab === "moduleFeedback" && (
            <ModuleFeedback
              scheduledId={scheduledId}
              courseName={courseContent?.course_name}
              startDate={scheduleCourseById?.start_date}
              endDate={scheduleCourseById?.end_date}
              locationName={scheduleCourseById?.location_name}
              classRoomName={classListMap[scheduleCourseById?.classroom_id]}
              instructorId={scheduleCourseById?.primary_trainer_id}
              nominationStartDate={scheduleCourseById?.nomination_start_date}
              nominationEndDate={scheduleCourseById?.nomination_end_date}
              status={courseScheduleStatusMap[scheduleCourseById?.status]}
            />
          )}
          {activeTab === "rating" &&
          new Date(new Date().setHours(0, 0, 0, 0)) >=
            new Date(
              new Date(scheduleCourseById?.end_date).setHours(0, 0, 0, 0)
            )
            ? rateLearnerStatus.includes(scheduleCourseById?.status) && (
                <RatingListing
                  scheduledId={scheduledId}
                  courseName={courseContent?.course_name}
                  startDate={scheduleCourseById?.start_date}
                  endDate={scheduleCourseById?.end_date}
                  locationName={scheduleCourseById?.location_name}
                  classRoomName={classListMap[scheduleCourseById?.classroom_id]}
                  instructorId={scheduleCourseById?.primary_trainer_id}
                  primaryTrainerId={scheduleCourseById?.primary_trainer_id}
                  replacedTrainerId={scheduleCourseById?.replaced_trainer_id}
                />
              )
            : activeTab === "rating" && (
                <div className="p-5">
                  <NoData
                    showCard={false}
                    showIcon={false}
                    svg="empty-folder-icon"
                    title={RatingEnableText}
                  />
                </div>
              )}

          {activeTab === "assets" && (
            <Assets
              scheduledId={scheduledId}
              startDate={new Date(scheduleCourseById?.start_date)}
              endDate={new Date(scheduleCourseById?.end_date)}
              setAssetModal={setAssetModal}
              assetModal={assetModal}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default ScheduleDetails;
