import PropTypes from "prop-types";
import React from "react";

import MUIIcons from "../MUIIcon/MUIIcons";

import "./style.scss";

interface ViewModeDropdownProps {
  onChange: (view: "card" | "table") => void;
  currentView: string;
}

const ViewModeDropdown: React.FC<ViewModeDropdownProps> = ({
  onChange,
  currentView,
}) => {
  return (
    <button
      className="view-toggle-btn border-0 bg-transparent pt-2"
      onClick={() => {
        onChange(currentView === "card" ? "table" : "card");
      }}
    >
      <MUIIcons
        iconName={
          currentView === "card" ? "ViewListOutlined" : "ViewModuleOutlined"
        }
        className="primary-icon-color"
        size={26}
      />
    </button>
  );
};

ViewModeDropdown.propTypes = {
  onChange: PropTypes.func,
  currentView: PropTypes.string,
};

export default ViewModeDropdown;
