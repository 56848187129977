import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import { Card, CardBody, Col, Form, FormGroup, Row } from "reactstrap";

import { Btn, H3 } from "../../../../AbstractElements";
import Divider from "../../../../CommonElements/Divider";
import FilterSliderModal from "../../../../CommonElements/FilterSliderModal";
import FixedFooter from "../../../../CommonElements/FixedFooter";
import LabelTooltip from "../../../../CommonElements/LabelTooltip";
import MultiSelectDropdown from "../../../../CommonElements/MultiSelectDropdown";
import SearchDropDownPaginator from "../../../../CommonElements/SearchDropDownPaginator";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { createMapping } from "../../../../ReduxToolkit/Reducers/LearnerSlice";
import {
  getDomainList,
  getFilterRolesList,
  getSbuList,
  getUserMasterList,
} from "../../../../ReduxToolkit/Reducers/MasterSlice";
import {
  Domain,
  DomainSelectDomainToolTipMessage,
  JobRole,
  MapButton,
  MapMentorAndMentee,
  Mentee,
  MenteeSelectMenteeToolTipMessage,
  Mentor,
  MentorSelectMentorToolTipMessage,
  PleaseSelect,
  SBU,
  Select,
} from "../../../../utils/Constant";
import {
  customStyles,
  mapListToOptions,
} from "../../../../utils/helper/helper";

import "./style.scss";

const MapMentorAndMenteeForm: React.FC = () => {
  const { state } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { mentorId, domain } = state || {};
  const { roleOnFilter, sbuList, domainList, userMasterList } = useAppSelector(
    state => state.master
  );

  const [domainListData, setDomainListData] = useState([]);
  const [selectedMentor, setSelectedMentor] = useState(mentorId || null);
  const [selectedMentee, setSelectedMentee] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [usersList, setUsersList] = useState([]);
  const [selectedMentorFilterValues, setSelectedMentorFilterValues] = useState(
    {}
  );
  const [defaultMentorFilterValues, setDefaultMentorFilterValues] = useState(
    {}
  );
  const [selectedMenteeFilterValues, setSelectedMenteeFilterValues] = useState(
    {}
  );
  const [defaultMenteeFilterValues, setDefaultMenteeFilterValues] = useState(
    {}
  );
  const [roleFilterList, setRoleFilterList] = useState([]);
  const [sbuFilterList, setSbuFilterList] = useState([]);

  const [errors, setErrors] = useState<{
    mentor?: string;
    mentee?: string;
    domain?: string;
  }>({});

  useEffect(() => {
    const fetchData = async () => {
      dispatch(getDomainList());
      dispatch(
        getUserMasterList({
          roleId: null,
          sbuId: null,
        })
      );
      dispatch(getSbuList());
      dispatch(getFilterRolesList());
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const value = [];
    if (roleOnFilter) {
      roleOnFilter?.job_role?.forEach(item => {
        value.push({
          name: item?.id,
          label: item?.name,
          value: item?.name,
        });
      });
    }
    setRoleFilterList(value);
  }, [roleOnFilter]);

  useEffect(() => {
    const value = [];
    if (sbuList) {
      sbuList?.sbu?.forEach(item => {
        value.push({
          name: item?.id,
          label: item?.name,
          value: item?.name,
        });
      });
    }
    setSbuFilterList(value);
  }, [sbuList]);

  useEffect(() => {
    const domains = mapListToOptions(domainList?.domain, "domain_name", "id");
    setDomainListData(domains);
    if (domain) {
      const preFillDomain = domainList?.domain?.find(
        mDomain => mDomain.domain_name === domain
      );
      if (preFillDomain) setSelectedDomain(preFillDomain.id);
    }
  }, [domainList]);

  useEffect(() => {
    const value = [];
    if (userMasterList) {
      userMasterList?.users?.forEach((item: any) => {
        value.push({
          name: item?.id,
          label: `${item?.employee_code || "-"} - ${item?.user_name} - ${item?.email || "-"}`,
          value: `${item?.employee_code || "-"} - ${item?.user_name} - ${item?.email || "-"}`,
        });
      });
    }
    setUsersList(value);
  }, [userMasterList]);

  const handleMentorChange = e => {
    setSelectedMentor(Number(e?.target?.value));
    setErrors(prevErrors => ({ ...prevErrors, mentor: "" }));
  };

  const handleMenteesChange = (selectedIds: string[]) => {
    setSelectedMentee(selectedIds);
    setErrors(prevErrors => ({ ...prevErrors, mentee: "" }));
  };

  const handleDomainChange = e => {
    setSelectedDomain(Number(e?.target?.value));
    setErrors(prevErrors => ({ ...prevErrors, domain: "" }));
  };

  const validateForm = () => {
    const newErrors: any = {};
    if (!selectedMentor) newErrors.mentor = `${PleaseSelect} ${Mentor}`;
    if (selectedMentee.length === 0)
      newErrors.mentee = `${PleaseSelect} ${Mentee}`;
    if (!selectedDomain) newErrors.domain = `${PleaseSelect} ${Domain}`;
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleMap = async () => {
    if (!validateForm()) return;

    await dispatch(
      createMapping({
        mapPayload: {
          mentor_id: selectedMentor,
          mentee_id: selectedMentee,
          domain_id: selectedDomain,
        },
      })
    );

    navigate(`${process.env.PUBLIC_URL}/mentoring/manage-mentoring`);
  };

  const menteeOptions =
    usersList
      ?.filter(user => user.name !== selectedMentor)
      .map(user => ({
        name: user.name,
        value: user.value,
        label: user.label,
      })) || [];

  const handleMentorFilterDone = async (values: {
    [key: string]: string[];
  }) => {
    if (values["role"] || values["sbu"]) {
      await dispatch(
        getUserMasterList({
          roleId: values["role"]?.toString(),
          sbuId: values["sbu"]?.toString(),
        })
      );
    }
  };

  const handleMentorFilterClear = async () => {
    setUsersList([]);
    await dispatch(
      getUserMasterList({
        roleId: "",
        sbuId: "",
      })
    );
    setSelectedMentorFilterValues({});
    setDefaultMentorFilterValues({});
  };

  const handleMenteeFilterDone = async (values: {
    [key: string]: string[];
  }) => {
    if (values["role"] || values["sbu"]) {
      await dispatch(
        getUserMasterList({
          roleId: values["role"]?.toString(),
          sbuId: values["sbu"]?.toString(),
        })
      );
    }
  };

  const handleMenteeFilterClear = async () => {
    setUsersList([]);
    await dispatch(
      getUserMasterList({
        roleId: "",
        sbuId: "",
      })
    );
    setSelectedMenteeFilterValues({});
    setDefaultMenteeFilterValues({});
  };

  return (
    <div className="page-body page-body-margin">
      <Card>
        <CardBody className="custom-map-form">
          <H3 className="fw-bold mt-3">{MapMentorAndMentee}</H3>
          <Divider />

          <Form className="mt-2">
            <Row className="align-items-center">
              <Col lg="6">
                <FormGroup>
                  <LabelTooltip
                    label={Mentor}
                    important
                    tooltipText={MentorSelectMentorToolTipMessage}
                  />
                  <ReactSelect
                    tabIndex={1}
                    name="mentor"
                    className="text-gray"
                    onChange={selectedOption =>
                      handleMentorChange({
                        target: {
                          name: "selectedRole",
                          value: selectedOption ? selectedOption?.value : "",
                        },
                      })
                    }
                    value={
                      usersList
                        ?.filter(user => !selectedMentee?.includes(user?.label))
                        .find(user => user?.name === selectedMentor) || null
                    }
                    options={usersList
                      ?.filter(user => !selectedMentee?.includes(user?.label))
                      .map(user => ({
                        value: user?.name,
                        label: user?.label,
                      }))}
                    isSearchable
                    isClearable={false}
                    placeholder={`${Select} ${Mentor}`}
                    styles={customStyles}
                    components={{
                      MenuList: props => (
                        <SearchDropDownPaginator {...props} maxHeight={400} />
                      ),
                    }}
                    filterOption={(option, inputValue) =>
                      option?.label
                        ?.toLowerCase()
                        ?.includes(inputValue?.toLowerCase())
                    }
                  />

                  {errors.mentor && (
                    <div className="text-danger">{errors.mentor}</div>
                  )}
                </FormGroup>
              </Col>
              <Col lg="1">
                <div className="mt-4">
                  <FilterSliderModal
                    dropdowns={[
                      {
                        label: JobRole,
                        key: "role",
                        tooltipText: `${Select} ${JobRole}`,
                        options: roleFilterList,
                        isMultiSelect: true,
                      },
                      {
                        label: SBU,
                        key: "sbu",
                        tooltipText: `${Select} ${SBU}`,
                        options: sbuFilterList,
                        isMultiSelect: true,
                      },
                    ]}
                    selectedFilterValues={selectedMentorFilterValues}
                    defaultFilterValues={defaultMentorFilterValues}
                    setSelectedFilterValues={setSelectedMentorFilterValues}
                    setDefaultFilterValues={setDefaultMentorFilterValues}
                    onDone={handleMentorFilterDone}
                    onClear={handleMentorFilterClear}
                  />
                </div>
              </Col>
            </Row>

            <Row className="position-relative align-items-center">
              <Col lg="6">
                <FormGroup>
                  <LabelTooltip
                    label={Mentee}
                    important
                    tooltipText={MenteeSelectMenteeToolTipMessage}
                  />
                  <MultiSelectDropdown
                    tabIndex={2}
                    options={menteeOptions}
                    placeholder={`${Select} ${Mentee}`}
                    defaultSelected={menteeOptions.filter(option =>
                      selectedMentee.includes(option.name)
                    )}
                    onChange={handleMenteesChange}
                  />
                  {errors.mentee && (
                    <div className="text-danger">{errors.mentee}</div>
                  )}
                </FormGroup>
              </Col>
              <Col lg="1">
                <div className="mt-4">
                  <FilterSliderModal
                    dropdowns={[
                      {
                        label: JobRole,
                        key: "role",
                        tooltipText: `${Select} ${JobRole}`,
                        options: roleFilterList,
                        isMultiSelect: true,
                      },
                      {
                        label: SBU,
                        key: "sbu",
                        tooltipText: `${Select} ${SBU}`,
                        options: sbuFilterList,
                        isMultiSelect: true,
                      },
                    ]}
                    selectedFilterValues={selectedMenteeFilterValues}
                    defaultFilterValues={defaultMenteeFilterValues}
                    setSelectedFilterValues={setSelectedMenteeFilterValues}
                    setDefaultFilterValues={setDefaultMenteeFilterValues}
                    onDone={handleMenteeFilterDone}
                    onClear={handleMenteeFilterClear}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg="6">
                <FormGroup>
                  <LabelTooltip
                    label={Domain}
                    important
                    tooltipText={DomainSelectDomainToolTipMessage}
                  />
                  <ReactSelect
                    tabIndex={3}
                    name="domain"
                    className="text-gray"
                    onChange={selectedOption =>
                      handleDomainChange({
                        target: {
                          name: "selectedRole",
                          value: selectedOption ? selectedOption?.value : "",
                        },
                      })
                    }
                    value={
                      domainListData?.find(
                        domain => Number(domain?.value) === selectedDomain
                      ) || null
                    }
                    options={domainListData.map(domain => ({
                      value: domain?.value,
                      label: domain?.label,
                    }))}
                    isSearchable={true}
                    isClearable={false}
                    placeholder={`${Select} ${Domain}`}
                    styles={customStyles}
                    components={{
                      MenuList: props => (
                        <SearchDropDownPaginator {...props} maxHeight={400} />
                      ),
                    }}
                    filterOption={(option, inputValue) =>
                      option?.label
                        ?.toLowerCase()
                        ?.includes(inputValue?.toLowerCase())
                    }
                  />
                  {errors.domain && (
                    <div className="text-danger">{errors.domain}</div>
                  )}
                </FormGroup>
              </Col>
            </Row>

            <FixedFooter>
              <Btn color="primary" tabIndex={4} onClick={handleMap}>
                {MapButton}
              </Btn>
            </FixedFooter>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default MapMentorAndMenteeForm;
