import React from "react";
import { Card, CardBody } from "reactstrap";

import { MUIIcons, H4, H5, P, ToolTip } from "../../AbstractElements";
import CommonCardHeader from "../../CommonElements/CommonCardHeader/CommonCardHeader";
import Divider from "../../CommonElements/Divider";
import { CreatedBy } from "../../utils/Constant";
import { sanitizeString } from "../../utils/helper/helper";
import useIsMobile from "../../utils/helper/responsive";

interface CourseCardProps {
  title: string;
  courseRequestId: number;
  header: string;
  rm: string;
  requestedOn: string;
  status: string;
}

const RequestCard: React.FC<CourseCardProps> = ({
  title,
  courseRequestId,
  header,
  rm,
  requestedOn,
  status,
}) => {
  const isMobile = useIsMobile();

  return (
    <Card
      className={
        isMobile
          ? "request-course-card-responsive-height"
          : "request-course-card-height"
      }
    >
      <CommonCardHeader
        requestId={courseRequestId?.toString()}
        status={status}
        badge="text"
        headClass="card-no-border pb-0"
        title={[title]}
      />
      <CardBody>
        <div>
          <div className="d-flex align-items-center gap-2">
            <div>
              <div>
                <span
                  id={sanitizeString(`tooltip-course-name-${courseRequestId}`)}
                >
                  <div className="text-ellips-parent">
                    <H4
                      className="header-text text-ellips"
                      data-fulltext={header}
                    >
                      {header}
                    </H4>
                  </div>
                </span>
                <ToolTip
                  placement="top"
                  target={sanitizeString(
                    `tooltip-course-name-${courseRequestId}`
                  )}
                >
                  {header}
                </ToolTip>
              </div>
              <span className="mt-2"></span>
              <H5>
                {CreatedBy}&nbsp;{rm}
              </H5>
            </div>
          </div>
          <Divider />
          <div className="d-flex gap-2">
            <MUIIcons
              className="text-gray mt-1"
              size={18}
              iconName="CalendarTodayOutlined"
            />
            <P className="text-muted mt-1">{requestedOn}</P>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default RequestCard;
