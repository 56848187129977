import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import React, { useEffect, useState } from "react";
import { Col, Form, FormGroup } from "reactstrap";

import LabelTooltip from "../../../../../../../CommonElements/LabelTooltip";
import {
  Review,
  Assessment,
  ReviewToolTipMessage,
  AssessmentToolTipMessage,
} from "../../../../../../../utils/Constant";
import {
  resourceAndReferenceDefaultData,
  reviewDefaultData,
  richTextSupportedItems,
} from "../../../../../../../utils/helper/helper";

interface BasicInputFormValueInterface {
  review: string;
  assessment: string;
  other_prerequisites: string;
}

interface BasicInputFormValuePropsType {
  basicInputFormValue: BasicInputFormValueInterface;
  getRichTextUserData: any;
  prerequisiteListDataRef: any;
  selectedPrerequisiteRef: any;
  reviewOverride: boolean;
  setReviewOverride: (val: boolean) => void;
  errors: {
    review: string;
    assessment: string;
    other_prerequisites: string;
  };
}

const CompetencyFormThree: React.FC<BasicInputFormValuePropsType> = ({
  basicInputFormValue,
  getRichTextUserData,
  errors,
  prerequisiteListDataRef,
  selectedPrerequisiteRef,
  reviewOverride,
  setReviewOverride,
}) => {
  const { review, assessment } = basicInputFormValue;
  const [reviewData, setReviewData] = useState("");

  useEffect(() => {
    let review = "";
    if (
      prerequisiteListDataRef &&
      selectedPrerequisiteRef &&
      selectedPrerequisiteRef?.length > 0 &&
      !reviewOverride
    ) {
      review = `
      <p>${reviewDefaultData}</p>
      ${prerequisiteListDataRef
        ?.filter(requisite =>
          selectedPrerequisiteRef?.includes(requisite?.name)
        )
        ?.map(item => `<p>• ${item?.value}</p>`)
        .join("")} 
      ${basicInputFormValue?.other_prerequisites || ""}`;
    }
    setReviewData(review);
  }, [prerequisiteListDataRef, selectedPrerequisiteRef]);

  return (
    <Form className="stepper-one g-3 needs-validation custom-input" noValidate>
      <Col className="mt-3">
        <FormGroup>
          <LabelTooltip
            label={Review}
            tooltipText={ReviewToolTipMessage}
            important
          />
          <div className={errors.review ? "border border-danger" : ""}>
            <CKEditor
              onChange={(_event, editor) => {
                if (review !== "") setReviewOverride(true);
                const data = editor.getData();
                getRichTextUserData("review", data);
              }}
              config={{
                toolbar: {
                  items: richTextSupportedItems,
                },
              }}
              editor={ClassicEditor as any}
              data={!reviewOverride ? reviewData : review}
            />
          </div>
          <div className="mt-1 text-sm text-danger">{errors.review}</div>
        </FormGroup>
      </Col>
      <Col className="mt-3">
        <FormGroup>
          <LabelTooltip
            label={Assessment}
            tooltipText={AssessmentToolTipMessage}
            important
          />
          <div className={errors.assessment ? "border border-danger" : ""}>
            <CKEditor
              onChange={(_event, editor) => {
                const data = editor.getData();
                getRichTextUserData("assessment", data);
              }}
              config={{
                toolbar: {
                  items: richTextSupportedItems,
                },
              }}
              editor={ClassicEditor as any}
              data={assessment || resourceAndReferenceDefaultData}
            />
          </div>
          <div className="mt-1 text-sm text-danger">{errors.assessment}</div>
        </FormGroup>
      </Col>
    </Form>
  );
};

export default CompetencyFormThree;
