import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";

import {
  ADD_COMMENT,
  ADD_COMMENT_URL,
  CREATE_COURSE_CONTENT,
  CREATE_COURSE_CONTENT_API,
  DOWNLOAD_COURSE_STAGE_DATA_API,
  DOWNLOAD_STAGE_DATA,
  GET,
  GET_COURSE_CONTENT,
  GET_COURSE_CONTENT_API,
  GET_COURSE_LIST,
  GET_COURSE_LIST_API,
  GET_PUBLISHED_COURSE_LIST,
  GET_PUBLISHED_COURSE_LIST_API,
  POST,
  PUT,
  REVIEW_COURSE,
  REVIEW_COURSE_API,
  SUBMIT_COURSE_DETAILS_BY_TRAINER,
  SUBMIT_COURSE_DETAILS_BY_TRAINER_URL,
  UPDATE_COURSE_CONTENT,
  UPDATE_COURSE_CONTENT_API,
  UPDATE_COMMENT_STATUS_URL,
  UPDATE_COMMENT_STATUS,
  GET_COURSE_CONTENT_BY_IDS_API,
  GET_COURSE_CONTENT_BY_IDS,
  GET_USER_SPECIFIC_COURSE_LIST,
  GET_USER_SPECIFIC_COURSE_LIST_API,
  GET_COURSE_SCEDULED_LIST,
  GET_COURSE_SCHEDULED_LIST_URL,
  GET_TRAINERS_AVAILABILITY,
  GET_TRAINERS_AVAILABILITY_URL,
  GET_SCHEDULED_TRAINERS,
  GET_SCHEDULED_TRAINERS_URL,
  ASSIGN_SECONDARY_TRSINER,
  ASSIGN_SECONDARY_TRAINERS,
  REVIEW_TRAINER_OR_NOMINEE,
  REVIEW_TRAINER_OR_NOMINEE_URL,
  REPLACE_TRAINER_OR_NOMINEE,
  REPLACE_TRAINER_OR_NOMINEE_URL,
  GET_NOMINEES_OF_SCHEDULE,
  GET_NOMINEES_OF_SCHEDULE_URL,
  GET_ELIGIBLE_LIST,
  GET_ELIGIBLE_LIST_URL,
  GET_GLOBAL_SETTINGS,
  GET_GLOBAL_SETTINGS_URL,
  PUT_ELIGIBLE_LIST,
  PUT_ELIGIBLE_LIST_URL,
  NOMINATE_PARTICIPANTS,
  NOMINATE_PARTICIPANTS_URL,
  UPDATE_GLOBAL_SETTINGS,
  GET_SCHEDULE_DETAILS,
  GET_SCHEDULE_DETAILS_API,
  GET_COURSE_ACTIVITY,
  GET_COURSE_ACTIVITY_API,
  GET_SKILL_ASSESSMENT,
  GET_SKILL_ASSESSMENT_API,
  GET_SCHEDULED_COURSE,
  GET_SCHEDULED_COURSE_API,
  MAP_SCHEDULE_COURSE,
  MAP_SCHEDULE_COURSE_API,
  MAIL_COURSE,
  MAIL_COURSE_API,
  GET_NOMINATION_REQUEST,
  GET_NOMINATION_REQUEST_API,
  GET_REPLACEMENT_REQUEST,
  GET_REPLACEMENT_REQUEST_API,
  DOWNLOAD_CERTIFICATE,
  DOWNLOAD_COURSE_CERTIFICATE_API,
  UPDATE_WBT_COURSE_STATUS,
  UPDATE_WBT_COURSE_STATUS_API,
  GET_SKILL_ASSESSMENT_TRAINING_HISTORY,
  GET_ALL_PUBLISHED_COURSE_LIST,
} from "../../Api";
import apiRequest from "../../Api/connector";
import { STATUS_200 } from "../../Api/constants";
import {
  ErrorWhileDownloading,
  SomethingWentWrong,
} from "../../utils/Constant";
import { showToast } from "../../utils/helper/helper";
import {
  getFromLocalStorage,
  LOGGED_IN_USER,
} from "../../utils/helper/localStorageutils";
import Store from "../Store";

import { hideLoader, showLoader } from "./LoaderSlice";

interface CourseInterface {
  courseList: CourseListResponse;
  allPublishedCourseList: CourseListResponse;
  publishedCourseList: CourseListResponse;
  courseContent: Course;
  loading: boolean;
  error: string | null;
  courseContentByIds: CourseContentByIdsResponse;
  userSpecificCourseList: CourseListResponse;
  courseScheduleList: CourseScheduleData;
  trainerAvailability: TrainerAvailability;
  scheduledTrainers: TrainersResponse;
  nominees: NomineesData;
  eligibleList: EligibleUsersResponse;
  scheduledDetails: CourseDetailsResponse;
  courseActivity: AssessmentActivityResponse;
  skillDocuments: SkillDocumentResponse;
  skillDocumentsHistory: SkillDocumentResponse;
  scheduleCourse: ScheduledCoursesData;
  nominationList: NominationListResponse;
  replacementList: ReplacementListResponse;
}

const initialState: CourseInterface = {
  courseList: { course_list: [] },
  allPublishedCourseList: { course_list: [] },
  userSpecificCourseList: { course_list: [] },
  publishedCourseList: { course_list: [] },
  courseContent: null,
  loading: false,
  error: null,
  courseContentByIds: null,
  trainerAvailability: { trainers: [] },
  courseScheduleList: {
    total_ongoing_classes: 0,
    total_upcoming_classes: 0,
    total_completed_classes: 0,
    scheduled_courses: [],
  },
  scheduledTrainers: { trainers: [] },
  nominees: { nominees: [] },
  eligibleList: { eligible_users: [] },
  skillDocuments: {
    skill_document: [],
  },
  skillDocumentsHistory: {
    skill_document: [],
  },
  courseActivity: {
    assessments: [],
  },
  scheduledDetails: {
    course_materials: [],
    learning_outcome: [],
    course_detail: null,
  },
  scheduleCourse: null,
  nominationList: {
    total_request: 0,
    total_approved: 0,
    total_declined: 0,
    total_expired: 0,
    nominees: [],
  },
  replacementList: {
    replacement: [],
  },
};

export const downloadFile = (id: any, type: string, fileName: string) => {
  const loginData = getFromLocalStorage(LOGGED_IN_USER);
  const courseApiUrl = `${process.env.REACT_APP_COURSE_API_ENDPOINT}`;
  Store.dispatch(showLoader());
  fetch(
    `${courseApiUrl}/course?request_type=course-content&id=${id}&type=${type}`,
    {
      method: GET,
      headers: { "x-access-token": loginData.accessToken },
    }
  )
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${fileName.replaceAll(" ", "_")}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      Store.dispatch(hideLoader());
    })
    .catch(error => {
      Store.dispatch(hideLoader());

      console.error("Error downloading file:", error);
    });
};

export const downloadAssessmentReportFile = (id: any, fileName: string) => {
  const loginData = getFromLocalStorage(LOGGED_IN_USER);
  const assessmentApiUrl = `${process.env.REACT_APP_ASSESSMENT_API_ENDPOINT}`;
  Store.dispatch(showLoader());
  fetch(
    `${assessmentApiUrl}/assessment?request_type=report&schedule_id=${id}&type=scheduled_assessment`,
    {
      method: GET,
      headers: { "x-access-token": loginData.accessToken },
    }
  )
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${fileName.replaceAll(" ", "_")}.xlsx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      Store.dispatch(hideLoader());
    })
    .catch(error => {
      Store.dispatch(hideLoader());

      console.error("Error downloading file:", error);
    });
};

export const downloadCourseAttendeeFile = (id: any, fileName: string) => {
  const loginData = getFromLocalStorage(LOGGED_IN_USER);
  const courseApiUrl = `${process.env.REACT_APP_COURSE_API_ENDPOINT}`;
  Store.dispatch(showLoader());
  fetch(
    `${courseApiUrl}/course?request_type=report&course_schedule_id=${id}&type=attendee`,
    {
      method: GET,
      headers: { "x-access-token": loginData.accessToken },
    }
  )
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${fileName.replaceAll(" ", "_")}.xlsx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      Store.dispatch(hideLoader());
    })
    .catch(error => {
      Store.dispatch(hideLoader());

      console.error("Error downloading file:", error);
    });
};

export const downloadCourseReportFile = (
  id: any,
  type: any,
  fileName: string
) => {
  const loginData = getFromLocalStorage(LOGGED_IN_USER);
  const learnerApiUrl = `${process.env.REACT_APP_LEARNER_API_ENDPOINT}`;
  Store.dispatch(showLoader());

  fetch(
    `${learnerApiUrl}/learner?request_type=report&course_schedule_id=${id}&type=${type}`,
    {
      method: GET,
      headers: { "x-access-token": loginData.accessToken },
    }
  )
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${fileName.replaceAll(" ", "_")}.xlsx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      Store.dispatch(hideLoader());
    })
    .catch(error => {
      Store.dispatch(hideLoader());

      console.error("Error downloading file:", error);
    });
};

export const getCourseList = createAsyncThunk(
  GET_COURSE_LIST,
  async ({
    status,
    course_type,
    domain,
    page,
  }: {
    status: string[];
    course_type: string[];
    domain: string[] | null;
    page?: string;
  }) => {
    const response = await apiRequest(
      GET,
      GET_COURSE_LIST_API(
        status.toString(),
        course_type.toString(),
        domain ? domain.toString() : null,
        page
      )
    );
    return response.data;
  }
);

export const getAllPublishedCourseList = createAsyncThunk(
  GET_ALL_PUBLISHED_COURSE_LIST,
  async ({
    status,
    course_type,
    domain,
    page,
  }: {
    status: string[];
    course_type: string[];
    domain: string[] | null;
    page?: string;
  }) => {
    const response = await apiRequest(
      GET,
      GET_COURSE_LIST_API(
        status.toString(),
        course_type.toString(),
        domain ? domain.toString() : null,
        page
      )
    );
    return response.data;
  }
);

export const getUserSpecificCourseList = createAsyncThunk(
  GET_USER_SPECIFIC_COURSE_LIST,
  async ({
    status,
    course_type,
    user_id,
  }: {
    status: string[];
    course_type: string[];
    user_id: string;
  }) => {
    const response = await apiRequest(
      GET,
      GET_USER_SPECIFIC_COURSE_LIST_API(
        status.toString(),
        course_type.toString(),
        user_id?.toString()
      )
    );
    return response.data;
  }
);

export const downloadCourseStageDataApi = createAsyncThunk(
  DOWNLOAD_STAGE_DATA,
  async ({ id, type }: { id: string; type: string }) => {
    const response = await apiRequest(
      GET,
      DOWNLOAD_COURSE_STAGE_DATA_API(id, type)
    );
    return response;
  }
);

export const getCourseContent = createAsyncThunk(
  GET_COURSE_CONTENT,
  async ({
    id,
    course_version_id,
  }: {
    id: string;
    course_version_id?: string;
  }) => {
    const response = await apiRequest(
      GET,
      GET_COURSE_CONTENT_API(id, course_version_id)
    );
    return response.data;
  }
);

export const getPublishedCourseList = createAsyncThunk(
  GET_PUBLISHED_COURSE_LIST,
  async () => {
    const response = await apiRequest(GET, GET_PUBLISHED_COURSE_LIST_API());
    return response.data;
  }
);

export const getCourseScheduledList = createAsyncThunk(
  GET_COURSE_SCEDULED_LIST,
  async ({
    course_id,
    from_date,
    to_date,
    instructor_id,
    location_id,
    classroom_id,
    status,
  }: {
    course_id: string;
    from_date?: string;
    to_date?: string;
    instructor_id?: string;
    location_id?: string;
    classroom_id?: string;
    status?: string;
  }) => {
    const response = await apiRequest(
      GET,
      GET_COURSE_SCHEDULED_LIST_URL(
        course_id,
        from_date,
        to_date,
        instructor_id,
        location_id,
        classroom_id,
        status
      )
    );
    return response?.data;
  }
);

export const getTrainersAvailability = createAsyncThunk(
  GET_TRAINERS_AVAILABILITY,
  async ({
    trainerMap,
    schedule_id,
  }: {
    trainerMap: any;
    schedule_id?: any;
  }) => {
    const response = await apiRequest(
      POST,
      GET_TRAINERS_AVAILABILITY_URL(schedule_id),
      trainerMap
    );
    return response?.data;
  }
);

export const getScheduledTrainers = createAsyncThunk(
  GET_SCHEDULED_TRAINERS,
  async (id: any) => {
    const response = await apiRequest(GET, GET_SCHEDULED_TRAINERS_URL(id));
    return response?.data;
  }
);

export const getNomineesOfSchedule = createAsyncThunk(
  GET_NOMINEES_OF_SCHEDULE,
  async ({ schedule_id, type }: { schedule_id: string; type: string }) => {
    const response = await apiRequest(
      GET,
      GET_NOMINEES_OF_SCHEDULE_URL(schedule_id, type)
    );
    return response?.data;
  }
);

export const createCourseContent = createAsyncThunk(
  CREATE_COURSE_CONTENT,
  async ({ courseContent }: { courseContent: CourseDetailsPayload }) => {
    const response = await apiRequest(
      POST,
      CREATE_COURSE_CONTENT_API(),
      courseContent
    );
    return response;
  }
);

export const updateCourseContent = createAsyncThunk(
  UPDATE_COURSE_CONTENT,
  async ({ courseContent }: { courseContent: any }) => {
    const response = await apiRequest(
      PUT,
      UPDATE_COURSE_CONTENT_API(),
      courseContent
    );
    return response;
  }
);

export const addCommentToStages = createAsyncThunk(
  ADD_COMMENT,
  async ({ comment }: { comment: any }) => {
    const response = await apiRequest(PUT, ADD_COMMENT_URL(), comment);
    return response;
  }
);

export const updateCommentStatus = createAsyncThunk(
  UPDATE_COMMENT_STATUS,
  async ({ correspondingStatus }: { correspondingStatus: any }) => {
    const response = await apiRequest(
      POST,
      UPDATE_COMMENT_STATUS_URL(),
      correspondingStatus
    );
    return response;
  }
);

export const reviewCourse = createAsyncThunk(
  REVIEW_COURSE,
  async ({ courseContent }: { courseContent: any }) => {
    const response = await apiRequest(PUT, REVIEW_COURSE_API(), courseContent);
    return response;
  }
);

export const submitCourseDetailsByTrainer = createAsyncThunk(
  SUBMIT_COURSE_DETAILS_BY_TRAINER,
  async ({ courseContent }: { courseContent: any }) => {
    const response = await apiRequest(
      PUT,
      SUBMIT_COURSE_DETAILS_BY_TRAINER_URL(),
      courseContent
    );
    return response;
  }
);

export const getCourseContentByIds = createAsyncThunk(
  GET_COURSE_CONTENT_BY_IDS,
  async ({ id }: { id: number[] }) => {
    const response = await apiRequest(GET, GET_COURSE_CONTENT_BY_IDS_API(id));
    return response.data;
  }
);

export const assignSecondaryTrainer = createAsyncThunk(
  ASSIGN_SECONDARY_TRSINER,
  async ({ trainers }: { trainers: any }) => {
    const response = await apiRequest(
      PUT,
      ASSIGN_SECONDARY_TRAINERS(),
      trainers
    );
    return response;
  }
);

export const reviewTrainerOrNominee = createAsyncThunk(
  REVIEW_TRAINER_OR_NOMINEE,
  async ({ review }: { review: any }) => {
    const response = await apiRequest(
      PUT,
      REVIEW_TRAINER_OR_NOMINEE_URL(),
      review
    );
    return response;
  }
);

export const replaceTrainerOrNominee = createAsyncThunk(
  REPLACE_TRAINER_OR_NOMINEE,
  async ({ replace }: { replace: any }) => {
    const response = await apiRequest(
      PUT,
      REPLACE_TRAINER_OR_NOMINEE_URL(),
      replace
    );
    return response;
  }
);

export const getEligibleList = createAsyncThunk<
  EligibleUsersResponse,
  {
    scheduledId: string;
    filters: any;
    roleId?: string;
    employeeType?: string;
    sbuId?: string;
  }
>(
  GET_ELIGIBLE_LIST,
  async ({ scheduledId, filters, roleId, employeeType, sbuId }) => {
    const response = await apiRequest(
      GET,
      GET_ELIGIBLE_LIST_URL(scheduledId, filters, roleId, employeeType, sbuId)
    );
    return response.data;
  }
);

export const getScheduleDetails = createAsyncThunk(
  GET_SCHEDULE_DETAILS,
  async ({
    courseScheduleId,
    courseId,
  }: {
    courseScheduleId?: number | string;
    courseId?: number | string;
  }) => {
    const response = await apiRequest(
      GET,
      GET_SCHEDULE_DETAILS_API(courseScheduleId, courseId)
    );
    return response.data;
  }
);

export const getCourseActivity = createAsyncThunk(
  GET_COURSE_ACTIVITY,
  async ({
    courseScheduleId,
    courseId,
  }: {
    courseScheduleId?: number | string;
    courseId?: number | string;
  }) => {
    const response = await apiRequest(
      GET,
      GET_COURSE_ACTIVITY_API(courseScheduleId, courseId)
    );
    return response.data;
  }
);

export const getSkillAssessment = createAsyncThunk(
  GET_SKILL_ASSESSMENT,
  async ({
    courseScheduleId,
    courseId,
    userId,
  }: {
    courseScheduleId?: number | string;
    courseId?: number;
    userId?: string;
  }) => {
    const response = await apiRequest(
      GET,
      GET_SKILL_ASSESSMENT_API(courseScheduleId, courseId, userId)
    );
    return response.data;
  }
);

export const getSkillAssessmentTrainingHistory = createAsyncThunk(
  GET_SKILL_ASSESSMENT_TRAINING_HISTORY,
  async ({
    courseScheduleId,
    courseId,
    userId,
  }: {
    courseScheduleId?: number | string;
    courseId?: number;
    userId?: string;
  }) => {
    const response = await apiRequest(
      GET,
      GET_SKILL_ASSESSMENT_API(courseScheduleId, courseId, userId)
    );
    return response.data;
  }
);

export const getScheduleCourse = createAsyncThunk(
  GET_SCHEDULED_COURSE,
  async ({
    status,
    course_id,
    filterData,
  }: {
    status: string;
    course_id?: any;
    filterData?: any;
  }) => {
    const response = await apiRequest(
      GET,
      GET_SCHEDULED_COURSE_API(status, course_id, filterData)
    );
    return response.data;
  }
);

export const putEligibleList = createAsyncThunk(
  PUT_ELIGIBLE_LIST,
  async ({ id, review }: { id; review: any }) => {
    const response = await apiRequest(PUT, PUT_ELIGIBLE_LIST_URL(id), review);
    return response;
  }
);

export const nominatePrticipants = createAsyncThunk(
  NOMINATE_PARTICIPANTS,
  async ({ participants }: { participants: any }) => {
    const response = await apiRequest(
      POST,
      NOMINATE_PARTICIPANTS_URL(),
      participants
    );
    return response;
  }
);

export const mapScheduleCourse = createAsyncThunk(
  MAP_SCHEDULE_COURSE,
  async ({ courseContent }: { courseContent: CourseMapRequest }) => {
    const response = await apiRequest(
      POST,
      MAP_SCHEDULE_COURSE_API(),
      courseContent
    );
    return response;
  }
);

export const getGlobalSettings = createAsyncThunk(
  GET_GLOBAL_SETTINGS,
  async () => {
    const response = await apiRequest(GET, GET_GLOBAL_SETTINGS_URL());
    return response.data;
  }
);

export const updateGlobalSettings = createAsyncThunk(
  UPDATE_GLOBAL_SETTINGS,
  async ({ payload }: { payload: any }) => {
    const response = await apiRequest(POST, GET_GLOBAL_SETTINGS_URL(), payload);
    return response;
  }
);

export const mailCourse = createAsyncThunk(
  MAIL_COURSE,
  async ({ courseContent }: { courseContent: any }) => {
    const response = await apiRequest(POST, MAIL_COURSE_API(), courseContent);
    return response;
  }
);

export const getNominationList = createAsyncThunk(
  GET_NOMINATION_REQUEST,
  async ({
    type,
    course_id,
    filterData,
  }: {
    type: string;
    course_id?: any;
    filterData?: any;
  }) => {
    const response = await apiRequest(
      GET,
      GET_NOMINATION_REQUEST_API(type, course_id, filterData)
    );
    return response.data;
  }
);

export const getReplacementList = createAsyncThunk(
  GET_REPLACEMENT_REQUEST,
  async ({ schedule_id }: { schedule_id?: string }) => {
    const response = await apiRequest(
      GET,
      GET_REPLACEMENT_REQUEST_API(schedule_id)
    );
    return response.data;
  }
);

export const downloadCertificate = createAsyncThunk(
  DOWNLOAD_CERTIFICATE,
  async ({
    type,
    courseId,
    courseScheduleId,
    courseName,
    isParticipated,
    id,
  }: {
    type: string;
    courseId?: any;
    courseScheduleId?: any;
    courseName?: any;
    isParticipated?: any;
    id?: any;
  }) => {
    const loginData = getFromLocalStorage(LOGGED_IN_USER);
    const templateURL = DOWNLOAD_COURSE_CERTIFICATE_API(
      type,
      courseId,
      courseScheduleId,
      isParticipated,
      id
    );

    try {
      Store.dispatch(showLoader());

      const response = await fetch(templateURL, {
        method: "GET",
        headers: { "x-access-token": loginData.accessToken },
      });

      if (!response.ok) {
        throw new Error(ErrorWhileDownloading);
      }

      let fileName = courseName;

      if (courseId) {
        fileName += courseId || "";
      } else {
        fileName += courseScheduleId || "";
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(ErrorWhileDownloading, error);
    } finally {
      Store.dispatch(hideLoader());
    }
  }
);

export const updateCourseStatus = createAsyncThunk(
  UPDATE_WBT_COURSE_STATUS,
  async ({ courseStatus }: { courseStatus: any }) => {
    const response = await apiRequest(
      PUT,
      UPDATE_WBT_COURSE_STATUS_API(),
      courseStatus
    );
    return response;
  }
);

const CourseSlice = createSlice({
  name: "course",
  initialState,
  reducers: {
    setBasicInputFormValue(state, action) {
      state.loading = action.payload;
    },
    setCourseContentToNull(state) {
      state.courseContent = null;
    },
    setCourseListToNull(state) {
      state.courseList = initialState.courseList;
    },
    setCourseActivityDetailsToNull(state) {
      state.courseActivity = initialState.courseActivity;
    },
    setCourseSkillAssessmentDetailsToNull(state) {
      state.skillDocuments = initialState.skillDocuments;
    },
    setCourseScheduleListToInitial(state) {
      state.courseScheduleList = initialState.courseScheduleList;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(downloadCourseStageDataApi.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(downloadCourseStageDataApi.fulfilled, state => {
        state.loading = false;
      })
      .addCase(downloadCourseStageDataApi.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(reviewCourse.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(reviewCourse.fulfilled, (state, action) => {
        state.loading = false;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(reviewCourse.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(updateGlobalSettings.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateGlobalSettings.fulfilled, (state, action) => {
        state.loading = false;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(updateGlobalSettings.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(submitCourseDetailsByTrainer.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(submitCourseDetailsByTrainer.fulfilled, (state, action) => {
        state.loading = false;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(submitCourseDetailsByTrainer.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(assignSecondaryTrainer.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(assignSecondaryTrainer.fulfilled, (state, action) => {
        state.loading = false;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(assignSecondaryTrainer.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(reviewTrainerOrNominee.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(reviewTrainerOrNominee.fulfilled, (state, action) => {
        state.loading = false;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(reviewTrainerOrNominee.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(replaceTrainerOrNominee.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(replaceTrainerOrNominee.fulfilled, (state, action) => {
        state.loading = false;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(replaceTrainerOrNominee.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(addCommentToStages.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addCommentToStages.fulfilled, (state, action) => {
        state.loading = false;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(addCommentToStages.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(updateCommentStatus.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCommentStatus.fulfilled, state => {
        state.loading = false;
      })
      .addCase(updateCommentStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || SomethingWentWrong;
      });

    builder
      .addCase(createCourseContent.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createCourseContent.fulfilled, (state, action) => {
        state.loading = false;

        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(createCourseContent.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(putEligibleList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(putEligibleList.fulfilled, (state, action) => {
        state.loading = false;

        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(putEligibleList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(nominatePrticipants.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(nominatePrticipants.fulfilled, (state, action) => {
        state.loading = false;

        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(nominatePrticipants.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(updateCourseContent.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCourseContent.fulfilled, (state, action) => {
        state.loading = false;

        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(updateCourseContent.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;

          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getCourseList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getCourseList.fulfilled,
        (state, action: PayloadAction<CourseListResponse>) => {
          state.loading = false;
          state.courseList = action.payload;
        }
      )
      .addCase(getCourseList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getAllPublishedCourseList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getAllPublishedCourseList.fulfilled,
        (state, action: PayloadAction<CourseListResponse>) => {
          state.loading = false;
          state.allPublishedCourseList = action.payload;
        }
      )
      .addCase(getAllPublishedCourseList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getUserSpecificCourseList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getUserSpecificCourseList.fulfilled,
        (state, action: PayloadAction<CourseListResponse>) => {
          state.loading = false;
          state.userSpecificCourseList = action.payload;
        }
      )
      .addCase(getUserSpecificCourseList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getCourseContent.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getCourseContent.fulfilled,
        (state, action: PayloadAction<Course>) => {
          state.loading = false;
          state.courseContent = action.payload;
        }
      )
      .addCase(getCourseContent.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          state.loading = false;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getPublishedCourseList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getPublishedCourseList.fulfilled,
        (state, action: PayloadAction<CourseListResponse>) => {
          state.loading = false;
          state.publishedCourseList = action.payload;
        }
      )
      .addCase(getPublishedCourseList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getCourseScheduledList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCourseScheduledList.fulfilled, (state, action) => {
        state.loading = false;
        state.courseScheduleList = action.payload;
      })
      .addCase(getCourseScheduledList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getTrainersAvailability.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTrainersAvailability.fulfilled, (state, action) => {
        state.loading = false;
        state.trainerAvailability = action.payload;
      })
      .addCase(getTrainersAvailability.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getScheduledTrainers.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getScheduledTrainers.fulfilled, (state, action) => {
        state.loading = false;
        state.scheduledTrainers = action.payload;
      })
      .addCase(getScheduledTrainers.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getNomineesOfSchedule.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getNomineesOfSchedule.fulfilled, (state, action) => {
        state.loading = false;
        state.nominees = action.payload;
      })
      .addCase(getNomineesOfSchedule.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getCourseContentByIds.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getCourseContentByIds.fulfilled,
        (state, action: PayloadAction<CourseContentByIdsResponse>) => {
          state.loading = false;
          state.courseContentByIds = action.payload;
        }
      )
      .addCase(getCourseContentByIds.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getEligibleList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getEligibleList.fulfilled, (state, action) => {
        state.loading = false;
        state.eligibleList = action.payload;
      })
      .addCase(getEligibleList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getScheduleDetails.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getScheduleDetails.fulfilled,
        (state, action: PayloadAction<CourseDetailsResponse>) => {
          state.loading = false;
          state.scheduledDetails = action.payload;
        }
      )
      .addCase(getScheduleDetails.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getCourseActivity.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getCourseActivity.fulfilled,
        (state, action: PayloadAction<AssessmentActivityResponse>) => {
          state.loading = false;
          state.courseActivity = action.payload;
        }
      )
      .addCase(getCourseActivity.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getSkillAssessmentTrainingHistory.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getSkillAssessmentTrainingHistory.fulfilled,
        (state, action: PayloadAction<SkillDocumentResponse>) => {
          state.loading = false;
          state.skillDocumentsHistory = action.payload;
        }
      )
      .addCase(getSkillAssessmentTrainingHistory.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getSkillAssessment.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getSkillAssessment.fulfilled,
        (state, action: PayloadAction<SkillDocumentResponse>) => {
          state.loading = false;
          state.skillDocuments = action.payload;
        }
      )
      .addCase(getSkillAssessment.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getScheduleCourse.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getScheduleCourse.fulfilled,
        (state, action: PayloadAction<ScheduledCoursesData>) => {
          state.loading = false;
          state.scheduleCourse = action.payload;
        }
      )
      .addCase(getScheduleCourse.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(mapScheduleCourse.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(mapScheduleCourse.fulfilled, (state, action) => {
        state.loading = false;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(mapScheduleCourse.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(mailCourse.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(mailCourse.fulfilled, (state, action) => {
        state.loading = false;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(mailCourse.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getNominationList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getNominationList.fulfilled,
        (state, action: PayloadAction<NominationListResponse>) => {
          state.loading = false;
          state.nominationList = action.payload;
        }
      )
      .addCase(getNominationList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getReplacementList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getReplacementList.fulfilled,
        (state, action: PayloadAction<ReplacementListResponse>) => {
          state.loading = false;
          state.replacementList = action.payload;
        }
      )
      .addCase(getReplacementList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(updateCourseStatus.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCourseStatus.fulfilled, (state, action) => {
        state.loading = false;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(updateCourseStatus.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
  },
});

export const {
  setBasicInputFormValue,
  setCourseScheduleListToInitial,
  setCourseContentToNull,
  setCourseListToNull,
  setCourseActivityDetailsToNull,
  setCourseSkillAssessmentDetailsToNull,
} = CourseSlice.actions;
export default CourseSlice.reducer;
