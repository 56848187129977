import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

import { Btn, MUIIcons, H4, P } from "../../../../AbstractElements";
import {
  ALL_COURSE_FILTER_STATUS,
  COURSE_CREATION_USERS_STATUS,
  EXPERTISE_LEVEL_STATUS,
  rmTabsStatusMap,
  SME_ASSIGN_VALUE,
  STATUS_200,
  TRAINER_ASSIGN_VALUE,
} from "../../../../Api/constants";
import { convertCourseDetailsPayload } from "../../../../Api/parser/course";
import Divider from "../../../../CommonElements/Divider";
import FilterSliderModal from "../../../../CommonElements/FilterSliderModal";
import FixedFooter from "../../../../CommonElements/FixedFooter";
import LabelTooltip from "../../../../CommonElements/LabelTooltip";
import CommonModal from "../../../../CommonElements/Modal";
import MultiSelectDropdown from "../../../../CommonElements/MultiSelectDropdown";
import SearchDropDownPaginator from "../../../../CommonElements/SearchDropDownPaginator";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import {
  assignUserToCourse,
  assignUserToExistingCourse,
} from "../../../../ReduxToolkit/Reducers/AssignSlice";
import { approveCourseRequest } from "../../../../ReduxToolkit/Reducers/CourseRequestSlice";
import {
  createCourseContent,
  getCourseContent,
  getCourseList,
  setCourseContentToNull,
  updateCourseContent,
} from "../../../../ReduxToolkit/Reducers/CourseSlice";
import { setBasicInputFormValue } from "../../../../ReduxToolkit/Reducers/CourseWizardSlice";
import { getDomainList } from "../../../../ReduxToolkit/Reducers/MasterSlice";
import { getUsersList } from "../../../../ReduxToolkit/Reducers/UserSlice";
import {
  AddBasicDetails,
  AddComment,
  AssignSME,
  CourseOwner,
  ClickingManagerCTA,
  Course,
  CourseName,
  CourseType,
  CreateCourse,
  Enter,
  ModifyCourse,
  ModifyExistingCourse,
  Objective,
  RejectReason,
  Submit,
  Select,
  CourseNameRequired,
  CourseTypeRequired,
  CourseOwnerNeeded,
  ObjectiveRequired,
  PleaseProvideRequiredInformation,
  Domain,
  ModifyCourseToolTipMessage,
  CourseNameToolTipMessage,
  CourseTypeToolTipMessage,
  TrainerToolTipMessage,
  SMEToolTipMessage,
  DescriptionToolTipMessage,
} from "../../../../utils/Constant";
import {
  courseFilterDropdownList,
  courseTypeOptions,
  customStyles,
  scrollToTop,
  showToast,
} from "../../../../utils/helper/helper";
import useIsMobile from "../../../../utils/helper/responsive";

const CreateBasicCourseDetails = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { usersList } = useAppSelector(state => state.user);
  const { domainList } = useAppSelector(state => state.master);
  const [modifyCourse, setModifyCourse] = useState(false);
  const { basicInputFormValue } = useAppSelector(state => state.courseWizard);
  const location = useLocation();
  const { courseList, courseContent } = useAppSelector(state => state.course);
  const { courseRequestId, edit } = location.state || "";
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [trainerList, setTrainerList] = useState([]);
  const [preSelectedTrainers, setPreSelectedTrainers] = useState([]);
  const [selectedTrainerList, setSelectedTrainerList] = useState([]);
  const [smeList, setSMEList] = useState([]);
  const [selectedCourseId, setSelectedCourseId] = useState(null);
  const [courseListData, setCourseListData] = useState([]);
  const [domainListData, setDomainListData] = useState([]);
  const [errors, setErrors] = useState({
    course_name: "",
    course_type: "",
    assign_trainer: "",
    assign_sme: "",
    objective: "",
  });
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});

  useEffect(() => {
    dispatch(getDomainList());
    dispatch(getUsersList({ role: COURSE_CREATION_USERS_STATUS }));
    dispatch(
      getCourseList({
        status: EXPERTISE_LEVEL_STATUS,
        course_type: ALL_COURSE_FILTER_STATUS,
        domain: null,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    const domains = [];
    domainList?.domain?.forEach(element => {
      domains.push({
        name: element.id.toString(),
        value: element.domain_name,
        label: element.domain_name,
      });
    });
    setDomainListData(domains);
  }, [domainList]);

  useEffect(() => {
    if (selectedCourseId) {
      dispatch(getCourseContent({ id: selectedCourseId }));
    }
  }, [dispatch, selectedCourseId]);

  useEffect(() => {
    const publishedCourse = [];
    publishedCourse.push({ label: "Select", value: "" });
    if (courseList) {
      courseList?.course_list?.forEach(item => {
        publishedCourse.push({ label: item?.course_name, value: item?.id });
      });
    }
    setCourseListData(publishedCourse);
  }, [courseList]);

  useEffect(() => {
    if ((courseContent && edit) || (courseContent && modifyCourse)) {
      const trainerIds: number[] = [];
      let smeIds: string | undefined;

      courseContent?.course_assignees?.forEach(
        ({ assignment_type, user_id }) => {
          if (assignment_type === "trainer") {
            trainerIds.push(user_id);
          } else if (assignment_type === "sme") {
            smeIds = user_id?.toString();
          }
        }
      );

      const uniqueTrainerIds = Array.from(new Set(trainerIds));
      const selectedTrainers = usersList?.users?.filter(({ id }) =>
        uniqueTrainerIds.includes(id)
      );

      const formattedTrainers =
        selectedTrainers?.map(({ user_name, id, employee_code, email }) => ({
          value: `${employee_code || "-"} - ${user_name} - ${email || "-"}`,
          name: id.toString(),
          label: `${employee_code || "-"} - ${user_name} - ${email || "-"}`,
        })) || [];
      setSelectedTrainerList(selectedTrainers);
      setPreSelectedTrainers(formattedTrainers);
      const editObj = {
        course_name: courseContent?.course_name,
        course_type: courseContent?.course_type,
        assign_trainer: trainerIds,
        assign_sme: smeIds,
        objective: courseContent?.objective,
      };

      dispatch(setBasicInputFormValue(editObj));
    }
    if (edit && !edit) setPreSelectedTrainers([]);
  }, [dispatch, edit, courseContent, modifyCourse]);

  useEffect(() => {
    const trainers = [];
    const smes = [];
    smes.push({ value: "", label: "Select" });
    usersList?.users?.forEach(user => {
      if (user?.role?.includes("it") || user?.role?.includes("et")) {
        const userObj = {
          name: user.id.toString(),
          value: `${user?.employee_code || "-"} - ${user?.user_name} - ${user?.email || "-"}`,
          label: `${user?.employee_code || "-"} - ${user?.user_name} - ${user?.email || "-"}`,
        };
        trainers.push(userObj);
      }
      if (user?.role?.includes(SME_ASSIGN_VALUE)) {
        const userObj = {
          value: user.id,
          label: `${user?.employee_code || "-"} - ${user?.user_name} - ${user?.email || "-"}`,
        };
        smes.push(userObj);
      }
    });
    setTrainerList(trainers);
    setSMEList(smes);
  }, [usersList]);

  const handleCheckboxChange = e => {
    if (!e.target.checked) {
      dispatch(setCourseContentToNull());
      setSelectedCourseId(null);
      dispatch(
        setBasicInputFormValue({
          course_name: "",
          course_type: "",
          assign_trainer: [],
          assign_sme: "",
          objective: "",
        })
      );
      setPreSelectedTrainers([]);
    }
    setModifyCourse(e.target.checked);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const getUserData = event => {
    const name = event.target.name;
    const value = event.target.value;
    dispatch(setBasicInputFormValue({ ...basicInputFormValue, [name]: value }));
    setErrors({ ...errors, [name]: "" });
  };

  const validateForm = () => {
    const newErrors = {
      course_name: "",
      course_type: "",
      assign_trainer: "",
      assign_sme: "",
      objective: "",
    };
    let isError = false;
    if (!basicInputFormValue.course_name) {
      newErrors.course_name = CourseNameRequired;
      isError = true;
    }
    if (!basicInputFormValue.course_type) {
      newErrors.course_type = CourseTypeRequired;
      isError = true;
    }
    if (!selectedTrainerList.length || selectedTrainerList.length === 0) {
      newErrors.assign_trainer = CourseOwnerNeeded;
      isError = true;
    }

    if (!basicInputFormValue.objective) {
      newErrors.objective = ObjectiveRequired;
      isError = true;
    }
    setErrors(newErrors);
    if (isError) {
      showToast(PleaseProvideRequiredInformation, "error");
      scrollToTop();
    }
    return isError;
  };

  const handleApproveReview = async () => {
    if (validateForm()) {
      return;
    }

    if (!edit) {
      const reasonObj: ApproveCourseRequest = {
        course_request_id: courseRequestId,
        status: rmTabsStatusMap["approved"] as "rejected" | "approved",
        reason: "",
      };
      const wrapperObj = {
        approveCourseData: reasonObj,
      };
      if (courseRequestId) await dispatch(approveCourseRequest(wrapperObj));
    }
    const parser = convertCourseDetailsPayload(
      basicInputFormValue,
      courseContent
    );

    if (!courseRequestId || courseRequestId === "") {
      if (!edit) parser.course_request_id = null;
    } else {
      parser.course_request_id = courseRequestId;
    }

    let createAction = null;
    if (!edit || modifyCourse) {
      if (modifyCourse) {
        parser.is_modify_course = true;
        parser.course_request_id = courseRequestId || null;
        parser.parent_course_id = courseContent?.id?.toString();
      }
      createAction = await dispatch(
        createCourseContent({ courseContent: parser })
      );
    } else {
      parser.id = courseContent?.id?.toString();
      createAction = await dispatch(
        updateCourseContent({ courseContent: parser })
      );
    }

    if (createAction?.payload?.status_code === STATUS_200) {
      const assignUserData = [];
      selectedTrainerList?.forEach((trainer: any) => {
        assignUserData.push({
          user_id: parseInt(trainer.id) || parseInt(trainer),
          assignment_type: TRAINER_ASSIGN_VALUE,
          user_priority: "primary",
        });
      });
      if (
        basicInputFormValue.assign_sme &&
        basicInputFormValue.assign_sme !== ""
      )
        assignUserData.push({
          user_id: parseInt(basicInputFormValue.assign_sme),
          assignment_type: SME_ASSIGN_VALUE,
          user_priority: "primary",
        });

      const postObj = {
        course_id: createAction?.payload?.data?.id,
        assignments: assignUserData,
      };

      if (edit) {
        await dispatch(assignUserToExistingCourse(postObj));
      } else {
        await dispatch(assignUserToCourse(postObj));
      }
      return navigate(
        `${process.env.PUBLIC_URL}/course-management/course-list`
      );
    }
  };

  const handleDone = async (values: { [key: string]: string[] }) => {
    clearForm();
    setSelectedCourseId(null);
    const { course_type = ALL_COURSE_FILTER_STATUS, domain = [] } = values;
    await dispatch(
      getCourseList({
        status: EXPERTISE_LEVEL_STATUS,
        course_type:
          course_type.length > 0 ? course_type : ALL_COURSE_FILTER_STATUS,
        domain: domain.length > 0 ? domain : null,
      })
    );
  };

  const clearForm = () => {
    setSelectedCourseId(null);
    dispatch(setCourseContentToNull());
    dispatch(
      setBasicInputFormValue({
        course_name: "",
        course_type: "",
        assign_trainer: [],
        assign_sme: "",
        objective: "",
      })
    );
    setPreSelectedTrainers([]);
  };

  const handleClear = async () => {
    clearForm();
    setSelectedCourseId(null);
    await dispatch(
      getCourseList({
        status: EXPERTISE_LEVEL_STATUS,
        course_type: ALL_COURSE_FILTER_STATUS,
        domain: null,
      })
    );
    setSelectedFilterValues({});
    setDefaultFilterValues({});
  };

  return (
    <div className="page-body">
      <CommonModal
        backdrop="static"
        size="lg"
        isOpen={isModalOpen}
        toggle={toggleModal}
        sizeTitle={RejectReason}
        showFooter={true}
        primaryBtnText={Submit}
        onPrimaryBtnClick={() => {
          toggleModal();
        }}
      >
        <FormGroup>
          <Label check>
            {AddComment}
            <span className="txt-danger">*</span>
          </Label>
          <Input
            rows={4}
            name="objective"
            type="textarea"
            placeholder="Enter Description"
          />
        </FormGroup>
      </CommonModal>
      <Card className="mt-4">
        <CardHeader>
          <H4>{AddBasicDetails}</H4>
        </CardHeader>
        <CardBody>
          <Form
            className="stepper-one g-3 needs-validation custom-input"
            noValidate
          >
            {!edit && (
              <div>
                <div className="form-check text-dark">
                  <Input
                    onChange={handleCheckboxChange}
                    name=""
                    className="primary-checkbox"
                    id="flexCheckDefault"
                    type="checkbox"
                  />
                  <Label className="text-dark" htmlFor="flexCheckDefault" check>
                    {ModifyExistingCourse}
                  </Label>
                </div>
              </div>
            )}
            {modifyCourse && (
              <>
                <div className="d-flex gap-3">
                  <Col sm="12" lg="6">
                    <FormGroup>
                      <LabelTooltip
                        label={Course}
                        tooltipText={ModifyCourseToolTipMessage}
                        important
                      />
                      <ReactSelect
                        name="course"
                        className="text-gray"
                        onChange={selectedOption => {
                          setSelectedCourseId(
                            selectedOption ? selectedOption.value : ""
                          );
                        }}
                        value={
                          courseListData?.find(
                            option => option.value === selectedCourseId
                          ) || null
                        }
                        options={courseListData?.map(option => ({
                          value: option.value,
                          label: option.label,
                        }))}
                        isSearchable
                        isClearable={false}
                        styles={customStyles}
                        components={{
                          MenuList: props => (
                            <SearchDropDownPaginator
                              {...props}
                              maxHeight={250}
                            />
                          ),
                        }}
                        filterOption={(option, inputValue) =>
                          option?.label
                            ?.toLowerCase()
                            ?.includes(inputValue?.toLowerCase())
                        }
                      />
                    </FormGroup>
                  </Col>
                  <div className="filter-margin">
                    <FilterSliderModal
                      dropdowns={[
                        {
                          label: CourseType,
                          key: "course_type",
                          tooltipText: `${Select} ${CourseType}`,
                          options: courseFilterDropdownList,
                          isMultiSelect: true,
                        },
                        {
                          label: Domain,
                          key: "domain",
                          tooltipText: `${Select} ${Domain}`,
                          options: domainListData,
                          isMultiSelect: true,
                        },
                      ]}
                      selectedFilterValues={selectedFilterValues}
                      defaultFilterValues={defaultFilterValues}
                      setSelectedFilterValues={setSelectedFilterValues}
                      setDefaultFilterValues={setDefaultFilterValues}
                      onDone={handleDone}
                      onClear={handleClear}
                    />
                  </div>
                </div>
                <Divider />
              </>
            )}
            <Row className="mt-4">
              <Col sm="12" lg="6">
                <FormGroup>
                  <LabelTooltip
                    label={CourseName}
                    tooltipText={CourseNameToolTipMessage}
                    important
                  />
                  <Input
                    onChange={getUserData}
                    value={basicInputFormValue.course_name}
                    name="course_name"
                    type="text"
                    placeholder={`${Enter} ${CourseName}`}
                    maxLength={100}
                    invalid={!!errors.course_name}
                  />
                  {errors.course_name && (
                    <div className="invalid-feedback">{errors.course_name}</div>
                  )}
                </FormGroup>
              </Col>
              <Col sm="12" lg="6">
                <FormGroup>
                  <LabelTooltip
                    label={CourseType}
                    tooltipText={CourseTypeToolTipMessage}
                    important
                  />
                  <Input
                    disabled={edit || modifyCourse}
                    onChange={getUserData}
                    value={basicInputFormValue.course_type}
                    name="course_type"
                    type="select"
                    invalid={!!errors.course_type}
                  >
                    {courseTypeOptions?.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Input>
                  {errors.course_type && (
                    <div className="invalid-feedback">{errors.course_type}</div>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm="12" lg="6">
                <FormGroup>
                  <LabelTooltip
                    label={CourseOwner}
                    tooltipText={TrainerToolTipMessage}
                    important
                  />
                  <MultiSelectDropdown
                    onChange={values => {
                      setSelectedTrainerList(values);
                      if (values.length) {
                        setErrors({ ...errors, assign_trainer: "" });
                      }
                    }}
                    options={trainerList}
                    placeholder={`${Select} ${CourseOwner}`}
                    defaultSelected={preSelectedTrainers}
                  />
                  {errors.assign_trainer && (
                    <div className="mt-1 text-danger">
                      {errors.assign_trainer}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col sm="12" lg="6">
                <FormGroup>
                  <LabelTooltip
                    label={AssignSME}
                    tooltipText={SMEToolTipMessage}
                    important={false}
                  />
                  <ReactSelect
                    name="assign_sme"
                    className="text-gray"
                    onChange={selectedOption => {
                      getUserData({
                        target: {
                          name: "assign_sme",
                          value: selectedOption ? selectedOption.value : "",
                        },
                      });
                    }}
                    value={
                      smeList?.find(
                        option =>
                          option.value?.toString() ===
                          basicInputFormValue.assign_sme?.toString()
                      ) || null
                    }
                    options={smeList?.map(option => ({
                      value: option.value,
                      label: option.label,
                    }))}
                    isSearchable
                    isClearable={false}
                    styles={customStyles}
                    components={{
                      MenuList: props => (
                        <SearchDropDownPaginator {...props} maxHeight={200} />
                      ),
                    }}
                    filterOption={(option, inputValue) =>
                      option?.label
                        ?.toLowerCase()
                        ?.includes(inputValue?.toLowerCase())
                    }
                  />

                  {errors.assign_sme && (
                    <div className="mt-1 text-danger">{errors.assign_sme}</div>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Col className="mt-3">
              <FormGroup>
                <LabelTooltip
                  label={Objective}
                  tooltipText={DescriptionToolTipMessage}
                  important
                />
                <Input
                  rows={4}
                  onChange={getUserData}
                  value={basicInputFormValue.objective}
                  name="objective"
                  type="textarea"
                  placeholder={`${Enter} ${Objective}`}
                  maxLength={500}
                  invalid={!!errors.objective}
                />
                {errors.objective && (
                  <div className="invalid-feedback">{errors.objective}</div>
                )}
              </FormGroup>
            </Col>
          </Form>
        </CardBody>
        <FixedFooter>
          <Row className="mt-2">
            <Col xs="auto" className="d-flex gap-2">
              <MUIIcons size={24} className="mt-1" iconName="InfoOutlined" />
              <P className="mt-2 text-muted">
                {ClickingManagerCTA(
                  modifyCourse || edit ? ModifyCourse : CreateCourse
                )}
              </P>
            </Col>
            <Col xs="auto" className={isMobile ? "mb-5" : ""}>
              <Btn onClick={handleApproveReview} color="primary">
                {modifyCourse || edit ? ModifyCourse : CreateCourse}
              </Btn>
            </Col>
          </Row>
        </FixedFooter>
      </Card>
    </div>
  );
};

export default CreateBasicCourseDetails;
