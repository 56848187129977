import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, Row } from "reactstrap";

import { MUIIcons } from "../../../AbstractElements";
import { MultiselectOption, SgaRole } from "../../../Api/entities/SgaEntity";
import FilterSliderModal from "../../../CommonElements/FilterSliderModal";
import SearchBar from "../../../CommonElements/SearchBar";
import TextTooltip from "../../../CommonElements/TextTooltip/inxex";
import NoData from "../../../container/NoData";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import {
  getGradeList,
  getProficiencyList,
  getRolesList,
} from "../../../ReduxToolkit/Reducers/MasterSlice";
import { getSgaList } from "../../../ReduxToolkit/Reducers/SgaSlice";
import {
  Duration,
  EffectiveFrom,
  Grade,
  Level,
  NoOfCourse,
  SelfAssessment,
  ReAttemptPolicy,
  Yes,
  No,
  Select,
  Grades,
  NoDataText,
  AutoAssign,
  Role,
  Action,
} from "../../../utils/Constant";
import {
  formatCustomDate,
  frequencyMapping,
  selefAssesmentOptions,
} from "../../../utils/helper/helper";

import "./style.scss";

const getSelfAssessmentName = level => {
  if (level.id && level.assessment_id) {
    return Yes;
  } else if (level.id && !level.assessment_id) {
    return No;
  }
  return "-";
};

const getAutoAssignName = level => {
  if (level.is_mappable !== null && level.is_mappable) {
    return Yes;
  } else if (level.is_mappable !== null && !level.is_mappable) {
    return No;
  }
  return "-";
};

const SgaList: React.FC = () => {
  const [listData, setListData] = useState<SgaRole[]>([]);
  const [noDataFound, setNoDataFound] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredData, setFilteredData] = useState<SgaRole[]>([]);
  const [proficiencyListData, setProficiencyListData] = useState(null);
  const { proficiencyList } = useAppSelector(state => state.master);
  const { gradeList } = useAppSelector(state => state.master);
  const [gradeOptions, setGradeOptions] = useState<MultiselectOption[]>([]);
  const [initialData, setInitialData] = useState<SgaRole[]>([]);
  const [clearData, setClearData] = useState(false);
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    await dispatch(getGradeList());
    await dispatch(getProficiencyList());
    await fetchData();
  };

  useEffect(() => {
    const levels = [];
    proficiencyList?.proficiency?.forEach(sites => {
      const userObj = {
        label: sites?.level_name,
        value: sites?.level_name,
        name: sites?.id.toString(),
      };
      levels.push(userObj);
    });
    setProficiencyListData(levels);
  }, [proficiencyList]);

  useEffect(() => {
    if (searchTerm) {
      const filtered = listData.filter(role =>
        role.label.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filtered);
      setNoDataFound(filtered?.length === 0);
    } else {
      setNoDataFound(listData?.length === 0);
      setFilteredData(listData);
    }
  }, [searchTerm, listData]);

  useEffect(() => {
    const data = gradeList?.grades?.map(grade => ({
      label: grade.name,
      value: grade.name,
      name: grade?.id.toString(),
    }));
    setGradeOptions(data);
  }, [gradeList]);

  const fetchData = async (): Promise<void> => {
    try {
      const [
        rolesResponse,
        sgaResponse,
        proficiencyResponse,
        gradListResponse,
      ] = await Promise.all([
        dispatch(
          getRolesList({
            getMappedRole: "1",
          })
        ).unwrap(),
        dispatch(getSgaList()).unwrap(),
        dispatch(getProficiencyList()).unwrap(),
        dispatch(getGradeList()).unwrap(),
      ]);

      if (!rolesResponse || !proficiencyResponse) {
        setNoDataFound(true);
        return;
      }

      const combinedData: SgaRole[] = rolesResponse?.job_role?.map(
        (role: any) => ({
          role_id: role.id,
          label: role.name,
          levels: proficiencyResponse?.proficiency.map((level: any) => ({
            id: null,
            level_name: level.level_name,
            level_id: level.id,
            policy_id: "",
            policy_name: "",
            courses_count: null,
            grades: [],
            duration_count: null,
            effective_from: "",
            duration_type: "",
            assessment_id: null,
            is_mappable: null,
          })),
        })
      );

      const modifiedData = getUpdatedData(
        sgaResponse.data,
        combinedData,
        gradListResponse
      );
      setInitialData(modifiedData);
      setListData(modifiedData);
    } catch (error) {
      setNoDataFound(true);
    }
  };

  const handleEdit = row => {
    navigate("/master-data/map-sga", {
      state: {
        title: row.label,
        role_id: row.role_id,
        level_id: row.level_id,
        id: row.id,
        level: row.level_name,
      },
    });
  };

  const getUpdatedData = (
    source: any[],
    target: SgaRole[],
    gradListResponse
  ): SgaRole[] => {
    const sourceRoleMap = {};
    source.forEach(sRole => {
      if (!sourceRoleMap[sRole.role_id]) {
        sourceRoleMap[sRole.role_id] = [];
      }
      sourceRoleMap[sRole.role_id].push(...sRole.levels);
    });

    return target.map(targetRole => {
      const sourceLevels = sourceRoleMap[targetRole.role_id] || [];

      return {
        ...targetRole,
        levels: targetRole.levels.map(targetLevel => {
          const sourceLevel = sourceLevels.find(
            sLevel => sLevel.level_id === targetLevel.level_id
          );
          const getValue = (sourceValue, targetValue) =>
            sourceValue ?? targetValue;

          if (sourceLevel) {
            const matchedGrades = gradListResponse?.grades?.filter(item =>
              sourceLevel?.grades?.includes(item.id)
            );
            const {
              policy_id,
              policy_name,
              courses_count,
              duration_count,
              effective_from,
              duration_type,
              id,
              assessment_id,
              is_mappable,
            } = sourceLevel;
            const {
              grades: targetGrades,
              policy_id: targetPolicyId,
              policy_name: targetPolicyName,
              courses_count: targetCoursesCount,
              duration_count: targetDurationCount,
              effective_from: targetEffectiveFrom,
              duration_type: targetDurationType,
              id: targetId,
              assessment_id: targetAssessmentId,
              is_mappable: isMappable,
            } = targetLevel;

            return {
              ...targetLevel,
              policy_id: getValue(policy_id, targetPolicyId),
              policy_name: getValue(policy_name, targetPolicyName),
              courses_count: getValue(courses_count, targetCoursesCount),
              grades: matchedGrades?.length ? matchedGrades : targetGrades,
              duration_count: getValue(duration_count, targetDurationCount),
              effective_from: getValue(effective_from, targetEffectiveFrom),
              duration_type: getValue(duration_type, targetDurationType),
              id: getValue(id, targetId),
              assessment_id: getValue(assessment_id, targetAssessmentId),
              is_mappable: getValue(is_mappable, isMappable),
            };
          }
          return targetLevel;
        }),
      };
    });
  };

  const handleClear = async () => {
    setClearData(true);
    setSelectedFilterValues({});
    setDefaultFilterValues({});
    setListData(initialData);
    setNoDataFound(false);
  };

  const handleDone = async (values: { [key: string]: string[] }) => {
    setClearData(true);
    let filteredList = initialData;

    if (values.level?.length) {
      filteredList = filteredList
        ?.map(role => ({
          ...role,
          levels: role?.levels?.filter(level =>
            values?.level.includes(level?.level_id?.toString())
          ),
        }))
        .filter(role => role?.levels?.length > 0);
    }

    if (values.self_assessment) {
      const isSelfAssessmentYes = values?.self_assessment.includes("yes");
      filteredList = filteredList
        .map(role => ({
          ...role,
          levels: role?.levels?.filter(level =>
            isSelfAssessmentYes ? level?.assessment_id : !level?.assessment_id
          ),
        }))
        .filter(role => role?.levels?.length > 0);
    }

    if (values.grades?.length) {
      filteredList = filteredList
        ?.map(role => ({
          ...role,
          levels: role?.levels?.filter(level =>
            level?.grades?.some(grade =>
              values?.grades?.includes(grade?.id?.toString())
            )
          ),
        }))
        .filter(role => role?.levels?.length > 0);
    }

    setListData(filteredList);
    setNoDataFound(filteredList.length === 0);
  };

  const transformFilteredData = (data: any[]) => {
    return data.flatMap(role =>
      role.levels.map(level => ({
        role_id: role.role_id,
        label: role.label,
        ...level,
      }))
    );
  };

  const createTooltipCell = (content: string | string[], uniqueId: string) => (
    <TextTooltip tooltipText={content || "-"} toolTipUniqueId={uniqueId}>
      <div className="text-ellips-parent">
        <div className="text-ellips">{content || "-"}</div>
      </div>
    </TextTooltip>
  );

  const sgaListColumns = [
    {
      name: Role,
      selector: row => row?.label || "-",
      cell: (row: any) => createTooltipCell(row?.label, `role-name-${row.id}`),
      minWidth: "8rem",
      sortable: true,
    },
    {
      name: Level,
      selector: row => row?.level_name || "-",
      minWidth: "12rem",
      sortable: true,
    },
    {
      name: SelfAssessment,
      selector: row => getSelfAssessmentName(row),
      sortable: true,
      center: true,
      minWidth: "10rem",
    },
    {
      name: AutoAssign,
      selector: row => getAutoAssignName(row),
      minWidth: "8rem",
      sortable: true,
      center: true,
    },

    {
      name: NoOfCourse,
      selector: row =>
        row?.courses_count ? row?.courses_count?.toString() : "-",
      center: true,
      minWidth: "10rem",
    },
    {
      name: Grade,
      selector: row =>
        row?.grades?.length > 0
          ? row.grades.map(item => item.name).join(", ")
          : "-",
      cell: (row: any) =>
        createTooltipCell(
          row.grades.map(item => item.name).join(", "),
          `grade-name-${row.id}`
        ),
    },
    {
      name: Duration,
      selector: row =>
        row?.duration_count
          ? `${row?.duration_count?.toString()} ${frequencyMapping[row.duration_type]}`
          : "-",
    },
    {
      name: EffectiveFrom,
      selector: row =>
        row.effective_from ? formatCustomDate(row.effective_from) : "-",
      minWidth: "8rem",
    },
    {
      name: ReAttemptPolicy,
      selector: row => (row.policy_name ? row.policy_name : " -"),
      cell: (row: any) =>
        createTooltipCell(row.policy_name, `policy-name-${row.id}`),
      minWidth: "12rem",
    },
    {
      name: Action,
      sortable: false,
      center: true,
      cell: row => (
        <MUIIcons
          className="primary-icon-color pointer"
          iconName="EditOutlined"
          size={20}
          onClick={() => handleEdit(row)}
        />
      ),
    },
  ];

  return (
    <div className="page-body page-body-margin">
      <Card>
        <CardBody>
          <div
            className={"d-flex flex-column flex-md-row justify-content-between"}
          >
            <div className="d-flex justify-content-end align-items-center mt-sm-0 gap-3 ms-auto">
              <SearchBar
                className="mb-2"
                showSearch
                onSearch={searchText => {
                  setClearData(false);
                  setSearchTerm(searchText);
                }}
                clearOnProp={clearData}
              />
              <div className="mt-1">
                <FilterSliderModal
                  dropdowns={[
                    {
                      label: Level,
                      key: "level",
                      tooltipText: `${Select} ${Level}`,
                      options: proficiencyListData,
                      isMultiSelect: true,
                    },
                    {
                      label: SelfAssessment,
                      key: "self_assessment",
                      tooltipText: `${Select} ${SelfAssessment}?`,
                      options: selefAssesmentOptions,
                      isMultiSelect: false,
                    },
                    {
                      label: Grades,
                      key: "grades",
                      tooltipText: `${Select} ${Grades}`,
                      options: gradeOptions,
                      isMultiSelect: true,
                    },
                  ]}
                  selectedFilterValues={selectedFilterValues}
                  defaultFilterValues={defaultFilterValues}
                  setSelectedFilterValues={setSelectedFilterValues}
                  setDefaultFilterValues={setDefaultFilterValues}
                  onDone={handleDone}
                  onClear={handleClear}
                />
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      <DataTable
        className="sga-list-table"
        columns={sgaListColumns}
        data={transformFilteredData(filteredData)}
        pagination
        fixedHeader
        striped
      />
      <Row className="mt-5">
        {noDataFound && (
          <NoData
            svg={"empty-folder-icon"}
            title={NoDataText}
            showCard={false}
          />
        )}
      </Row>
    </div>
  );
};

export default SgaList;
