import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

import { H3 } from "../../AbstractElements";
import { ALL_USERS_STATUS } from "../../Api/constants";
import FilterSliderModal from "../../CommonElements/FilterSliderModal";
import CommonModal from "../../CommonElements/Modal";
import SearchBar from "../../CommonElements/SearchBar";
import TotalCountCard from "../../container/TotalCountCard";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks";
import {
  getCourseScheduledList,
  reviewTrainerOrNominee,
} from "../../ReduxToolkit/Reducers/CourseSlice";
import { getClassRoomList } from "../../ReduxToolkit/Reducers/ManageMasterDataSlice";
import { getLocationList } from "../../ReduxToolkit/Reducers/MasterSlice";
import { getUsersList } from "../../ReduxToolkit/Reducers/UserSlice";
import {
  Approve,
  ApproveCourseSchedule,
  ClassRoom,
  Completed,
  Confirm,
  EndDate,
  Instructor,
  Locations,
  OngoingClass,
  Reject,
  RejectCourseSchedule,
  ScheduleList,
  Select,
  StartDate,
  Status,
  Upcoming,
} from "../../utils/Constant";
import {
  customTableStyles,
  scheduledCourseColumns,
  scheduleStatusDropValues,
} from "../../utils/helper/helper";

import "./style.scss";

const ScheduledCourseList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedModalType, setSelectedModalType] = useState("");
  const [activeStatus, setActiveStatus] = useState("");
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});
  const { courseScheduleList } = useAppSelector(state => state.course);
  const [userMap, setUserMap] = useState(null);
  const { usersList } = useAppSelector(state => state.user);
  const [clearData, setClearData] = useState(false);
  const [totalCountData, setTotalCountData] = useState([
    {
      count: 0,
      title: OngoingClass,
      icon: "total-request",
      id: "ongoing",
    },
    {
      count: 0,
      title: Upcoming,
      icon: "approved",
      id: "trainer_approved,waiting_for_trainer_approval,cancelled,nomination_pending,nomination_ongoing,nomination_open,nomination_closed",
    },
    {
      count: 0,
      title: Completed,
      icon: "declined",
      id: "course_completed,rescheduled,tm_cancelled",
    },
  ]);
  const [locationNameMap, setLocationNameMap] = useState([]);
  const [classRoomNameMap, setClassRoomNameMap] = useState([]);
  const [instructorNameMap, setInstructorNameMap] = useState([]);
  const { locationList } = useAppSelector(state => state.master);
  const { classRoomList } = useAppSelector(state => state.manageMasterData);

  useEffect(() => {
    setActiveStatus("");
    dispatch(getLocationList());
    dispatch(getClassRoomList());
  }, [dispatch]);

  useEffect(() => {
    const userMapObj = {};
    const instructorMapObj = [];
    usersList?.users?.forEach(user => {
      if (user?.role?.includes("it") || user?.role?.includes("et")) {
        instructorMapObj.push({
          name: user.id?.toString(),
          value: user?.user_name,
          label: user?.user_name,
        });
      }
      userMapObj[user.id] = user;
    });
    setInstructorNameMap(instructorMapObj);
    setUserMap(userMapObj);
  }, [usersList]);

  useEffect(() => {
    const locationMapObj = [];
    locationList?.location?.forEach(location => {
      locationMapObj.push({
        name: location?.id?.toString(),
        value: location?.name,
        label: location?.name,
      });
    });
    setLocationNameMap(locationMapObj);
  }, [locationList]);

  useEffect(() => {
    const classRoomMapObj = [];
    classRoomList?.class_room?.forEach(class_room => {
      classRoomMapObj.push({
        name: class_room?.id?.toString(),
        value: class_room?.name,
        label: class_room?.name,
      });
    });
    setClassRoomNameMap(classRoomMapObj);
  }, [classRoomList]);

  const toggleModal = () => {
    if (isModalOpen) {
      setSelectedId("");
      setSelectedModalType("");
    }
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    setActiveStatus("ongoing");
    dispatch(getUsersList({ role: ALL_USERS_STATUS }));
    dispatch(
      getCourseScheduledList({
        course_id: "",
        from_date: "",
        to_date: "",
        location_id: "",
        classroom_id: "",
        status: "ongoing",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    setTotalCountData([
      {
        count: courseScheduleList?.total_ongoing_classes,
        title: OngoingClass,
        icon: "total-request",
        id: "ongoing",
      },
      {
        count: courseScheduleList?.total_upcoming_classes,
        title: Upcoming,
        icon: "approved",
        id: "trainer_approved,waiting_for_trainer_approval",
      },
      {
        count: courseScheduleList?.total_completed_classes,
        title: Completed,
        icon: "declined",
        id: "course_completed",
      },
    ]);
  }, [courseScheduleList]);

  const handleDone = async (values: { [key: string]: string[] }) => {
    setClearData(true);
    const fromDate = values["start_date"] ? values["start_date"][0] : "";
    const toDate = values["end_date"] ? values["end_date"][0] : "";

    const formattedFromDate = fromDate
      ? new Date(fromDate).toLocaleDateString("en-CA").split("T")[0]
      : "";
    const formattedToDate = toDate
      ? new Date(toDate).toLocaleDateString("en-CA").split("T")[0]
      : "";
    const instructorIds = values["instructor"]
      ? values["instructor"].join(",")
      : "";
    const locationIds = values["location"] ? values["location"].join(",") : "";
    const classroomIds = values["classroom"]
      ? values["classroom"].join(",")
      : "";
    const status = values["status"] ? values["status"].join(",") : "";

    setActiveStatus("");
    await dispatch(
      getCourseScheduledList({
        course_id: null,
        from_date: formattedFromDate,
        to_date: formattedToDate,
        location_id: locationIds,
        instructor_id: instructorIds,
        classroom_id: classroomIds,
        status: status,
      })
    );
  };

  const handleClear = async () => {
    setClearData(true);
    setSelectedFilterValues({});
    setDefaultFilterValues({});
    setActiveStatus("");
    await dispatch(
      getCourseScheduledList({
        course_id: "",
        from_date: "",
        to_date: "",
        location_id: "",
        classroom_id: "",
        status: "",
      })
    );
  };

  const handleSearch = (value, courses) => {
    if (!value) return courses;

    return courses.filter(course =>
      [
        course.course_name,
        course.primary_trainer_name,
        course.location_name,
        course.classroom_name,
        course.course_start_date,
        course.course_end_date,
        course.nomination_start_date,
        course.nomination_end_date,
      ].some(field => field?.toLowerCase()?.includes(value.toLowerCase()))
    );
  };

  const filteredCourses = handleSearch(
    searchValue,
    courseScheduleList?.scheduled_courses || []
  );

  const handleRowClick = row => {
    return navigate(
      `/course-management/course-details?courseId=${row.course_id}`,
      {
        state: {
          scheduled: true,
        },
      }
    );
  };

  const onReview = (id, type) => {
    setSelectedId(id);
    setSelectedModalType(type);
    toggleModal();
  };

  let sizeTitle = "";
  if (selectedModalType === "approve") {
    sizeTitle = Approve;
  } else if (selectedModalType === "reject") {
    sizeTitle = Reject;
  }

  const renderModalBody = type => {
    if (type === "approve") {
      return ApproveCourseSchedule;
    } else if (type === "reject") {
      return RejectCourseSchedule;
    }
  };

  const handlePrimaryBtnClick = async () => {
    let payload;
    if (selectedModalType === "approve") {
      payload = {
        action: "approved",
        type: "trainer",
        id: null,
        reason: "",
        course_schedule_id: selectedId,
      };
      await dispatch(reviewTrainerOrNominee({ review: payload }));
    } else if (selectedModalType === "reject") {
      payload = {
        action: "rejected",
        type: "trainer",
        id: null,
        reason: "",
        course_schedule_id: selectedId,
      };
      await dispatch(reviewTrainerOrNominee({ review: payload }));
    }

    setActiveStatus("");
    await dispatch(
      getCourseScheduledList({
        course_id: "",
        from_date: "",
        to_date: "",
        location_id: "",
        classroom_id: "",
        status: "",
      })
    );
    toggleModal();
  };

  const onIconClick = async id => {
    setClearData(true);
    let parsedStatus = "";
    if (activeStatus === "" || activeStatus !== id) {
      parsedStatus = id;
      setActiveStatus(id);
    } else {
      setActiveStatus("");
    }
    await dispatch(
      getCourseScheduledList({
        course_id: "",
        from_date: "",
        to_date: "",
        location_id: "",
        classroom_id: "",
        status: parsedStatus,
      })
    );
  };

  return (
    <div className="page-body course-schedule-list">
      <CommonModal
        sizeTitle={sizeTitle}
        modalBodyClassName=""
        isOpen={isModalOpen}
        toggle={toggleModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={handlePrimaryBtnClick}
        primaryBtnText={Confirm}
      >
        {renderModalBody(selectedModalType)}
      </CommonModal>
      <TotalCountCard
        data={totalCountData}
        onIconClick={onIconClick}
        activeStatus={activeStatus}
        showColor={true}
      />
      <div className="mt-2">
        <Card>
          <CardBody>
            <div className="d-flex justify-content-between">
              <H3 className="mt-3">{ScheduleList}</H3>
              <div className="d-flex gap-2 align-items-center">
                <SearchBar
                  showSearch
                  className="mb-1"
                  onSearch={value => {
                    setClearData(false);
                    setSearchValue(value);
                  }}
                  clearOnProp={clearData}
                />
                <div className="mt-2">
                  <FilterSliderModal
                    dropdowns={[
                      {
                        label: StartDate,
                        key: "start_date",
                        tooltipText: StartDate,
                        isDate: true,
                        isMultiSelect: false,
                      },
                      {
                        label: EndDate,
                        key: "end_date",
                        tooltipText: EndDate,
                        isDate: true,
                        isMultiSelect: false,
                      },
                      {
                        label: Instructor,
                        key: "instructor",
                        tooltipText: `${Select} ${Instructor}`,
                        options: instructorNameMap,
                        isMultiSelect: true,
                      },
                      {
                        label: Locations,
                        key: "location",
                        tooltipText: `${Select} ${Locations}`,
                        options: locationNameMap,
                        isMultiSelect: true,
                      },
                      {
                        label: ClassRoom,
                        key: "classroom",
                        tooltipText: `${Select} ${ClassRoom}`,
                        options: classRoomNameMap,
                        isMultiSelect: true,
                      },
                      {
                        label: Status,
                        key: "status",
                        tooltipText: `${Select} ${Status}`,
                        options: scheduleStatusDropValues,
                        isMultiSelect: true,
                      },
                    ]}
                    selectedFilterValues={selectedFilterValues}
                    defaultFilterValues={defaultFilterValues}
                    setSelectedFilterValues={setSelectedFilterValues}
                    setDefaultFilterValues={setDefaultFilterValues}
                    onDone={handleDone}
                    onClear={handleClear}
                  />
                </div>
              </div>
            </div>
            <div className="mt-3">
              <DataTable
                className="course-schedule-list__table"
                columns={scheduledCourseColumns(onReview, userMap, navigate)}
                data={filteredCourses}
                pagination
                fixedHeader
                striped
                onRowClicked={handleRowClick}
                customStyles={customTableStyles}
              />
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default ScheduledCourseList;
