import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

import { Btn, MUIIcons } from "../../../AbstractElements";
import {
  REPORTING_MANAGER_STATUS,
  rmTabsStatusMap,
} from "../../../Api/constants";
import FilterSliderModal from "../../../CommonElements/FilterSliderModal";
import JustifyTabs from "../../../CommonElements/JustifyTabs";
import SearchBar from "../../../CommonElements/SearchBar";
import TextTooltip from "../../../CommonElements/TextTooltip/inxex";
import ViewModeDropdown from "../../../CommonElements/ViewMoreDropdown";
import NoData from "../../../container/NoData";
import RequestCard from "../../../container/RequestCard";
import TableView from "../../../container/TableView";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import {
  getCourseRequestListByFilter,
  setRequestListToInitials,
} from "../../../ReduxToolkit/Reducers/CourseRequestSlice";
import {
  setBasicInputFormValueToInitials,
  setNumberLevel,
  setShowFinish,
} from "../../../ReduxToolkit/Reducers/RequestCourseWizardSlice";
import { getUsersList } from "../../../ReduxToolkit/Reducers/UserSlice";
import {
  RequestType,
  NewRequest,
  RequestedOn,
  Select,
  NoDataText,
  ContentTopic,
  CreatedBy,
  RMRequestTypeToolTipMessage,
} from "../../../utils/Constant";
import {
  formatCustomDate,
  rmTabs,
  tmRequestTabs,
  courseRequestFilterDropdownList,
  capitalizeFirstLetter,
} from "../../../utils/helper/helper";
import { hasPermissionToComponent } from "../../../utils/helper/permission";
import useIsMobile from "../../../utils/helper/responsive";

const RequestList: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const statusParam = params.get("status");
  const viewParam = params.get("view");
  const [userMapData, setUserMapData] = useState({});
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const { courseRequestList } = useAppSelector(state => state.courseRequest);
  const { usersList } = useAppSelector(state => state.user);
  const [activeTab, setActiveTab] = useState<string>(
    statusParam || "new_request"
  );
  const [clearData, setClearData] = useState(false);
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});
  const [searchResults, setSearchResults] = useState([]);
  const [noDataFound, setNoDataFound] = useState(false);
  const [viewMode, setViewMode] = useState("card");
  const isMobile = useIsMobile();

  useEffect(() => {
    if (statusParam && statusParam !== activeTab) {
      setActiveTab(statusParam);
    }
  }, [statusParam, activeTab]);

  useEffect(() => {
    if (viewParam) {
      setViewMode(viewParam);
    }
  }, [viewParam]);

  useEffect(() => {
    dispatch(getUsersList({ role: REPORTING_MANAGER_STATUS }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(setRequestListToInitials());
    fetchRequestList();
  }, [activeTab, selectedItems]);

  const fetchRequestList = () => {
    const combinedFilters = {
      status: [rmTabsStatusMap[activeTab]],
      filters: selectedItems,
    };
    dispatch(getCourseRequestListByFilter(combinedFilters)).then(response => {
      if (response?.payload?.course_request_list?.length === 0) {
        setNoDataFound(true);
      } else {
        setNoDataFound(false);
      }
    });
  };

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserMapData(userMapObj);
  }, [usersList]);

  const handleTabClick = (tabId: any) => {
    setClearData(true);
    setActiveTab(tabId);
    setSearchResults([]);
    params.set("status", tabId);
    navigate(`${location.pathname}?${params.toString()}`);
  };

  const handleClick = () => {
    dispatch(setBasicInputFormValueToInitials());
    dispatch(setShowFinish(false));
    dispatch(setNumberLevel(1));
  };

  const handleSearch = (query: string) => {
    setClearData(false);
    const filteredCourses = courseRequestList?.course_request_list?.filter(
      course => course.topic.toLowerCase().includes(query.toLowerCase())
    );
    setSearchResults(filteredCourses || []);
    setNoDataFound(!filteredCourses || filteredCourses?.length === 0);
  };

  const renderRequestCard = (course, index) => {
    return (
      <Col key={index} sm={12} lg={3}>
        <Link to={`/course-management/request-details?id=${course.id}`}>
          <RequestCard
            title={
              course?.request_type
                ? capitalizeFirstLetter(course.request_type)
                : "-"
            }
            courseRequestId={course.id}
            header={course.topic}
            rm={userMapData[course.created_by]?.user_name}
            status={course.status}
            requestedOn={RequestedOn + formatCustomDate(course?.created_at)}
          />
        </Link>
      </Col>
    );
  };

  const createTooltipCell = (content: string | string[], uniqueId: string) => (
    <TextTooltip tooltipText={content || "-"} toolTipUniqueId={uniqueId}>
      <div className="text-ellips-parent">
        <div className="text-ellips">{content || "-"}</div>
      </div>
    </TextTooltip>
  );

  const renderRequestTable = () => {
    const tableColumns = [
      {
        name: RequestType,
        selector: row => capitalizeFirstLetter(row.request_type) || "-",
        sortable: true,
      },
      {
        name: ContentTopic,
        selector: row => capitalizeFirstLetter(row.topic),
        cell: (row: any) =>
          createTooltipCell(row.topic, `content-topic-${row.id}`),
        sortable: true,
      },
      {
        name: CreatedBy,
        selector: row => userMapData[row.created_by]?.user_name,
        sortable: true,
      },
      {
        name: RequestedOn,
        selector: row => formatCustomDate(row.created_at),
        sortable: true,
      },
    ];

    const tableData =
      searchResults?.length > 0
        ? searchResults
        : courseRequestList?.course_request_list;

    return (
      <Col>
        <Card className="p-1">
          <CardBody>
            <TableView
              tableColumns={tableColumns}
              tableData={tableData}
              onRowClick={row =>
                navigate(`/course-management/request-details?id=${row.id}`)
              }
            />
          </CardBody>
        </Card>
      </Col>
    );
  };

  const handleSetView = type => {
    setViewMode(type);
    params.set("view", type);
    navigate(`${location.pathname}?${params.toString()}`);
  };

  const handleDone = async (values: { [key: string]: string[] }) => {
    setClearData(true);
    if (values["request_type"]) {
      setSelectedItems(values["request_type"]);
    }
  };

  const handleClear = async () => {
    setClearData(true);
    setSelectedFilterValues({});
    setDefaultFilterValues({});
    setSelectedItems([]);
  };

  const renderCourseRequestsContent = () => {
    if (noDataFound || courseRequestList?.course_request_list?.length === 0) {
      return (
        <NoData svg={"empty-folder-icon"} title={NoDataText} showCard={false} />
      );
    }

    const cardData =
      searchResults?.length > 0
        ? searchResults
        : courseRequestList?.course_request_list;

    return viewMode === "card"
      ? cardData?.map((course, index) => renderRequestCard(course, index))
      : renderRequestTable();
  };

  return (
    <div className="page-body page-body-margin course-list">
      <Card className="p-1">
        <CardBody>
          <div
            className={`d-flex flex-column flex-md-row justify-content-between ${!isMobile ? "align-items-center" : ""}`}
          >
            <div className="mb-1 mb-sm-0 d-flex flex-column gap-2 ">
              <JustifyTabs
                tabs={
                  hasPermissionToComponent("REQUEST_TABS")
                    ? rmTabs
                    : tmRequestTabs
                }
                activeTab={activeTab}
                onTabClick={handleTabClick}
              />
            </div>
            <div className="d-flex justify-content-end align-items-center gap-3 ms-auto">
              <SearchBar
                className="mb-2"
                showSearch
                onSearch={handleSearch}
                clearOnProp={clearData}
              />
              <div className="ml-2 mt-2">
                <FilterSliderModal
                  dropdowns={[
                    {
                      label: RequestType,
                      key: "request_type",
                      tooltipText: `${Select} ${RequestType}`,
                      extraTooltipText: RMRequestTypeToolTipMessage,
                      options: courseRequestFilterDropdownList,
                      isMultiSelect: true,
                    },
                  ]}
                  selectedFilterValues={selectedFilterValues}
                  defaultFilterValues={defaultFilterValues}
                  setSelectedFilterValues={setSelectedFilterValues}
                  setDefaultFilterValues={setDefaultFilterValues}
                  onDone={handleDone}
                  onClear={handleClear}
                />
              </div>
              <ViewModeDropdown
                currentView={viewMode}
                onChange={handleSetView}
              />
              {hasPermissionToComponent("RAISE_REQUEST") && (
                <Link to={"/course-management/new-request"}>
                  <Btn
                    icon={
                      <MUIIcons iconName="AddCircleOutlineOutlined" size={16} />
                    }
                    color="primary"
                    onClick={handleClick}
                  >
                    {NewRequest}
                  </Btn>
                </Link>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
      <Row className="mt-5">{renderCourseRequestsContent()}</Row>
    </div>
  );
};

export default RequestList;
