export const EXTERNAL_TRAINER = "et";
export const INTERNAL_TRAINER = "it";
export const INTERNAL_LEARNER = "il";
export const EXTERNAL_LEARNER = "el";

export const TRAINER = "trainer";
export const LEARNER = "learner";

// Roles based on status for assigning to course
export const INSTRUCOR_ASSIGN_VALUE = "instructor";
export const TRAINER_ASSIGN_VALUE = "trainer";
export const SME_ASSIGN_VALUE = "sme";

// Course Type
export const ILT = "ilt";
export const VILT = "vilt";
export const WEBINAR = "webinar";
export const WBT = "wbt";

// Status
export const ALL_COURSE_REQUEST_STATUS = ["pending, approved"];
export const NEW = ["new"];
export const PENDING = ["pending"];
export const APPROVED = ["approved"];
export const REJECTED = ["rejected"];
export const DRAFT = ["draft"];
export const REPORTING_MANAGER_STATUS = ["rm"];
export const TRAINER_STATUS = ["it", "et"];
export const COURSE_CREATION_USERS_STATUS = ["it", "et", "sme"];
export const ALL_USERS_STATUS = [
  "rm",
  "tm",
  "ta",
  "hra",
  "sme",
  "it",
  "et",
  "il",
  "el",
];
export const LEARNER_STATUS = ["il", "el"];
export const ALL_COURSE_FILTER_STATUS = ["ilt", "vilt", "webinar", "wbt"];
export const REMEDIAL_ACTION_FILTER_STATUS = ["ilt", "vilt", "webinar", "wbt"];
export const EDIT_COURSE_REQUEST_STATUS = ["new", "draft"];
export const APPROVE_COURSE_REQUEST_STATUS = ["new"];
export const EXPERTISE_LEVEL_STATUS = ["published"];
export const REMEDIAL_ACTION_STATUS = ["published"];
export const PUBLISH_UNPUBLISH_STATUS = ["published", "unpublished"];
export const PUBLISH_STATUS = ["published"];
export const ILT_VILT_COURSE_FILTER_STATUS = ["ilt", "vilt"];

// Tab status
export const rmTabsStatusMap = {
  approved: "approved",
  new: "new",
  submitted: "submitted",
  new_request: "new",
  draft: "draft",
  reject: "rejected",
};

export const tmTabsStatusMap = {
  pending: "pending",
  approved: "approved",
  new: "new",
  submitted: "submitted",
  new_request: "new",
  draft: "draft",
  rejected: "rejected",
  published: "published",
  unpublished: "unpublished",
};

export const policyTabsMap = {
  mapped: "mapped",
  unmapped: "unmapped",
};

export const curriculumTabsStatusMap = {
  draft: "draft",
  published: ["published", "scheduled_for_publish"],
};

export const courseTypeMap = {
  ilt: "ILT",
  vilt: "VILT",
  webinar: "Webinar",
  wbt: "WBT",
};

export const requestTypeMap = {
  internal: "internal",
  external: "external",
};

export const courseStatusMap = {
  new: "New",
};

// Course Content Status
export const NEW_STATUS = "new";
export const DRAFT_STATUS = "draft";
export const REJECTED_STATUS = "rejected";
export const PENDING_STATUS = "pending";
export const SUBMITTED = "submitted";
export const CONTENT_SUBMITTED = "content_submitted";
export const LO_SUBMITTED = "lo_submitted";
export const LO_APPROVED_TM = "lo_approved_tm";
export const LO_APPROVED_SME = "lo_approved_sme";
export const CONTENT_APPROVED_TM = "content_approved_tm";
export const CONTENT_APPROVED_SME = "content_approved_sme";
export const LO_REJECTED_TM = "lo_rejected_tm";
export const LO_REJECTED_SME = "lo_rejected_sme";
export const CONTENT_REJECTED_TM = "content_rejected_tm";
export const CONTENT_REJECTED_SME = "content_rejected_sme";
export const PUBLISHED = "published";
export const SCHEDULED_FOR_PUBLISH = "scheduled_for_publish";
export const UNPUBLISHED = "unpublished";

// Listing screen Status
export const listStatusMapping = {
  new: { message: "New", color: "#48A3D7" },
  draft: { message: "Draft", color: "#FC971E" },
  rejected: { message: "Rejected", color: "#E44141" },
  lo_approved_tm: { message: "Draft", color: "#FC971E" },
  lo_submitted: { message: "LO Submitted", color: "#2c323f" },
  content_submitted: { message: "Content Submitted", color: "#2c323f" },
  lo_approved_sme: { message: "LO Approved SME", color: "#C95E9E" },
  content_approved_sme: { message: "Content Approved SME", color: "#C95E9E" },
  content_approved_tm: { message: "Content Approved TM", color: "#C95E9E" },
  lo_rejected_tm: { message: "LO Rejected TM", color: "#E44141" },
  lo_rejected_sme: { message: "LO Rejected SME", color: "#E44141" },
  content_rejected_tm: { message: "Content Rejected TM", color: "#E44141" },
  content_rejected_sme: { message: "Content Rejected SME", color: "#E44141" },
  published: { message: "Published", color: "#0DA759" },
  scheduled_for_publish: { message: "Scheduled For Publish", color: "#0DA759" },
  waiting_for_rm_approval: {
    message: "Waiting for RM Approval",
    color: "#FFC1C1",
  },
  yet_to_start: { message: "Yet to Start", color: "#48A3D7" },
  yts: { message: "Yet to Start", color: "#48A3D7" },
  completed: { message: "Completed", color: "#0DA759" },
  certified: { message: "Certified", color: "#0DA759" },
  assigned: { message: "Assigned", color: "#0DA759" },
  in_progress: { message: "In Progress", color: "#FFDA4D" },
  unlinked: { message: "Un-linked", color: "#E44141" },
  upcoming: { message: "Upcoming", color: "#FFDA4D" },
  not_scheduled: { message: "Not Scheduled", color: "#C95E9E" },
  mapped: { message: "Not Scheduled", color: "#C95E9E" },
  over_due: { message: "Overdue", color: "#FF0000" },
  overdue: { message: "Overdue", color: "#FF0000" },
  passed: { message: "Pass", color: "#0DA759", icon: "Check" },
  failed: { message: "Failed", color: "#E44141" },
  re_attempt: { message: "Re-attempt", color: "#F5F5F5" },
  unpublished: { message: "Archived", color: "#E44141" },
  requested_rm: { message: "Waiting for TM Approval", color: "#48A3D7" },
  waiting_rm: { message: "Requested By RM", color: "#48A3D7" },
  requested: { message: "Waiting for TM Approval", color: "#48A3D7" },
  modification: { message: "Request for modification", color: "#2c323f" },
  request_for_extesion: {
    message: "Request for extension",
    color: "#48A3D7",
  },
  extension_requested: { message: "Extension Requested", color: "#48A3D7" },
  active: { message: "Available", color: "#0DA759" },
  need_attention: { message: "Need Attention", color: "#FC971E" },
  retired: { message: "Retired", color: "#E44141" },
  replaced: { message: "Need Replacement", color: "#E44141" },
  need_repair: { message: "Need Repair", color: "#E44141" },
  start: { message: "start", color: "#48A3D7" },
};

//COURSE STAGES
export const LEARNING_OUTCOME = "learning_outcome";
export const COMPETENCY_STATEMENT = "competency_statement";
export const LESSON_PLAN = "lesson_plan";
export const COURSE_STRUCTURE = "course_structure";
export const ASSESSMENTS = "assessments";
export const COURSE_MATERIALS = "course_materials";

// File keys
export const CourseStructureFileKey = "download-course-structure";
export const CompetencyFileKey = "download-competency-statement";
export const LessonFileKey = "download-lesson-plan";
export const LOFileKey = "download-learning-outcome";

// API Status Codes
export const STATUS_200 = 200;
export const STATUS_401 = 401;
export const STATUS_403 = 403;
export const NO_RESPONSE = "NO_RESPONSE";
export const REQUEST_SETUP_ERROR = "REQUEST_SETUP_ERROR";

// QuestionBank status
export const COMPETENCY_STATUS = "competency";

export const WILL_BE_UPLOADED = "WILL_BE_UPLOADED";
//Limits
export const MINUTES_PER_DAY = 1440;

// ILP
export const PendingStatus = "pending";
export const YtsStatus = "yts";
export const RejectedStatus = "rejected";

export const sourceTypeMap = {
  sga: "SGA",
  self: "Self",
  remedial_action: "SGA",
  admin_assigned: "TM Assigned",
};

export const statusMap = {
  assigned: "yet_to_start",
  in_progress: "in_progress",
  requested: "waiting_for_rm_approval",
  upcoming: "yet_to_start",
  overdue: "over_due",
  not_scheduled: "yet_to_start",
  completed: "completed",
};

// Tag request type
export const TAG_QUESTION_BANK = "question_bank";
export const TAG_ASSESSMENT = "assessment";

export const ASSESSMENT_PRE_PHASE = "pre";
export const ASSESSMENT_POST_PHASE = "post";

export const courseScheduleStatus = {
  waiting_for_trainer_approval: "waiting_for_trainer_approval",
  nomination_ongoing: "nomination_ongoing",
  nomination_closed: "nomination_closed",
  rescheduled: "rescheduled",
  cancelled: "cancelled",
  tm_cancelled: "tm_cancelled",
  trainer_approved: "trainer_approved",
  completed: "completed",
  ongoing: "ongoing",
  nomination_pending: "nomination_pending",
  nomination_open: "nomination_open",
  course_completed: "course_completed",
};

//current role
export const TM = "tm";
export const TA = "ta";
export const HRA = "hra";
