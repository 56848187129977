import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FixedSizeList as List } from "react-window";
import { Badge, Card, CardBody } from "reactstrap";

import { ToolTip } from "../../../../AbstractElements";
import BorderTabs from "../../../../CommonElements/BorderTabs";
import Divider from "../../../../CommonElements/Divider";
import NoData from "../../../../container/NoData";
import UserProfileContainer from "../../../../container/UserProfileContainer";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { getAllMentorUnderMenteeList } from "../../../../ReduxToolkit/Reducers/LearnerSlice";
import { getFilterRolesList } from "../../../../ReduxToolkit/Reducers/MasterSlice";
import { Mentor, NoDataText } from "../../../../utils/Constant";
import {
  formatCustomDate,
  MentorsListTabs,
  truncateText,
} from "../../../../utils/helper/helper";

const MentorsList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState({
    id: "1",
    title: MentorsListTabs[0].title.toLowerCase(),
  });

  const { mentorUnderMenteeList } = useAppSelector(state => state.learner);
  const { roleOnFilter } = useAppSelector(state => state.master);
  const [combinedMentorData, setCombinedMentorData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [roleFilterList, setRoleFilterList] = useState({});

  useEffect(() => {
    dispatch(getAllMentorUnderMenteeList({ status: activeTab.title }));
  }, [dispatch, activeTab.title]);

  useEffect(() => {
    dispatch(getFilterRolesList());
  }, []);

  useEffect(() => {
    const value = {};

    if (roleOnFilter?.job_role) {
      roleOnFilter.job_role.forEach(item => {
        if (item?.id && item?.name) {
          value[item.id] = {
            label: item.name,
            value: item.name,
          };
        }
      });
    }

    setRoleFilterList(value);
  }, [roleOnFilter]);

  const getIcons = (
    role,
    sbu,
    countOfSessions,
    latestSessiondate,
    menteeId
  ) => [
    {
      id: 1,
      iconName: "EngineeringOutlined",
      value: (
        <>
          <span
            id={`tooltip-role-${menteeId}`}
            className="text-truncate d-flex align-items-center"
          >
            {truncateText(role, 20)}
          </span>
          <ToolTip placement="top" target={`tooltip-role-${menteeId}`}>
            {role}
          </ToolTip>
        </>
      ),
      columnSize: "4",
    },
    {
      id: 2,
      iconName: "BadgeOutlined",
      value: sbu,
      columnSize: "3",
    },
    {
      id: 3,
      iconName: "CastForEducationOutlined",
      value: countOfSessions,
      columnSize: "2",
    },
    {
      id: 4,
      iconName: "CalendarTodayOutlined",
      value: latestSessiondate,
      columnSize: "3",
    },
  ];

  const handleTabClick = (tabId, title) => {
    setActiveTab({ id: tabId, title: title.toLowerCase() });
    setSearchQuery("");
  };

  const onRowClick = (mentorMenteeId, mentorId) => {
    navigate(
      `${process.env.PUBLIC_URL}/mentoring/i-am-mentee/mentor-details-page`,
      {
        state: { mentorMenteeId, mentorId },
      }
    );
  };

  useEffect(() => {
    const data = (mentorUnderMenteeList?.mentors || []).map(mentee => {
      const userDetails = mentorUnderMenteeList.user_dict[mentee.mentor_id];
      return { ...mentee, ...userDetails };
    });
    setCombinedMentorData(data);
  }, [dispatch, mentorUnderMenteeList]);

  useEffect(() => {
    if (searchQuery) {
      const filtered = combinedMentorData?.filter(
        mentee =>
          mentee?.user_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          mentee?.employee_code
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(combinedMentorData);
    }
  }, [searchQuery, combinedMentorData]);

  const renderMentorProfile = mentor => (
    <React.Fragment key={mentor?.mentor_mentee_id}>
      <UserProfileContainer
        userDetails={{
          name: mentor?.user_name,
          empId: mentor?.employee_code || "-",
          mobile: mentor?.phone || "-",
          id: mentor?.mentor_id,
        }}
        iconItems={getIcons(
          roleFilterList[mentor?.job_role_id]?.value,
          mentor?.sbu,
          mentor?.count_of_sessions,
          formatCustomDate(mentor?.latest_session_date) || "-",
          mentor?.mentor_id
        )}
        onRowClick={() =>
          onRowClick(mentor?.mentor_mentee_id, mentor?.mentor_id)
        }
      />
      <Divider />
    </React.Fragment>
  );

  return (
    <Card>
      <CardBody>
        <div>
          <BorderTabs
            tabs={MentorsListTabs}
            activeTab={activeTab.id}
            showCard={false}
            handleTabClick={tabId => {
              const selectedTab = MentorsListTabs.find(
                tab => tab.tabId === tabId
              );
              handleTabClick(tabId, selectedTab?.title || "");
            }}
            searchBar
            handleSearch={query => setSearchQuery(query)}
          />
          <Badge className="text-black mt-3 custom-Mentees-badge">
            {mentorUnderMenteeList?.count_of_mentors} {Mentor}
          </Badge>

          {filteredData.length > 0 ? (
            <List
              height={500}
              itemCount={filteredData.length}
              itemSize={100}
              width="100%"
              className="user-list__card-container"
            >
              {({ index, style }) => {
                const mentor = filteredData[index];
                return (
                  <div
                    key={mentor.user_id}
                    style={style}
                    className="pointer user-list__card"
                  >
                    {mentor && renderMentorProfile(mentor)}
                  </div>
                );
              }}
            </List>
          ) : (
            <NoData
              svg={"empty-folder-icon"}
              title={NoDataText}
              showCard={false}
            />
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default MentorsList;
