import { formatDate } from "../../utils/helper/helper";
import {
  MapSgaPayload,
  FormValues,
  MultiselectOption,
} from "../entities/SgaEntity";

export const parseSgaRequestData = (
  formValues: FormValues,
  roleId: number,
  levelId: number,
  selectedCourse: MultiselectOption[],
  selectedGrades: MultiselectOption[]
): MapSgaPayload => {
  return {
    role_id: roleId,
    level_id: levelId,
    competency_assessment_id: parseInt(formValues.selfRatingAssessment, 10),
    courses: selectedCourse
      ?.filter(course => course.isNew)
      ?.map(item => parseInt(item.name, 10)),
    grades: selectedGrades.map(element => parseInt(element.name)),
    duration_months: parseInt(formValues.duration, 10),
    duration_type: formValues.durationType,
    effective_from: formatDate(formValues.effectiveFromDate),
    policy_id: parseInt(formValues.reAttemptPolicy, 10),
    duration_count: parseInt(formValues.duration, 10),
    is_mappable: formValues?.isMappable,
  };
};
