import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

import { Badges, MUIIcons, P } from "../../../AbstractElements";
import { courseTypeMap, sourceTypeMap } from "../../../Api/constants";
import DetailHeader from "../../../CommonElements/DetailHeader";
import FilterSliderModal from "../../../CommonElements/FilterSliderModal";
import CommonModal from "../../../CommonElements/Modal";
import SearchBar from "../../../CommonElements/SearchBar";
import YearNavigator from "../../../CommonElements/YearNavigator";
import { LearningPlanCardData } from "../../../container/LearningPlanCourseCard";
import NoData from "../../../container/NoData";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import { getSkillAssessmentTrainingHistory } from "../../../ReduxToolkit/Reducers/CourseSlice";
import {
  getLOForAssessment,
  getTrainingHistory,
} from "../../../ReduxToolkit/Reducers/LearningPlanSlice";
import {
  Close,
  CourseType,
  ILT,
  Location,
  MachineSerialNumber,
  NoDataText,
  Select,
  SkillAssessment,
  StartDate,
  Status,
  VILT,
  WBT,
  Webinar,
  WorkOrderNumber,
} from "../../../utils/Constant";
import {
  courseFilterDropdownList,
  extractFileName,
  formatCustomDate,
  getEllipsedFileName,
  getOngoingFiscalYear,
  handleAllFilesDownload,
  trainingHistoryColumns,
  trainingHistoryFilterList,
} from "../../../utils/helper/helper";

const ILPTrainingHistory = ({ userId }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { trainingHistory, loading } = useAppSelector(
    state => state.learningPlan
  );
  const [fyYear, setFyYear] = useState(getOngoingFiscalYear());
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [searchResults, setSearchResults] = useState<LearningPlanCardData[]>(
    []
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [outcomesData, setOutcomesData] = useState({});
  const [clearData, setClearData] = useState(false);

  const toggleModal = () => {
    if (isModalOpen) {
      setOutcomesData({});
    }
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    fetchCourses();
  }, [selectedItems, trainingHistory]);

  useEffect(() => {
    dispatch(
      getTrainingHistory({
        userId: userId,
        fyYear: fyYear,
      })
    );
  }, [dispatch, fyYear, selectedItems]);

  const mapTrainingHistoryToLearningPlanCardData = (
    trainingHistoryData: any
  ): LearningPlanCardData[] => {
    return trainingHistoryData.history.map(course => {
      const domainName =
        trainingHistoryData.domain[course.id]?.map(
          domainItem => domainItem.name
        ) || [];
      return {
        courseId: course?.course_id?.toString(),
        scheduleId: course?.course_schedule_id?.toString(),
        domainName: domainName,
        status: course.status,
        badgeTextColor: true,
        courseName: course.course_name,
        completedOn: course?.completed_on,
        assignedBy: sourceTypeMap[course?.source_type],
        courseType: courseTypeMap[course?.course_type],
        knowledgeScore: course?.knowledge_percentage,
        skillScore: course?.skill_percentage,
      };
    });
  };

  const filterCourses = (courses, selectedItems) => {
    if (selectedItems.length === 0) {
      return courses;
    }
    return courses.filter(item =>
      selectedItems.includes(item.courseType.toUpperCase())
    );
  };

  const fetchCourses = () => {
    if (!trainingHistory?.history) {
      setSearchResults([]);
      return;
    }

    const transformedCourses =
      mapTrainingHistoryToLearningPlanCardData(trainingHistory);
    const filteredCourses = filterCourses(transformedCourses, selectedItems);

    setSearchResults(filteredCourses);
  };

  const handleSearch = (text: string) => {
    setClearData(false);
    const transformedCourses =
      mapTrainingHistoryToLearningPlanCardData(trainingHistory);

    const filteredCourses = transformedCourses.filter(
      course =>
        course?.courseName?.toLowerCase()?.includes(text?.toLowerCase()) ||
        course?.courseType?.toLowerCase()?.includes(text?.toLowerCase()) ||
        course?.status?.toLowerCase()?.includes(text?.toLowerCase())
    );
    setSearchResults(filteredCourses);
  };

  const handleDone = async (values: { [key: string]: string[] }) => {
    setClearData(true);
    await dispatch(
      getTrainingHistory({
        userId: userId,
        fyYear: fyYear,
        course_type: values["course_type"]?.toString() || null,
        status: values["status"]?.toString() || null,
      })
    );
  };

  const handleClear = async () => {
    setClearData(true);
    setSelectedFilterValues({});
    setDefaultFilterValues({});
    setSelectedItems([]);
    setSearchResults(mapTrainingHistoryToLearningPlanCardData(trainingHistory));
    await dispatch(
      getTrainingHistory({
        userId: userId,
        fyYear: fyYear,
      })
    );
  };

  const onRowClick = item => {
    return navigate(
      `/my-learning-plan/course-details?course_id=${item.courseId}`
    );
  };

  const handleSkillCompletionClick = async row => {
    const assessmentIds = [];

    const recievedData = await dispatch(
      getSkillAssessmentTrainingHistory({
        courseScheduleId: row?.scheduleId,
        userId: userId,
      })
    );

    recievedData?.payload?.skill_document?.forEach(item => {
      assessmentIds.push(item?.course_assessment_id);
    });

    const LOs = await dispatch(
      getLOForAssessment({ ids: assessmentIds?.toString() })
    );

    const outcomes = {};
    if (LOs?.payload?.data) {
      recievedData?.payload?.skill_document?.forEach(item => {
        if (LOs?.payload?.data[item?.course_assessment_id]) {
          if (!outcomes[item?.course_assessment_id]) {
            outcomes[item?.course_assessment_id] = {};
          }
          LOs?.payload?.data[item?.course_assessment_id]?.outcomes?.forEach(
            lo => {
              outcomes[item?.course_assessment_id] = {
                outcome: lo?.outcome,
                work_order_number: item?.work_order_number,
                start_date: item?.start_date,
                machine_serial_number: item?.machine_serial_number,
                location: item?.location,
                uploaded_learner_document: item?.uploaded_learner_document,
              };
            }
          );
        }
      });
    }
    setOutcomesData(outcomes);

    toggleModal();
  };

  const renderCourses = () => {
    if (loading) {
      return null;
    }

    if (trainingHistory?.history?.length === 0 || searchResults?.length === 0) {
      return (
        <NoData svg={"empty-folder-icon"} title={NoDataText} showCard={false} />
      );
    }

    return (
      <DataTable
        columns={trainingHistoryColumns(handleSkillCompletionClick)}
        data={searchResults}
        pagination
        fixedHeader
        striped
        onRowClicked={onRowClick}
      />
    );
  };

  const handleYearChange = (year: string) => {
    setClearData(true);
    setFyYear(year);
  };

  return (
    <>
      <CommonModal
        sizeTitle={SkillAssessment}
        modalBodyClassName=""
        isOpen={isModalOpen}
        toggle={toggleModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={async () => {
          toggleModal();
        }}
        primaryBtnText={Close}
      >
        <div>
          {outcomesData &&
            Object.keys(outcomesData)?.map((item: any, index) => {
              return (
                <Card
                  key={`skill-doc-${index}`}
                  className="mt-2 p-2 border border-grey"
                >
                  <CardBody>
                    <P className="fw-bold">{outcomesData[item]?.outcome}</P>
                    <div className="mt-4">
                      <Row>
                        <Col>
                          {
                            <DetailHeader
                              assign={Location}
                              name={outcomesData[item]?.location}
                            />
                          }
                        </Col>
                        <Col>
                          {
                            <DetailHeader
                              assign={MachineSerialNumber}
                              name={outcomesData[item]?.machine_serial_number}
                            />
                          }
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col>
                          {
                            <DetailHeader
                              assign={StartDate}
                              name={
                                outcomesData[item]?.start_date
                                  ? formatCustomDate(
                                      outcomesData[item]?.start_date
                                    )
                                  : "-"
                              }
                            />
                          }
                        </Col>
                        <Col>
                          {
                            <DetailHeader
                              assign={WorkOrderNumber}
                              name={outcomesData[item]?.work_order_number}
                            />
                          }
                        </Col>
                      </Row>
                    </div>

                    <Row className="mt-4">
                      {outcomesData[item]?.uploaded_learner_document?.map(
                        (file, ind) => (
                          <Col
                            lg="4"
                            className="mb-3 w-50"
                            key={`file-${index}-${ind}`}
                          >
                            <Badges className="w-100" color="light text-dark">
                              <div className="d-flex align-items-center">
                                <MUIIcons
                                  size={24}
                                  iconName="InsertDriveFileOutlined"
                                  className="mb-1"
                                />
                                <span className="text-start m-1 w-100 gap-1 p-1 ">
                                  {getEllipsedFileName(
                                    extractFileName(file),
                                    45
                                  )}
                                </span>
                                <MUIIcons
                                  className="pointer mb-1"
                                  onClick={() => handleAllFilesDownload([file])}
                                  size={24}
                                  iconName="FileDownloadOutlined"
                                />
                              </div>
                            </Badges>
                          </Col>
                        )
                      )}
                    </Row>
                  </CardBody>
                </Card>
              );
            })}
        </div>
      </CommonModal>
      <Card className="p-1 mb-3">
        <CardBody>
          <Row className="align-items-center gap-lg-0 gap-3">
            <Col xs="12" lg="5" className="d-flex justify-content-start gap-3">
              <span className="text-gray d-flex gap-1">
                {ILT} :
                <strong className="text-black">
                  {trainingHistory?.history_course_type?.ilt}
                </strong>
              </span>
              <span className="text-gray d-flex gap-1">
                {VILT} :
                <strong className="text-black">
                  {trainingHistory?.history_course_type?.vilt}
                </strong>
              </span>
              <span className="text-gray d-flex gap-1">
                {Webinar}:
                <strong className="text-black">
                  {trainingHistory?.history_course_type?.webinar}
                </strong>
              </span>
              <span className="text-gray d-flex gap-1">
                {WBT}:
                <strong className="text-black">
                  {trainingHistory?.history_course_type?.wbt}
                </strong>
              </span>
            </Col>
            <Col
              xs="12"
              lg="7"
              className="d-flex flex-column flex-lg-row justify-content-start justify-content-lg-end "
            >
              <div className="d-flex flex-row gap-3 align-items-center">
                <SearchBar
                  className="mb-2"
                  showSearch
                  onSearch={handleSearch}
                  clearOnProp={clearData}
                />
                <div className="mt-2">
                  <FilterSliderModal
                    dropdowns={[
                      {
                        label: CourseType,
                        key: "course_type",
                        tooltipText: `${Select} ${CourseType}`,
                        options: courseFilterDropdownList,
                        isMultiSelect: true,
                      },
                      {
                        label: Status,
                        key: "status",
                        tooltipText: `${Select} ${Status}`,
                        options: trainingHistoryFilterList,
                        isMultiSelect: true,
                      },
                    ]}
                    selectedFilterValues={selectedFilterValues}
                    defaultFilterValues={defaultFilterValues}
                    setSelectedFilterValues={setSelectedFilterValues}
                    setDefaultFilterValues={setDefaultFilterValues}
                    onDone={handleDone}
                    onClear={handleClear}
                  />
                </div>
                <YearNavigator
                  pastYears={20}
                  futureYears={20}
                  onYearChange={handleYearChange}
                />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>

      {renderCourses()}
    </>
  );
};
ILPTrainingHistory.propTypes = {
  userId: PropTypes.number,
};

export default ILPTrainingHistory;
